import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import Layout from './layout';
import { createTrialRoom, validateTrialRoom } from '../../../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { alertMsg } from '../../common/web/alert';
import { DEFAULT_LAODING_MSG } from '../../../utils/util';
import { Link } from 'react-router-dom';

function RoomSelection() {
    const simpleValidator = useRef(new SimpleReactValidator());
    const [isFinalStep, setFinalStep] = useState(false);
    const [isValidRequest, setIsValid] = useState(false);
    const [roomLoader, setRoomLoader] = useState(false);
    const [email, setEmail] = useState('');
    const [roomId, setRoomId] = useState('');
    const [room, setRoom] = useState('');
    const [, forceUpdate] = useState();
    const { search } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const params = new URLSearchParams(search);
        const id = params.get('id');
        if (id) {
            validateRoomId(id);
        } else {
            alertMsg({ msg: 'Something went wrong' }, redirectToPricing);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToPricing = () => {
        navigate('/pricing');
    };

    const validateRoomId = async (id) => {
        const response = await validateTrialRoom({ id });
        if (response.status === 200) {
            setRoomId(id);
            setIsValid(true);
        } else {
            alertMsg({ msg: response.data[0].msg }, redirectToPricing);
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        const newValue = value.replace(/[^A-Z0-9]+/ig, '');

        setRoom(newValue);
    };

    const createRoom = async (e) => {

        e.preventDefault();
        toast.dismiss();

        if (simpleValidator.current.allValid()) {
            setRoomLoader(true);
            const response = await createTrialRoom({ id: roomId, room });

            if (response.status === 200) {
                const data = response.data[0];
                setEmail(data.email);
                setRoomLoader(false);
                setFinalStep(true);
            } else {
                setRoomLoader(false);
                toast.error(response.data[0].msg, {
                    position: 'top-center',
                });
            }
        } else {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        }
    };

    const finalStep = () => {

        const roomUrl = `${window.location.origin}/${room}`;
        return (
            <div className="col-md-6 offset-md-3 createwrap">

                <div className="row">
                    <div className="col-md-12 dialogue-bg white-dialogue-box pt-0">
                        <div className="col-md-10 offset-md-1 create-pages">
                            <h2>Your Meetn Room is Ready!</h2>

                            <div className="form-group row">
                                <label className="col-md-12 justify-content-end p-0 ">
                                    You can now use your Meetn {room}! Just click on the room link below to visit your {room}.
                                </label>
                                {
                                    <label className="col-md-12 justify-content-end p-0 ">
                                        <Link to={`/${room}`} target="_blank" className="text-green">{roomUrl}</Link>
                                    </label>
                                }
                                <label className="col-md-12 justify-content-end p-0 ">
                                    Important: When you enter your room, if you want host/admin/owner privileges,
                                    you will have to log in using your email address and password.
                                </label>
                                <label className="col-md-12 justify-content-end p-0 ">
                                    Look for the "Log-in" link on your room page
                                </label>

                            </div>
                            <div className="form-group row">
                                <label className="col-md-12 justify-content-end p-0 ">
                                    If you ever need help or training, visit  <Link to="/support" target="_blank" className="text-green">{`${window.location.origin}/support`}</Link>. The video-training
                                    library, knowledgebase and FAQ are available 24x7 and live support is available
                                    M-F 9-5 U.S. mountain-time.
                                </label>
                                <label className="col-md-12 justify-content-end p-0 ">
                                    Note: An email with this information was sent to {email}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (<>
        <Layout>

            <div className="body-content page-content topalign createstep2">
                <div className="container">
                    <div className="row">
                        {
                            isFinalStep ? finalStep() : <div className={`col-md-6 offset-md-3 createwrap ${!isValidRequest ? 'loader_effect' : ''}`}>
                                <div className="row">
                                    <div className="col-md-12 dialogue-bg white-dialogue-box pt-0">

                                        <form className="col-md-10 offset-md-1 create-pages" onSubmit={(e) => createRoom(e)}>
                                            <div className="row">
                                                <h2>Room Name Selection</h2>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-12 justify-content-end p-0 ">
                                                    You can choose a personalized name for your room. This is the
                                                    name guests will see in your room link. For example, if your name is David and if
                                                    that room name is available, your room link would be meetn.com/david.
                                                </label>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-12 justify-content-end p-0 ">
                                                    Room names can include letters and numbers. Spaces and special characters are not allowed. Capitalization does not matter.
                                                </label>
                                            </div>
                                            <h4>Type Your Desired Room Name and click 'Next'</h4>
                                            <div className="form-group row">
                                                <div className="col-md-7 pl-0">
                                                    <label className="">Room Name</label>
                                                    <input spellCheck="false" type="text" name="room" minLength="2" maxLength="50" className="form-control" value={room} onChange={(e) => handleChange(e)} />
                                                    {simpleValidator.current.message('room', room, 'required|min:2|max:50', { messages: { default: 'Room field is required.' } })}
                                                </div>
                                            </div>

                                            <div className="form-group mt-3 row buttonwrap">
                                                <button type="submit" disabled={roomLoader} className="btn btn-green abso-btn pull-right">{roomLoader ? DEFAULT_LAODING_MSG : 'Next'}
                                                    <i className="icon-arrow-right-circle icons" />
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </Layout>

    </>);
}

export default RoomSelection