/* eslint-disable react-native/no-inline-styles */
import React from 'react';

function ProgressBar() {

    return (
        <div className="progress">
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }} />
        </div>
    );
}

export default ProgressBar