import React, {useRef, useState} from 'react';

import {CommonModal} from '../../../../common/web';
import SimpleReactValidator from 'simple-react-validator';
import {useSelector} from 'react-redux';
import {
  addRoomConfirm,
  addRoomProcess,
  updateRoomPlanConfirm,
  updateRoomPlanProcess,
} from '../../../../../utils/socket';
import {toast} from 'react-toastify';
import swal from 'sweetalert';
import {test_mode_id} from '../../../../../utils/util';
import {alertMsg} from '../../../../common/web/alert';

const UpdateRoomPlanModal = ({
  closeModal,
  roomDetail,
  free,
  roomListHandler,
}) => {
  const [state, setState] = useState({plan: 'pro'});
  const [, forceUpdate] = useState();
  const [loader, setLoader] = useState(false);
  const {localUser} = useSelector(state => state.conf);
  const {room} = roomDetail;
  const {token} = localUser;

  useState(() => {
    if (roomDetail?.plan) {
      setState({...state, plan: roomDetail?.plan});
    }
  }, [roomDetail]);

  const setStateHandler = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const updateRoomConfirmHandler = e => {
    e.preventDefault();
    const {plan} = state;
    setLoader(true);
    updateRoomPlanConfirm({plan, token, room}).then(res => {
      if (res.status === 200) {
        closeModal();
        setLoader(false);
        swal({
          title: res.data[0].msg,
          closeOnClickOutside: false,
          buttons: ['Cancel', 'Confirm'],
          dangerMode: true,
        }).then(Confirm => {
          if (Confirm) {
            updateRoomProcessHandler();
          }
        });
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoader(false);
      }
    });
  };

  const updateRoomProcessHandler = () => {
    const {plan} = state;
    updateRoomPlanProcess({
      room,
      plan,
      token,
      test_mode_id: test_mode_id,
    }).then(res => {
      if (res.status == 200) {
        alertMsg({msg: res.data[0].msg}, roomListHandler);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };
  const updateRoomBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <form name="addRoomModelForm">
              <div className="modal_min_height--sm">
                <div className="form-group row mt-20">
                  <label className="col-md-12 col-12 pr-0">Room Type</label>

                  <div className="col-sm-12 col-12">
                    {free && (
                      <label className="radiowrap float-left mb-0">
                        Free
                        <input
                          spellCheck="false"
                          type="radio"
                          name="plan"
                          value="free"
                          checked={state.plan === 'free'}
                          onChange={e => setStateHandler(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    )}

                    <label className="radiowrap float-left mb-0">
                      Pro
                      <input
                        spellCheck="false"
                        type="radio"
                        name="plan"
                        value="pro"
                        checked={state.plan === 'pro'}
                        onChange={e => setStateHandler(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radiowrap float-left mb-0">
                      Ultimate
                      <input
                        spellCheck="false"
                        type="radio"
                        name="plan"
                        value="ultimate"
                        checked={state.plan === 'ultimate'}
                        onChange={e => setStateHandler(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 text-right">
                  <button
                    type="button"
                    data-toggle="modal"
                    onClick={e => closeModal(e)}
                    className="btn-link">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-green btn-mini float-right"
                    onClick={e => updateRoomConfirmHandler(e)}>
                    {loader ? 'Please Wait' : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };

  const updateRoomModalObject = {
    body: updateRoomBody,
    classNameModal: 'fade smallmodalwrap addRoomUserpanel',
    show: true,
    headingSize: 'h5',
    classNameTitle: 'ng-binding',
    classNameDialog: 'smallmodal',
  };

  return <CommonModal {...updateRoomModalObject} />;
};

export default UpdateRoomPlanModal;
