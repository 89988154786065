/* eslint-disable no-void */
import React, { useState } from 'react';
import { CommonModal } from '../../../../../common/web';
import { Capitalize } from '../../../../../../utils/functions';
import { validateFbAppInstalled } from '../../../../../../utils/socket';
import FacebookGroupPermissionModal from './facebookGroupPermissionModal';

function FacebookGroupModal({ fbLoader, fb_groups_list, handleReturn, handleAddFacebookDestination }) {
    const [showPermission, showPermissionModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState('');

    const checkAppInstallation = (id, name) => {

        validateFbAppInstalled({ id }).then((response) => {
            if (response.status === 200) {
                handleAddFacebookDestination(id, name);
            } else {
                setSelectedGroup(id);
                showPermissionModal(true);
            }
        });
    };
    const facebookGroupBody = () => {
        return (
            <>
                {
                    fbLoader ? <><div className="row modal_min_height">
                        <a href={void 0} className="loader_icon">
                            <img src="assets/images/refresh-loader.gif" />
                        </a>
                    </div> </> : <>
                        <div className="row modal_min_height">
                            <div className="col-md-12">
                                {fb_groups_list.length > 0 ? (
                                    <p>Choose the group you would like to connect to Meetn.</p>
                                ) : (
                                    <p>We couldn't find any Facebook group.</p>
                                )}
                                <ul>
                                    {fb_groups_list.length > 0 &&
                                        fb_groups_list.map(fb => {
                                            return (
                                                <>
                                                    <li>
                                                        <a href={void (0)} onClick={() => checkAppInstallation(fb.page_id, fb.page_name)}>
                                                            <div className="profile__initials">
                                                                {Capitalize(fb.page_name[0])}
                                                            </div>
                                                            <div className="wrap-content">
                                                                <span className="heading">{fb.page_name}</span>
                                                                <span className="content">group</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </>
                                            );
                                        })}
                                </ul>
                                {
                                    <a className="tooltip_custom d-inline-flex">
                                        <u>Why is one of my groups not listed?</u>
                                        <div className="tooltiptext tooltiptext--right tooltiptext--lg tooltiptext--lg-right">
                                            <h6 className="semiBold">
                                                What if your group is not listed?
                                            </h6>
                                            There are a few reasons why your Facebook group may not show
                                            up on MEETN.
                                            <br />
                                            <br />
                                            <span className="semiBold">You're not an admin</span>
                                            <br />
                                            Your profile must be an admin of a group to connect it to
                                            MEETN. If your group is owned by a page, you'll want to make
                                            sure your profile is also an admin.
                                            <br />
                                            <br />
                                            <span className="semiBold">
                                                You're logged in to the wrong Facebook profile
                                            </span>
                                            <br />
                                            On facebook.com, you must be logged in to the profile that is
                                            an admin of the group you're trying to connect.
                                            <br />
                                            <br />
                                            <span className="semiBold">
                                                You haven't granted permissions for the specific group
                                            </span>
                                            <br />
                                            When first connecting to Facebook, MEETN asks for a number of
                                            permissions. You may have clicked “Choose what you allow”,
                                            which lets you choose which groups MEETN can access. If you
                                            turn off any groups, MEETN won't have access to them or any
                                            new groups you join AFTER connecting to MEETN. In this case,
                                            you'll need to reauthorize Facebook.
                                        </div>
                                    </a>
                                }
                                <br />
                                <p>
                                    Want to use a different account? Log in to that account on
                                    facebook.com then try again.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            {
                                <div className="col-md-12 text-right">
                                    <a
                                        href={void (0)}
                                        className="btn-green btn-mini mr-0"
                                        data-dismiss="modal"
                                        onClick={e => handleReturn(false)}>
                                        Return
                                    </a>
                                </div>
                            }
                        </div>
                    </>
                }

            </>
        );
    };

    const facebookGroupObject = {
        heading: 'Choose a Facebook group',
        body: facebookGroupBody,
        show: true,
        classNameBody: 'overflow_visible',
        classNameModal: 'fade facebook-list-modal',
        headingSize: 'h5',
    };
    return <>
        <CommonModal {...facebookGroupObject} />
        {
            showPermission && <FacebookGroupPermissionModal selectedGroup={selectedGroup} handleReturn={showPermissionModal} />
        }
    </>;
}

export default FacebookGroupModal;
