import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setPage} from '../../../reduxStore/confSlice';
import {CommonModal} from '../../common/web';

const WaitingRoom = () => {
  const dispatch = useDispatch();
  const {isRequestDeclined, waitingRoomModal} = useSelector(
    state => state.conf,
  );

  const manageRedirect = e => {
    e.preventDefault();
    window.location.href = '/';
  };

  const handleLogin = e => {
    e.preventDefault();
    dispatch(setPage('Login'));
  };

  const joinSoonBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12 text-center modal_min_height--sm d-flex flex-column justify-content-center">
            <h4 className="mb-2">
              {isRequestDeclined
                ? 'Your request to enter the room was declined.'
                : 'You will join as soon as someone lets you in.'}
            </h4>
            {!isRequestDeclined && (
              <div className="form-group">
                <small>
                  If you are the host, please&nbsp;
                  <a href="#" onClick={handleLogin}>
                    &nbsp;log in
                  </a>
                </small>
              </div>
            )}
          </div>
          <div className="col-md-12 mt-3">
            {isRequestDeclined ? (
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-green btn-mini float-right"
                  onClick={manageRedirect}>
                  {' '}
                  Exit
                </button>
              </div>
            ) : (
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-link btn-mini float-right"
                  onClick={manageRedirect}>
                  {' '}
                  Exit Meetn
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const waitingRoomObject = {
    body: joinSoonBody,
    show: true,
    classNameDialog: 'modal-width-sm',
  };
  return (
    <>{waitingRoomModal === true && <CommonModal {...waitingRoomObject} />};</>
  );
};

export default WaitingRoom;
