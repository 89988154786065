/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ConferenceHeader from './conferenceHeader';
import Connecting from './connecting';
import { LoaderEffect } from '../../common';
import LargeEventView from './largeEventView';
import { END_MEETN_MSG, LEM_ERROR_MSG } from '../../../utils/util';
import { alertMsg } from '../../common/web/alert';
import { getLemSignedUrl } from '../../../utils/socket';

const LargeEvent = () => {
    const { localUser, conferenceConnecting, roomDetail } = useSelector(state => state.conf);
    const [loaderEffect, setloaderEffect] = useState(false);
    const [lem_signed_url, setLemSignedUrl] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { room } = roomDetail;

    useEffect(() => {
        getLemSignedUrl({ room }).then(response => {
            let url = response.data[0].lem_signed_url;
            setLemSignedUrl(url);
            console.log('getLemSignedUrl', url);
        });
    }, []);
    useEffect(() => {
        console.log("==>>", roomDetail?.lem_mode, roomDetail?.end_meetn, localUser?.role)
        if (roomDetail?.end_meetn && localUser?.role === 'guest') {
            setloaderEffect(true);
            alertMsg({ msg: END_MEETN_MSG }, redirectToHome);
        } else if (roomDetail?.lem_mode === false) {
            setloaderEffect(true);
            alertMsg({ msg: LEM_ERROR_MSG }, redirectToHome);
        }
    }, [roomDetail?.end_meetn, roomDetail?.lem_mode]);

    const redirectToHome = () => {
        navigate('/');
    };

    return loaderEffect ? (
        <LoaderEffect />
    ) : (
        <>
            <ToastContainer
                hideProgressBar={true}
                draggable={false}
                autoClose={3000}
                closeOnClick={false}
            />
            <div className="conference-wrap">
                <ConferenceHeader />
                {conferenceConnecting ? <Connecting /> :
                    <>{lem_signed_url && <LargeEventView lem_signed_url={lem_signed_url} />}</>}
            </div>
        </>
    );
};
export default LargeEvent;
