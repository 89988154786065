import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {setLoginModal} from '../../../reduxStore/mainSlice';
import LogInModal from './modals/loginModal';
import ForgotPassword from './modals/forgotPassword';

const OuterHeader = props => {
  const [modal, setModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const {loggedInUserData} = useSelector(state => state.persist);
  const {loginModal, forgotModal} = useSelector(state => state.main);

  return (
    <>
      <div id="main_header" className="outerhead">
        <nav className="navbar navbar-expand-lg navbar-dark outer-nav">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src="/assets/images/logo.png" className="img-fluid" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ml-auto align-items-center">
                {/* <li className="nav-item">
                  <a
                    className={`nav-link ${
                      pathname === '/' ? 'text-green' : ''
                    }`}
                    href=""
                    onClick={e => {
                      e.preventDefault();
                      navigate('/');
                    }}>
                    Home
                  </a>
                </li> */}

                <li className="nav-item">
                  <a
                    className={`nav-link text-green`}
                    onClick={() => dispatch(setLoginModal(true))}>
                    {loggedInUserData &&
                      Object.keys(loggedInUserData).length > 0 && (
                        <small>Logged in as</small>
                      )}
                    {loggedInUserData &&
                    Object.keys(loggedInUserData).length > 0
                      ? loggedInUserData.name
                      : 'Log In'}
                  </a>
                </li>

                {/* <li className="nav-item">
                  <a className={`nav-link ${pathname === "/features" ? "text-green" : "" }`} href="/features">
                    Features
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      pathname === '/pricing' ? 'text-green' : ''
                    }`}
                    href=""
                    onClick={e => {
                      e.preventDefault();
                      navigate('/pricing');
                    }}>
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      pathname === '/support' ? 'text-green' : ''
                    }`}
                    href=""
                    onClick={e => {
                      e.preventDefault();
                      navigate('/support');
                    }}>
                    Support
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {loginModal && <LogInModal />}
      {forgotModal && <ForgotPassword />}
    </>
  );
};

export default OuterHeader;
