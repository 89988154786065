import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setCurrentPage} from '../../../reduxStore/confSlice';

function Pagination_Custom() {
  const dispatch = useDispatch();
  const [pagingContainer, setPagingContainer] = useState(null);
  const [user, setUser] = useState({});
  const {currrentPage, numPages} = useSelector(state => state.conf);

  useEffect(() => {
    setPagingContainer(getPagingContainer(currrentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currrentPage, numPages]);

  const previousPage = e => {
    e.preventDefault();
    if (currrentPage !== 1) {
      dispatch(setCurrentPage(currrentPage - 1));
    }
  };
  const nextPage = e => {
    e.preventDefault();
    if (currrentPage < numPages) {
      dispatch(setCurrentPage(currrentPage + 1));
    }
  };
  const getPagingContainer = _currrentPage => {
    let left_active = null;
    let right_active = null;
    if (_currrentPage > 1) {
      left_active = 'text-green';
    }
    if (_currrentPage < numPages) {
      right_active = 'text-green';
    }
    if (numPages <= 1) {
      return null;
    } else {
      return (
        <div class="pagination_custom">
          <div class={`pagi-left ${left_active}`} onClick={previousPage}>
            <i class="fas fa-chevron-left"></i>
          </div>
          <div class="pagi-count">
            {_currrentPage} of {numPages}
          </div>
          <div class={`pagi-left ${right_active}`} onClick={nextPage}>
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
      );
    }
  };

  return <>{pagingContainer}</>;
}

export default Pagination_Custom;
