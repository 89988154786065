import React, {useRef, useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import {useDispatch, useSelector} from 'react-redux';
import {setForgotModal, setLoginModal} from '../../../../reduxStore/mainSlice';
import {CommonModal} from '../../../common/web';
import SimpleReactValidator from 'simple-react-validator';
import {toast} from 'react-toastify';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import {getUserRooms, userLogin} from '../../../../utils/api';
import {recentUsersList} from '../../../../utils/functions';
import {
  setLoggedInUserData,
  setLoggedInUserRooms,
} from '../../../../reduxStore/persistSlice';

function LogInModal() {
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    password: '',
    room: '',
  });
  const [loader, setLoader] = useState(false);
  const [, forceUpdate] = useState();
  const {loggedInUserData, loggedInUserRooms} = useSelector(
    state => state.persist,
  );
  const setStateHandler = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (loggedInUserData && Object.keys(loggedInUserData).length > 0) {
      getUserRooms({
        email: loggedInUserData?.email,
        token: loggedInUserData?.token,
      }).then(async res => {
        if (res.status !== 500) {
          if (res.status === 200) {
            dispatch(setLoggedInUserRooms(res?.data?.room));
          } else {
            toast.error(res.data[0].msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        } else {
          swal({
            title:
              'Something went wrong, unable to connect to meetn, please check your network.',
            closeOnClickOutside: false,
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: false,
          }).then(() => {
            navigate('/');
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = e => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoader(true);
      userLogin({
        email: state.email,
        password: state.password,
      }).then(async res => {
        console.log('userLogin res', res);
        if (res.status !== 500) {
          if (res.status === 200) {
            dispatch(setLoggedInUserData(res?.data?.login));
            dispatch(setLoggedInUserRooms(res?.data?.room));
            dispatch(recentUsersList(res?.data?.login));
          } else {
            toast.error(res.data[0].msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoader(false);
          }
        } else {
          swal({
            title:
              'Something went wrong, unable to connect to meetn, please check your network.',
            closeOnClickOutside: false,
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: false,
          }).then(() => {
            navigate('/');
          });
        }
      });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };

  const logoutUser = () => {
    dispatch(setLoggedInUserData({}));
    dispatch(setLoggedInUserRooms([]));
    dispatch(setLoginModal(false));
  };

  const enterOnRoom = (e, room) => {
    navigate(`/${room !== '' ? room : state.room}`);
  };

  const loginBody = () => {
    return (
      <>
        <form onSubmit={e => submitForm(e)}>
          <div className="modal_min_height--sm">
            <div class="form-group">
              <label class="">Email</label>
              <input
                spellCheck="false"
                type="email"
                name="email"
                value={state.email}
                onChange={e => setStateHandler(e)}
                maxLength="50"
                required=""
                class="form-control"
              />
              {validator.current.message(
                'email',
                state.email,
                'required|email',
                {
                  messages: {
                    default: 'Email field is required.',
                    email: 'Email must be a valid email address.',
                  },
                },
              )}
            </div>
            <div class="form-group">
              <label class="">Password</label>
              <input
                spellCheck="false"
                type="password"
                name="password"
                maxLength="20"
                required=""
                value={state.password}
                onChange={e => setStateHandler(e)}
                class="form-control"
              />
              {validator.current.message(
                'password',
                state.password,
                'required',
                {
                  messages: {default: 'Password field is required.'},
                },
              )}
            </div>
            <div class="form-group text-right mb-0">
              <a
                className="forgotP text-green"
                onClick={() => {
                  dispatch(setForgotModal(true));
                  dispatch(setLoginModal(false));
                }}>
                Forgot Password?
              </a>
            </div>
          </div>
          <div class="form-group text-right mt-3 modal-footer-container mb-0">
            <button
              type="button"
              class="btn-link"
              data-dismiss="modal"
              onClick={() => dispatch(setLoginModal(false))}>
              Close
            </button>
            <button type="submit" class="btn btn-green abso-btn">
              {loader ? 'Please Wait...' : 'Log in'}
            </button>
          </div>
        </form>
      </>
    );
  };

  const roomBody = () => {
    return (
      <>
        {loggedInUserRooms && loggedInUserRooms.length > 0 && (
          <div className="form-group mb-3">
            <small>Rooms:</small>&nbsp;
            <small>
              {loggedInUserRooms.map((room, k) => {
                return (
                  <>
                    <a
                      href="javascript:void(0)"
                      onClick={e => enterOnRoom(e, room)}>
                      {room}
                    </a>
                    ,{' '}
                  </>
                );
              })}
            </small>
          </div>
        )}
        <form onSubmit={e => enterOnRoom(e, '')}>
          <div class="form-group another-room">
            <label class="">
              {loggedInUserRooms && loggedInUserRooms.length > 0
                ? 'Or, Type a Room Name'
                : 'Type a Room Name'}{' '}
            </label>
            <input
              spellCheck="false"
              type="text"
              name="room"
              value={state.room}
              onChange={e => setStateHandler(e)}
              maxLength="50"
              required=""
              class="form-control"
            />
          </div>

          <div class="form-group text-right mt-3 d-flex align-items-center justify-content-between flex-row">
            <a
              href="javascript:void(0)"
              onClick={e => logoutUser(e)}
              className="float-left">
              Logout
            </a>
            <div className="d-flex align-items-center">
              <button
                type="button"
                class="btn-link"
                onClick={() => dispatch(setLoginModal(false))}>
                Cancel
              </button>
              <button type="submit" class="btn btn-green abso-btn">
                Enter Room
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const loginObject = {
    heading:
      loggedInUserData && Object.keys(loggedInUserData).length > 0
        ? 'Which meetn room would you like to enter?'
        : 'Log in to your account',
    body:
      loggedInUserData && Object.keys(loggedInUserData).length > 0
        ? roomBody
        : loginBody,
    show: true,
    classNameDialog: 'modal-sm',
    headingSize: 'h5',
  };

  return (
    <>
      <CommonModal {...loginObject} />
    </>
  );
}

export default LogInModal;
