import React from 'react';

const LoaderEffect = () => {

  return (
    <>
      <span className="loader_effect">
        <nav
          id="main_header"
          className="navbar navbar-expand-lg navbar-dark custom-nav join-nav">
          <a href="/" className="navbar-brand">
            <img src="assets/images/logo.png" alt="" />
          </a>
        </nav>
        <div className="body-content page-content updatedWrap joinFlowWrap">
          <div className="container-fluid">
            <div className="row updatedWraprow">
              <div className="col-md-6 col-6 dark-bg imgwrap">
                <h4>Welcome to Meetn</h4>
              </div>
              <div className="col-md-6 col-6 light-bg pl-15">
                <h2>Welcome to the Meetn!</h2>
                <form className="mt-3">
                  <div className="form-group">
                    <input
                      spellCheck="false"
                      type="text"
                      placeholder="Name"
                      name="name"
                      maxLength="50"
                      className="form-control"
                      value=""
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <input
                      spellCheck="false"
                      type="text"
                      placeholder="Title (optional)"
                      name="bio"
                      maxLength="100"
                      className="form-control"
                      value=""
                      readOnly
                    />
                  </div>
                  <div className="form-group mt-4">
                    <button type="submit" className="btn btn-green abso-btn">
                      Continue
                      <i className="icon-arrow-right-circle icons" />
                    </button>
                  </div>
                  <div className="form-group mt-4">
                    <small>
                      Have an account?&nbsp;
                      <a>&nbsp;Log In</a>
                    </small>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    </>
  );
};
export default LoaderEffect;
