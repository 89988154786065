import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ExtensionWebpage from './extensionWebpage';
import ExtensionButton from './extensionButton';
import ExtensionCTA from './extensionCTA';
import ExtensionTimer from './extensionTimer';

const ExtensionHandler = () => {
  const {
    extensionDetails,
    screenDimension,
    attendeesClick,
    chatClick,
    widgetClick,
  } = useSelector(state => state.conf);
  const {screenHeight, screenWidth} = screenDimension;

  let navBarHeight = 0,
    sideBarWidth = 0;
  navBarHeight = document.getElementById('conference-nav-bar')?.clientHeight;
  sideBarWidth = document.getElementById('conferecne-sidebar')?.clientWidth;

  const sidebar = attendeesClick || chatClick || widgetClick ? true : false;

  const handlerStyle = {
    height: `${
      (screenHeight *
        (extensionDetails.height > 100 || extensionDetails.height < 0
          ? 100
          : extensionDetails.height)) /
      100
    }px`,
    width: `${
      (screenWidth *
        (extensionDetails.width > 100 || extensionDetails.width < 0
          ? 100
          : extensionDetails.width)) /
      100
    }px`,
    position: 'absolute',
    top: `${
      navBarHeight +
      (screenHeight *
        (extensionDetails.top > 100 || extensionDetails.top < 0
          ? 100
          : extensionDetails.top)) /
        100
    }px`,
    left: `${
      (screenWidth *
        (extensionDetails.left > 100 || extensionDetails.left < 0
          ? 100
          : extensionDetails.left)) /
      100
    }px`,
    opacity: 1,
    zIndex: 1,
  };

  return (
    <div style={handlerStyle} className={sidebar ? 'extension-handler' : ''}>
      {extensionDetails?.extensionid === 26999 ? (
        <ExtensionButton extensionDetails={extensionDetails} />
      ) : extensionDetails?.extensionid === 66199 ? (
        <ExtensionCTA extensionDetails={extensionDetails} />
      ) : extensionDetails?.extensionid === 36999 ? (
        <ExtensionTimer extensionDetails={extensionDetails} />
      ) : (
        <ExtensionWebpage extensionDetails={extensionDetails} />
      )}
    </div>
  );
};

export default ExtensionHandler;
