import { createSlice } from '@reduxjs/toolkit';
import { viewType } from '../utils/util';
export const confSlice = createSlice({
  name: 'conf',
  initialState: {
    noiseSuppression: false,
    isNoiseSuppressionEnabled: false,
    localAudioTrack: null,
    localVideoTrack: null,
    localDesktopVideoTrack: null,
    localDesktopAudioTrack: null,
    mediaPermissionPrompt: false,
    screenShared: false,
    view: viewType.gallery,
    lastView: viewType.gallery,
    maxVideos: 9,
    dominantSpeaker: null,
    numPages: 1,
    currrentPage: 1,
    screenSharedJid: null,
    prevScreenSharedJid: null,
    conferenceFailed: false,
    conferenceLeft: false,
    ///
    localUser: {},
    roomDetail: {},
    guestDetail: {},
    virtualBackgroundOptions: {},
    ///
    localParticipantData: null,
    remoteParticipantIds: [],
    room: undefined,
    ///
    participantsJID: [],
    participantsList: [],
    participantsUnmuted: [],
    participantsGuest: [],
    participantsHost: [],
    participantsWaiting: [],
    participantsHandRaised: [],
    participantsScreenShared: [],
    ///
    localParticipantDetail: {},
    gallery_participants: '',
    dominant: '',
    selectLanguage: 'en',
    isBackButtonShow: false,
    clickForgotPassword: false,
    chatMessage: [],
    featuredMsg: {},
    currentRoom: '',
    muteAllLoader: false,
    unmuteAllLoader: false,
    myRole: '',
    openLoginModel: false,
    orientation: 'PORTRAIT',
    chatClick: false,
    attendeesClick: false,
    guestsUnmuteLoader: false,
    recordingViewValue: 'active',
    extensionList: [],
    isRequestDeclined: false,
    mutedAttendeesLoader: false,
    waitingStatusLoader: false,
    breakoutRoomJoined: false,
    openRecordingModal: false,
    mic: false,
    camera: false,
    speaker: true,
    raise: false,
    waitingRoomLoaders: {
      accept: [],
      decline: [],
    },
    waitingRoomPopup: false,
    waitingEnabled: false,
    exitModal: false,
    feedbackPopUp: false,
    participantsLoaders: {
      audio: [],
      video: [],
      hand_raised: [],
    },
    failedCameraAlert: false,
    conferenceConnecting: false,
    muteUnmutePermission: false,
    openStreamManageModal: false,
    mouseOverUser: '',
    openUpgradePlanModal: false,
    waitingRoomModal: false,
    hostRequiredModal: false,
    videoPlayModal: false,
    unreadMessageCount: 0,
    showMenu: false,
    showMenuType: '',
    showRecording: false,
    showStreaming: false,
    shareScreen: false,
    openStopRecordingModal: false,
    isRecordingStart: false,
    recordingDuration: 0,
    recordingStopLoader: false,
    recordingStartLoader: false,
    streamingStarted: false,
    startStreamingLoader: false,
    stopStreamingLoader: false,
    addDestinationType: 'custom',
    ratingPopUp: false,
    socketConnected: false,
    recordingData: {},
    recordingName: '',
    streamingTitleDescription: {
      title: '',
      description: '',
      display_social_media_chat: '',
    },
    streamingDestinations: [],
    timeCal: 0,
    intval: null,
    hours: 0,
    minutes: 0,
    seconds: 0,
    streamingData: {},
    streamingDuration: 0,
    destinationsList: [],
    streamingErrors: {},
    destinationListLoader: false,
    maxDestination: false,
    withoutChoosingDestination: false,
    streamTimeCal: 0,
    streamIntval: null,
    streamHours: 0,
    streamMinutes: 0,
    streamSeconds: 0,
    screenDimension: {},
    windowDimension: {},
    page: 'JoinFlow',
    previousRouteStep: '',
    loginAsGuest: false,
    audioInputList: [],
    videoInputList: [],
    audioOutputList: [],
    cameraDevice: null,
    micDevice: null,
    speakerDevice: null,
    tracks: [],
    participants: {},
    hasAudioPermission: false,
    hasVideoPermission: false,
    hasAudioEnable: false,
    hasVideoEnable: false,
    joinWithVideo: false,
    loaderEffect: false,
    waitingTitleInterval: null,
    showInviteModal: false,
    showAvSettingModal: false,
    showEditProfileModal: false,
    showPhoneCommandModal: false,
    showWebinarSettingModal: false,
    showRoomSettingModal: false,
    showMeetingModal: false,
    showRoomPermissionModal: false,
    showShareWidgetModal: false,
    showManageRoomModal: false,
    showInvoiceModal: false,
    showBillingInfoModal: false,
    userListForRoomPermission: [],
    eventsList: [],
    chatBlockUsers: [],

    trial: false,
    billing: false,

    //extensions
    extensionShow: false,
    extensionDetails: {},
    widgetClick: false,

    kickedOutRequest: false,
    localParticipantKicked: false,
    lemAttendeesCount: 0,
  },

  reducers: {
    SetLocalParticipantKicked: (state, action) => {
      state.localParticipantKicked = action.payload;
    },
    setFailedCameraAlert: (state, action) => {
      state.failedCameraAlert = action.payload;
    },
    setConferenceLeft: (state, action) => {
      state.conferenceLeft = action.payload;
    },
    setConferenceFailed: (state, action) => {
      state.conferenceFailed = action.payload;
    },
    setNoiseSuppression: (state, action) => {
      state.noiseSuppression = action.payload;
    },
    setVirtualBackgroundOptions: (state, action) => {
      state.virtualBackgroundOptions = action.payload;
    },
    setNoiseSuppressionEnabledState: (state, action) => {
      state.isNoiseSuppressionEnabled = action.payload;
    },
    setMediaPermissionPrompt: (state, action) => {
      state.mediaPermissionPrompt = action.payload;
    },
    addLocalAudioTrack: (state, action) => {
      const tmpTrack = { ...state.localAudioTrack };
      let track = action.payload.track;
      let device = action.payload.device;
      if (Object.keys(tmpTrack).length > 0) {
        tmpTrack.jitsiTrack.dispose();
      }
      //console.log('addLocalAudioTrack', action.payload, track);

      if (track !== null) {
        let trackdata = {
          muted: track.isMuted(),
          type: track.type,
          jitsiTrack: track,
          device: device,
        };
        state.localAudioTrack = trackdata;
      } else {
        state.localAudioTrack = null;
      }
    },
    updateLocalAudioTrackMute: (state, action) => {
      state.localAudioTrack.muted = action.payload;
    },
    removeLocalAudioTrack: state => {
      state.localAudioTrack = null;
    },

    addLocalVideoTrack: (state, action) => {
      const tmpTrack = { ...state.localVideoTrack };
      let track = action.payload.track;
      let device = action.payload.device;
      console.log(
        'addLocalVideoTrack tmpTrack track',
        tmpTrack,
        track,
        Object.keys(tmpTrack).length,
      );
      if (Object.keys(tmpTrack).length > 0) {
        console.log('addLocalVideoTrack disposing track', tmpTrack);
        tmpTrack.jitsiTrack.dispose();
      }
      if (track !== null) {
        let isReceivingData = true;
        let noDataFromSourceNotificationInfo;
        let trackdata = {
          trackID: track.getId(),
          isReceivingData,
          mediaType: track.videoType,
          muted: track.isMuted(),
          noDataFromSourceNotificationInfo,
          participantId: track.getParticipantId(),
          videoStarted: false,
          type: track.type,
          jitsiTrack: track,
          device: device,
        };
        state.camera = track.isMuted();
        state.localVideoTrack = trackdata;
      } else {
        state.camera = false;
        state.localVideoTrack = null;
      }
    },
    updateLocalVideoTrackMute: (state, action) => {
      state.localVideoTrack.muted = action.payload;
    },
    removeLocalVideoTrack: state => {
      state.localVideoTrack = null;
    },
    addLocalDesktopVideoTrack: (state, action) => {
      //console.log('addLocalDesktopVideoTrack', action.payload);
      let track = action.payload;
      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let trackdata = {
        trackID: track.getId(),
        isReceivingData,
        mediaType: track.videoType,
        muted: track.isMuted(),
        noDataFromSourceNotificationInfo,
        participantId: track.getParticipantId(),
        videoStarted: false,
        type: track.type,
        jitsiTrack: track,
      };
      state.localDesktopVideoTrack = trackdata;
    },
    addLocalDesktopAudioTrack: async (state, action) => {
      const tmpTrack = { ...state.localDesktopAudioTrack };
      let track = action.payload;
      if (tmpTrack.length > 0) {
        //console.log('addLocalDesktopAudioTrack Old track dispose', tmpTrack);
        await tmpTrack.dispose();
      }
      //console.log('addLocalDesktopAudioTrack new track added', track);
      state.localDesktopAudioTrack = track;
    },
    muteLocalDesktopVideoTrack: state => {
      state.localDesktopVideoTrack.muted = true;
    },
    setLocalParticipantData: (state, action) => {
      state.localParticipantData = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currrentPage = action.payload;
    },
    setNumPages: (state, action) => {
      state.numPages = action.payload;
    },
    setDominantSpeaker: (state, action) => {
      state.dominantSpeaker = action.payload;
    },
    ///
    setSocketConnected: (state, action) => {
      state.socketConnected = action.payload;
    },
    setParticipantsList: (state, action) => {
      let tmpParticipantsList = {};
      let tmpParticipantsJID = {};
      let tmpParticipantsUnmuted = {};
      let tmpParticipantsGuest = {};
      let tmpParticipantsHost = {};
      let tmpParticipantsWaiting = {};
      let tmpParticipantsHandRaised = {};
      let tmpparticipantsScreenShared = {};
      let screenSharedJid = state.screenSharedJid;

      if (Array.isArray(action.payload) && action.payload.length > 0) {
        action.payload.forEach(val => {
          if (!val?.is_hidden) {
            if (val.token_unique === state.localUser.token_unique) {
              state.localUser = val;
            } else {
              if (
                val.waiting_status === 'accepted' &&
                val.interaction_type === 'interactive'
              ) {
                tmpParticipantsJID[val.jid] = val;
              }
              tmpParticipantsList[val.token_unique] = val;

              if (val.role !== 'guest' && val.waiting_status === 'accepted') {
                tmpParticipantsHost[val.token_unique] = val;
              }

              if (val.role === 'guest' && val.waiting_status === 'accepted') {
                tmpParticipantsGuest[val.token_unique] = val;
                if (tmpParticipantsWaiting[val.token_unique]) {
                  delete tmpParticipantsWaiting[val.token_unique];
                }
              } else if (
                val.role === 'guest' &&
                val.waiting_status === 'pending'
              ) {
                tmpParticipantsWaiting[val.token_unique] = val;
              }

              if (val.hand_raised) {
                tmpParticipantsHandRaised[val.token_unique] = val;
              } else if (tmpParticipantsHandRaised[val.token_unique]) {
                delete tmpParticipantsHandRaised[val.token_unique];
              }

              if (val.audio && val.waiting_status === 'accepted') {
                tmpParticipantsUnmuted[val.jid] = val;
              } else if (tmpParticipantsUnmuted[val.jid]) {
                delete tmpParticipantsUnmuted[val.jid];
              }

              ///Presentation view code
              if (val.waiting_status === 'accepted' && val?.screenShare) {
                tmpparticipantsScreenShared[val.jid] = val.jid;
                screenSharedJid = val.jid;
              }

              ////////
            }
          }
        });
      }
      ///////////////

      //console.log(
      //   'tmpParticipantsList',
      //   tmpParticipantsList,
      //   'action',
      //   action.payload,
      // );
      state.participantsJID = tmpParticipantsJID;
      state.participantsList = tmpParticipantsList;
      state.participantsUnmuted = tmpParticipantsUnmuted;
      state.participantsGuest = tmpParticipantsGuest;
      state.participantsHost = tmpParticipantsHost;
      state.participantsWaiting = tmpParticipantsWaiting;
      state.participantsHandRaised = tmpParticipantsHandRaised;
      state.screenSharedJid = screenSharedJid;
      if (!state.participantsScreenShared[screenSharedJid]) {
        if (screenSharedJid) {
          state.view = viewType.presentation;
        }
      }
      state.participantsScreenShared = tmpparticipantsScreenShared;
    },
    updParticipantsList: (state, action) => {
      let tmpParticipantsJID = { ...state.participantsJID };
      let tmpParticipantsList = { ...state.participantsList };
      let tmpParticipantsUnmuted = { ...state.participantsUnmuted };
      let tmpParticipantsGuest = { ...state.participantsGuest };
      let tmpParticipantsHost = { ...state.participantsHost };
      let tmpParticipantsWaiting = { ...state.participantsWaiting };
      let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
      let tmpParticipantsHandRaised = { ...state.participantsHandRaised };
      //console.log('updParticipantsList', {...state.localUser});

      let val = action.payload;
      if (!val?.is_hidden) {
        if (val.token_unique === state.localUser.token_unique) {
          state.localUser = val;
        } else {
          let preScreenSharedValue = tmpParticipantsJID[val.jid]?.screenShare
            ? true
            : false;

          if (
            val.waiting_status === 'accepted' &&
            val.interaction_type === 'interactive'
          ) {
            tmpParticipantsJID[val.jid] = val;
          }

          tmpParticipantsList[val.token_unique] = val;
          if (val.role !== 'guest' && val.waiting_status === 'accepted') {
            tmpParticipantsHost[val.token_unique] = val;
          }

          if (val.role === 'guest' && val.waiting_status === 'accepted') {
            tmpParticipantsGuest[val.token_unique] = val;
            if (tmpParticipantsWaiting[val.token_unique]) {
              delete tmpParticipantsWaiting[val.token_unique];
            }
          } else if (
            val.role === 'guest' &&
            val.waiting_status === 'declined'
          ) {
            if (tmpParticipantsWaiting[val.token_unique]) {
              delete tmpParticipantsWaiting[val.token_unique];
            }
          } else if (val.role === 'guest' && val.waiting_status === 'pending') {
            tmpParticipantsWaiting[val.token_unique] = val;
          }

          if (val.hand_raised) {
            tmpParticipantsHandRaised[val.token_unique] = val;
          } else if (tmpParticipantsHandRaised[val.token_unique]) {
            delete tmpParticipantsHandRaised[val.token_unique];
          }

          if (val.audio && val.waiting_status === 'accepted') {
            tmpParticipantsUnmuted[val.jid] = val;
          } else if (tmpParticipantsUnmuted[val.jid]) {
            delete tmpParticipantsUnmuted[val.jid];
          }

          ///Presentation view code

          let screenSharedJid = state.screenSharedJid;
          const prevScreenSharedJid = [];
          if (
            val.waiting_status === 'accepted' &&
            val?.screenShare &&
            preScreenSharedValue !== true
          ) {
            tmpparticipantsScreenShared[val.jid] = val.jid;
            screenSharedJid = val.jid;
          } else if (
            val.waiting_status === 'accepted' &&
            !val?.screenShare &&
            preScreenSharedValue === true
          ) {
            if (tmpparticipantsScreenShared[val.jid]) {
              delete tmpparticipantsScreenShared[val.jid];
            }

            if (screenSharedJid === val.jid) {
              Object.keys(tmpparticipantsScreenShared).forEach(key =>
                prevScreenSharedJid.push(key),
              );
              if (prevScreenSharedJid.length > 0) {
                screenSharedJid = prevScreenSharedJid[0];
              } else {
                screenSharedJid = null;
              }
            }
          }

          if (screenSharedJid === val.jid && preScreenSharedValue === false) {
            state.view = viewType.presentation;
          } else if (!screenSharedJid && preScreenSharedValue === true) {
            state.view = state.lastView;
          }
          state.screenSharedJid = screenSharedJid;
          state.participantsScreenShared = tmpparticipantsScreenShared;

          ///////////////
        }

        state.participantsJID = tmpParticipantsJID;
        state.participantsList = tmpParticipantsList;
        state.participantsUnmuted = tmpParticipantsUnmuted;
        state.participantsGuest = tmpParticipantsGuest;
        state.participantsHost = tmpParticipantsHost;
        state.participantsWaiting = tmpParticipantsWaiting;
        state.participantsHandRaised = tmpParticipantsHandRaised;
      }
    },
    remParticipantsList: (state, action) => {
      let tmpParticipantsJID = { ...state.participantsJID };
      let tmpParticipantsList = { ...state.participantsList };
      let tmpParticipantsUnmuted = { ...state.participantsUnmuted };
      let tmpParticipantsGuest = { ...state.participantsGuest };
      let tmpParticipantsHost = { ...state.participantsHost };
      let tmpParticipantsWaiting = { ...state.participantsWaiting };
      let tmpparticipantsScreenShared = { ...state.participantsScreenShared };
      let tmpParticipantsHandRaised = { ...state.participantsHandRaised };

      let val = action.payload;
      delete tmpParticipantsJID[val.jid];

      delete tmpParticipantsList[val.token_unique];
      delete tmpParticipantsUnmuted[val.jid];
      delete tmpParticipantsGuest[val.token_unique];
      delete tmpParticipantsHost[val.token_unique];
      if (tmpParticipantsWaiting[val.token_unique]) {
        delete tmpParticipantsWaiting[val.token_unique];
      }

      if (tmpParticipantsHandRaised[val.token_unique]) {
        delete tmpParticipantsHandRaised[val.token_unique];
      }
      ///Presentation view code
      let screenSharedJid = state.screenSharedJid;
      const prevScreenSharedJid = [];
      if (tmpparticipantsScreenShared[val.jid]) {
        delete tmpparticipantsScreenShared[val.jid];
      }

      if (screenSharedJid === val.jid) {
        Object.keys(tmpparticipantsScreenShared).forEach(key =>
          prevScreenSharedJid.push(key),
        );
        if (prevScreenSharedJid.length > 0) {
          screenSharedJid = prevScreenSharedJid[0];
        } else {
          screenSharedJid = null;
        }
      }

      state.screenSharedJid = screenSharedJid;
      state.participantsScreenShared = tmpparticipantsScreenShared;

      if (!screenSharedJid) {
        state.view = state.lastView;
      }
      ///////////////
      state.participantsJID = tmpParticipantsJID;
      state.participantsList = tmpParticipantsList;
      state.participantsUnmuted = tmpParticipantsUnmuted;
      state.participantsGuest = tmpParticipantsGuest;
      state.participantsHost = tmpParticipantsHost;
      state.participantsWaiting = tmpParticipantsWaiting;
      state.participantsHandRaised = tmpParticipantsHandRaised;
    },
    ////////////////////////////////////////////
    setRoomDetails: (state, action) => {
      state.roomDetail = action.payload;
    },
    setUserDetail: (state, action) => {
      // console.log('setUserDetail', {...state.localUser}, action.payload);
      state.localUser = action.payload;
    },
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    updateLocalParticipantDetail: (state, action) => {
      state.localParticipantDetail = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
      if (action.payload !== viewType.presentation) {
        state.lastView = state.view;
      }
    },
    galleryViewParticipants: (state, action) => {
      state.gallery_participants = action.payload;
    },
    dominantViewParticipant: (state, action) => {
      state.dominantSpeaker = action.payload;
    },
    setLanguage: (state, action) => {
      state.selectLanguage = action.payload;
    },
    setBackButtonShow: (state, action) => {
      state.isBackButtonShow = action.payload;
    },
    setOnClickForgotPassword: (state, action) => {
      state.clickForgotPassword = action.payload;
    },
    setChatMessage: (state, action) => {
      state.chatMessage = action.payload;
    },
    setFeatureMsg: (state, action) => {
      state.featuredMsg = action.payload;
    },
    setCurrentRoom: (state, action) => {
      state.currentRoom = action.payload;
    },
    setMuteAllLoader: (state, action) => {
      state.muteAllLoader = action.payload;
    },
    setUnmuteAllLoader: (state, action) => {
      state.unmuteAllLoader = action.payload;
    },
    setMyRole: (state, action) => {
      state.myRole = action.payload;
    },
    setOpenLoginModel: (state, action) => {
      state.openLoginModel = action.payload;
    },
    setOrientation: (state, action) => {
      state.orientation = action.payload;
    },
    setChatClick: (state, action) => {
      state.chatClick = action.payload;
    },
    setAttendeesClick: (state, action) => {
      state.attendeesClick = action.payload;
    },
    setRecordingView: (state, action) => {
      state.recordingViewValue = action.payload;
    },
    setExtensionList: (state, action) => {
      state.extensionList = action.payload;
    },
    setGuestsUnmuteLoader: (state, action) => {
      state.guestsUnmuteLoader = action.payload;
    },
    setRequestDeclined: (state, action) => {
      state.isRequestDeclined = action.payload;
    },
    setMutedAttendeesLoader: (state, action) => {
      state.mutedAttendeesLoader = action.payload;
    },
    setWaitingStatusLoader: (state, action) => {
      state.waitingStatusLoader = action.payload;
    },
    setBreakoutRoomJoined: (state, action) => {
      state.breakoutRoomJoined = action.payload;
    },
    setOpenRecordingModal: (state, action) => {
      state.openRecordingModal = action.payload;
    },
    setMic: (state, action) => {
      state.mic = action.payload;
    },
    setCamera: (state, action) => {
      state.camera = action.payload;
    },
    setSpeaker: (state, action) => {
      state.speaker = action.payload;
    },
    setWaitingRoomLoaders: (state, action) => {
      state.waitingRoomLoaders = action.payload;
    },
    updateWaitingRoomPopup: (state, action) => {
      state.waitingRoomPopup = action.payload;
    },
    setWaitingEnabled: (state, action) => {
      state.waitingEnabled = action.payload;
    },
    setParticipantsLoaders: (state, action) => {
      state.participantsLoaders = action.payload;
    },
    setExitModalShow: (state, action) => {
      state.exitModal = action.payload;
    },
    setFeedbackPopUp: (state, action) => {
      state.feedbackPopUp = action.payload;
    },
    setConferenceConnecting: (state, action) => {
      state.conferenceConnecting = action.payload;
    },
    setMuteUnmutePermission: (state, action) => {
      state.muteUnmutePermission = action.payload;
    },
    setOpenStreamManagerModal: (state, action) => {
      state.openStreamManageModal = action.payload;
    },
    setUpgradePlanModal: (state, action) => {
      state.openUpgradePlanModal = action.payload;
    },
    setwaitingRoomModal: (state, action) => {
      state.waitingRoomModal = action.payload;
    },
    setHostRequiredModal: (state, action) => {
      state.hostRequiredModal = action.payload;
    },
    setMouseOverUser: (state, action) => {
      state.mouseOverUser = action.payload;
    },
    setUnreadMessageCount: (state, action) => {
      state.unreadMessageCount = action.payload;
    },
    setVideoPlayModal: (state, action) => {
      state.videoPlayModal = action.payload;
    },
    setShowMenu: (state, action) => {
      state.showMenu = action.payload;
    },
    setShowMenuType: (state, action) => {
      state.showMenuType = action.payload;
    },
    setRecording: (state, action) => {
      state.showRecording = action.payload;
    },
    setStreaming: (state, action) => {
      state.showStreaming = action.payload;
    },

    setShareScreen: (state, action) => {
      state.shareScreen = action.payload;
    },
    setStopRecordingModal: (state, action) => {
      state.openStopRecordingModal = action.payload;
    },
    setIsRecordingStart: (state, action) => {
      state.isRecordingStart = action.payload;
    },
    setRecordingDuration: (state, action) => {
      state.recordingDuration = action.payload;
    },
    setRecordingStopLoader: (state, action) => {
      state.recordingStopLoader = action.payload;
    },
    setRecordingStartLoader: (state, action) => {
      state.recordingStartLoader = action.payload;
    },
    setStreamingStarted: (state, action) => {
      state.streamingStarted = action.payload;
    },
    setStartStreamingLoader: (state, action) => {
      state.startStreamingLoader = action.payload;
    },
    setStopStreamingLoader: (state, action) => {
      state.stopStreamingLoader = action.payload;
    },
    setAddDestinationType: (state, action) => {
      state.addDestinationType = action.payload;
    },
    setRatingPopUp: (state, action) => {
      state.ratingPopUp = action.payload;
    },
    setGuestDetail: (state, action) => {
      state.guestDetail = action.payload;
    },
    setRecordingData: (state, action) => {
      state.recordingData = action.payload;
    },
    setRecordingName: (state, action) => {
      state.recordingName = action.payload;
    },
    setStreamingTitleDescription: (state, action) => {
      state.streamingTitleDescription = action.payload;
    },
    setStreamingDestinations: (state, action) => {
      state.streamingDestinations = action.payload;
    },
    setTimeCal: (state, action) => {
      state.timeCal = action.payload;
    },
    setIntval: (state, action) => {
      state.intval = action.payload;
    },
    setHour: (state, action) => {
      state.hours = action.payload;
    },
    setminutes: (state, action) => {
      state.minutes = action.payload;
    },
    setseconds: (state, action) => {
      state.seconds = action.payload;
    },
    setStreamingData: (state, action) => {
      state.streamingData = action.payload;
    },
    setStreamingDuration: (state, action) => {
      state.streamingDuration = action.payload;
    },
    setMaximumDestination: (state, action) => {
      state.maxDestination = action.payload;
    },
    setDestinationList: (state, action) => {
      state.destinationsList = action.payload;
    },
    setStreamingErrors: (state, action) => {
      state.streamingErrors = action.payload;
    },
    setDestinationListLoader: (state, action) => {
      state.destinationListLoader = action.payload;
    },
    setWithoutChoosingDestination: (state, action) => {
      state.withoutChoosingDestination = action.payload;
    },

    setStreamTimeCal: (state, action) => {
      state.streamTimeCal = action.payload;
    },
    setStreamIntval: (state, action) => {
      state.streamIntval = action.payload;
    },
    setStreamHours: (state, action) => {
      state.streamHours = action.payload;
    },
    setStreamMinutes: (state, action) => {
      state.streamMinutes = action.payload;
    },
    setStreamSeconds: (state, action) => {
      state.streamSeconds = action.payload;
    },
    setJoinWithVideo: (state, action) => {
      state.joinWithVideo = action.payload;
    },
    setScreenDimension: (state, action) => {
      state.screenDimension = action.payload;
    },
    setWindowDimension: (state, action) => {
      state.windowDimension = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPreviousRouteStep: (state, action) => {
      state.previousRouteStep = action.payload;
    },
    setLoginAsGuest: (state, action) => {
      state.loginAsGuest = action.payload;
    },
    sethasAudioEnable: (state, action) => {
      state.hasAudioEnable = action.payload;
    },
    sethasVideoEnable: (state, action) => {
      state.hasVideoEnable = action.payload;
    },
    setAudioInputList: (state, action) => {
      state.audioInputList = action.payload;
    },
    setVideoInputList: (state, action) => {
      state.videoInputList = action.payload;
    },
    setAudioOutputList: (state, action) => {
      state.audioOutputList = action.payload;
    },
    setCameraDevice: (state, action) => {
      state.cameraDevice = action.payload;
    },
    setMicDevice: (state, action) => {
      state.micDevice = action.payload;
    },
    setSpeakerDevice: (state, action) => {
      state.speakerDevice = action.payload;
    },
    trackAdd: (state, action) => {
      let track = action.payload;
      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let trackdata = {
        trackID: track.getId(),
        isReceivingData,
        mediaType: track.videoType,
        muted: track.isMuted(),
        noDataFromSourceNotificationInfo,
        participantId: track.getParticipantId(),
        videoStarted: false,
        type: track.type,
        jitsiTrack: track,
      };
      state.tracks.push(trackdata);
    },
    trackRemove: (state, action) => {
      let track = action.payload;
      // let trackID = track.getId();
      let tracks = state.tracks.filter(t => t.jitsiTrack !== track);
      state.tracks = tracks;
    },
    partcipantAdd: (state, action) => {
      let participantId = action.payload;
      if (!state.participants[participantId]) {
        state.participants[participantId] = [];
      }
    },
    partcipantRemove: (state, action) => {
      let participantId = action.payload;
      if (state.participants[participantId]) {
        delete state.participants[participantId];
      }
    },
    trackMediaTypeUpdate: (state, action) => {
      let track = action.payload;
      let trackID = track.getId();
      state.tracks.map((t, i) => {
        if (t.trackID === trackID) {
          state.tracks[i].mediaType = track.videoType;
        }
      });
    },
    trackMuteUpdate: (state, action) => {
      let track = action.payload;
      let trackID = track.getId();
      state.tracks.map((t, i) => {
        if (t.trackID === trackID) {
          state.tracks[i].muted = track.isMuted();
        }
      });
    },
    disposeLocalTracks: (state, action) => {
      const localVideoTrack = state.localVideoTrack;
      const localAudioTrack = state.localAudioTrack;
      const localDesktopVideoTrack = state.localDesktopVideoTrack;
      if (localVideoTrack) {
        //console.log('disposing localVideoTrack', localVideoTrack);

        localVideoTrack.jitsiTrack.dispose();
        state.localVideoTrack = null;
      }
      if (localDesktopVideoTrack) {
        //console.log('disposing localDesktopVideoTrack', localDesktopVideoTrack);

        localDesktopVideoTrack.jitsiTrack.dispose();
        state.localDesktopVideoTrack = null;
      }
      if (localAudioTrack) {
        //console.log('disposing localDesktopVideoTrack', localDesktopVideoTrack);

        localAudioTrack.jitsiTrack.dispose();
        state.localAudioTrack = null;
      }
      state.participantsJID = [];
      state.participantsList = [];
      state.participantsUnmuted = [];
      state.participantsGuest = [];
      state.participantsHost = [];
      state.participantsWaiting = [];
      state.participantsHandRaised = [];
      state.participantsScreenShared = [];
    },
    setHasAudioPermission: (state, action) => {
      state.hasAudioPermission = action.payload;
    },
    setHasVideoPermission: (state, action) => {
      state.hasVideoPermission = action.payload;
    },
    setloaderEffect: (state, action) => {
      state.loaderEffect = action.payload;
    },

    setShowInviteModal: (state, action) => {
      state.showInviteModal = action.payload;
    },
    setShowAvSettingModal: (state, action) => {
      state.showAvSettingModal = action.payload;
    },
    setShowEditProfileModal: (state, action) => {
      state.showEditProfileModal = action.payload;
    },
    setShowPhoneCommandModal: (state, action) => {
      state.showPhoneCommandModal = action.payload;
    },
    setShowWebinarSettingModal: (state, action) => {
      state.showWebinarSettingModal = action.payload;
    },
    setShowRoomSettingModal: (state, action) => {
      state.showRoomSettingModal = action.payload;
    },
    setShowMeetingModal: (state, action) => {
      state.showMeetingModal = action.payload;
    },
    setShowRoomPermissionModal: (state, action) => {
      state.showRoomPermissionModal = action.payload;
    },
    setShowShareWidgetModal: (state, action) => {
      state.showShareWidgetModal = action.payload;
    },
    setShowManageRoomModal: (state, action) => {
      state.showManageRoomModal = action.payload;
    },
    setShowInvoiceModal: (state, action) => {
      state.showInvoiceModal = action.payload;
    },
    setShowBillingInfoModal: (state, action) => {
      state.showBillingInfoModal = action.payload;
    },
    setUserListForRoomPermission: (state, action) => {
      state.userListForRoomPermission = action.payload;
    },
    setEventsList: (state, action) => {
      state.eventsList = action.payload;
    },
    setChatBlockUser: (state, action) => {
      state.chatBlockUsers = action.payload;
    },

    setMaxVideos: (state, action) => {
      state.maxVideos = action.payload;
    },
    setTrial: (state, action) => {
      state.trial = action.payload;
    },
    setBilling: (state, action) => {
      state.billing = action.payload;
    },

    //extensions
    setExtensionShow: (state, action) => {
      state.extensionShow = action.payload;
    },

    setExtensionDetails: (state, action) => {
      state.extensionDetails = action.payload;
    },
    setWidgetClick: (state, action) => {
      state.widgetClick = action.payload;
    },

    setKickedOutRequest: (state, action) => {
      state.kickedOutRequest = action.payload;
    },
    setLemAttendeesCount: (state, action) => {
      state.lemAttendeesCount = action.payload;
    },
  },
});

export const {
  SetLocalParticipantKicked,
  setFailedCameraAlert,
  setConferenceLeft,
  setConferenceFailed,
  setNoiseSuppression,
  setVirtualBackgroundOptions,
  setNoiseSuppressionEnabledState,
  addLocalAudioTrack,
  addLocalVideoTrack,
  addLocalDesktopVideoTrack,
  addLocalDesktopAudioTrack,
  muteLocalDesktopVideoTrack,
  setCurrentPage,
  setNumPages,
  setMediaPermissionPrompt,
  updateLocalAudioTrackMute,
  updateLocalVideoTrackMute,
  removeLocalVideoTrack,
  removeLocalAudioTrack,
  //////////////////////////////

  setloaderEffect,
  setRecentRooms,
  setRoomDetails,
  setUserDetail,
  setRoom,
  setSocketConnected,
  setParticipantsList,
  updParticipantsList,
  remParticipantsList,
  updateLocalParticipantDetail,
  setView,
  galleryViewParticipants,
  dominantViewParticipant,
  setLanguage,
  setBackButtonShow,
  setOnClickForgotPassword,
  setChatMessage,
  setFeatureMsg,
  setCurrentRoom,
  setMuteAllLoader,
  setUnmuteAllLoader,
  setMyRole,
  setOpenLoginModel,
  setOrientation,
  setChatClick,
  setAttendeesClick,
  setExtensionList,
  setGuestsUnmuteLoader,
  setRecordingView,
  setRequestDeclined,
  setMutedAttendeesLoader,
  setWaitingStatusLoader,
  setBreakoutRoomJoined,
  setOpenRecordingModal,
  setMic,
  setCamera,
  setSpeaker,
  setWaitingRoomLoaders,
  updateWaitingRoomPopup,
  setWaitingEnabled,
  setExitModalShow,
  setFeedbackPopUp,
  setParticipantsLoaders,
  setConferenceConnecting,
  setMuteUnmutePermission,
  setOpenStreamManagerModal,
  setMouseOverUser,
  setUpgradePlanModal,
  setwaitingRoomModal,
  setHostRequiredModal,
  setVideoPlayModal,
  setUnreadMessageCount,
  setShowMenu,
  setShowMenuType,
  setRecording,
  setStreaming,
  setShareScreen,
  setStopRecordingModal,
  setIsRecordingStart,
  setRecordingDuration,
  setRecordingStopLoader,
  setRecordingStartLoader,
  setStreamingStarted,
  setStartStreamingLoader,
  setStopStreamingLoader,
  setAddDestinationType,
  setRatingPopUp,
  setDominantSpeaker,
  setGuestDetail,
  setRecordingData,
  setRecordingName,
  setStreamingTitleDescription,
  setStreamingDestinations,
  setTimeCal,
  setIntval,
  setHour,
  setminutes,
  setseconds,
  setStreamingData,
  setStreamingDuration,
  setDestinationList,
  setStreamingErrors,
  setDestinationListLoader,
  setWithoutChoosingDestination,
  setMaximumDestination,
  setStreamTimeCal,
  setStreamIntval,
  setStreamHours,
  setStreamMinutes,
  setStreamSeconds,

  setScreenDimension,
  setWindowDimension,
  setPage,
  setPreviousRouteStep,
  setLoginAsGuest,
  sethasAudioEnable,
  sethasVideoEnable,
  setAudioInputList,
  setVideoInputList,
  setAudioOutputList,
  setCameraDevice,
  setMicDevice,
  setSpeakerDevice,
  trackAdd,
  trackRemove,
  partcipantAdd,
  partcipantRemove,
  trackMediaTypeUpdate,
  trackMuteUpdate,
  disposeLocalTracks,
  setHasAudioPermission,
  setHasVideoPermission,
  setJoinWithVideo,
  setShowInviteModal,
  setShowAvSettingModal,
  setShowEditProfileModal,
  setShowPhoneCommandModal,
  setShowWebinarSettingModal,
  setShowRoomSettingModal,
  setShowMeetingModal,
  setShowRoomPermissionModal,
  setShowShareWidgetModal,
  setShowManageRoomModal,
  setShowInvoiceModal,
  setShowBillingInfoModal,
  setUserListForRoomPermission,
  setEventsList,
  setChatBlockUser,

  setMaxVideos,
  setBilling,
  setTrial,

  //extensions
  setExtensionShow,
  setExtensionDetails,
  setWidgetClick,

  setKickedOutRequest,
  setLemAttendeesCount,
} = confSlice.actions;

export default confSlice.reducer;
