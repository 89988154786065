import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

const OuterFooter = props => {
  return (
    <footer id="footer">
            <div className="container">
              <div className="row flex-div">
              <div className="col-md-9 pr-0 copyright-text-line">
                  Copyright &copy;{new Date().getFullYear()} Meetn.com. This website only uses cookies for essential features, including login, secure payment and referral tracking.<br/>
                  We respect your privacy. We never share or rent user data. User data collected from third party accounts (e.g., if you stream to YouTube or Facebook, etc)<br/>
                  will only be used for the purposes explained when connecting the the third party and will be deleted from our servers if you remove the connection to the third party.
              </div>
              <div className="col-md-3">
              <ul className="list-inline text-right mb-0">
                <li><a href="privacy" target="_blank">Privacy Policy</a></li>
                    <li><a href="terms" target="_blank">Terms of Services</a></li>          
              </ul>
              </div>
            </div>
            </div>
          </footer>
  );
};

export default OuterFooter;
