/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect } from 'react';
import Layout from '../layout';
import { useSelector } from 'react-redux';
import MarketingBanner from './marketingBanner';
import Player from '@vimeo/player';
import { useNavigate } from 'react-router-dom';

function MarketingPage() {
    const { routeDetail: marketingOffer } = useSelector(state => state.main);
    const [playerReady, setPlayerReady] = useState(false);
    const navigate = useNavigate();
    const { pagename, free, pro, ultimate } = marketingOffer.data;

    useEffect(() => {
        const player = new Player('video-player', {
            url: 'https://vimeo.com/656352758/b8f3ec00e6',
            width: '100%',
            height: '100%',
            autoplay: false,
        });
        player.ready().then(function () {
            setPlayerReady(true);
        });
        player.on('play', function () {
        });
    }, []);

    const managePlan = (selectedPlan) => {
        navigate(`/signup?plan=${selectedPlan}&offer=${pagename.trim()}`);
    };

    return (<>
        <Layout>
            <div className={`body-content page-content ${!playerReady ? 'loader_effect' : ''}`}>
                <MarketingBanner headline={'Welcome to marketing page'} paragraph_text={'Introducing MEETN -- The Video Meeting Service that Helps You Promote, Close Sales, Educate and More'} />
                <div className="marketing_form_container marketing">
                    <div className="container">
                        <div className="price_comparison price_comparison--80">
                            {
                                free && <div className="price_comparison__block price_comparison__block--free">
                                    <div className="price_comparison__container">
                                        <img src={'https://download.meetn.com/profile/404a2a11.png'} loading="eager" style={{ cursor: 'pointer' }} onClick={() => managePlan('free')} />
                                    </div>
                                </div>
                            }
                            {
                                pro && <div className="price_comparison__block price_comparison__block--pro">
                                    <div className="price_comparison__container">
                                        <img src={'https://download.meetn.com/profile/965ea1f2.png'} loading="eager" style={{ cursor: 'pointer' }} onClick={() => managePlan('pro')} />
                                    </div>
                                </div>
                            }

                            {
                                ultimate && <div className="price_comparison__block price_comparison__block--unlimited">
                                    <div className="price_comparison__container">
                                        <img src={'https://download.meetn.com/profile/29376545.png'} loading="eager" style={{ cursor: 'pointer' }} onClick={() => managePlan('ultimate')} />
                                    </div>
                                </div>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </Layout>

    </>);
}

export default MarketingPage