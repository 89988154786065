import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {CommonModal} from '../../../common/web';
import AddSubstitutionModal from './addSubstitutionModal';
import {setShowRoomSettingModal} from '../../../../reduxStore/confSlice';
import {
  getPasscode,
  roomSettings,
  updateRoomName,
} from '../../../../utils/socket';
import swal from 'sweetalert';
import {
  ROOM_NAME_UPDATE_MSG,
  TenMegaBytes,
  uploadImageMimeType,
} from '../../../../utils/util';
import {getImagesURL, uploadFileInWasabi} from '../../../../utils/functions';
import {toast} from 'react-toastify';
import {Icon, IconClose} from '../../../../icons';
import {alertMsg} from '../../../common/web/alert';
import {getPasscodeHandler} from '../utils/functions';

const RoomSettingModal = props => {
  const {closeRoomSettingModal, roomDetail} = props;
  const {localUser} = useSelector(state => state.conf);
  const {room} = roomDetail;
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {token} = localUser;

  const [state, setState] = useState({passcode: ''});
  const [loader, setLoader] = useState(false);
  const regExp = /^[0-9]*$/;
  const [backgroungImageData, setBackgroundImageData] = useState({
    displayImageUrl: '',
    file: {},
    filename: '',
  });
  const [isRoomEdit, setIsRoomEdit] = useState(false);

  useEffect(() => {
    setState(prevState => ({...prevState, ...roomDetail}));
    getPasscodeHandler(setState);
    setBackgroundImageData({
      ...backgroungImageData,
      displayImageUrl: roomDetail?.room_background,
    });

    if (roomDetail?.room_background !== '') {
      const filenameData = roomDetail?.room_background.substring(
        roomDetail?.room_background.lastIndexOf('/') + 1,
      );

      setBackgroundImageData(prevState => ({
        ...prevState,
        filename: filenameData,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeSettingModal = data => {
    closeRoomSettingModal(data);
  };

  const openModal = e => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };
  const setStateHandler = e => {
    setState({
      ...state,
      [e.target.name]:
        e.target.name === 'show_names_on_videos'
          ? e.target.checked
          : e.target.value,
    });
  };

  const addLinkSubstitution = (e, data) => {
    e.preventDefault();
    const updated_link_substitution = [...state.link_substitution];
    updated_link_substitution.push(data);
    setState({...state, link_substitution: updated_link_substitution});
    setIsOpenModal(false);
  };

  const saveSetting = async e => {
    e.preventDefault();
    setLoader(true);
    let isImageUpdated = false;
    let imageName = '';
    if (roomDetail?.room_background !== backgroungImageData?.displayImageUrl) {
      if (backgroungImageData?.displayImageUrl !== '') {
        isImageUpdated = true;
        const selectedFiles = [];
        selectedFiles.push(backgroungImageData.file);
        const [imageResp] = await uploadFileInWasabi(selectedFiles);
        imageName =
          imageResp && imageResp.length > 0 && imageResp[0].attachmentName;
      }
    }
    const room_background_url = `https://download.meetn.com/profile/${imageName}`;
    const roomPayload = {
      ...state,
      room_background: isImageUpdated
        ? room_background_url
        : state.room_background,
    };
    console.log('roomPayload', roomPayload);
    roomSettings(roomPayload).then(res => {
      if (res.status === 200) {
        closeSettingModal(res.data);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoader(false);
      }
    });
  };

  const removeItem = index => {
    swal({
      title: 'Are you sure you want to delete this item?',
      closeOnClickOutside: false,
      buttons: ['No', 'Yes'],
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        const updated_link_substitution = [...state.link_substitution];
        updated_link_substitution.splice(index, 1);
        setState({...state, link_substitution: updated_link_substitution});
      }
    });
  };

  const pinHandler = e => {
    console.log('pinHandler', regExp.test(e.target.value));
    if (regExp.test(e.target.value) === true) {
      console.log('pinHandler in');
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onFileChange = e => {
    const files = e.target.files;
    let imgType = files[0]?.type;
    let imgSize = files[0]?.size;
    if (uploadImageMimeType()?.includes(imgType)) {
      if (imgSize < TenMegaBytes) {
        getImagesURL(files).then(res => {
          setBackgroundImageData({
            displayImageUrl: res[0],
            file: files[0],
            filename: files[0]?.name,
          });
        });
      } else {
        alertMsg({
          msg: 'File too large. Please upload a file less than 10 MB.',
          type: 'info',
        });
      }
    } else {
      document.getElementById('file').value = '';
      alertMsg({msg: 'Please upload a valid image file.', type: 'info'});
    }
  };

  const removeImage = () => {
    setBackgroundImageData({
      displayImageUrl: '',
      file: {},
      filename: '',
    });
    setState({...state, room_background: ''});
    document.getElementById('file').value = '';
  };

  const UpdateRoomName = () => {
    if (room !== state?.room) {
      swal({
        title: ROOM_NAME_UPDATE_MSG,
        closeOnClickOutside: false,
        buttons: ['Cancel', 'Ok'],
        dangerMode: true,
      }).then(Ok => {
        if (Ok) {
          updateRoomName({room, token, room_name: state?.room}).then(res => {
            if (res.status === 200) {
              setIsRoomEdit(false);
              toast.success(res.data[0], {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.error(res.data[0].msg, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
        }
      });
    } else {
      setIsRoomEdit(false);
    }
  };
  const roomSettingBody = () => {
    return (
      <form>
        <div className="row firstrow align-items-baseline justify-content-start p-0 modal_min_height">
          <div className="col-md-8 col-8 offset-md-2 offset-2">
            <div className="container-fluid darkbg servwrap p-15 minHauto mb-15">
              <p className="semiBold">Room Name</p>
              <div className="form-group continfowrap row mb-0">
                <label className="col-md-3 col-3 pr-0 justify-left">
                  Meetn.com/
                </label>
                <div className="col-md-9 col-9 d-flex align-items-center">
                  <input
                    spellCheck="false"
                    type="text"
                    maxLength="40"
                    minLength="2"
                    name="room"
                    className="form-control float-left"
                    value={state.room}
                    onChange={e => setStateHandler(e)}
                    required
                    disabled={!isRoomEdit}
                  />
                  <button
                    type="button"
                    className="btn btn-green btn-xs"
                    onClick={() =>
                      !isRoomEdit ? setIsRoomEdit(true) : UpdateRoomName()
                    }>
                    {isRoomEdit ? 'Save' : 'Edit'}
                  </button>
                </div>
              </div>
            </div>
            <div className="container-fluid darkbg servwrap p-15 minHauto mb-15">
              <div className="form-group continfowrap row mb-0">
                <div className="col-md-12 d-flex align-items-center semibold">
                  <label className="switch mr-3 modal-switch">
                    <input
                      spellCheck="false"
                      type="checkbox"
                      name="show_names_on_videos"
                      checked={state.show_names_on_videos}
                      onChange={e => setStateHandler(e)}
                    />
                    <span className="slider round" />
                    <span className="slider_text font-1">
                      Show people's names on top of videos
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="container-fluid darkbg servwrap p-15 minHauto mb-15">
              <p className="semiBold">Room Headline</p>
              <div className="form-group continfowrap row mb-0">
                <div className="col-md-12">
                  <input
                    spellCheck="false"
                    maxLength="300"
                    required
                    type="text"
                    name="room_headline"
                    className="form-control float-left"
                    value={state.room_headline}
                    onChange={e => setStateHandler(e)}
                  />
                </div>
              </div>
            </div>
            <div className="container-fluid darkbg servwrap p-15 minHauto mb-15">
              <p className="semiBold">
                Webpage On Exit URL (Optional)
                <a
                  className="tooltip_container tooltip_container--right float-right"
                  data-tooltip="The exit URL should begin with http:// or https://">
                  <span className="with_circle">
                    <svg height="60%" viewBox="7 4 10 16">
                      <path
                        fill="#ffffff"
                        d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                      />
                    </svg>
                  </span>
                </a>
              </p>
              <div className="form-group continfowrap row mb-01">
                <div className="col-md-12">
                  <input
                    spellCheck="false"
                    type="text"
                    maxLength="100"
                    className="form-control float-left"
                    name="webpage_on_exit_url"
                    value={state.webpage_on_exit_url}
                    onChange={e => setStateHandler(e)}
                  />
                </div>
              </div>
            </div>

            <div className="container-fluid darkbg servwrap p-15 minHauto mb-15">
              <p className="semiBold">Custom Toolbar Button</p>
              <div className="col-md-12">
                <div className="form-group row">
                  <label className="justify-content-start">Caption</label>
                  <input
                    spellCheck="false"
                    type="text"
                    maxLength="40"
                    className="form-control float-left"
                    name="custom_button_text"
                    value={state.custom_button_text}
                    onChange={e => setStateHandler(e)}
                  />
                </div>
                <div className="form-group row">
                  <label className="justify-content-start">Target URL</label>
                  <input
                    spellCheck="false"
                    type="text"
                    maxLength="2048"
                    className="form-control float-left"
                    name="custom_button_url"
                    value={state.custom_button_url}
                    onChange={e => setStateHandler(e)}
                  />
                </div>
              </div>
            </div>

            <div className="container-fluid darkbg servwrap p-15 minHauto mb-15">
              <p className="semiBold">
                Room Background Image (Minimum 1920x1080) (BETA)
              </p>
              <div className="form-group continfowrap row mb-01">
                <div className="col-md-12">
                  <div className="custom-input-file">
                    <input
                      spellCheck="false"
                      className="form-control"
                      type="file"
                      id="file"
                      accept="image/*"
                      onChange={e => onFileChange(e)}
                    />
                    <div className="custom-file justify-content-start">
                      <button>Choose file</button>
                      <span>
                        {backgroungImageData?.filename !== ''
                          ? backgroungImageData?.filename
                          : 'No file choosen'}
                      </span>
                    </div>
                  </div>
                </div>
                {backgroungImageData.displayImageUrl !== '' && (
                  <>
                    <div className="col-md-12">
                      <div className="room__bg">
                        <img
                          src={backgroungImageData.displayImageUrl}
                          height="100%"
                          width="100%"
                        />

                        <Icon
                          className={'room__cross__icon'}
                          onClick={() => removeImage()}
                          src={IconClose}
                          size={'.7em'}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="container-fluid darkbg servwrap p-15 minHauto mb-15">
              <div className="listing__head mt-0">
                <p className="d-flex align-items-center semiBold mb-0">
                  Link Substitution
                  <a className="tooltip_container ml-2">
                    <span className="with_circle">
                      <svg height="60%" viewBox="7 4 10 16">
                        <path
                          fill="#ffffff"
                          d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                        />
                      </svg>
                    </span>
                    <div className="tooltip_data">
                      Watch the Link Substitution tutorial video on&nbsp;
                      <a href="/support" target="_blank" className="text-green">
                        meetn.com/support
                      </a>
                      &nbsp;for details.
                    </div>
                  </a>
                </p>
                <button
                  type="button"
                  className="btn btn-green btn-xs"
                  onClick={e => {
                    openModal(e);
                  }}>
                  Add
                </button>
              </div>
              <div className="listing__body bg-white h-auto max-h-em-13 mb-0">
                <ul>
                  {state.link_substitution?.length > 0 ? (
                    <>
                      {state.link_substitution.map((data, index) => {
                        return (
                          <li>
                            <div className="content__container">
                              <div className="content__left">
                                <span className="max-w-45 min-w-17 pr-3 overflow_ellipsis">
                                  {data.actual_text}
                                </span>
                                <span className="w-15 d-flex justify-content-center align-items-center">
                                  <svg
                                    width=".85em"
                                    viewBox="6 5.29 13.41 13.41">
                                    <path
                                      fill="#2e3235"
                                      d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
                                    />
                                  </svg>
                                </span>
                                <span className="max-w-45 min-w-17 pl-3 overflow_ellipsis">
                                  {data.replace_text}
                                </span>
                              </div>
                              <div className="content__right">
                                <a
                                  className="btn-menu d-flex"
                                  type="button"
                                  onClick={() => removeItem(index)}>
                                  <svg
                                    width=".8em"
                                    viewBox="6.29 6.34 11.31 11.31">
                                    <path
                                      fill="#2e3235"
                                      d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
                                    />
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </>
                  ) : (
                    <> </>
                  )}
                </ul>
              </div>
            </div>

            <div className="container-fluid darkbg servwrap p-15 minHauto mini_passcode">
              <p className="semiBold">
                <span className="float-left">4-digit Passcode (Optional)</span>{' '}
                <small className="form-text text-light font11 inlinetext mt-0">
                  &nbsp;(Numbers Only)
                </small>
              </p>
              <div className="form-group continfowrap row mb-01">
                <div className="col-md-12">
                  <input
                    spellCheck="false"
                    type="text"
                    maxLength="4"
                    className="form-control width90 float-left"
                    name="passcode"
                    value={state.passcode}
                    onChange={e => pinHandler(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-left">
          <div className="col-md-12 text-right mt-3">
            <button
              type="button"
              className="btn-link"
              onClick={closeSettingModal}>
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-green btn-mini abso-btn float-right"
              onClick={e => saveSetting(e)}>
              {loader ? 'Please Wait...' : 'Save'}
              <i className="icon-arrow-right-circle icons" />
            </button>
          </div>
        </div>
      </form>
    );
  };

  const roomSettingsObject = {
    heading: `Room Settings For ${roomDetail?.room}`,
    body: roomSettingBody,
    classNameModal: 'fade settingsmodal midmodalwrap scheduleMeet createsched',
    show: true,
    headingSize: 'h5',
  };

  return (
    <>
      <CommonModal {...roomSettingsObject} />
      {isOpenModal && (
        <AddSubstitutionModal
          addLinkSubstitution={addLinkSubstitution}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default RoomSettingModal;
