import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EventAndRecordingModal from './modals/eventsAndRecordings/eventAndRecordingModal';
import {
  setOpenLoginModel,
  setShowMeetingModal,
} from '../../../reduxStore/confSlice';

function EventAndRecording() {
  const {showMeetingModal, localUser} = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const handleOpenModal = e => {
    e.preventDefault();
    dispatch(setShowMeetingModal(true));
  };
  return (
    <>
      <a
        className="nav-link"
        onClick={e =>
          localUser.role !== 'guest'
            ? handleOpenModal(e)
            : dispatch(setOpenLoginModel(true))
        }>
        <i className="far fa-square doticon bg-red eventsicon" />
        <span>Events &amp; Recordings</span>
      </a>
      {showMeetingModal && <EventAndRecordingModal />}
    </>
  );
}

export default EventAndRecording;
