import React from "react";

const MarketingBanner = ({ headline, paragraph_text }) => {
    return (
        <div className="marketing_banner">
            <div className="container marketing_banner_container">
                <div className="marketing_banner_video order-xs-2">
                    <div
                        id="video-player"
                        className="marketing_video mt-xs-0"
                    />
                </div>
                <div className="marketing_banner_content mt-xs-3 order-xs-1">
                    <h1 className="heading_text">{headline}</h1>
                    <p className="sub_heading_text">{paragraph_text}</p>
                </div>
            </div>
        </div>
    );
};

export default MarketingBanner;
