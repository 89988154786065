import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {CommonModal} from '../../../../common/web';
import {setShowRoomPermissionModal} from '../../../../../reduxStore/confSlice';
import AddHostModal from './addHostModal';
import {
  addRoomPermission,
  getRoomPermissionList,
  removeRoomInvite,
  removeRoomPermission,
} from '../../../../../utils/socket';
import {toast} from 'react-toastify';
import {Capitalize} from '../../../../../utils/functions';
import swal from 'sweetalert';

const RoomPermissionModal = props => {
  const {closeRoomPermissionModal, roomDetail} = props;
  const dispatch = useDispatch();
  const [isOpenHostModal, setIsOpenHostModal] = useState(false);
  const [userListForRoomPermission, setUserListForRoomPermission] = useState(
    [],
  );
  const [listLoader, setListLoader] = useState(true);

  const {room} = roomDetail;
  useEffect(() => {
    setListLoader(true);
    getRoomPermissionList({room}).then(res => {
      setListLoader(false);
      if (res.status === 200) {
        setUserListForRoomPermission([...res.data]);
      }
    });
  }, [room]);

  const closePermissionModal = () => {
    closeRoomPermissionModal();
  };

  const handleOpenModal = () => {
    setIsOpenHostModal(true);
  };

  const closeModal = () => {
    setIsOpenHostModal(false);
  };
  const removeRoomPermissionHandler = (deleteData, index, invited) => {
    swal({
      title: invited ? 'Cancel Invitation' : ' Remove Host / Admin',
      text: invited
        ? 'Are you sure you want to cancel'
        : 'Are you sure you want to remove',
      closeOnClickOutside: false,
      buttons: ['No', 'Yes'],
      dangerMode: true,
    }).then(Confirm => {
      if (Confirm) {
        if (invited) {
          removeRoomInvite({
            token: deleteData.token,
            role: deleteData.role,
            room,
          }).then(res => {
            console.log('removeRoomInvite', res);
            if (res.status === 200) {
              const deleteItem = userListForRoomPermission.filter(
                (item, indx) => indx !== index,
              );
              setUserListForRoomPermission(deleteItem);
            } else {
              toast.error(res.data[0].msg, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
        } else {
          removeRoomPermission({
            token: deleteData.token,
            role: deleteData.role,
            room,
          }).then(res => {
            console.log('removeRoomPermission', res);
            if (res.status === 200) {
              const deleteItem = userListForRoomPermission.filter(
                (item, indx) => indx !== index,
              );
              setUserListForRoomPermission(deleteItem);
            } else {
              toast.error(res.data[0].msg, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
        }
      }
    });
  };

  const updateRoomPermission = (e, data) => {
    const {email} = data;
    addRoomPermission({room, email, role: e.currentTarget.value}).then(res => {
      console.log('updateRoomPermission', res);
      if (res.status === 200) {
        const updatedUserListForRoomPermission = [...userListForRoomPermission];
        const tempIndex = userListForRoomPermission.findIndex(
          item => item.token === res?.data?.token,
        );
        if (tempIndex !== -1) {
          updatedUserListForRoomPermission[tempIndex] = res?.data;
        }

        setUserListForRoomPermission(updatedUserListForRoomPermission);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const roomPermissionBody = () => {
    return (
      <>
        <div className="row firstrow align-items-baseline p-0 modal_min_height">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 table-responsive mb-0">
                <table
                  className={`table table-striped ${
                    listLoader ? 'min-h-200' : ''
                  }`}>
                  <thead>
                    <tr>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Email</span>
                      </th>
                      <th className="text-center">
                        <span> Access Level</span>
                      </th>
                      <th className="text-center">
                        <span>Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!listLoader ? (
                      userListForRoomPermission.length > 0 ? (
                        userListForRoomPermission?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td className="text-center">
                                <>
                                  {item.role === 'owner' ? (
                                    item.role
                                  ) : (
                                    <>
                                      {item.invite ? (
                                        <>{Capitalize(item.role)} (Pending)</>
                                      ) : (
                                        <div>
                                          <label className="radiowrap">
                                            Host
                                            <input
                                              spellCheck="false"
                                              type="checkbox"
                                              name="access"
                                              value="host"
                                              checked={item.role === 'host'}
                                              onChange={e =>
                                                updateRoomPermission(e, item)
                                              }
                                            />
                                            <span className="checkmark" />
                                          </label>
                                          <label className="radiowrap ">
                                            Admin
                                            <input
                                              spellCheck="false"
                                              type="checkbox"
                                              name="access"
                                              value="admin"
                                              checked={item.role === 'admin'}
                                              onChange={e =>
                                                updateRoomPermission(e, item)
                                              }
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              </td>
                              <td className="text-center icon_delete">
                                {item.role !== 'owner' && (
                                  <>
                                    <a
                                      onClick={() =>
                                        removeRoomPermissionHandler(
                                          item,
                                          index,
                                          item.invite,
                                        )
                                      }>
                                      {/* <img
                                      src="/assets/images/delete-table-icon.png"
                                      alt=""
                                      className="deleteimg"
                                    /> */}
                                      <svg
                                        viewBox="3 2 18 20"
                                        width={'100%'}
                                        className="fill-grey">
                                        <path d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"></path>
                                        <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                                      </svg>
                                    </a>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">
                            <span>No Record Found.</span>
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center border-top">
                          <img
                            src="assets/images/loader-x.gif"
                            className="pop-loader"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-right">
            <button
              type="button"
              data-toggle="modal"
              className="btn-link float-right"
              onClick={() => closePermissionModal()}>
              Close
            </button>
          </div>
        </div>
      </>
    );
  };

  const headingContent = () => {
    return (
      <>
        <a
          data-toggle="modal"
          className="btn btn-green btn-mini heading-content"
          onClick={handleOpenModal}>
          Add Host/Admin
        </a>
      </>
    );
  };

  const alertMeetingModalObject = {
    heading: `Room Permissions For ${room}`,
    headingContent: headingContent,
    body: roomPermissionBody,
    classNameModal:
      'fade settingsmodal midmodalwrap scheduleMeet createsched title-full',
    show: true,
    headingSize: 'h5',
    classNameTitle: 'w-100',
  };

  return (
    <>
      <CommonModal {...alertMeetingModalObject} />
      {isOpenHostModal && (
        <AddHostModal
          closeModal={closeModal}
          userListForRoomPermission={userListForRoomPermission}
          setUserListForRoomPermission={setUserListForRoomPermission}
          roomDetail={roomDetail}
        />
      )}
    </>
  );
};

export default RoomPermissionModal;
