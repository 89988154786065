import React from 'react';
import { featureAccess } from '../../../utils/functions';
import { useSelector } from 'react-redux';
import { alertMsg } from '../../common/web/alert';
import { ULTIMATE_FEATURE_MSG, ULTIMATE_FEATURE_TRIAL_MSG } from '../../../utils/util';

function WebinarSettingContent({ state, setStateHandler }) {
  const { roomDetail, trial } = useSelector(state => state.conf);
  const { plan } = roomDetail;

  const handleChange = (e) => {

    if (featureAccess('webinar', plan)) {
      setStateHandler(e);
    } else {
      if (plan === 'pro' && trial) {
        alertMsg({ msg: ULTIMATE_FEATURE_TRIAL_MSG, type: 'info' });
      } else {
        alertMsg({ msg: ULTIMATE_FEATURE_MSG, type: 'info' });
      }
    }
  };

  console.log('state', state);
  return (
    <>
      <div className="col-md-8 col-8 offset-md-2 offset-2 darkbg borderRD10 switch-modal">
        <h6 className="semiBold mb-15">Webinar Settings</h6>
        <div className="form-group justify-content-between">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="hide_muted_attendees"
              checked={state.hide_muted_attendees}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Hide Muted Attendees (Webinar Mode)
            </span>
          </label>
        </div>

        <div class="form-group justify-content-between">
          <label class="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="hide_guest_count_names_for_guests"
              checked={state.hide_guest_count_names_for_guests}
              onChange={e => handleChange(e)}
            />
            <span class="slider round" />
            <span class="text-size-12 slider_text">
              Hide Guest Count and Names from Other Guests
            </span>
          </label>
          <a
            className="tooltip_container tooltip_container--right float-right"
            data-tooltip="This will prevent guests from knowing how many people are in the meeting.">
            <span className="with_circle">
              <svg height="60%" viewBox="7 4 10 16">
                <path
                  fill="#ffffff"
                  d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                />
              </svg>
            </span>
          </a>
        </div>

        <div class="form-group justify-content-between">
          <label class="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="everyone_joins_muted"
              checked={state.everyone_joins_muted}
              onChange={e => handleChange(e)}
            />
            <span class="slider round" />
            <span class="text-size-12 slider_text">Everyone Joins Muted</span>
          </label>
          <a
            className="tooltip_container tooltip_container--right float-right"
            data-tooltip="This only affects small meetings. After 15 people, new people always join muted.">
            <span className="with_circle">
              <svg height="60%" viewBox="7 4 10 16">
                <path
                  fill="#ffffff"
                  d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                />
              </svg>
            </span>
          </a>
        </div>
        <div className="form-group">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="active_speaker_default_view"
              checked={state.active_speaker_default_view}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Make “Active Speaker View” the Default View
            </span>
          </label>
        </div>
        <div className="form-group">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="hide_widgets_for_guests"
              checked={state.hide_widgets_for_guests}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Hide All Widgets from Guests
            </span>
          </label>
        </div>
        <div className="form-group">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="hide_chat_for_guests"
              checked={state.hide_chat_for_guests}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Hide Chat from Guests
            </span>
          </label>
        </div>
        <div className="form-group">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="prevent_chat_auto_appear"
              checked={state.prevent_chat_auto_appear}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Prevent Chat from Auto Appearing
            </span>
          </label>
        </div>
        <div className="form-group">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="host_required"
              checked={state.host_required}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Meetings Require a Host+ Present
            </span>
          </label>
        </div>

        <div className="form-group justify-content-between">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="guests_have_extra_powers"
              checked={state.guests_have_extra_powers}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Guests Have Extra Powers
            </span>
          </label>
          <a
            className="tooltip_container tooltip_container--right float-right"
            data-tooltip="This allows guests to mute/unmute other people, remove people from the meetn and to start/stop recordings.">
            <span className="with_circle">
              <svg height="60%" viewBox="7 4 10 16">
                <path
                  fill="#ffffff"
                  d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                />
              </svg>
            </span>
          </a>
        </div>

        <div className="form-group">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="guests_can_unmute_themselves"
              checked={state.guests_can_unmute_themselves}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Guests can Unmute Themselves
            </span>
          </label>
        </div>

        <div class="form-group justify-content-between">
          <label class="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="lem_mode"
              checked={state.lem_mode}
              onChange={e => handleChange(e)}
            />
            <span class="slider round" />
            <span class="text-size-12 slider_text">
              Large Event Mode (Guests Invisible. For Events &gt; 50 Attendees)
            </span>
          </label>
          <a
            className="tooltip_container tooltip_container--right float-right"
            data-tooltip="This makes it easier for guests to join because they won't be asked to test their microphone or webcam. Perfect for large webinars.">
            <span className="with_circle">
              <svg height="60%" viewBox="7 4 10 16">
                <path
                  fill="#ffffff"
                  d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                />
              </svg>
            </span>
          </a>
        </div>
        <div className="form-group">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="hide_share_screen_for_guests"
              checked={state.hide_share_screen_for_guests}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Guests can Share Their Screens
            </span>
          </label>
        </div>
        <div className="form-group mb-0">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="hide_raise_hand_for_guests"
              checked={state.hide_raise_hand_for_guests}
              onChange={e => handleChange(e)}
            />
            <span className="slider round" />
            <span className="text-size-12 slider_text">
              Enable the "Raise Hand" Feature
            </span>
          </label>
        </div>
      </div>
    </>
  );
}

export default WebinarSettingContent;
