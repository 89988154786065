import React from 'react';
import {upgradeAccountConfirmHandler} from './utils/functions';

function UpgradeNow() {
  return (
    <>
      <a className="nav-link" onClick={e => upgradeAccountConfirmHandler(e)}>
        <div className="menu_icons">
          <img height="20" width="20" src="assets/images/upgrade_now.png" />
        </div>
        <span className="pl-2">Upgrade Now</span>
      </a>
    </>
  );
}

export default UpgradeNow;
