import React from 'react';
import {useSelector} from 'react-redux';
import {useStopwatch} from 'react-timer-hook';
import {getRecordingDuration} from '../../../utils/functions';

function Start({stopwatchOffset}) {
  const {seconds, minutes, hours} = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset,
  });

  return (
    <>
      {hours.toString().length === 1 ? '0' + hours : hours}:
      {minutes.toString().length === 1 ? '0' + minutes : minutes}:
      {seconds.toString().length === 1 ? '0' + seconds : seconds}
    </>
  );
}

export default function Timer() {
  const stopwatchOffset = new Date();

  const recordingduration = getRecordingDuration();

  const seconds = Math.floor((recordingduration % (1000 * 60)) / 1000);

  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + seconds);
  return (
    <div>{stopwatchOffset && <Start stopwatchOffset={stopwatchOffset} />}</div>
  );
}
