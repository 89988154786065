import React, {useRef, useState} from 'react';

import {CommonModal} from '../../../../common/web';
import SimpleReactValidator from 'simple-react-validator';
import {useSelector} from 'react-redux';
import {addRoomConfirm, addRoomProcess} from '../../../../../utils/socket';
import {toast} from 'react-toastify';
import swal from 'sweetalert';
import {test_mode_id} from '../../../../../utils/util';

const AddRoomModal = ({closeModal, UpdateRoomList}) => {
  const [state, setState] = useState({room_name: '', plan: 'pro'});
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loader, setLoader] = useState(false);
  const {roomDetail, localUser} = useSelector(state => state.conf);
  const {room} = roomDetail;
  const {token, email} = localUser;

  const setStateHandler = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const addRoomConfirmHandler = e => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      const {plan} = state;
      setLoader(true);
      addRoomConfirm({plan, token}).then(res => {
        if (res.status === 200) {
          closeModal();
          setLoader(false);
          swal({
            title: res.data[0].msg,
            closeOnClickOutside: false,
            buttons: ['Cancel', 'Confirm'],
            dangerMode: true,
          }).then(Confirm => {
            if (Confirm) {
              addRoomProcessHandler();
            }
          });
        } else {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoader(false);
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const addRoomProcessHandler = () => {
    const {plan, room_name} = state;
    addRoomProcess({
      room,
      room_name,
      plan,
      email,
      token,
      test_mode_id: test_mode_id,
    }).then(res => {
      if (res.status == 200) {
        UpdateRoomList({...res.data[0]});
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const addRoomBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <form name="addRoomModelForm">
              <div className="modal_min_height--sm">
                <div className="form-group">
                  <label className="">Room Name</label>
                  <input
                    spellCheck="false"
                    type="text"
                    name="room_name"
                    className="form-control"
                    required
                    onChange={e => setStateHandler(e)}
                  />
                  {simpleValidator.current.message(
                    'room_name',
                    state.room_name,
                    'required',
                    {
                      messages: {
                        default: 'Room Name is required.',
                      },
                    },
                  )}
                </div>
                <div className="form-group row mt-20">
                  <label className="col-md-12 col-12 pr-0">Room Type</label>
                  <div className="col-sm-12 col-12">
                    <label className="radiowrap float-left mb-0">
                      Pro
                      <input
                        spellCheck="false"
                        type="radio"
                        name="plan"
                        value="pro"
                        checked={state.plan === 'pro'}
                        onChange={e => setStateHandler(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radiowrap float-left mb-0">
                      Ultimate
                      <input
                        spellCheck="false"
                        type="radio"
                        name="plan"
                        value="ultimate"
                        checked={state.plan === 'ultimate'}
                        onChange={e => setStateHandler(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 text-right">
                  <button
                    type="button"
                    data-toggle="modal"
                    onClick={e => closeModal(e)}
                    className="btn-link">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-green btn-mini float-right"
                    onClick={e => addRoomConfirmHandler(e)}>
                    {loader ? 'Please Wait' : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };

  const addRoomModalObject = {
    heading: 'Add Room',
    body: addRoomBody,
    classNameModal: 'fade smallmodalwrap addRoomUserpanel',
    show: true,
    headingSize: 'h5',
    classNameTitle: 'ng-binding',
    classNameDialog: 'smallmodal',
  };

  return <CommonModal {...addRoomModalObject} />;
};

export default AddRoomModal;
