import React from 'react';

import {CommonModal} from '../../../../common/web';
import WebinarSettingContent from '../../webinarSettingContent';

const StepThreeMeetingModal = ({
  nextButton,
  backButton,
  state,
  setStateHandler,
  setStepThree,
  isEditModalOpen,
  saveEventsAndRecording,
}) => {
  const stepThreeMeetingBody = () => {
    const saveHandler = e => {
      e.preventDefault();
      if (isEditModalOpen) {
        saveEventsAndRecording(() => {
          setStepThree(false);
        });
      } else {
        nextButton(e, 'stepFour');
      }
    };
    return (
      <>
        <form>
          <div className="row firstrow modal_min_height">
            <div className="col-md-12">
              <div className="row">
                <WebinarSettingContent
                  state={state}
                  setStateHandler={setStateHandler}
                />
              </div>
              <div className="row" />
            </div>
          </div>
          <div className="row arrowrap mt-3">
            <div className="col-md-6 col-6 d-flex align-items-center">
              {isEditModalOpen ? (
                <button
                  type="submit"
                  className="btn-green btn-mini float-right"
                  onClick={e => {
                    setStepThree(false);
                  }}>
                  {'Close'}
                </button>
              ) : (
                <a
                  className="font30"
                  data-toggle="modal"
                  onClick={e => {
                    backButton(e, 'stepThree');
                  }}>
                  <svg width=".7em" viewBox="2 1.99 20 20">
                    <path
                      fill="#6cb33f"
                      d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
                    />
                    <path
                      fill="#6cb33f"
                      d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
                    />
                  </svg>
                </a>
              )}
            </div>
            <div className="col-md-6 col-6 text-right">
              <button
                type="submit"
                className="btn-green btn-mini float-right"
                onClick={e => saveHandler(e)}>
                {isEditModalOpen ? 'Save' : 'Next'}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const stepThreeModalObject = {
    heading: 'Event',
    body: stepThreeMeetingBody,
    classNameModal: 'fade scheduleMeet modal-transbg createsched',
    show: true,
    headingSize: 'h5',
  };

  return <CommonModal {...stepThreeModalObject} />;
};

export default StepThreeMeetingModal;
