import React from 'react';

const ExtensionWebpage = ({ extensionDetails }) => {
  return (
    <iframe
      title={extensionDetails?.name}
      src={extensionDetails?.url}
      width="100%"
      height="100%" />
  );
};

export default ExtensionWebpage;
