import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {CommonModal} from '../../../../common/web';
import {removeTags} from '../../../../../utils/functions';

const StepFiveMeetingModal = ({manageFinalSteps, backButton, state}) => {
  const {invite_text} = state;
  const stepFiveMeetingBody = () => {
    return (
      <>
        <div className="row firstrow p-0 modal_min_height">
          <div className="col-md-12">
            <div className="container-fluid darkbg borderRD10">
              <div className="row justify-center">
                <div className="col-md-12">
                  <h3 className="text-center semiBold mb-3">
                    {' '}
                    Success! Your Event is Scheduled!
                  </h3>
                  <p className="text-center mb-2 semiBold">
                    Copy the following text and send
                    <br /> it to everyone you would like to invite.
                  </p>
                </div>
                <div className="col-md-8 col-8">
                  <div className="form-group row">
                    <p className="form-control">
                      {invite_text.indexOf('<br') !== -1 ? (
                        <div
                          className="TextHighlight"
                          dangerouslySetInnerHTML={{
                            __html: invite_text.replace(
                              /(<? *script)/gi,
                              'illegalscript',
                            ),
                          }}
                        />
                      ) : (
                        invite_text
                      )}
                    </p>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-3 col-3 pl-0">
                      <CopyToClipboard
                        options={{format: 'text/plain'}}
                        text={removeTags(invite_text)}>
                        <button role="button" className="btn btn-green m-0">
                          Copy Link
                        </button>
                      </CopyToClipboard>
                    </div>
                    <div className="col-md-9 col-9 p-0">
                      <small>
                        <span>HINT:</span> Remind co-hosts that they have to
                        log-in when they join the meetn in order to have host
                        powers.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="row arrowrap">
              <div className="col-md-6 col-6 d-flex align-items-center">
                <a
                  data-toggle="modal"
                  className="font30"
                  onClick={e => {
                    backButton(e, 'stepFive');
                  }}>
                  <svg width=".7em" viewBox="2 1.99 20 20">
                    <path
                      fill="#6cb33f"
                      d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
                    />
                    <path
                      fill="#6cb33f"
                      d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
                    />
                  </svg>
                </a>
              </div>
              <div className="col-md-6 col-6 text-right">
                <button
                  type="button"
                  onClick={e => manageFinalSteps(e)}
                  className="btn-green btn-mini float-right">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const stepFiveModalObject = {
    heading: `Event`,
    body: stepFiveMeetingBody,
    classNameModal: 'fade eventSettingsPop scheduleMeet createsched',
    show: true,
    headingSize: 'h5',
  };

  return <CommonModal {...stepFiveModalObject} />;
};

export default StepFiveMeetingModal;
