/* eslint-disable no-void */
import React, { useState } from 'react';
import { CommonModal } from '../../../../common/web';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function ManageReplay({ closeModal, state, updateStateHandler, downloadRecordingHandler }) {
    const { replay_enabled, event_url, download_audio, download_video, recording_video_url, recording_audio_url, embed_replay, replay_link } = state;

    console.log('download_video', download_audio, download_video,);
    const modalBody = () => {

        return (<>

            <div className="row firstrow p-0 modal_min_height">
                <div className="col-md-12 col-12 d-flex">
                    <div className="container">
                        <div className="form-group row">
                            {recording_video_url ? (
                                <>
                                    <label className="checkwrap backcheckwrap width70 float-left justify-left hAuto">
                                        Enable Replay:
                                        <input
                                            spellCheck="false"
                                            type="checkbox"
                                            name="replay_enabled"
                                            checked={
                                                replay_enabled
                                            }
                                            onChange={e => updateStateHandler(e)}

                                        />
                                        <span className="checkmark" />
                                    </label>
                                </>
                            ) : (
                                <>
                                    <p className="text-center mb-0">
                                        There is no recording attached to this event.
                                    </p>
                                </>
                            )}
                        </div>
                        {replay_enabled && recording_video_url ? (
                            <>
                                <div className="form-group row">
                                    <label className="justify-left">
                                        Replay Link:{' '}
                                    </label>
                                    <input
                                        spellCheck="false"
                                        type="text"
                                        readOnly
                                        value={event_url}
                                        className="form-control"

                                    />

                                </div>
                                <div className="form-group row">

                                    <CopyToClipboard
                                        options={{ format: 'text/plain' }}
                                        text={event_url}>
                                        <button role="button" className="btn btn-green m-0">
                                            Copy Link
                                        </button>
                                    </CopyToClipboard>
                                </div>
                                <div className="form-group row" />
                                {embed_replay && (
                                    <>
                                        <div className="form-group row">
                                            <span className="d-flex justify-content-between w-100">
                                                <label className="justify-left">
                                                    Replay Embed Code:
                                                </label>
                                                <a
                                                    className="tooltip_container tooltip_container--right float-right"
                                                    data-tooltip="This codes lets you embded the replay on your own site.  Send it to your webmaster.">
                                                    <span className="with_circle">
                                                        <svg height="60%" viewBox="7 4 10 16">
                                                            <path
                                                                fill="#ffffff"
                                                                d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z" />
                                                        </svg>
                                                    </span>
                                                </a>
                                            </span>

                                            <textarea
                                                spellCheck="false"
                                                readOnly
                                                value={embed_replay}
                                                className="form-control"
                                                rows={3} />
                                        </div>
                                        <div className="form-group row" />

                                    </>
                                )}



                                <div className="form-group row d-flex justify-content-between flex-row">
                                    <label className="switch modal-switch">
                                        <input
                                            spellCheck="false"
                                            type="checkbox"
                                            name="download_video"
                                            checked={
                                                download_video
                                            }
                                            onChange={e => updateStateHandler(e)}

                                        />
                                        <span className="slider round" />
                                        <span className="text-size-12 slider_text">
                                            Show a download video link on the replay page
                                        </span>
                                    </label>
                                </div>

                                <div className="form-group row d-flex justify-content-between flex-row">
                                    <label className="switch modal-switch">
                                        <input
                                            spellCheck="false"
                                            type="checkbox"
                                            name="download_audio"
                                            checked={
                                                download_audio
                                            }
                                            onChange={e => updateStateHandler(e)}
                                        />
                                        <span className="slider round" />
                                        <span className="text-size-12 slider_text">
                                            Show a download audio link on the replay page
                                        </span>
                                    </label>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                        {
                            recording_video_url && (<div className="form-group row mt-3">
                                <a href={void (0)} onClick={() => downloadRecordingHandler(recording_video_url)}>
                                    Download video recording (video+audio)
                                </a>
                            </div>
                            )}
                        {recording_audio_url && (
                            <div className="form-group row">
                                <a href={void (0)} onClick={() => downloadRecordingHandler(recording_audio_url)} >
                                    Download audio recording
                                </a>
                            </div>
                        )}
                        {recording_video_url && (
                            <div className="form-group row mt-3 mb-0">
                                <p className="mb-0">
                                    <small>
                                        NOTE: The Replay link is meant to be shared. The
                                        Download link is meant for one-time use only, and
                                        only works while you are logged in.
                                    </small>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row arrowrap mt-3">
                <div className="col-md-6 col-6" />
                <div className="col-md-6 col-6 text-right">
                    <button
                        type="button"
                        data-toggle="modal"
                        data-target="#managereplay"
                        className="btn-green btn-mini float-right"
                        onClick={e => closeModal(false)}
                    >
                        Close
                    </button>
                </div>
            </div>

        </>);
    };

    const modalObject = {
        heading: 'Event',
        body: modalBody,
        classNameModal: 'fade eventSettingsPop scheduleMeet createsched',
        show: true,
        headingSize: 'h5',
    };

    return <CommonModal {...modalObject} />;

}

export default ManageReplay;
