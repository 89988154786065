import React from 'react';
import { _getAvatarStyle, getAvatarColor } from '../../../utils/functions';
import AttendeeName from './attendeeName';

function Avatar({ participant, key }) {

    return (

        <div
            className="avatarParticipantName avatar-foreign"
            style={_getAvatarStyle(getAvatarColor(participant.name))}
            key={key || ''}
        >
            {
                participant.profile_image ? <>
                    <img
                        src={participant.profile_image}
                    />
                    <AttendeeName name={participant.name} />
                </> : participant.name
            }

        </div>
    );

}

export default Avatar;
