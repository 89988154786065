import React, {useRef, useState} from 'react';

import {CommonModal} from '../../../../common/web';
import SimpleReactValidator from 'simple-react-validator';
import {eventsAndRecording} from '../../../../../utils/socket';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

const StepFourMeetingModal = ({
  nextButton,
  backButton,
  state,
  setStateHandler,
  eventsAndRecordingList,
  setEventsAndRecordingList,
  setState,
  setStepFour,
  isEditModalOpen,
  saveEventsAndRecording,
}) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loader, setLoader] = useState(false);
  const {roomDetail} = useSelector(state => state.conf);

  const saveHandler = e => {
    e.preventDefault();
    setLoader(true);
    if (simpleValidator.current.allValid()) {
      saveEventsAndRecording(() => {
        if (!isEditModalOpen) {
          console.log('isEditModalOpen', isEditModalOpen);
          nextButton(e, 'stepFive');
        } else {
          setStepFour(false);
        }
      }, setLoader);
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setLoader(false);
    }
  };

  const stepFourMeetingBody = () => {
    return (
      <>
        <form className="form-format">
          <div className="row firstrow modal_min_height">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8 col-8 offset-md-2 offset-2 darkbg borderRD10">
                  <h6 className="semiBold mb-15">Basic Information</h6>
                  <div className="form-group row">
                    <label className="col-md-5 col-5 justify-content-end pr-0">
                      Event Name (Public):
                    </label>
                    <div className="col-md-7 col-7">
                      <div className="d-flex align-items-center justify-content-between">
                      <input
                        spellCheck="false"
                        type="text"
                        name="public_name"
                        value={state.public_name}
                        maxLength="50"
                        className="form-control width85 float-left"
                        onChange={e => setStateHandler(e)}
                      />

                      <a
                        className="tooltip_container tooltip_container--right float-right"
                        data-tooltip="The public name is used on the invite text and the replay page.">
                        <span className="with_circle">
                          <svg height="60%" viewBox="7 4 10 16">
                            <path
                              fill="#ffffff"
                              d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                            />
                          </svg>
                        </span>
                      </a>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-5 col-5 justify-content-end pr-0">
                      Event Name (Private):
                    </label>
                    <div className="col-md-7 col-7">
                      <div className="d-flex align-items-center justify-content-between">
                      <input
                        spellCheck="false"
                        type="text"
                        name="private_name"
                        maxLength="50"
                        className="form-control width85 float-left"
                        value={state.private_name}
                        onChange={e => setStateHandler(e)}
                      />
                      <a
                        className="tooltip_container tooltip_container--right float-right"
                        data-tooltip="The private name is used in the internal list of recordings/events. Only room admins and owners can see this.">
                        <span className="with_circle">
                          <svg height="60%" viewBox="7 4 10 16">
                            <path
                              fill="#ffffff"
                              d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                            />
                          </svg>
                        </span>
                      </a>
                      </div>
                      {simpleValidator.current.message(
                        'Event name',
                        state.private_name,
                        'required',
                        {messages: {default: 'Event name  is required.'}},
                      )}
                    </div>
                  </div>
                  <div className="form-group row d-flex justify-content-center flex-row">
                    <label className="col-md-5 col-5 justify-content-end pr-0">
                      Enable replay automatically if a recording is made:
                    </label>
                    <div className="col-md-7 col-7">
                      <label className="checkwrap backcheckwrap width70 float-left justify-left hAuto mr-3">
                        <input
                          spellCheck="false"
                          type="checkbox"
                          name="auto_replay"
                          value={state.auto_replay}
                          onChange={e => setStateHandler(e)}
                        />
                        <span className="checkmark" />
                      </label>
                      <a
                        className="tooltip_container tooltip_container--right float-right"
                        data-tooltip="This turns your event link into a replay as soon as the recording is ready.">
                        <span className="with_circle">
                          <svg height="60%" viewBox="7 4 10 16">
                            <path
                              fill="#ffffff"
                              d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-5 col-5 justify-content-end pr-0">
                      4-digit Passcode (Optional):
                    </label>
                    <div className="col-md-7 col-7">
                      <input
                        spellCheck="false"
                        type="text"
                        name="passcode"
                        value={state.passcode}
                        maxLength="4"
                        className="form-control width40 float-left"
                        onChange={e => setStateHandler(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row arrowrap mt-3">
            <div className="col-md-6 col-6 d-flex align-items-center">
              {isEditModalOpen ? (
                <button
                  type="submit"
                  className="btn-green btn-mini float-right"
                  onClick={e => {
                    setStepFour(false);
                  }}>
                  {'Close'}
                </button>
              ) : (
                <a
                  className="font30"
                  data-toggle="modal"
                  data-target="#ARstep2-5"
                  onClick={e => {
                    backButton(e, 'stepFour');
                  }}>
                  <svg width=".7em" viewBox="2 1.99 20 20">
                    <path
                      fill="#6cb33f"
                      d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
                    />
                    <path
                      fill="#6cb33f"
                      d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
                    />
                  </svg>
                </a>
              )}
            </div>
            <div className="col-md-6 col-6 text-right">
              <button
                type="submit"
                className="btn-green btn-mini float-right"
                onClick={e => {
                  saveHandler(e);
                }}>
                {isEditModalOpen ? 'Save' : 'Next'}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const stepFourModalObject = {
    heading: 'Event',
    body: stepFourMeetingBody,
    classNameModal: 'fade scheduleMeet createsched modal-transbg',
    show: true,
    headingSize: 'h5',
  };

  return <CommonModal {...stepFourModalObject} />;
};

export default StepFourMeetingModal;
