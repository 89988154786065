import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  recentRooms: [],
  recentUsers: [],
  AVPageRefresh: null,
  mirrorSelfView: false,
  loggedInUserData: {},
  loggedInUserRooms: [],
  goToSignIn: false,
  persistcameraDevice: null,
  persistmicDevice: null,
};
export const persistSclice = createSlice({
  name: 'persist',

  initialState,
  reducers: {
    setRecentRooms: (state, action) => {
      state.recentRooms = action.payload;
    },
    setRecentUsers: (state, action) => {
      state.recentUsers = action.payload;
    },
    setAVPageRefresh: (state, action) => {
      state.AVPageRefresh = action.payload;
    },
    setMirrorSelfView: (state, action) => {
      state.mirrorSelfView = action.payload;
    },
    setLoggedInUserData: (state, action) => {
      state.loggedInUserData = action.payload;
    },
    setLoggedInUserRooms: (state, action) => {
      state.loggedInUserRooms = action.payload;
    },
    setGoToSignIn: (state, action) => {
      state.goToSignIn = action.payload;
    },
    setPersistMicDevice: (state, action) => {
      state.persistmicDevice = action.payload;
    },
    setPersistCameraDevice: (state, action) => {
      state.persistcameraDevice = action.payload;
    },
  },
});

export const {
  setPersistMicDevice,
  setPersistCameraDevice,
  setRecentRooms,
  setRecentUsers,
  setAVPageRefresh,
  setMirrorSelfView,
  setLoggedInUserData,
  setLoggedInUserRooms,
  setGoToSignIn,
} = persistSclice.actions;

export default persistSclice.reducer;
