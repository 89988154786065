/* eslint-disable react-native/no-inline-styles */
import React, {memo, useEffect, useState} from 'react';
import {useTimer, useStopwatch} from 'react-timer-hook';

const ExtensionTimer = ({extensionDetails}) => {
  ///////////////DOWN TIMER///////////////
  // extensionDetails = {
  //   id: 'PWOgF9bi6MUUiO62P91j',
  //   extensionid: 36999,
  //   room: 'lovnish101',
  //   height: 15,
  //   width: 15,
  //   top: 15,
  //   left: 15,
  //   name: 'Down Timer',
  //   type: 'DOWN',
  //   status: 'inactive',
  //   goodMessage: true,
  //   goodMinutesCountDown: {label: '03 min', value: 3},
  //   goodSeconds: 180,
  //   goodText: 'Good',
  //   warningMessage: true,
  //   warningMinutesCountDown: {label: '02 min', value: 2},
  //   warningSeconds: 120,
  //   warningText: 'Warning',
  //   wrapUpMessage: true,
  //   wrapUpMinutesCountDown: {label: '01 min', value: 1},
  //   wrapupSeconds: 60,
  //   wrapupText: 'Wrap Up',
  //   visible_to_active_speaker: true,
  //   visible_to_guest: true,
  //   visible_to_host: true,
  //   seconds: 240,
  //   minutesCountDown: {label: '04 min', value: 4},
  //   secondsCountDown: {label: '00 sec', value: 0},
  //   timer_start: '',
  //   timer_resume: '',
  //   timer_resume_seconds: '',
  //   locked: false,
  //   position: 9,
  //   hideAtZero: true,
  // };

  ////////////Postman Object
  // {
  //   "id": "PWOgF9bi6MUUiO62P91j",
  //   "extensionid": 36999,
  //   "room": "lovnish101",
  //   "height": 15,
  //   "width": 15,
  //   "top": 15,
  //   "left": 15,
  //   "name": "Down Timer",
  //   "type": "DOWN",
  //   "status": "inactive",
  //   "goodMessage": true,
  //   "goodMinutesCountDown": {
  //     "label": "03 min",
  //     "value": 3
  //   },
  //   "goodSeconds": 180,
  //   "goodText": "Good",
  //   "warningMessage": true,
  //   "warningMinutesCountDown": {
  //     "label": "02 min",
  //     "value": 2
  //   },
  //   "warningSeconds": 120,
  //   "warningText": "Warning",
  //   "wrapUpMessage": true,
  //   "wrapUpMinutesCountDown": {
  //     "label": "01 min",
  //     "value": 1
  //   },
  //   "wrapupSeconds": 60,
  //   "wrapupText": "Wrap Up",
  //   "visible_to_active_speaker": true,
  //   "visible_to_guest": true,
  //   "visible_to_host": true,
  //   "seconds": 240,
  //   "minutesCountDown": {
  //     "label": "04 min",
  //     "value": 4
  //   },
  //   "secondsCountDown": {
  //     "label": "00 sec",
  //     "value": 0
  //   },
  //   "timer_start": "",
  //   "timer_resume": "",
  //   "timer_resume_seconds": "",
  //   "locked": false,
  //   "position": 9,
  //   "hideAtZero": true
  // }

  ///////////////UP TIMER///////////////
  // extensionDetails = {
  //   id: 'RwIbkmcOGMkqnsENOpeu',
  //   extensionid: 36999,
  //   room: 'lovnish101',
  //   height: 15,
  //   width: 15,
  //   top: 15,
  //   left: 15,
  //   name: 'UP TIMER',
  //   type: 'UP',
  //   status: 'paused',
  //   goodMessage: true,
  //   goodMinutesCountUp: {label: '01 min', value: 1},
  //   goodSeconds: 10,
  //   goodText: 'Good TexT',
  //   warningMessage: true,
  //   warningMinutesCountUp: {label: '02 min', value: 2},
  //   warningSeconds: 20,
  //   warningText: 'Warn TexT',
  //   wrapUpMessage: true,
  //   wrapUpMinutesCountUp: {label: '03 min', value: 3},
  //   wrapupSeconds: 30,
  //   wrapupText: 'Wrap TexT',
  //   visible_to_active_speaker: true,
  //   visible_to_guest: true,
  //   visible_to_host: true,
  //   timer_start: '',
  //   timer_resume: '',
  //   timer_resume_seconds: '',
  //   locked: false,
  //   position: 10,
  // };

  ////////////Postman Object
  //   {
  //     "id": "RwIbkmcOGMkqnsENOpeu",
  //     "extensionid": 36999,
  //     "room": "lovnish101",
  //     "height": 15,
  //     "width": 15,
  //     "top": 15,
  //     "left": 15,
  //     "name": "UP TIMER",
  //     "type": "UP",
  //     "status": "start",
  //     "goodMessage": true,
  //     "goodMinutesCountUp": {
  //         "label": "01 min",
  //         "value": 1
  //     },
  //     "goodSeconds": 10,
  //     "goodText": "Good TexT",
  //     "warningMessage": true,
  //     "warningMinutesCountUp": {
  //         "label": "02 min",
  //         "value": 2
  //     },
  //     "warningSeconds": 20,
  //     "warningText": "Warn TexT",
  //     "wrapUpMessage": true,
  //     "wrapUpMinutesCountUp": {
  //         "label": "03 min",
  //         "value": 3
  //     },
  //     "wrapupSeconds": 30,
  //     "wrapupText": "Wrap TexT",
  //     "visible_to_active_speaker": true,
  //     "visible_to_guest": true,
  //     "visible_to_host": true,
  //     "timer_start": "",
  //     "timer_resume": "",
  //     "timer_resume_seconds": "",
  //     "locked": false,
  //     "position": 10
  // }

  const [timerBackground, setTimerBackground] = useState('');
  const [timerViewMessage, setTimerViewMessage] = useState('Timer');
  const [showTimerLabel, setShowTimerLabel] = useState(false);

  const manageTimerLabelIframe = () => {};
  const manageTimerIframe = () => {};
  const showTime = () => {};
  const showLabel = () => {};
  const showTimer = () => {};
  const timerMessage =
    extensionDetails.goodMessage ||
    extensionDetails.warningMessage ||
    extensionDetails.wrapUpMessage
      ? true
      : false;

  function timerConditions(totalSeconds) {
    if (
      extensionDetails.goodMessage &&
      totalSeconds === extensionDetails.goodSeconds
    ) {
      setTimerBackground('timer-good-bg');
      setTimerViewMessage(extensionDetails.goodText);
    }

    if (
      extensionDetails.warningMessage &&
      totalSeconds === extensionDetails.warningSeconds
    ) {
      setTimerBackground('timer-warn-bg');
      setTimerViewMessage(extensionDetails.warningText);
    }

    if (
      extensionDetails.wrapUpMessage &&
      totalSeconds === extensionDetails.wrapupSeconds
    ) {
      setTimerBackground('timer-wrap-bg');
      setTimerViewMessage(extensionDetails.wrapupText);
    }
  }

  const time = new Date();
  time.setSeconds(time.getSeconds() + extensionDetails.seconds);

  return (
    <div className="meeting_time_container">
      <div className={`meeting_time ${timerBackground}`}>
        <div className="d-flex justify-content-center">
          {extensionDetails.type.toLowerCase() === 'down' ? (
            <DownTimer
              expiryTimestamp={time}
              extensionDetails={extensionDetails}
              timerConditions={timerConditions}
              timerMessage={timerMessage}
            />
          ) : (
            <UpTimer
              extensionDetails={extensionDetails}
              timerConditions={timerConditions}
              timerMessage={timerMessage}
            />
          )}
          <small>{timerViewMessage}</small>
        </div>
      </div>

      <div>
        <a
          className="meeting_time_menu_btn"
          onClick={e => {
            manageTimerLabelIframe();
          }}>
          <svg width="100%" viewBox="10 4 4 16">
            <path fill="#ffffff" d="M10 10h4v4h-4zm0-6h4v4h-4zm0 12h4v4h-4z" />
          </svg>
        </a>
        {showTimerLabel && (
          <div className="meeting_time_menu_options">
            <label className="checkwrap">
              Show Time
              <input
                spellCheck="false"
                type="checkbox"
                id="showTime"
                name="showTime"
                onClick={e => {
                  manageTimerIframe(e);
                }}
                checked={showTime}
              />
              <span className="checkmark" />
            </label>
            <label className="checkwrap">
              Show Label
              <input
                spellCheck="false"
                type="checkbox"
                id="showLabel"
                name="showLabel"
                onClick={e => {
                  manageTimerIframe(e);
                }}
                checked={showLabel}
              />
              <span className="checkmark" />
            </label>
            <label className="checkwrap">
              Show Timer
              <input
                spellCheck="false"
                type="checkbox"
                id="showTimer"
                name="showTimer"
                onClick={e => {
                  manageTimerIframe(e);
                }}
                checked={showTimer}
              />
              <span className="checkmark" />
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

const DownTimer = ({
  expiryTimestamp,
  extensionDetails,
  timerConditions,
  timerMessage,
}) => {
  const {seconds, minutes, totalSeconds, start, pause, resume} = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn('onExpire called'),
  });

  if (timerMessage) {
    timerConditions(totalSeconds);
  }

  const formattedMinutes = minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const formattedSeconds = seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const timerStatus = extensionDetails.status.toLowerCase();

  useEffect(() => {
    if (timerStatus === 'start') {
      start();
    } else if (timerStatus === 'pause') {
      pause();
    } else if (timerStatus === 'resume') {
      // const time = new Date();
      // time.setSeconds(
      //   time.getSeconds() + extensionDetails.timer_resume_seconds,
      // );
      // restart(time);
      resume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerStatus]);

  return (
    <div>
      <div>
        <span>{formattedMinutes}</span>:<span>{formattedSeconds}</span>
      </div>
    </div>
  );
};

const UpTimer = ({extensionDetails, timerConditions, timerMessage}) => {
  const {seconds, minutes, totalSeconds, start, pause} = useStopwatch({
    autoStart: true,
  });

  if (timerMessage) {
    timerConditions(totalSeconds);
  }

  const formattedMinutes = minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const formattedSeconds = seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const timerStatus = extensionDetails.status.toLowerCase();

  useEffect(() => {
    if (timerStatus === 'start' || timerStatus === 'resume') {
      start();
    } else if (timerStatus === 'pause') {
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerStatus]);

  return (
    <div>
      <div>
        <span>{formattedMinutes}</span>:<span>{formattedSeconds}</span>
      </div>
    </div>
  );
};

export default memo(ExtensionTimer);
