import React, { useRef, useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { DEFAULT_LAODING_MSG } from '../../../utils/util';
import { validateRoomPasscode } from '../../../utils/socket';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setPreviousRouteStep } from '../../../reduxStore/confSlice';


function Passcode() {
    const simpleValidator = useRef(new SimpleReactValidator());
    const { roomDetail } = useSelector(state => state.conf);
    const [passcode, setPasscode] = useState('');
    const [submitLoader, setSubmitLoader] = useState(false);
    const [, forceUpdate] = useState();
    const dispatch = useDispatch();

    const validatePasscode = async (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            setSubmitLoader(true);
            validateRoomPasscode({
                room: roomDetail?.room,
                id: roomDetail?.id,
                passcode,
            }).then(response => {
                console.log('validateRoomPasscode', response);
                if (response?.status === 200) {
                    dispatch(setPage('Connect'));
                    dispatch(setPreviousRouteStep('Passcode'));
                } else {
                    toast.error(response.data[0].msg, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }

            }).finally(() => {
                setSubmitLoader(false);
            })
        } else {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        }
    };

    return (<>

        <div className="col-md-6 light-bg p-equal MobileContent">
            {
                <form className="mt-3" onSubmit={validatePasscode}>
                    <label className="mb-15">
                        This Meetn Room requires a passcode.
                    </label>
                    <div className="form-group">
                        <label>
                            Enter the 4-digit passcode
                        </label>
                        <input spellCheck="false" type="password" maxLength={4} name="passcode" value={passcode} onChange={(e) => setPasscode(e.target.value)} className="form-control" />
                        {simpleValidator.current.message('passcode', passcode, 'required|integer|min:4|max:4',)}
                    </div>
                    <div className="form-group mt-4">
                        <button type="submit" disabled={submitLoader} className="btn btn-green abso-btn" >
                            {submitLoader ? DEFAULT_LAODING_MSG : 'Next'}
                            <i class="icon-arrow-right-circle icons" />
                        </button>
                    </div>
                </form>
            }
        </div>
    </>);
}

export default Passcode