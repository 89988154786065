import React from 'react';
import {Video} from '../../../../common/web';
import {useSelector} from 'react-redux';

const VirtualBackgroundPreview = ({localVideoTrack}) => {
  const {mirrorSelfView} = useSelector(state => state.persist);

  console.log('mirrorSelfView==>', mirrorSelfView);
  let videoTrack = {jitsiTrack: localVideoTrack};
  return (
    <div className="video-preview">
      <label>Camera Preview:</label>
      {localVideoTrack ? (
        <div className="video-background-preview-entry">
          <Video
            videoTrack={videoTrack}
            className={`video-input-preview-display video-preview-video ${
              mirrorSelfView ? 'flipVideoX' : ''
            }`}
          />
        </div>
      ) : (
        <div className="video-input-preview video-background-preview-entry">
          <div className="video-input-preview-error">
            <b>Preview Unavailable.</b> Did you give permission? <br /> Is
            camera already in use? <br /> Is camera on?
          </div>
        </div>
      )}
    </div>
  );
};

export default VirtualBackgroundPreview;
