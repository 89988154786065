import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {CommonModal} from '../../../common/web';
import {
  setShowEditProfileModal,
  setUserDetail,
} from '../../../../reduxStore/confSlice';
import {
  MEDIA_URL,
  TenMegaBytes,
  uploadImageMimeType,
} from '../../../../utils/util';
import {getImagesURL, uploadFileInWasabi} from '../../../../utils/functions';
import {updParticipant, updateProfile} from '../../../../utils/socket';
import {
  setGoToSignIn,
  setRecentUsers,
} from '../../../../reduxStore/persistSlice';
import {alertMsg} from '../../../common/web/alert';
import {toast} from 'react-toastify';

const EditProfileModal = () => {
  const dispatch = useDispatch();
  const {localUser} = useSelector(state => state.conf);
  const {recentUsers} = useSelector(state => state.persist);
  const [loader, setLoader] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  console.log('localUser', localUser);

  const [state, setState] = useState({
    name: '',
    password: '',
    email: '',
    allow_host_unmute_audio: false,
    displayImageUrl: '',
    file: {},
  });
  useEffect(() => {
    const {name, password, email, allow_host_unmute_audio, profile_image} =
      localUser;
    setState({
      ...state,
      name,
      password,
      email,
      allow_host_unmute_audio,
      displayImageUrl: profile_image,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localUser]);

  const closeModal = () => {
    dispatch(setShowEditProfileModal(false));
  };

  const setStateHandler = e => {
    if (e.target.name === 'name') {
      let newVal = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
      setState({
        ...state,
        name: newVal,
      });
    } else {
      setState({
        ...state,
        [e.target.name]:
          e.target.name === 'allow_host_unmute_audio'
            ? e.target.checked
            : e.target.value,
      });
    }
  };

  const onFileChange = e => {
    const files = e.target.files;
    let imgType = files[0]?.type;
    let imgSize = files[0]?.size;
    if (uploadImageMimeType().includes(imgType)) {
      if (imgSize < TenMegaBytes) {
        getImagesURL(files).then(res => {
          setState({
            ...state,
            displayImageUrl: res[0],
            file: files[0],
          });
        });
      } else {
        alertMsg({
          msg: 'File too large. Please upload a file less than 10 MB.',
          type: 'info',
        });
      }
    } else {
      document.getElementById('file').value = '';
      alertMsg({msg: 'Please upload a valid image file.', type: 'info'});
    }
  };

  const removeImage = () => {
    setState({
      ...state,
      displayImageUrl: '',
      file: {},
    });
  };

  const UpdateUserInfo = profile_image_url => {
    const {name, email, password, allow_host_unmute_audio} = state;
    const tempLocalUser = {...localUser};
    tempLocalUser.name = name;
    tempLocalUser.email = email;
    tempLocalUser.allow_host_unmute_audio = allow_host_unmute_audio;
    tempLocalUser.profile_image = profile_image_url;
    if (
      localUser.allow_host_unmute_audio !== allow_host_unmute_audio ||
      localUser.profile_image !== profile_image_url ||
      localUser.name !== name
    ) {
      updParticipant(tempLocalUser);
    }
    dispatch(setUserDetail(tempLocalUser));
    const updatedRecenetUser = [...recentUsers];
    const findIndex = recentUsers.findIndex(
      item => item.token === localUser.token,
    );
    const particularUser = {...updatedRecenetUser[findIndex]};
    particularUser.profile_image = profile_image_url;
    particularUser.name = name;
    particularUser.email = email;
    particularUser.allow_host_unmute_audio = allow_host_unmute_audio;
    updatedRecenetUser[findIndex] = particularUser;
    dispatch(setRecentUsers(updatedRecenetUser));
  };

  const saveSetting = async e => {
    e.preventDefault();
    const IsUserGuest = localUser?.email === '' ? true : false;
    console.log('IsUserGuest', IsUserGuest);
    if (IsUserGuest) {
      if (state.email !== '') {
        if (
          simpleValidator.current.fieldValid('email') &&
          simpleValidator.current.fieldValid('password') &&
          simpleValidator.current.fieldValid('name')
        ) {
          /// update profile as host
          setLoader(true);
          const profileResp = await updateProfileImage();
          console.log('profileResp', profileResp);
          const {imageName, isImageUpdated} = profileResp;
          const profile_image_url = isImageUpdated
            ? `${MEDIA_URL}${imageName}`
            : state.displayImageUrl;
          updateLoggedInUserProfile(profile_image_url);
        } else {
          simpleValidator.current.showMessageFor('email');
          simpleValidator.current.showMessageFor('password');
          simpleValidator.current.showMessageFor('name');
          forceUpdate(1);
        }
      } else {
        if (state.name === '') {
          simpleValidator.current.showMessageFor('name');
          forceUpdate(1);
        } else {
          /// guest profile update
          setLoader(true);
          const profileResp = await updateProfileImage();
          console.log('profileResp', profileResp);
          const {imageName, isImageUpdated} = profileResp;
          const profile_image_url = isImageUpdated
            ? `${MEDIA_URL}${imageName}`
            : state.displayImageUrl;
          UpdateUserInfo(profile_image_url);
          closeModal();
        }
      }
    } else {
      if (simpleValidator.current.fieldValid('name')) {
        /// update profile as host
        setLoader(true);
        const profileResp = await updateProfileImage();
        console.log('profileResp', profileResp);
        const {imageName, isImageUpdated} = profileResp;
        const profile_image_url = isImageUpdated
          ? `${MEDIA_URL}${imageName}`
          : state.displayImageUrl;
        updateLoggedInUserProfile(profile_image_url);
      } else {
        simpleValidator.current.showMessageFor('name');
        forceUpdate(1);
      }
    }
  };

  const updateProfileImage = async () => {
    return new Promise(async resolve => {
      const {displayImageUrl} = state;
      let imageName = '';
      let isImageUpdated = false;
      if (localUser.profile_image !== displayImageUrl) {
        if (displayImageUrl !== '') {
          isImageUpdated = true;
          const selectedFiles = [];
          selectedFiles.push(state.file);
          const [imageResp] = await uploadFileInWasabi(selectedFiles);
          imageName =
            imageResp && imageResp.length > 0 && imageResp[0].attachmentName;
        }
      }
      resolve({imageName, isImageUpdated});
    });
  };

  const updateLoggedInUserProfile = profile_image_url => {
    const {name, email, allow_host_unmute_audio, password} = state;
    const profilePayload = {
      name,
      email,
      allow_host_unmute_audio,
      password,
      profile_image: profile_image_url,
    };
    updateProfile(profilePayload).then(res => {
      setLoader(false);
      if (res.status === 200) {
        UpdateUserInfo(profile_image_url);
        closeModal();
      } else {
        toast.error(res?.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const goToSignIn = e => {
    e.preventDefault();
    dispatch(setGoToSignIn(true));
    window.location.reload();
  };
  const editProfileBody = () => {
    return (
      <>
        <div className="row profilemodalwrap">
          <div
            className={`col-md-5 col-5 text-center ${
              (localUser.email === '' || localUser.email === null) &&
              'fullHeight'
            } `}>
            <div className="container-fluid bg-light modal_min_height">
              <div className="row justify-center">
                <div className="imgwrap text-center">
                  <div className="imagewrap">
                    <span className="shadow1">
                      <span className="shadow2">
                        <div>
                          <img
                            src={
                              state.displayImageUrl !== ''
                                ? state.displayImageUrl
                                : 'assets/images/img5.png'
                            }
                          />
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row justify-center">
                <div className="col-md-12 p-0 mt-3">
                  <a
                    href="javascript:void(0)"
                    className="custom-input-file btn btn-green btn-mini abso-btn">
                    <input
                      spellCheck="false"
                      className="form-control"
                      type="file"
                      id="file"
                      accept="image/*"
                      onChange={e => onFileChange(e)}
                    />
                    Upload a Photo
                  </a>
                </div>
              </div>
              <div className="row justify-center">
                <div className="col-md-12 mt-3">
                  <p className="semiBold mb-0">
                    <a href="javascript:void(0)" onClick={() => removeImage()}>
                      Remove Photo
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-7 pl-0">
            <div
              className={`container-fluid bg-light ${
                localUser.email === '' || localUser.email === null
                  ? 'mb-3'
                  : 'modal_min_height'
              } `}>
              <p className="semiBold mb-4">Profile Info:</p>
              <div className="form-group row">
                <label className="col-md-4 col-4 label-inline pr-0 text-right">
                  Name:
                </label>
                <div className="col-md-8 col-8">
                  <input
                    spellCheck="false"
                    type="text"
                    maxLength="50"
                    name="name"
                    className="form-control"
                    onChange={e => setStateHandler(e)}
                    value={state.name}
                  />
                  {simpleValidator.current.message(
                    'name',
                    state.name,
                    'required',
                  )}
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-md-3 col-3" />
                <div className="col-md-9 col-9">
                  <label className="checkwrap checkwrap_profile justify-left hAuto">
                    Allow hosts+ to unmute my audio
                    <input
                      spellCheck="false"
                      type="checkbox"
                      name="allow_host_unmute_audio"
                      checked={state.allow_host_unmute_audio}
                      onChange={e => setStateHandler(e)}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
            </div>
            {(localUser.email === '' || localUser.email === null) && (
              <div className="container-fluid bg-light">
                <p className="semiBold">Login Info:</p>
                <p className="mb-4">
                  <small>
                    Fill out this section so you can use your profile in future
                    events and on multiple devices.&nbsp;
                    <a href={location.href} onClick={e => goToSignIn(e)}>
                      Sign in
                    </a>
                  </small>
                </p>
                <div className="form-group row">
                  <label className="col-md-4 col-4 label-inline pr-0 text-right">
                    Email:
                  </label>
                  <div className="col-md-8 col-8">
                    <input
                      spellCheck="false"
                      type="text"
                      name="email"
                      className="form-control"
                      onChange={e => setStateHandler(e)}
                      value={state.email}
                    />
                    {simpleValidator.current.message(
                      'email',
                      state.email,
                      'required|email',
                      {
                        messages: {
                          default: 'Email field is required.',
                          email: 'Email must be a valid email address.',
                        },
                      },
                    )}
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-md-4 col-4 label-inline pr-0 text-right">
                    Desired Password:
                  </label>
                  <div className="col-md-8 col-8">
                    <input
                      spellCheck="false"
                      type="password"
                      autoComplete="new-password"
                      name="password"
                      className="form-control"
                      onChange={e => setStateHandler(e)}
                      value={state.password}
                    />
                    {simpleValidator.current.message(
                      'password',
                      state.password,
                      'required',
                      {messages: {default: 'Password field is required.'}},
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="row space-btw mt-3">
              <div className="col-md-12 text-right">
                <button
                  type="button"
                  onClick={() => {
                    closeModal();
                  }}
                  data-dismiss="modal"
                  className="btn-link">
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-green btn-mini abso-btn float-right"
                  onClick={e => saveSetting(e)}>
                  {loader ? 'Please Wait...' : 'Save'}
                  <i className="icon-arrow-right-circle icons" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const editProfileObject = {
    heading: 'Edit Profile',
    body: editProfileBody,
    show: true,
    classNameDialog: 'modal-lg',
    headingSize: 'h5',
    closeModal: closeModal,
  };

  return (
    <>
      <CommonModal {...editProfileObject} />;
    </>
  );
};

export default EditProfileModal;
