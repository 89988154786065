/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
import React, { startTransition, useEffect } from 'react';
import OvenPlayer from 'ovenplayer';
import { useDispatch, useSelector } from 'react-redux';
import Attendees from './attendees/attendees';
import Chat from './chat';
import { LEM_ERROR_MSG, LEM_STREAM_ERROR_MSG } from '../../../utils/util';
import swal from 'sweetalert';
import { lemAuthenticate } from '../../../utils/socket';
import { useNavigate } from 'react-router-dom';
import { alertMsg } from '../../common/web/alert';
import { setSpeaker } from '../../../reduxStore/confSlice';

let interval = 0;
let intervalTime = 30 * 1000;
function LargeEventView({ lem_signed_url }) {
    const { attendeesClick, chatClick, roomDetail, localUser, speaker } = useSelector(state => state.conf);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        loadPlayer();
    }, []);

    useEffect(() => {
        interval = setInterval(() => {
            validateLem();
        }, intervalTime);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const player = OvenPlayer.getPlayerByContainerId('oven-player');

        if (roomDetail?.end_meetn && localUser?.role === 'guest') {
            player?.remove();
            clearInterval(interval);
        } else if (localUser?.lem_mode === false) {
            player?.remove();
            clearInterval(interval);
        }
    }, [roomDetail?.end_meetn, localUser?.lem_mode]);

    useEffect(() => {

        const player = OvenPlayer.getPlayerByContainerId('oven-player');

        if (!speaker) {
            player?.setMute(true);
        } else {
            player?.setMute(false);
        }
    }, [speaker]);

    const redirectToHome = () => {
        navigate('/');
    };
    const loadPlayer = () => {

        console.log('lem_signed_url==>>', lem_signed_url);
        const player = OvenPlayer.create('oven-player', {
            sources: [
                {
                    label: 'Meetn',
                    type: 'webrtc',
                    file: lem_signed_url,
                },
            ],
            autoStart: true,
            showBigPlayButton: false,
            controls: false,
            mute: true,
        });

        player.on('ready', (data) => {
            console.log('ready');
            dispatch(setSpeaker(false));
            player.getConfig().systemText.api.error[501].message = '...';
            player.getConfig().systemText.api.error[511].message = '...';
        });
        player.on('error', (error) => {
            let errMsg = LEM_ERROR_MSG;
            if (error?.code === 510 || error?.code === 511) {
                errMsg = LEM_STREAM_ERROR_MSG;
            }

            swal(errMsg, {
                button: {
                    text: 'Retry',
                },
                icon: 'warning',
                className: 'oven-player-alert',
            }).then((ok) => {
                validateLem(errMsg, error?.code);
                player?.remove();
                startTransition(() => {
                    console.log('Load player');
                    loadPlayer();
                });
            });
        });
    };

    const validateLem = (error = '', error_code = '', prevPlayer = '') => {
        let playerdata = {};
        let player = '';

        if (error && prevPlayer) {
            player = prevPlayer;
        } else {
            player = OvenPlayer.getPlayerByContainerId('oven-player');
        }

        if (player) {
            playerdata = {
                getVersion: player?.getVersion(),
                getState: player?.getState(),
                getFramerate: player?.getFramerate(),
                getDuration: player?.getDuration(),
                getPosition: player?.getPosition(),
                getVolume: player?.getVolume(),
                getMute: player?.getMute(),
                getQualityLevels: player?.getQualityLevels(),
                isAutoQuality: player?.isAutoQuality(),
            };
        }

        const payload = {
            room: roomDetail?.room,
            token_unique: localUser?.token_unique,
            error,
            error_code,
            ...playerdata,
        };
        console.log('payload', payload);

        lemAuthenticate(payload).then((response) => {

            if (response.status === 200) {
                const { lem_mode } = response.data[0];
                console.log('lem_mode', lem_mode);
                if (!lem_mode) {
                    alertMsg({ msg: LEM_ERROR_MSG }, redirectToHome);
                    player?.remove();
                    clearInterval(interval);
                }

            } else {

            }
        });
    };

    return (<>
        <div className={`player-wrapper ${chatClick || attendeesClick ? 'wrap-width' : ''}`} style={{ position: 'relative' }}>
            <div id="oven-player" />
        </div>

        <div className={`Slidebar asside ${chatClick || attendeesClick ? 'active' : ''}`} id="conferecne-sidebar">
            <div className="tab-content" id="myTabContent">
                {attendeesClick && <Attendees />}
                {chatClick && <Chat />}
            </div>
        </div>
    </>);

}

export default LargeEventView;
