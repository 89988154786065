import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CommonModal } from '../../../../common/web';
import { Capitalize, featureAccess } from '../../../../../utils/functions';
import AddRoomModal from './addRoomModal';
import {
  setBilling,
  setShowManageRoomModal,
  setTrial,
} from '../../../../../reduxStore/confSlice';
import {
  deleteRoomConfirm,
  deleteRoomProcess,
  getRoomList,
  revokeDeleteRoomPermission,
  revokeDowngradeRoomPermission,
} from '../../../../../utils/socket';
import { toast } from 'react-toastify';
import RoomSettingModal from '../roomSettingModal';
import WebinarSetting from '../../webinarSetting';
import WebinarSettingsModal from '../webinarSettingsModal';
import RoomPermissionModal from '../roomPermission/roomPermissionModal';
import UpdateRoomPlanModal from './updateRoomPlanModal';

import swal from 'sweetalert';
import { test_mode_id } from '../../../../../utils/util';
import { alertMsg } from '../../../../common/web/alert';
import {
  manageUpgrade,
  upgradeAccountConfirmHandler,
} from '../../utils/functions';

const ManageRoomModal = () => {
  const [isAddModalOpen, setIsOpenModal] = useState(false);
  const [roomList, setRoomList] = useState([]);
  const { roomDetail, localUser, trial, billing } = useSelector(
    state => state.conf,
  );
  const { token } = localUser;
  const [selectedRoom, setSelecetedRoom] = useState({});
  const [RoomSetting, setRoomSetting] = useState(false);
  const [Webinar, setWebinar] = useState(false);
  const [permission, setPermission] = useState(false);
  const [free, SetFree] = useState(false);
  const [listLoader, setListLoader] = useState(true);

  const [updateRoomPlanShow, setUpdateRoomPlanShow] = useState(false);

  const dispatch = useDispatch();

  console.log('roomList', roomList);
  console.log('roomDetail', roomDetail);

  useEffect(() => {
    roomListHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    dispatch(setShowManageRoomModal(false));
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const roomListHandler = () => {
    setListLoader(true);
    getRoomList({
      token,
    }).then(res => {
      setListLoader(false);
      if (res.status === 200) {
        setRoomList([...res.data]);
        dispatch(setTrial(res.trial));
        dispatch(setBilling(res.billing));
        SetFree(res.free);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const addRecordInRoomList = data => {
    setRoomList([...roomList, data]);
  };

  const closeRoomSettingModal = data => {
    setRoomSetting(false);
    updateRoomList(data);
  };

  const closeWebinarSettingModal = data => {
    setWebinar(false);
    updateRoomList(data);
  };

  const closePermissionSettingModal = data => {
    setPermission(false);
  };

  const updateRoomList = data => {
    const tempRoomList = [...roomList];
    const tmpIndex = roomList.findIndex(item => item.room === data?.room);
    if (tmpIndex !== -1) {
      tempRoomList[tmpIndex] = data;
    }
    setRoomList(tempRoomList);
  };

  const revokeDeletePermissionHandler = room => {
    revokeDeleteRoomPermission({ room, token }).then(res => {
      if (res.status === 200) {
        roomListHandler();
        toast.success(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const revokeDowngradePermissionHandler = room => {
    revokeDowngradeRoomPermission({ room, token }).then(res => {
      if (res.status === 200) {
        roomListHandler();
        toast.success(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const deleteRoomConfirmHandler = (e, roomItem) => {
    e.preventDefault();
    deleteRoomConfirm({ token, room: roomItem?.room }).then(res => {
      if (res.status === 200) {
        swal({
          title: res.data[0].msg,
          closeOnClickOutside: false,
          buttons: ['Cancel', 'Confirm'],
          dangerMode: true,
        }).then(Confirm => {
          if (Confirm) {
            deleteRoomProcessHandler(roomItem);
          }
        });
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const deleteRoomProcessHandler = roomItem => {
    deleteRoomProcess({
      room: roomItem?.room,
      token,
      test_mode_id: test_mode_id,
    }).then(res => {
      if (res.status == 200) {
        alertMsg({ msg: res.data[0].msg }, roomListHandler);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const addBundleBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="row firstrow align-items-baseline p-0 modal_min_height">
              <div className="col-md-12 p-0">
                {trial && billing && (
                  <div className="col-md-12 mb-3">
                    <div className="d-flex">
                      You are using trial version of Meetn. &nbsp;
                      <a
                        href="javascript:void(0)"
                        onClick={e => upgradeAccountConfirmHandler(e)}>
                        Click here
                      </a>
                      &nbsp; to Upgrade
                    </div>
                  </div>
                )}
                <div className="col-md-12 table-responsive mb-3">
                  <table
                    className={`table table-striped ${listLoader ? 'min-h-200' : ''
                      }`}>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>
                          <span className="font16">Room Name</span>
                        </th>
                        <th>
                          <span className="font16">Room Type</span>
                        </th>

                        <th className="text-center">
                          <span>Settings</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!listLoader ? (
                        roomList.length > 0 ? (
                          roomList.map((roomItem, index) => {
                            return (
                              <>
                                <tr className="room-settings">
                                  <td className="font15">{index + 1}</td>
                                  <td className="font15" data-ng-if="room.room">
                                    Meetn.com/
                                    {roomItem.room}
                                  </td>
                                  <td className="font15">
                                    {Capitalize(roomItem.plan)}
                                    {!trial && (
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => {
                                          setSelecetedRoom(roomItem);
                                          setUpdateRoomPlanShow(true);
                                        }}>
                                        Change Plan
                                      </a>
                                    )}
                                  </td>

                                  <td className="text-center">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        setSelecetedRoom(roomItem);
                                        setRoomSetting(true);
                                      }}>
                                      Room
                                    </a>

                                    {
                                      featureAccess('webinar', roomItem.plan) ? <a
                                        href="javascript:void(0)"
                                        onClick={() => {
                                          setSelecetedRoom(roomItem);
                                          setWebinar(true);
                                        }}>
                                        Webinar
                                      </a> : <a
                                        href="javascript:void(0)"
                                        className="m-3"
                                      >
                                        ---
                                      </a>
                                    }

                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        setSelecetedRoom(roomItem);
                                        setPermission(true);
                                      }}>
                                      Permissions
                                    </a>

                                    <a
                                      href="javascript:void(0)"
                                      onClick={e => {
                                        deleteRoomConfirmHandler(e, roomItem);
                                      }}>
                                      <i
                                        class="fa fa-close"
                                        style={{ color: 'red' }}></i>
                                    </a>
                                  </td>
                                </tr>
                                {roomItem?.deleted && (
                                  <>
                                    <tr>
                                      {' '}
                                      <td></td>
                                      <td colSpan="3" className=" border-top">
                                        <p className="text-left mb-0">
                                          {`Meetn.com/${roomItem.room} is set to be deleted on 2023-07-20.`}
                                          <a
                                            href="javascript:void(0)"
                                            onClick={e =>
                                              revokeDeletePermissionHandler(
                                                roomItem.room,
                                              )
                                            }>
                                            Cancel
                                          </a>
                                        </p>
                                      </td>
                                    </tr>
                                  </>
                                )}

                                {roomItem?.downgraded && (
                                  <>
                                    <tr>
                                      {' '}
                                      <td></td>
                                      <td colSpan="3" className=" border-top">
                                        <p className="text-left mb-0">
                                          {`Meetn.com/${roomItem.room} is set to be downgrade on 2023-07-20.`}
                                          <a
                                            href="javascript:void(0)"
                                            onClick={e =>
                                              revokeDowngradePermissionHandler(
                                                roomItem.room,
                                              )
                                            }>
                                            Cancel
                                          </a>
                                        </p>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <tr className="room-settings">
                            <td colSpan="3" className="text-center">
                              <span>No Rooms Rocord.</span>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr className="room-settings">
                          <td colSpan="3" className="text-center border-top">
                            <img
                              src="assets/images/loader-x.gif"
                              className="pop-loader"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-right">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn-link float-right"
                  onClick={() => handleClose()}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const headingContent = () => {
    return (
      <>
        <a
          id="addRoomUserpanelBtn"
          data-toggle="modal"
          onClick={() => openModal()}
          className="btn btn-green btn-mini heading-content">
          Add a Room
        </a>
      </>
    );
  };

  const manageRoomModalObject = {
    heading: ' Add/Remove/Manage Rooms',
    headingContent: !trial && billing ? headingContent : undefined,
    body: addBundleBody,
    classNameModal: 'fade scheduleMeet createsched title-full',
    show: true,
    headingSize: 'h5',
    classNameTitle: 'w-100',
  };

  return (
    <>
      <CommonModal {...manageRoomModalObject} />
      {isAddModalOpen && (
        <AddRoomModal
          closeModal={closeModal}
          UpdateRoomList={addRecordInRoomList}
        />
      )}
      {RoomSetting && (
        <RoomSettingModal
          roomDetail={selectedRoom}
          closeRoomSettingModal={closeRoomSettingModal}
        />
      )}

      {Webinar && (
        <WebinarSettingsModal
          roomDetail={selectedRoom}
          closeWebinarSettingModal={closeWebinarSettingModal}
        />
      )}

      {permission && (
        <RoomPermissionModal
          roomDetail={selectedRoom}
          closeRoomPermissionModal={closePermissionSettingModal}
        />
      )}

      {updateRoomPlanShow && (
        <UpdateRoomPlanModal
          roomDetail={selectedRoom}
          closeModal={() => setUpdateRoomPlanShow(false)}
          roomListHandler={roomListHandler}
          free={free}
        />
      )}
    </>
  );
};

export default ManageRoomModal;
