import React from "react";

export default function Icon({
    className,
    src: IconComponent,
    onClick
}) {

    return (<div className={`${className || ''}`} onClick={onClick}>
        <IconComponent />
    </div>)
}