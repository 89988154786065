import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {CommonModal} from '../../../common/web';
import {setShowWebinarSettingModal} from '../../../../reduxStore/confSlice';
import {roomSettings} from '../../../../utils/socket';
import {toast} from 'react-toastify';
import WebinarSettingContent from '../webinarSettingContent';

const WebinarSettingsModal = props => {
  const {closeWebinarSettingModal, roomDetail} = props;
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setState({...roomDetail});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = e => {
    e.preventDefault();
    closeSettingModal();
  };
  const setStateHandler = e => {
    setState({...state, [e.target.name]: e.target.checked});
  };

  const closeSettingModal = data => {
    closeWebinarSettingModal(data);
  };

  const saveWebinarSetting = () => {
    setLoader(true);
    console.log('state payload', state);
    roomSettings(state).then(res => {
      if (res.status === 200) {
        closeSettingModal(res.data);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoader(false);
      }
    });
  };

  const webinarSettingsBody = () => {
    return (
      <>
        <div className="row firstrow modal_min_height">
          <div className="col-md-12">
            <div className="row">
              <WebinarSettingContent
                state={state}
                setStateHandler={setStateHandler}
              />
            </div>
            <div className="row" />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 text-right">
            <button
              type="button"
              aria-label="Close"
              className="btn-link"
              data-toggle="modal"
              data-target="#webinarmodal5"
              onClick={e => {
                onCancel(e);
              }}>
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-green btn-mini abso-btn float-right"
              onClick={() => saveWebinarSetting()}>
              {loader ? 'Please Wait...' : 'OK'}
              <i className="icon-arrow-right-circle icons" />
            </button>
          </div>
        </div>
      </>
    );
  };

  const webinarSettingsObject = {
    heading: 'Webinar Settings',
    body: webinarSettingsBody,
    classNameModal:
      'fade scheduleMeet livemeetnstep3 modal-transbg createsched',
    show: true,
    headingSize: 'h5',
  };

  return <CommonModal {...webinarSettingsObject} />;
};

export default WebinarSettingsModal;
