import React, {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import swal from 'sweetalert';
import {
  roomSettings,
  individualAttendeeAction,
  multipleAttendeeAction,
} from '../../../../utils/socket';

import {
  setAttendeesClick,
  setChatClick,
  setOpenLoginModel,
} from '../../../../reduxStore/confSlice';
import HostSignInModal from '../modals/hostSignInByModal';
import {
  MicDisabledTextForGuest,
  maxWaitingRoomList,
  supportTokens,
} from '../../../../utils/util';
import {stopscreenshare} from '../../../../libs';
import {toast} from 'react-toastify';
import AttendeesDropdown from './attendeesDropdown';
import AttendeesButton from './attendeesButton';
import {
  getSortedData,
  handleCamera,
  handleMic,
  haveMicPermission,
  isLemMode,
  sortParticipantsByName,
} from '../../../../utils/functions';

const Attendees = props => {
  const dispatch = useDispatch();

  const {
    localUser,
    roomDetail,
    participantsHost,
    participantsGuest,
    participantsWaiting,
    participantsList,
    //

    waitingStatusLoader,
    attendeesClick,
    participantsHandRaised,
  } = useSelector(state => state.conf);

  const {
    guests_have_extra_powers,
    hide_guest_count_names_for_guests,
    guests_can_unmute_themselves,
  } = roomDetail;
  const [mouseOverUser, setMouseOverUser] = useState('');
  const lemMode = isLemMode();
  // console.log('participantsList ALL', participantsList);

  const participantsGuestData = useMemo(() => {
    let guests = Object.values(participantsGuest).filter(
      u => u.interaction_type === 'interactive' && u.sub_role !== 'presenter',
    );
    console.log('guests', guests);
    const list = getSortedData(guests);
    return sortParticipantsByName(list);
  }, [participantsGuest]);

  const participantsHostData = useMemo(() => {
    let hosts = Object.values(participantsHost).filter(
      u => u.interaction_type === 'interactive',
    );
    console.log('hosts', hosts);
    const list = getSortedData(hosts);
    return sortParticipantsByName(list);
  }, [participantsHost]);

  const streamingParticipants = useMemo(() => {
    let streamAttendees = Object.values(participantsList).filter(
      u =>
        u.interaction_type === 'streaming' && u.waiting_status === 'accepted',
    );
    console.log('streamAttendees', streamAttendees);
    const list = getSortedData(streamAttendees);
    return sortParticipantsByName(list);
  }, [participantsList]);

  const presentersList = useMemo(() => {
    let presenters = Object.values(participantsGuest).filter(
      u => u.interaction_type === 'interactive' && u.sub_role === 'presenter',
    );
    console.log('presenters', presenters);
    const list = getSortedData(presenters);
    return sortParticipantsByName(list);
  }, [participantsGuest]);

  const mutedGuestList = useMemo(() => {
    const {hide_muted_attendees} = roomDetail;
    if (hide_muted_attendees) {
      let mutedGuests = Object.values(participantsGuest).filter(
        u =>
          u.interaction_type === 'interactive' &&
          u.sub_role !== 'presenter' &&
          !u.audio,
      );
      const list = getSortedData(mutedGuests);
      return sortParticipantsByName(list);
    } else {
      return [];
    }
  }, [participantsGuest, roomDetail]);

  const unmutedGuestList = useMemo(() => {
    const {hide_muted_attendees} = roomDetail;
    if (hide_muted_attendees) {
      let unmutedGuests = Object.values(participantsGuest).filter(
        u =>
          u.interaction_type === 'interactive' &&
          u.sub_role !== 'presenter' &&
          u.audio,
      );
      const list = getSortedData(unmutedGuests);
      return sortParticipantsByName(list);
    } else {
      return [];
    }
  }, [participantsGuest, roomDetail]);

  // console.log('participantsList', participantsList);

  const handleAccept = doc => {
    if (localUser?.role !== 'guest') {
      individualAttendeeAction({
        room: roomDetail?.room,
        token_unique: localUser?.token_unique,
        attendee_token_unique: doc.token_unique,
        action: 'w-accept',
      }).then(() => {});
    } else {
      dispatch(setOpenLoginModel(true));
    }
  };

  const handleDecline = doc => {
    if (localUser?.role !== 'guest') {
      individualAttendeeAction({
        room: roomDetail?.room,
        token_unique: localUser?.token_unique,
        attendee_token_unique: doc.token_unique,
        action: 'w-decline',
      }).then(() => {});
    } else {
      dispatch(setOpenLoginModel(true));
    }
  };

  const handleAcceptAll = e => {
    e.preventDefault();
    if (localUser?.role !== 'guest') {
      if (Object.keys(participantsWaiting).length > 0) {
        multipleAttendeeAction({
          room: roomDetail?.room,
          token_unique: localUser?.token_unique,
          action: 'w-accept-all',
        }).then(res => {
          if (res.status !== 200) {
            toast.error(res.data[0].msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
      }
    } else {
      dispatch(setOpenLoginModel(true));
    }
  };

  const handleDeclineAll = e => {
    e.preventDefault();
    if (localUser?.role !== 'guest') {
      if (Object.keys(participantsWaiting).length > 0) {
        multipleAttendeeAction({
          room: roomDetail?.room,
          token_unique: localUser?.token_unique,
          action: 'w-decline-all',
        }).then(res => {
          if (res.status !== 200) {
            toast.error(res.data[0].msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
      }
    } else {
      dispatch(setOpenLoginModel(true));
    }
  };

  const toggleWaitingRoom = () => {
    const {waiting_room} = roomDetail;
    const tempRoomDetail = {...roomDetail};
    tempRoomDetail['waiting_room'] = !waiting_room;
    roomSettings(tempRoomDetail).then(res => {
      if (res.status !== 200) {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const handleToggleWaitingRoom = e => {
    e.preventDefault();
    if (localUser?.role !== 'guest') {
      swal({
        title: `Turn the waiting room ${
          roomDetail?.waiting_room ? 'OFF' : 'ON'
        }?`,
        closeOnClickOutside: false,
        buttons: ['Cancel', 'OK'],
        dangerMode: true,
      }).then(ok => {
        if (ok) {
          toggleWaitingRoom();
        }
      });
    } else {
      dispatch(setOpenLoginModel(true));
    }
  };

  const handleOptions = e => {
    e.preventDefault();
    dispatch(setOpenLoginModel(true));
  };

  const kickOutHandler = doc => {
    const updateUser = {...doc};
    swal({
      title: `Remove ${doc?.name}?`,
      closeOnClickOutside: false,
      buttons: ['Cancel', 'OK'],
      dangerMode: true,
    }).then(OK => {
      if (OK) {
        individualAttendeeAction({
          room: roomDetail?.room,
          token_unique: localUser?.token_unique,
          attendee_token_unique: doc.token_unique,
          action: 'kicked-out',
        });
        // updateUser['kickedOut'] = true;
        // updParticipant(updateUser).then(res => {});
      }
    });
  };

  const stopScreenHandler = () => {
    const tmpUser = {...localUser};
    if (tmpUser?.screenShare) {
      stopscreenshare();
    }
  };

  const attendeeListRenderLocal = () => {
    let trackVideoStatus = localUser.video;
    let trackAudioStatus = localUser.audio;

    return (
      <>
        <div className="row justify-content-space-between align-items-center">
          <h6 className="semiBold">You</h6>
        </div>
        <div className="rowrap" key={localUser.unique_token}>
          <span className="txtwrap">
            {localUser?.profile_image !== '' ? (
              <span className="fitIcon">
                <img
                  src={`${localUser.profile_image}`}
                  className="usericon"
                  alt=""
                />
              </span>
            ) : (
              <a className="initialValueOfUser">{localUser.name[0]}</a>
            )}

            <p>
              <span className="text-ellipsis">{localUser.name}</span>
              <small>
                <i>
                  {localUser.sub_role === 'presenter'
                    ? localUser.sub_role
                    : localUser.role}
                </i>
              </small>
            </p>
          </span>
          {!lemMode && (
            <span className="iconwrap">
              <a
                onClick={() => {
                  !haveMicPermission()
                    ? console.log('cannot unmute')
                    : handleMic();
                }}
                className={`${
                  !haveMicPermission()
                    ? 'tooltip_container tooltip_container--bottom-right tooltip_container--sm'
                    : ''
                }`}
                data-tooltip={
                  !haveMicPermission() ? MicDisabledTextForGuest : ''
                }>
                <div>
                  <img
                    src={`assets/images/${
                      !trackAudioStatus ? 'icon2-mute.png' : 'icon2.png'
                    }`}
                    alt=""
                    className="theme-default-img"
                  />
                  <img
                    src={`assets/images/monochrome/${
                      !trackAudioStatus ? 'icon2-mute.png' : 'icon2.png'
                    }`}
                    alt=""
                    className="theme-monochrome-img"
                  />
                </div>
              </a>
              {!localUser.screenShare ? (
                <a onClick={handleCamera}>
                  <div>
                    <img
                      src={`assets/images/${
                        !trackVideoStatus
                          ? 'video-off-icon.png'
                          : 'video-on-icon.png'
                      }`}
                      alt=""
                      className="audio_vidio_mute_unmute theme-default-img"
                    />
                    <img
                      src={`assets/images/monochrome/${
                        !trackVideoStatus
                          ? 'video-off-icon.png'
                          : 'video-on-icon.png'
                      }`}
                      alt=""
                      className="audio_vidio_mute_unmute theme-monochrome-img"
                    />
                  </div>
                </a>
              ) : (
                <a onClick={() => stopScreenHandler()}>
                  <div>
                    <img
                      src="assets/images/share-screen-active.png"
                      alt=""
                      className="share-screen theme-default-img"
                    />
                    <img
                      src="assets/images/share-screen-active.png"
                      alt=""
                      className="share-screen theme-monochrome-img"
                    />
                  </div>
                </a>
              )}
            </span>
          )}
        </div>
      </>
    );
  };

  const attendeeListRenderHosts = () => {
    if (participantsHostData.length > 0) {
      return (
        <>
          <div className="row justify-content-space-between align-items-center">
            <h6 className="semiBold">{`Hosts (${participantsHostData.length})`}</h6>
          </div>
          {participantsHostData.map(key => {
            const doc = key;
            let trackVideoStatus = doc.video;
            let trackAudioStatus = doc.audio;

            return (
              <div
                className="rowrap"
                key={doc.token_unique}
                onMouseOver={() => setMouseOverUser(doc.token_unique)}
                onMouseLeave={() => setMouseOverUser('')}>
                <span className="txtwrap">
                  {doc?.profile_image !== '' ? (
                    <span className="fitIcon">
                      <img
                        src={`${doc.profile_image}`}
                        className="usericon"
                        alt=""
                      />
                    </span>
                  ) : (
                    <a className="initialValueOfUser">{doc.name[0]}</a>
                  )}

                  <p>
                    <span className="text-ellipsis">{doc.name}</span>
                    {supportTokens.includes(doc.token) ? (
                      <small>
                        <i>meetn support</i>
                      </small>
                    ) : (
                      <small>
                        <i>{doc.role}</i>
                      </small>
                    )}
                  </p>
                </span>
                {!lemMode && (
                  <span className="iconwrap">
                    {doc.hand_raised && (
                      <a
                        className={`${
                          localUser?.role === 'guest' && 'cursor-default'
                        } `}
                        onClick={() =>
                          localUser.role !== 'guest' &&
                          individualAttendeeAction({
                            room: roomDetail?.room,
                            token_unique: localUser?.token_unique,
                            attendee_token_unique: doc.token_unique,
                            action: 'lower-raise-hand',
                          })
                        }>
                        <img
                          src={`assets/images/${'btm-active-icon4.png'}`}
                          alt=""
                        />
                      </a>
                    )}

                    <>
                      {(localUser?.role !== 'guest' ||
                        guests_have_extra_powers === true) &&
                      mouseOverUser === doc.token_unique ? (
                        <>
                          {!doc.allow_host_unmute_audio && !trackAudioStatus ? (
                            <button
                              className={`btn btn-mini btn-style ${
                                !doc.ask_to_unmute ? 'btn-green' : 'btn--yellow'
                              } `}
                              onClick={() =>
                                individualAttendeeAction({
                                  room: roomDetail?.room,
                                  token_unique: localUser?.token_unique,
                                  attendee_token_unique: doc.token_unique,
                                  action: !doc.ask_to_unmute
                                    ? 'ask-to-unmute-true'
                                    : 'ask-to-unmute-false',
                                })
                              }>
                              {!doc.ask_to_unmute ? (
                                <>
                                  {' '}
                                  Ask to
                                  <br />
                                  Unmute
                                </>
                              ) : (
                                <>
                                  Unmute <br />
                                  Requested
                                </>
                              )}
                            </button>
                          ) : (
                            <button
                              className={`btn btn-mini ${
                                !trackAudioStatus ? 'btn-green' : 'btn-red'
                              }`}
                              onClick={() =>
                                !trackAudioStatus
                                  ? individualAttendeeAction({
                                      room: roomDetail?.room,
                                      token_unique: localUser?.token_unique,
                                      attendee_token_unique: doc.token_unique,
                                      action: 'unmute',
                                    })
                                  : individualAttendeeAction({
                                      room: roomDetail?.room,
                                      token_unique: localUser?.token_unique,
                                      attendee_token_unique: doc.token_unique,
                                      action: 'mute',
                                    })
                              }>
                              {!trackAudioStatus ? 'Unmute' : 'Mute'}
                            </button>
                          )}
                        </>
                      ) : (
                        <a className="cursor-default">
                          <div>
                            <img
                              src={`assets/images/${
                                !trackAudioStatus
                                  ? 'icon2-mute.png'
                                  : 'icon2.png'
                              }`}
                              className="theme-default-img"
                            />
                            <img
                              src={`assets/images/monochrome/${
                                !trackAudioStatus
                                  ? 'icon2-mute.png'
                                  : 'icon2.png'
                              }`}
                              className="theme-monochrome-img"
                            />
                          </div>
                        </a>
                      )}
                    </>
                    <>
                      {!doc.screenShare ? (
                        <>
                          <a
                            className={`${
                              localUser?.role === 'guest'
                                ? 'cursor-default'
                                : trackVideoStatus
                                ? ''
                                : 'cursor-default'
                            } `}
                            onClick={() =>
                              localUser?.role !== 'guest' && trackVideoStatus
                                ? individualAttendeeAction({
                                    room: roomDetail?.room,
                                    token_unique: localUser?.token_unique,
                                    attendee_token_unique: doc.token_unique,
                                    action: 'stop-video',
                                  })
                                : console.log('connot stop video')
                            }>
                            <img
                              src={`assets/images/${
                                !trackVideoStatus
                                  ? 'video-off-icon.png'
                                  : 'video-on-icon.png'
                              }`}
                              alt=""
                              className="theme-default-img"
                            />
                            <img
                              src={`assets/images/monochrome/${
                                !trackVideoStatus
                                  ? 'video-off-icon.png'
                                  : 'video-on-icon.png'
                              }`}
                              alt=""
                              className="theme-monochrome-img"
                            />
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            className={`${
                              localUser?.role === 'guest' && 'cursor-default'
                            } `}
                            onClick={() =>
                              localUser?.role !== 'guest'
                                ? individualAttendeeAction({
                                    room: roomDetail?.room,
                                    token_unique: localUser?.token_unique,
                                    attendee_token_unique: doc.token_unique,
                                    action: 'stop-screen',
                                  })
                                : console.log('connot stop')
                            }>
                            <img
                              src="assets/images/share-screen-active.png"
                              alt=""
                              className="share-screen theme-default-img"
                            />
                            <img
                              src="assets/images/share-screen-active.png"
                              alt=""
                              className="share-screen theme-monochrome-img"
                            />
                          </a>
                        </>
                      )}
                    </>
                    {(localUser?.role !== 'guest' ||
                      guests_have_extra_powers === true) && (
                      <div className="dropdown network-dropdown">
                        <a
                          href="javascript:void(0)"
                          className="dropdown-toggle"
                          id="NetworkOptions"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button">
                          <i className="fa fa-ellipsis-h"></i>
                        </a>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="NetworkOptions">
                          <ul className="navbar-nav center_menu">
                            <li className="nav-item">
                              <a
                                href="javascript:void(0)"
                                className="nav-link"
                                onClick={() => kickOutHandler(doc)}>
                                Kick
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </span>
                )}
              </div>
            );
          })}
        </>
      );
    }
  };

  const attendeeListRenderGuests = type => {
    if (participantsGuestData.length > 0 && !roomDetail?.hide_muted_attendees) {
      return (
        <>
          {localUser.role === 'guest' && hide_guest_count_names_for_guests ? (
            <></>
          ) : (
            <>
              <div className="row justify-content-space-between align-items-center">
                <h6 className="semiBold">{`Guests (${participantsGuestData.length})`}</h6>
              </div>
              {participantsGuestData.map(key => {
                const doc = key;
                let trackVideoStatus = doc.video;
                let trackAudioStatus = doc.audio;
                // console.log('trackAudioStatus', trackAudioStatus);

                return (
                  <div
                    className="rowrap"
                    key={doc.token_unique}
                    onMouseOver={() => setMouseOverUser(doc.token_unique)}
                    onMouseLeave={() => setMouseOverUser('')}>
                    <span className="txtwrap">
                      {doc?.profile_image !== '' ? (
                        <span className="fitIcon">
                          <img
                            src={`${doc.profile_image}`}
                            className="usericon"
                            alt=""
                          />
                        </span>
                      ) : (
                        <a className="initialValueOfUser">{doc.name[0]}</a>
                      )}
                      <p>
                        <span className="text-ellipsis">{doc.name}</span>
                        <small>
                          <i>{doc.role}</i>
                        </small>
                      </p>
                    </span>
                    {!lemMode && (
                      <span className="iconwrap">
                        {doc.hand_raised && (
                          <a
                            className={`${
                              localUser?.role === 'guest' && 'cursor-default'
                            } `}
                            onClick={e =>
                              localUser.role !== 'guest' &&
                              individualAttendeeAction({
                                room: roomDetail?.room,
                                token_unique: localUser?.token_unique,
                                attendee_token_unique: doc.token_unique,
                                action: 'lower-raise-hand',
                              })
                            }>
                            <img
                              src={`assets/images/${'btm-active-icon4.png'}`}
                              alt=""
                            />
                          </a>
                        )}

                        <>
                          {(localUser?.role !== 'guest' ||
                            guests_have_extra_powers === true) &&
                          mouseOverUser === doc.token_unique ? (
                            <>
                              {!doc.allow_host_unmute_audio &&
                              !trackAudioStatus ? (
                                <button
                                  className={`btn btn-mini btn-style ${
                                    !doc.ask_to_unmute
                                      ? 'btn-green'
                                      : 'btn--yellow'
                                  } `}
                                  onClick={() =>
                                    individualAttendeeAction({
                                      room: roomDetail?.room,
                                      token_unique: localUser?.token_unique,
                                      attendee_token_unique: doc.token_unique,
                                      action: !doc.ask_to_unmute
                                        ? 'ask-to-unmute-true'
                                        : 'ask-to-unmute-false',
                                    })
                                  }>
                                  {!doc.ask_to_unmute ? (
                                    <>
                                      {' '}
                                      Ask to
                                      <br />
                                      Unmute
                                    </>
                                  ) : (
                                    <>
                                      Unmute <br />
                                      Requested
                                    </>
                                  )}
                                </button>
                              ) : (
                                <button
                                  className={`btn btn-mini ${
                                    !trackAudioStatus ? 'btn-green' : 'btn-red'
                                  }`}
                                  onClick={() =>
                                    !trackAudioStatus
                                      ? individualAttendeeAction({
                                          room: roomDetail?.room,
                                          token_unique: localUser?.token_unique,
                                          attendee_token_unique:
                                            doc.token_unique,
                                          action: 'unmute',
                                        })
                                      : individualAttendeeAction({
                                          room: roomDetail?.room,
                                          token_unique: localUser?.token_unique,
                                          attendee_token_unique:
                                            doc.token_unique,
                                          action: 'mute',
                                        })
                                  }>
                                  {!trackAudioStatus ? 'Unmute' : 'Mute'}
                                </button>
                              )}
                            </>
                          ) : (
                            <a className="cursor-default">
                              <img
                                src={`assets/images/${
                                  !trackAudioStatus
                                    ? 'icon2-mute.png'
                                    : 'icon2.png'
                                }`}
                                className="theme-default-img"
                              />
                              <img
                                src={`assets/images/monochrome/${
                                  !trackAudioStatus
                                    ? 'icon2-mute.png'
                                    : 'icon2.png'
                                }`}
                                alt=""
                                className="theme-monochrome-img"
                              />
                            </a>
                          )}
                        </>

                        <>
                          {!doc.screenShare ? (
                            <>
                              <a
                                className={`${
                                  localUser?.role === 'guest'
                                    ? 'cursor-default'
                                    : trackVideoStatus
                                    ? ''
                                    : 'cursor-default'
                                } `}
                                onClick={() =>
                                  localUser?.role !== 'guest' &&
                                  trackVideoStatus
                                    ? individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'stop-video',
                                      })
                                    : console.log('connot stop video')
                                }>
                                <img
                                  src={`assets/images/${
                                    !trackVideoStatus
                                      ? 'video-off-icon.png'
                                      : 'video-on-icon.png'
                                  }`}
                                  alt=""
                                  className="theme-default-img"
                                />
                                <img
                                  src={`assets/images/monochrome/${
                                    !trackVideoStatus
                                      ? 'video-off-icon.png'
                                      : 'video-on-icon.png'
                                  }`}
                                  alt=""
                                  className="theme-monochrome-img"
                                />
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                className={`${
                                  localUser?.role === 'guest' &&
                                  'cursor-default'
                                } `}
                                onClick={() =>
                                  localUser?.role !== 'guest'
                                    ? individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'stop-screen',
                                      })
                                    : console.log('connot stop')
                                }>
                                <img
                                  src="assets/images/share-screen-active.png"
                                  alt=""
                                  className="share-screen theme-default-img"
                                />
                                <img
                                  src="assets/images/share-screen-active.png"
                                  alt=""
                                  className="share-screen theme-monochrome-img"
                                />
                              </a>
                            </>
                          )}
                        </>
                        {(localUser?.role !== 'guest' ||
                          guests_have_extra_powers === true) && (
                          <div className="dropdown network-dropdown">
                            <a
                              href="javascript:void(0)"
                              className="dropdown-toggle"
                              id="NetworkOptions"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              role="button">
                              <i className="fa fa-ellipsis-h"></i>
                            </a>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="NetworkOptions">
                              <ul className="navbar-nav center_menu">
                                <li
                                  className={`nav-item toggle-nav-item ${
                                    doc.keep_muted ? 'active' : ''
                                  }`}>
                                  <a
                                    href="javascript:void(0)"
                                    className="nav-link"
                                    onClick={() =>
                                      individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'keep-muted',
                                      })
                                    }>
                                    <i
                                      className={`${
                                        doc.keep_muted
                                          ? 'fas fa-check text-green'
                                          : ''
                                      }`}
                                    />
                                    Keep Muted
                                  </a>
                                </li>

                                <li className="nav-item">
                                  <a
                                    href="javascript:void(0)"
                                    className="nav-link"
                                    onClick={() => kickOutHandler(doc)}>
                                    Kick
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </span>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </>
      );
    }
  };

  const attendeeListRenderMutedGuests = type => {
    if (mutedGuestList.length > 0) {
      return (
        <>
          {localUser.role === 'guest' && hide_guest_count_names_for_guests ? (
            <></>
          ) : (
            <>
              <div className="row justify-content-space-between align-items-center">
                <h6 className="semiBold">{`Muted Guests (${mutedGuestList.length})`}</h6>
              </div>
              {mutedGuestList.map(key => {
                const doc = key;
                let trackVideoStatus = doc.video;
                let trackAudioStatus = doc.audio;
                console.log('trackAudioStatus', trackAudioStatus);

                return (
                  <div
                    className="rowrap"
                    key={doc.token_unique}
                    onMouseOver={() => setMouseOverUser(doc.token_unique)}
                    onMouseLeave={() => setMouseOverUser('')}>
                    <span className="txtwrap">
                      {doc?.profile_image !== '' ? (
                        <span className="fitIcon">
                          <img
                            src={`${doc.profile_image}`}
                            className="usericon"
                            alt=""
                          />
                        </span>
                      ) : (
                        <a className="initialValueOfUser">{doc.name[0]}</a>
                      )}
                      <p>
                        <span className="text-ellipsis">{doc.name}</span>
                        <small>
                          <i>{doc.role}</i>
                        </small>
                      </p>
                    </span>
                    {!lemMode && (
                      <span className="iconwrap">
                        {doc.hand_raised && (
                          <a
                            className={`${
                              localUser?.role === 'guest' && 'cursor-default'
                            } `}
                            onClick={e =>
                              localUser.role !== 'guest' &&
                              individualAttendeeAction({
                                room: roomDetail?.room,
                                token_unique: localUser?.token_unique,
                                attendee_token_unique: doc.token_unique,
                                action: 'lower-raise-hand',
                              })
                            }>
                            <img
                              src={`assets/images/${'btm-active-icon4.png'}`}
                              alt=""
                            />
                          </a>
                        )}

                        <>
                          {(localUser?.role !== 'guest' ||
                            guests_have_extra_powers === true) &&
                          mouseOverUser === doc.token_unique ? (
                            <>
                              {!doc.allow_host_unmute_audio &&
                              !trackAudioStatus ? (
                                <button
                                  className={`btn btn-mini btn-style ${
                                    !doc.ask_to_unmute
                                      ? 'btn-green'
                                      : 'btn--yellow'
                                  } `}
                                  onClick={() =>
                                    individualAttendeeAction({
                                      room: roomDetail?.room,
                                      token_unique: localUser?.token_unique,
                                      attendee_token_unique: doc.token_unique,
                                      action: !doc.ask_to_unmute
                                        ? 'ask-to-unmute-true'
                                        : 'ask-to-unmute-false',
                                    })
                                  }>
                                  {!doc.ask_to_unmute ? (
                                    <>
                                      {' '}
                                      Ask to
                                      <br />
                                      Unmute
                                    </>
                                  ) : (
                                    <>
                                      Unmute <br />
                                      Requested
                                    </>
                                  )}
                                </button>
                              ) : (
                                <button
                                  className={`btn btn-mini ${
                                    !trackAudioStatus ? 'btn-green' : 'btn-red'
                                  }`}
                                  onClick={() =>
                                    !trackAudioStatus
                                      ? individualAttendeeAction({
                                          room: roomDetail?.room,
                                          token_unique: localUser?.token_unique,
                                          attendee_token_unique:
                                            doc.token_unique,
                                          action: 'unmute',
                                        })
                                      : individualAttendeeAction({
                                          room: roomDetail?.room,
                                          token_unique: localUser?.token_unique,
                                          attendee_token_unique:
                                            doc.token_unique,
                                          action: 'mute',
                                        })
                                  }>
                                  {!trackAudioStatus ? 'Unmute' : 'Mute'}
                                </button>
                              )}
                            </>
                          ) : (
                            <a className="cursor-default">
                              <img
                                src={`assets/images/${
                                  !trackAudioStatus
                                    ? 'icon2-mute.png'
                                    : 'icon2.png'
                                }`}
                                className="theme-default-img"
                              />
                              <img
                                src={`assets/images/monochrome/${
                                  !trackAudioStatus
                                    ? 'icon2-mute.png'
                                    : 'icon2.png'
                                }`}
                                alt=""
                                className="theme-monochrome-img"
                              />
                            </a>
                          )}
                        </>

                        <>
                          {!doc.screenShare ? (
                            <>
                              <a
                                className={`${
                                  localUser?.role === 'guest'
                                    ? 'cursor-default'
                                    : trackVideoStatus
                                    ? ''
                                    : 'cursor-default'
                                } `}
                                onClick={() =>
                                  localUser?.role !== 'guest' &&
                                  trackVideoStatus
                                    ? individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'stop-video',
                                      })
                                    : console.log('connot stop video')
                                }>
                                <img
                                  src={`assets/images/${
                                    !trackVideoStatus
                                      ? 'video-off-icon.png'
                                      : 'video-on-icon.png'
                                  }`}
                                  alt=""
                                  className="theme-default-img"
                                />
                                <img
                                  src={`assets/images/monochrome/${
                                    !trackVideoStatus
                                      ? 'video-off-icon.png'
                                      : 'video-on-icon.png'
                                  }`}
                                  alt=""
                                  className="theme-monochrome-img"
                                />
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                className={`${
                                  localUser?.role === 'guest' &&
                                  'cursor-default'
                                } `}
                                onClick={() =>
                                  localUser?.role !== 'guest'
                                    ? individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'stop-screen',
                                      })
                                    : console.log('connot stop')
                                }>
                                <img
                                  src="assets/images/share-screen-active.png"
                                  alt=""
                                  className="share-screen theme-default-img"
                                />
                                <img
                                  src="assets/images/share-screen-active.png"
                                  alt=""
                                  className="share-screen theme-monochrome-img"
                                />
                              </a>
                            </>
                          )}
                        </>
                        {(localUser?.role !== 'guest' ||
                          guests_have_extra_powers === true) && (
                          <div className="dropdown network-dropdown">
                            <a
                              href="javascript:void(0)"
                              className="dropdown-toggle"
                              id="NetworkOptions"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              role="button">
                              <i className="fa fa-ellipsis-h"></i>
                            </a>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="NetworkOptions">
                              <ul className="navbar-nav center_menu">
                                <li
                                  className={`nav-item toggle-nav-item ${
                                    doc.keep_muted ? 'active' : ''
                                  }`}>
                                  <a
                                    href="javascript:void(0)"
                                    className="nav-link"
                                    onClick={() =>
                                      individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'keep-muted',
                                      })
                                    }>
                                    <i
                                      className={`${
                                        doc.keep_muted
                                          ? 'fas fa-check text-green'
                                          : ''
                                      }`}
                                    />
                                    Keep Muted
                                  </a>
                                </li>

                                <li className="nav-item">
                                  <a
                                    href="javascript:void(0)"
                                    className="nav-link"
                                    onClick={() => kickOutHandler(doc)}>
                                    Kick
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </span>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </>
      );
    }
  };

  const attendeeListRenderUnmutedGuests = type => {
    if (unmutedGuestList.length > 0) {
      return (
        <>
          {localUser.role === 'guest' && hide_guest_count_names_for_guests ? (
            <></>
          ) : (
            <>
              <div className="row justify-content-space-between align-items-center">
                <h6 className="semiBold">{`Unmuted Guests (${unmutedGuestList.length})`}</h6>
              </div>
              {unmutedGuestList.map(key => {
                const doc = key;
                let trackVideoStatus = doc.video;
                let trackAudioStatus = doc.audio;
                console.log('trackAudioStatus', trackAudioStatus);

                return (
                  <div
                    className="rowrap"
                    key={doc.token_unique}
                    onMouseOver={() => setMouseOverUser(doc.token_unique)}
                    onMouseLeave={() => setMouseOverUser('')}>
                    <span className="txtwrap">
                      {doc?.profile_image !== '' ? (
                        <span className="fitIcon">
                          <img
                            src={`${doc.profile_image}`}
                            className="usericon"
                            alt=""
                          />
                        </span>
                      ) : (
                        <a className="initialValueOfUser">{doc.name[0]}</a>
                      )}
                      <p>
                        <span className="text-ellipsis">{doc.name}</span>
                        <small>
                          <i>{doc.role}</i>
                        </small>
                      </p>
                    </span>
                    {!lemMode && (
                      <span className="iconwrap">
                        {doc.hand_raised && (
                          <a
                            className={`${
                              localUser?.role === 'guest' && 'cursor-default'
                            } `}
                            onClick={e =>
                              localUser.role !== 'guest' &&
                              individualAttendeeAction({
                                room: roomDetail?.room,
                                token_unique: localUser?.token_unique,
                                attendee_token_unique: doc.token_unique,
                                action: 'lower-raise-hand',
                              })
                            }>
                            <img
                              src={`assets/images/${'btm-active-icon4.png'}`}
                              alt=""
                            />
                          </a>
                        )}

                        <>
                          {(localUser?.role !== 'guest' ||
                            guests_have_extra_powers === true) &&
                          mouseOverUser === doc.token_unique ? (
                            <>
                              {!doc.allow_host_unmute_audio &&
                              !trackAudioStatus ? (
                                <button
                                  className={`btn btn-mini btn-style ${
                                    !doc.ask_to_unmute
                                      ? 'btn-green'
                                      : 'btn--yellow'
                                  } `}
                                  onClick={() =>
                                    individualAttendeeAction({
                                      room: roomDetail?.room,
                                      token_unique: localUser?.token_unique,
                                      attendee_token_unique: doc.token_unique,
                                      action: !doc.ask_to_unmute
                                        ? 'ask-to-unmute-true'
                                        : 'ask-to-unmute-false',
                                    })
                                  }>
                                  {!doc.ask_to_unmute ? (
                                    <>
                                      {' '}
                                      Ask to
                                      <br />
                                      Unmute
                                    </>
                                  ) : (
                                    <>
                                      Unmute <br />
                                      Requested
                                    </>
                                  )}
                                </button>
                              ) : (
                                <button
                                  className={`btn btn-mini ${
                                    !trackAudioStatus ? 'btn-green' : 'btn-red'
                                  }`}
                                  onClick={() =>
                                    !trackAudioStatus
                                      ? individualAttendeeAction({
                                          room: roomDetail?.room,
                                          token_unique: localUser?.token_unique,
                                          attendee_token_unique:
                                            doc.token_unique,
                                          action: 'unmute',
                                        })
                                      : individualAttendeeAction({
                                          room: roomDetail?.room,
                                          token_unique: localUser?.token_unique,
                                          attendee_token_unique:
                                            doc.token_unique,
                                          action: 'mute',
                                        })
                                  }>
                                  {!trackAudioStatus ? 'Unmute' : 'Mute'}
                                </button>
                              )}
                            </>
                          ) : (
                            <a className="cursor-default">
                              <img
                                src={`assets/images/${
                                  !trackAudioStatus
                                    ? 'icon2-mute.png'
                                    : 'icon2.png'
                                }`}
                                className="theme-default-img"
                              />
                              <img
                                src={`assets/images/monochrome/${
                                  !trackAudioStatus
                                    ? 'icon2-mute.png'
                                    : 'icon2.png'
                                }`}
                                alt=""
                                className="theme-monochrome-img"
                              />
                            </a>
                          )}
                        </>

                        <>
                          {!doc.screenShare ? (
                            <>
                              <a
                                className={`${
                                  localUser?.role === 'guest'
                                    ? 'cursor-default'
                                    : trackVideoStatus
                                    ? ''
                                    : 'cursor-default'
                                } `}
                                onClick={() =>
                                  localUser?.role !== 'guest' &&
                                  trackVideoStatus
                                    ? individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'stop-video',
                                      })
                                    : console.log('connot stop video')
                                }>
                                <img
                                  src={`assets/images/${
                                    !trackVideoStatus
                                      ? 'video-off-icon.png'
                                      : 'video-on-icon.png'
                                  }`}
                                  alt=""
                                  className="theme-default-img"
                                />
                                <img
                                  src={`assets/images/monochrome/${
                                    !trackVideoStatus
                                      ? 'video-off-icon.png'
                                      : 'video-on-icon.png'
                                  }`}
                                  alt=""
                                  className="theme-monochrome-img"
                                />
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                className={`${
                                  localUser?.role === 'guest' &&
                                  'cursor-default'
                                } `}
                                onClick={() =>
                                  localUser?.role !== 'guest'
                                    ? individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'stop-screen',
                                      })
                                    : console.log('connot stop')
                                }>
                                <img
                                  src="assets/images/share-screen-active.png"
                                  alt=""
                                  className="share-screen theme-default-img"
                                />
                                <img
                                  src="assets/images/share-screen-active.png"
                                  alt=""
                                  className="share-screen theme-monochrome-img"
                                />
                              </a>
                            </>
                          )}
                        </>
                        {(localUser?.role !== 'guest' ||
                          guests_have_extra_powers === true) && (
                          <div className="dropdown network-dropdown">
                            <a
                              href="javascript:void(0)"
                              className="dropdown-toggle"
                              id="NetworkOptions"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              role="button">
                              <i className="fa fa-ellipsis-h"></i>
                            </a>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="NetworkOptions">
                              <ul className="navbar-nav center_menu">
                                <li
                                  className={`nav-item toggle-nav-item ${
                                    doc.keep_muted ? 'active' : ''
                                  }`}>
                                  <a
                                    href="javascript:void(0)"
                                    className="nav-link"
                                    onClick={() =>
                                      individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'keep-muted',
                                      })
                                    }>
                                    <i
                                      className={`${
                                        doc.keep_muted
                                          ? 'fas fa-check text-green'
                                          : ''
                                      }`}
                                    />
                                    Keep Muted
                                  </a>
                                </li>

                                <li className="nav-item">
                                  <a
                                    href="javascript:void(0)"
                                    className="nav-link"
                                    onClick={() => kickOutHandler(doc)}>
                                    Kick
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </span>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </>
      );
    }
  };

  const attendeeListRenderPresenter = type => {
    if (presentersList.length > 0) {
      return (
        <>
          {localUser.role === 'guest' && hide_guest_count_names_for_guests ? (
            <></>
          ) : (
            <>
              <div className="row justify-content-space-between align-items-center">
                <h6 className="semiBold">{`Presenters (${presentersList.length})`}</h6>
              </div>
              {presentersList.map(key => {
                const doc = key;
                let trackVideoStatus = doc.video;
                let trackAudioStatus = doc.audio;
                console.log('trackAudioStatus', trackAudioStatus);

                return (
                  <div
                    className="rowrap"
                    key={doc.token_unique}
                    onMouseOver={() => setMouseOverUser(doc.token_unique)}
                    onMouseLeave={() => setMouseOverUser('')}>
                    <span className="txtwrap">
                      {doc?.profile_image !== '' ? (
                        <span className="fitIcon">
                          <img
                            src={`${doc.profile_image}`}
                            className="usericon"
                            alt=""
                          />
                        </span>
                      ) : (
                        <a className="initialValueOfUser">{doc.name[0]}</a>
                      )}
                      <p>
                        <span className="text-ellipsis">{doc.name}</span>
                        <small>
                          <i>{doc.sub_role}</i>
                        </small>
                      </p>
                    </span>
                    {!lemMode && (
                      <span className="iconwrap">
                        {doc.hand_raised && (
                          <a
                            className={`${
                              localUser?.role === 'guest' && 'cursor-default'
                            } `}
                            onClick={e =>
                              localUser.role !== 'guest' &&
                              individualAttendeeAction({
                                room: roomDetail?.room,
                                token_unique: localUser?.token_unique,
                                attendee_token_unique: doc.token_unique,
                                action: 'lower-raise-hand',
                              })
                            }>
                            <img
                              src={`assets/images/${'btm-active-icon4.png'}`}
                              alt=""
                            />
                          </a>
                        )}

                        <>
                          {(localUser?.role !== 'guest' ||
                            guests_have_extra_powers === true) &&
                          mouseOverUser === doc.token_unique ? (
                            <>
                              {!doc.allow_host_unmute_audio &&
                              !trackAudioStatus ? (
                                <button
                                  className={`btn btn-mini btn-style ${
                                    !doc.ask_to_unmute
                                      ? 'btn-green'
                                      : 'btn--yellow'
                                  } `}
                                  onClick={() =>
                                    individualAttendeeAction({
                                      room: roomDetail?.room,
                                      token_unique: localUser?.token_unique,
                                      attendee_token_unique: doc.token_unique,
                                      action: !doc.ask_to_unmute
                                        ? 'ask-to-unmute-true'
                                        : 'ask-to-unmute-false',
                                    })
                                  }>
                                  {!doc.ask_to_unmute ? (
                                    <>
                                      {' '}
                                      Ask to
                                      <br />
                                      Unmute
                                    </>
                                  ) : (
                                    <>
                                      Unmute <br />
                                      Requested
                                    </>
                                  )}
                                </button>
                              ) : (
                                <button
                                  className={`btn btn-mini ${
                                    !trackAudioStatus ? 'btn-green' : 'btn-red'
                                  }`}
                                  onClick={() =>
                                    !trackAudioStatus
                                      ? individualAttendeeAction({
                                          room: roomDetail?.room,
                                          token_unique: localUser?.token_unique,
                                          attendee_token_unique:
                                            doc.token_unique,
                                          action: 'unmute',
                                        })
                                      : individualAttendeeAction({
                                          room: roomDetail?.room,
                                          token_unique: localUser?.token_unique,
                                          attendee_token_unique:
                                            doc.token_unique,
                                          action: 'mute',
                                        })
                                  }>
                                  {!trackAudioStatus ? 'Unmute' : 'Mute'}
                                </button>
                              )}
                            </>
                          ) : (
                            <a className="cursor-default">
                              <div>
                                <img
                                  src={`assets/images/${
                                    !trackAudioStatus
                                      ? 'icon2-mute.png'
                                      : 'icon2.png'
                                  }`}
                                  className="theme-default-img"
                                />
                                <img
                                  src={`assets/images/monochrome/${
                                    !trackAudioStatus
                                      ? 'icon2-mute.png'
                                      : 'icon2.png'
                                  }`}
                                  className="theme-monochrome-img"
                                />
                              </div>
                            </a>
                          )}
                        </>

                        <>
                          {!doc.screenShare ? (
                            <>
                              <a
                                className={`${
                                  localUser?.role === 'guest'
                                    ? 'cursor-default'
                                    : trackVideoStatus
                                    ? ''
                                    : 'cursor-default'
                                } `}
                                onClick={
                                  localUser?.role !== 'guest' &&
                                  trackVideoStatus
                                    ? individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'stop-video',
                                      })
                                    : console.log('connot stop video')
                                }>
                                <img
                                  src={`assets/images/${
                                    !trackVideoStatus
                                      ? 'video-off-icon.png'
                                      : 'video-on-icon.png'
                                  }`}
                                  alt=""
                                  className="theme-default-img"
                                />
                                <img
                                  src={`assets/images/monochrome/${
                                    !trackVideoStatus
                                      ? 'video-off-icon.png'
                                      : 'video-on-icon.png'
                                  }`}
                                  alt=""
                                  className="theme-monochrome-img"
                                />
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                className={`${
                                  localUser?.role === 'guest' &&
                                  'cursor-default'
                                } `}
                                onClick={() =>
                                  localUser?.role !== 'guest'
                                    ? individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'stop-screen',
                                      })
                                    : console.log('connot stop')
                                }>
                                <img
                                  src="assets/images/share-screen-active.png"
                                  alt=""
                                  className="share-screen theme-default-img"
                                />
                                <img
                                  src="assets/images/share-screen-active.png"
                                  alt=""
                                  className="share-screen theme-monochrome-img"
                                />
                              </a>
                            </>
                          )}
                        </>
                        {(localUser?.role !== 'guest' ||
                          guests_have_extra_powers === true) && (
                          <div className="dropdown network-dropdown">
                            <a
                              href="javascript:void(0)"
                              className="dropdown-toggle"
                              id="NetworkOptions"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              role="button">
                              <i className="fa fa-ellipsis-h"></i>
                            </a>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="NetworkOptions">
                              <ul className="navbar-nav center_menu">
                                <li
                                  className={`nav-item toggle-nav-item ${
                                    doc.keep_muted ? 'active' : ''
                                  }`}>
                                  <a
                                    href="javascript:void(0)"
                                    className="nav-link"
                                    onClick={() =>
                                      individualAttendeeAction({
                                        room: roomDetail?.room,
                                        token_unique: localUser?.token_unique,
                                        attendee_token_unique: doc.token_unique,
                                        action: 'keep-muted',
                                      })
                                    }>
                                    <i
                                      className={`${
                                        doc.keep_muted
                                          ? 'fas fa-check text-green'
                                          : ''
                                      }`}
                                    />
                                    Keep Muted
                                  </a>
                                </li>

                                <li className="nav-item">
                                  <a
                                    href="javascript:void(0)"
                                    className="nav-link"
                                    onClick={() => kickOutHandler(doc)}>
                                    Kick
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </span>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </>
      );
    }
  };

  const attendeeListRenderLem = type => {
    if (streamingParticipants.length > 0) {
      return (
        <>
          {localUser.role === 'guest' && hide_guest_count_names_for_guests ? (
            <></>
          ) : (
            <>
              <div className="row justify-content-space-between align-items-center">
                <h6 className="semiBold">Large Event Mode</h6>
              </div>
              {streamingParticipants.map(key => {
                const doc = key;

                return (
                  <div className="rowrap" key={doc.token_unique}>
                    <span className="txtwrap">
                      {doc?.profile_image !== '' ? (
                        <span className="fitIcon">
                          <img
                            src={`${doc?.profile_image}`}
                            className="usericon"
                            alt=""
                          />
                        </span>
                      ) : (
                        <a className="initialValueOfUser">{doc.name[0]}</a>
                      )}
                      <p>
                        <span className="text-ellipsis">{doc.name}</span>
                        <small>
                          <i>{doc.role}</i>
                        </small>
                      </p>
                    </span>
                  </div>
                );
              })}
            </>
          )}
        </>
      );
    }
  };

  const handleAttendee = e => {
    e.preventDefault();
    dispatch(setAttendeesClick(false));
    dispatch(setChatClick(!attendeesClick));
  };
  return (
    <>
      <div
        className={`tab-pane controltab ${attendeesClick ? 'active' : ''}`}
        id="attendees"
        role="tabpanel"
        aria-labelledby="control-tab">
        <div className="chat-header">
          <AttendeesDropdown />
          <div
            className="chat-close text-grey float-right attendees-question-icon"
            onClick={handleAttendee}>
            <svg width=".7em" viewBox="6.29 6.34 11.31 11.31">
              <path
                fill="#93a5ad"
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              />
            </svg>
          </div>
        </div>
        <div className="participantShare-tab">
          <div className="col-md-12 chatWholeWrap attendees-inner">
            <AttendeesButton />
            <div className="waiting-room row">
              <div className="d-flex waiting-room-flex">
                <h6
                  className={`semiBold ${
                    Object.keys(participantsWaiting).length > 0 &&
                    (localUser?.role !== 'guest' ||
                      roomDetail?.guests_have_host_powers)
                      ? 'waiting-room-wrap'
                      : ''
                  }`}>
                  <span
                    className={`${
                      Object.keys(participantsWaiting).length > 0 &&
                      (localUser?.role !== 'guest' ||
                        roomDetail?.guests_have_host_powers)
                        ? 'blinkingBackground'
                        : ''
                    }`}>
                    Waiting Room
                  </span>
                </h6>
                {waitingStatusLoader ? (
                  <img
                    src="assets/images/loader-x.gif"
                    className="attendees-loader"
                  />
                ) : (
                  <a
                    href=""
                    onClick={handleToggleWaitingRoom}
                    className={`status-text ${
                      roomDetail?.waiting_room ? 'text-green' : 'text-Bred'
                    } `}>
                    {roomDetail?.waiting_room ? 'ON' : 'OFF'}
                  </a>
                )}
              </div>
              <div className="dropdown">
                {localUser?.role !== 'guest' ||
                roomDetail?.guests_have_host_powers === 'Y' ? (
                  <>
                    <a
                      href=""
                      className="dropdown-toggle"
                      id="optionsMenu"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                      role="button">
                      <span>Options</span>
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="optionsMenu">
                      <ul className="navbar-nav center_menu">
                        <a class="nav-link">
                          <label class="checkwrap justify-left hAuto">
                            Enabled
                            <input
                              spellCheck="false"
                              type="checkbox"
                              checked={roomDetail?.waiting_room}
                              onChange={e => {
                                handleToggleWaitingRoom(e);
                              }}
                              name="waitingEnabled"
                              id="waitingEnabled"
                            />
                            <span class="checkmark" />
                          </label>
                        </a>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            onClick={e => handleAcceptAll(e)}>
                            Accept All
                          </a>
                          <a
                            className="nav-link"
                            onClick={e => handleDeclineAll(e)}>
                            Decline All
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <a
                    href=""
                    onClick={handleOptions}
                    className="dropdown-toggle"
                    role="button">
                    <span>Options</span>
                  </a>
                )}
              </div>
            </div>
            {roomDetail?.waiting_room && localUser?.role !== 'guest' && (
              <div className="waiting-room-rowrap">
                {Object.keys(participantsWaiting).length > 0 && (
                  <>
                    {Object.keys(participantsWaiting)
                      .slice(0, maxWaitingRoomList)
                      .map(key => {
                        const doc = participantsWaiting[key];
                        if (
                          doc.role &&
                          doc.name &&
                          doc.token &&
                          doc.token_unique
                        ) {
                          return (
                            <>
                              <div className="rowrap">
                                <span className="txtwrap">
                                  <a className={'initialValueOfUser'}>
                                    {doc.name[0]}
                                  </a>
                                  <p>
                                    <span className="text-ellipsis">
                                      {doc.name}
                                    </span>
                                    {supportTokens.includes(doc.token) ? (
                                      <small>
                                        <i>meetn support</i>
                                      </small>
                                    ) : (
                                      <small>
                                        <i>{doc.role}</i>
                                      </small>
                                    )}
                                  </p>
                                </span>
                                <span className="iconwrap-status">
                                  <a
                                    className="waiting-room-loader"
                                    onClick={() => handleAccept(doc)}>
                                    Accept
                                  </a>
                                  <a
                                    className="waiting-room-loader"
                                    onClick={() => handleDecline(doc)}>
                                    Decline
                                  </a>
                                </span>
                              </div>
                            </>
                          );
                        }
                      })}
                  </>
                )}
              </div>
            )}

            {attendeeListRenderLocal()}
            {attendeeListRenderHosts()}
            {attendeeListRenderPresenter()}
            {attendeeListRenderGuests()}
            {attendeeListRenderLem()}
            {attendeeListRenderMutedGuests()}
            {attendeeListRenderUnmutedGuests()}
          </div>
          {localUser.role === 'guest' && hide_guest_count_names_for_guests && (
            <div className="guest-hidden">
              <small className="text-semibold text-white">
                Other Guests are Currently Hidden
              </small>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Attendees;
