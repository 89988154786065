import React, {useRef, useState} from 'react';
import {toast} from 'react-toastify';
import swal from 'sweetalert';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {setPage} from '../../../reduxStore/confSlice';
import {resetPassword} from '../../../utils/api';

const ForgotPassword = props => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const {room} = useSelector(state => state.conf);
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [disabled, setDisabled] = useState(false);
  const handleLogin = e => {
    e.preventDefault();
    dispatch(setPage('Login'));
  };

  const forgotPasswordHandler = e => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setDisabled(true);
      toast.dismiss();
      resetPassword({
        room,
        email,
      }).then(async res => {
        if (res.status !== 500) {
          if (res.status === 200) {
            toast.success(
              'Email has been sent to your email address. Please check your email.',
              {
                position: toast.POSITION.TOP_CENTER,
              },
            );
            dispatch(setPage('Login'));
          } else {
            toast.error(res.data[0].msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            setDisabled(false);
          }
        } else {
          swal({
            title:
              'Something went wrong, unable to connect to meetn, please check your network.',
            closeOnClickOutside: false,
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: false,
          }).then(() => {
            navigate('/');
          });
        }
      });
    } else {
      simpleValidator.current.showMessages(true);
      forceUpdate(1);
    }
  };
  return (
    <>
      <div className="col-md-6 col-6 light-bg loginProcess pl-15">
        <h2>Forgot Password</h2>
        <form className="mt-3 login-button">
          <div className="form-group">
            <label className="">Email</label>
            <input
              spellCheck="false"
              type="email"
              name="email"
              maxLength="100"
              className="form-control"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
            />
            {simpleValidator.current.message('email', email, 'required|email', {
              messages: {
                default: 'Email field is required.',
                email: 'Email must be a valid email address.',
              },
            })}
          </div>
          <div className="form-group mt-4">
            <button
              className="btn btn-green"
              disabled={disabled}
              onClick={forgotPasswordHandler}>
              {disabled ? <div className="spinner" /> : 'Submit'}
            </button>
          </div>
          <div className="form-group mt-4">
            <a href="#" className="forgotP" onClick={handleLogin}>
              <small className="text-green">
                <b>Log in</b>
              </small>
            </a>
          </div>
          <div className="form-group backWrap">
            <a href="#" aria-label="Close" onClick={handleLogin}>
              <svg width="1.3em" viewBox="2 1.99 20 20">
                <path
                  fill="#6cb33f"
                  d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
                />
                <path
                  fill="#6cb33f"
                  d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
                />
              </svg>
            </a>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
