import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import RoomSettingModal from './modals/roomSettingModal';
import {
  setOpenLoginModel,
  setShowRoomSettingModal,
} from '../../../reduxStore/confSlice';

function RoomSetting() {
  const {showRoomSettingModal, localUser} = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    dispatch(setShowRoomSettingModal(true));
  };
  const {roomDetail} = useSelector(state => state.conf);
  return (
    <>
      <a
        className="nav-link"
        onClick={() =>
          localUser.role !== 'guest'
            ? handleOpenModal()
            : dispatch(setOpenLoginModel(true))
        }>
        <i className="fas fa-moon bg-purple roomproicon"></i>
        <span>Room Settings</span>
      </a>
      {showRoomSettingModal && (
        <RoomSettingModal
          roomDetail={roomDetail}
          closeRoomSettingModal={() => dispatch(setShowRoomSettingModal(false))}
        />
      )}
    </>
  );
}

export default RoomSetting;
