import React from 'react';
import { useSelector } from 'react-redux';
import { JoinFlow } from '../../joinFlow/web';
import { MarketingPage } from './..';
import { LoaderEffect, LoadingMeetn } from '../../common/web';


function MainComponent() {
    const { routeDetail } = useSelector((state) => state.main);

    const renderContent = () => {
        const { route } = routeDetail;
        console.log('route', route)
        switch (route) {
            case 'marketing':
                return <MarketingPage />;
            case 'room':
                return <JoinFlow />;
            default:
                return <LoaderEffect />;
        }
    };
    return (<>
        {
            renderContent()
        }
    </>);


}

export default MainComponent