/* eslint-disable no-void */
import React from 'react';
import { CommonModal } from '../../../../../common/web';
import { Capitalize } from '../../../../../../utils/functions';


function FacebookPageModal({ fbLoader, fb_pages_list, handleReturn, handleAddFacebookDestination }) {

    const facebookPageBody = () => {
        return (
            <>
                {
                    fbLoader ? <><div className="row modal_min_height">
                        <a href={void 0} className="loader_icon">
                            <img src="assets/images/refresh-loader.gif" />
                        </a>
                    </div> </> : <>
                        <div className="row modal_min_height">
                            <div className="col-md-12">
                                {fb_pages_list && fb_pages_list.length > 0 ? (
                                    <p>Choose the page you would like to connect to Meetn.</p>
                                ) : (
                                    <p>We couldn't find any Facebook page.</p>
                                )}
                                <ul>
                                    {fb_pages_list && fb_pages_list.length > 0 &&
                                        fb_pages_list.map(fb => {
                                            return (
                                                <>
                                                    <li>
                                                        <a href={void (0)} onClick={() => handleAddFacebookDestination(fb.page_id, fb.page_name)}>
                                                            <div className="profile__initials">
                                                                {Capitalize(fb.page_name[0])}
                                                            </div>
                                                            <div className="wrap-content">
                                                                <span className="heading">{fb.page_name}</span>
                                                                <span className="content">Page</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </>
                                            );
                                        })}
                                </ul>
                                {fb_pages_list && fb_pages_list.length > 0 && (
                                    <a className="tooltip_custom d-inline-flex">
                                        <u>Why is one of my pages not listed?</u>
                                        <div className="tooltiptext tooltiptext--right tooltiptext--lg tooltiptext--lg-right">
                                            <h6 className="semiBold">What if your page is not listed?</h6>
                                            There are a few reasons why your Facebook page may not show up
                                            on MEETN.
                                            <br />
                                            <br />
                                            <span className="semiBold">
                                                You're not an admin or editor:
                                            </span>
                                            <br />
                                            You must be an admin or editor of a page to connect it to
                                            MEETN.
                                            <br />
                                            <br />
                                            <span className="semiBold">
                                                You're logged in to the wrong Facebook profile:
                                            </span>
                                            <br />
                                            On facebook.com, you must be logged into the profile that is
                                            an admin or editor of the page you're trying to connect.
                                            <br />
                                            <br />
                                            <span className="semiBold">
                                                You haven't granted permissions for the specific page:
                                            </span>
                                            <br />
                                            When first connecting to Facebook, MEETN asks for a number of
                                            permissions. You may have clicked “Choose what you allow”,
                                            which lets you choose which pages MEETN can access. If you
                                            turn off any pages, MEETN won't have access to them or any new
                                            pages you join AFTER connecting to MEETN. In this case, you'll
                                            need to reauthorize Facebook.
                                        </div>
                                    </a>
                                )}
                                <br />
                                <p className="mb-0">
                                    Want to use a different account? Log in to that account on
                                    facebook.com then try again.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12 text-right help_text">
                            {fb_pages_list && fb_pages_list.length < 1 && (
                                <p className="mb-0 mt-3">
                                    Are your Facebook pages not being shown?
                                    <a href={void (0)} className="text-green d-inline">
                                        Click here
                                    </a>
                                    to learn how to fix it.
                                </p>
                            )}
                        </div>
                        <div className="row mt-3">
                            {
                                <div className="col-md-12 text-right">
                                    <a
                                        href={void (0)}
                                        className="btn-green btn-mini mr-0"
                                        data-dismiss="modal"
                                        onClick={e => handleReturn(false)}>
                                        Return
                                    </a>
                                </div>
                            }
                        </div>


                    </>
                }

            </>
        );
    };

    const facebookPageObject = {
        heading: 'Choose a Facebook page',
        body: facebookPageBody,
        show: true,
        classNameBody: 'overflow_visible',
        classNameModal: 'fade facebook-list-modal',
        headingSize: 'h5',
    };
    return <CommonModal {...facebookPageObject} />;
}

export default FacebookPageModal;
