import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExtensionList } from '../../../../utils/socket';
import { toast } from 'react-toastify';
import { setGoToSignIn } from '../../../../reduxStore/persistSlice';
import { alertMsg } from '../../../common/web/alert';
import { ULTIMATE_FEATURE_MSG, ULTIMATE_FEATURE_TRIAL_MSG } from '../../../../utils/util';
import { featureAccess } from '../../../../utils/functions';

const WidgetList = props => {
  const { setParticularWidgetData } = props;
  const [userExtensionsList, setUserExtensionsList] = useState([]);
  const { localUser, roomDetail, trial } = useSelector(state => state.conf);
  const [listLoader, setListLoader] = useState(true);
  const { room, plan } = roomDetail;

  const dispatch = useDispatch();

  useEffect(() => {
    extensionListHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extensionListHandler = () => {
    setListLoader(true);
    getExtensionList({
      room,
    }).then(res => {
      setListLoader(false);
      if (res.status === 200) {
        setUserExtensionsList([...res.data]);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const isVisibleToGuests = useMemo(() => {
    return userExtensionsList?.some(ext => ext.visible_to_guest === true);
  }, [userExtensionsList]);

  const goToSignIn = e => {
    e.preventDefault();
    dispatch(setGoToSignIn(true));
    window.location.reload();
  };

  const openWidget = data => {
    event.preventDefault();

    if (featureAccess('widgets', plan)) {
      setParticularWidgetData(data);
    } else {
      if (plan === 'pro' && trial) {
        alertMsg({ msg: ULTIMATE_FEATURE_TRIAL_MSG, type: 'info' });
      } else {
        alertMsg({ msg: ULTIMATE_FEATURE_MSG, type: 'info' });
      }

    }
  };
  return (
    <>
      <div className="col-md-12 extensions-wrap on-first extensions-container p-0">
        {!listLoader ? (
          <ul>
            <div className="extensions-list">
              {userExtensionsList?.length > 0 ? (
                <>
                  {localUser.role === 'guest' && !isVisibleToGuests ? (
                    <>
                      <div className="font-1">
                        In this room, only hosts can see available widgets. If
                        you are a host+, please&nbsp;
                        <a href={location.href} onClick={goToSignIn}>
                          Log in
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      {userExtensionsList?.map(ext => {
                        if (
                          ext.visible_to_guest === false &&
                          localUser.role === 'guest'
                        ) {
                          return false;
                        }
                        return (
                          <>
                            <li>
                              <a
                                href="javascript:void(0)"
                                className="extensions-options"
                                onClick={() => openWidget(ext)}>
                                <span id={`ext_${ext.extensionid}`}>
                                  <div className="extension_icon">
                                    <img
                                      heigth="50"
                                      width="300"
                                      src={ext.extensionImage}
                                      className="extension-logo"
                                    />
                                  </div>
                                  {ext.extension}
                                </span>
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </>
                  )}
                </>
              ) : (
                <div className="no_published_extension">
                  No published widgets
                </div>
              )}
            </div>
            {/* <div className="extension_additional_links">
                {localUser.role !== 'guest' &&
                  _room_info.extensions === 'Y' && (
                    <a
                      href="javascript:void(0)"
                      onClick={e => this.manageTab('add_extension')}>
                      Add an Unpublished Widget
                    </a>
                  )}
                {_user_info.role !== 'guest' &&
                  _room_info.extensions === 'Y' && (
                    <a
                      href="javascript:void(0)"
                      onClick={e => this.validateMakeExtension()}>
                      Make Your Own Widgets
                    </a>
                  )}
              </div> */}
          </ul>
        ) : (
          <div className="loader d-flex loader_extension">
            <img className="gif" src="/assets/images/loader-x.gif" alt="gif" />
          </div>
        )}
      </div>
    </>
  );
};

export default WidgetList;
