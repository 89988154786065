import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  setBackButtonShow,
  setConferenceConnecting,
  setLoginAsGuest,
  setPage,
  setPreviousRouteStep,
} from '../../../reduxStore/confSlice';

const Connect = () => {
  const dispatch = useDispatch();
  const {loginAsGuest, localUser, roomDetail} = useSelector(
    state => state.conf,
  );
  const joinInLem =
    localUser?.role === 'guest' && roomDetail?.lem_mode ? true : false;

  useEffect(() => {
    if (joinInLem) {
      dispatch(setPage('Lem'));
      dispatch(setConferenceConnecting(true));
    } else {
      dispatch(setPage('AVSettings'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepBackHandler = e => {
    e.preventDefault();
    if (loginAsGuest === true) {
      dispatch(setPage('Guest'));
      dispatch(setBackButtonShow(false));
      dispatch(setLoginAsGuest(false));
    } else {
      dispatch(setPage('RecentUser'));
    }
  };

  const handleAVSettings = e => {
    e.preventDefault();
    dispatch(setPage('AVSettings'));
    dispatch(setPreviousRouteStep('Connect'));
  };
  const handleStreamingMode = e => {
    e.preventDefault();
    dispatch(setPage('Lem'));
    dispatch(setConferenceConnecting(true));
  };
  // const handleConnectPhone = e => {
  //   e.preventDefault();
  //   dispatch(setPage('ConnectPhone'));
  // };

  return (
    <div className="col-md-6 col-6 light-bg p-equal per5 connectWrap">
      <h2>
        How would you
        <br /> like to connect?
      </h2>
      <div className="row justify-content-center">
        {joinInLem ? (
          <>
            {' '}
            <div className="col-md-6 col-6 text-center">
              <div className="connect_options">
                <img src="assets/images/pc.png" />
              </div>
              <button
                type="button"
                onClick={handleStreamingMode}
                className="btn btn-green btn_stream_mode">
                Watch & Listen <br /> On This Device
              </button>
            </div>
          </>
        ) : (
          <>
            {' '}
            <div className="col-md-6 col-6 text-center">
              <div className="connect_options">
                <img src="assets/images/audio.png" />
              </div>
              <button
                type="button"
                className="btn btn-green"
                onClick={handleAVSettings}>
                Connect by Computer
              </button>
            </div>
            <div className="col-md-6 col-6 text-center">
              <div className="connect_options">
                <img src="assets/images/phone-audio.png" />
              </div>
              <button
                type="button"
                id="phoneAudioButton"
                className="btn btn-green btn-green-disabled">
                Call in by Phone
              </button>
            </div>
          </>
        )}
      </div>
      <div className="row justify-center mt-4" />
      <div className="row pl-15 backWrap">
        <a className="font30" aria-label="Close" onClick={stepBackHandler}>
          <svg width=".7em" viewBox="2 1.99 20 20">
            <path
              fill="#6cb33f"
              d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
            />
            <path
              fill="#6cb33f"
              d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Connect;
