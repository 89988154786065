import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setOpenLoginModel} from '../../../reduxStore/confSlice';

function TutorialsContact() {
  const {localUser} = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    window.open(`${window.location.origin}/support`, '_blank');
  };
  return (
    <a
      className="nav-link"
      onClick={() =>
        localUser.role !== 'guest'
          ? handleOpenModal()
          : dispatch(setOpenLoginModel(true))
      }>
      <div className="menu_icons">
        <img height="20" width="20" src="assets/images/support.png" />
      </div>
      <span className="pl-2">Tutorials/Contact Support</span>
    </a>
  );
}

export default TutorialsContact;
