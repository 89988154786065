import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InvitePeopleModal from './modals/invitePeopleModal';
import {setShowInviteModal} from '../../../reduxStore/confSlice';

function InvitePeople() {
  const {showInviteModal} = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const handleOpenModal = e => {
    e.preventDefault();
    dispatch(setShowInviteModal(true));
  };
  return (
    <>
      <a className="nav-link" onClick={e => handleOpenModal(e)}>
        <div className="menu_icons">
          <img height="20" width="20" src="assets/images/invite-sidebar.png" />
        </div>
        <span className="pl-2">Invite People</span>
      </a>
      {showInviteModal && <InvitePeopleModal />}
    </>
  );
}

export default InvitePeople;
