import {toast} from 'react-toastify';
import {
  setBilling,
  setMuteUnmutePermission,
  setTrial,
  setUserDetail,
} from '../../../../reduxStore/confSlice';
import {store} from '../../../../reduxStore/store';
import {
  getPasscode,
  updParticipant,
  updateProfile,
  upgradeAccountConfirm,
  upgradeAccountProcess,
} from '../../../../utils/socket';
import {
  updateRecenetUsersList,
  updateUserSettingsData,
} from '../../../../utils/functions';
import {localAudioTrackUnMute} from '../../../../libs';
import {setMirrorSelfView} from '../../../../reduxStore/persistSlice';
import {test_mode_id} from '../../../../utils/util';
import {alertMsg} from '../../../common/web/alert';
import swal from 'sweetalert';

export const updateUserSettings = key => {
  updateUserSettingsData(key, msg => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  });
};

export const setMirrorView = e => {
  const {dispatch} = store;
  if (e.target.checked) {
    dispatch(setMirrorSelfView(true));
  } else {
    dispatch(setMirrorSelfView(false));
  }
};

export const upgradeAccountConfirmHandler = e => {
  e.preventDefault();
  const state = store.getState();
  const {localUser, roomDetail} = state.conf;
  const {email, token} = localUser;
  const {room} = roomDetail;
  upgradeAccountConfirm({token, email, room: room}).then(res => {
    if (res.status === 200) {
      swal({
        title: res.data[0].msg,
        closeOnClickOutside: false,
        buttons: ['Cancel', 'Confirm'],
        dangerMode: true,
      }).then(Confirm => {
        if (Confirm) {
          upgradeAccountProcessHandler(room, token, email);
        }
      });
    } else {
      toast.error(res.data[0].msg, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  });
};

const upgradeAccountProcessHandler = (room, token, email) => {
  const {dispatch} = store;
  upgradeAccountProcess({
    room: room,
    token,
    test_mode_id: test_mode_id,
    email,
  }).then(res => {
    if (res.status === 200) {
      dispatch(setTrial(res.data[0].trail));
      dispatch(setBilling(res.data[0].billing));
      alertMsg({msg: res.data[0].msg});
    } else {
      toast.error(res.data[0].msg, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  });
};

export const getPasscodeHandler = setState => {
  const state = store.getState();
  const {localUser, roomDetail} = state.conf;
  const {token} = localUser;
  const {room} = roomDetail;
  getPasscode({room, token}).then(res => {
    if (res.status === 200) {
      setState(prevState => ({...prevState, passcode: res?.data[0].passcode}));
    }
  });
};
