import React from 'react';
import {useDispatch} from 'react-redux';

import {setStopRecordingModal} from '../../../../reduxStore/confSlice';
import {CommonModal} from '../../../common/web';

function StopRecordingModal() {
  const dispatch = useDispatch();

  const stopRecordingBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12 modal_min_height--sm d-flex align-items-center justify-content-center">
            <p className="mb-0 text-center">
              Are you sure you want to stop the recording?
            </p>
          </div>
          <div className="col-md-12 text-right mt-3">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-link"
              onClick={e => {
                e.preventDefault();
                dispatch(setStopRecordingModal(false));
              }}>
              Continue Recording
            </button>
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-green"
              onClick={e => {
                e.preventDefault();
                dispatch(setStopRecordingModal(false));
              }}>
              Stop Recording
            </button>
          </div>
        </div>
      </>
    );
  };

  const stopRecordingObject = {
    heading: 'Stop Recording',
    body: stopRecordingBody,
    show: true,
    classNameDialog: 'midmodal',
    classNameModal: 'fade',
    headingSize: 'h5',
  };

  return (
    <>
      <CommonModal {...stopRecordingObject} />
    </>
  );
}

export default StopRecordingModal;
