import React from 'react';
import {Video} from '../common/web';
import {useSelector} from 'react-redux';

const VideoInputPreview = ({tracks}) => {
  const {mirrorSelfView} = useSelector(state => state.persist);
  let videoTrack = {jitsiTrack: tracks};
  return (
    <Video
      videoTrack={videoTrack}
      className={`video-input-preview-display ${
        mirrorSelfView ? 'flipVideoX' : ''
      } `}
    />
  );
};

export default VideoInputPreview;
