import swal from 'sweetalert';

export const alertMsg = ({ msg, type }, cb) => {

    swal({
        title: msg,
        closeOnClickOutside: false,
        buttons: {
            cancel: false,
            confirm: type === "hideOk" ? false : true,
        },
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            if (type === "info") {

            } else {
                if (cb && typeof (cb) === 'function') {
                    cb();
                } else {
                }
            }
        } else {
        }
    });
};
