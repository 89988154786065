import React, { useState } from 'react';

import { CommonModal } from '../../../../common/web';
import { TenMegaBytes, uploadAllowedMimeType } from '../../../../../utils/util';
import { alertMsg } from '../../../../common/web/alert';
import { uploadFileInWasabi } from '../../../../../utils/functions';
import ProgressBar from '../../../../common/web/progressBar';

const StepTwoMeetingModal = ({
  nextButton,
  backButton,
  setState,
  state,
  setStateHandler,
  setStepTwo,
  isEditModalOpen,
  saveEventsAndRecording,
  setEventPhoto
}) => {
  const [uploadLoader, setUploadLoader] = useState(false);

  const saveHandler = e => {
    e.preventDefault();
    if (isEditModalOpen) {
      saveEventsAndRecording(() => {
        setStepTwo(false);
      });
    } else {
      nextButton(e, 'stepThree');
    }
  };

  const uploadFile = async file => {
    let fileType = file[0]?.type;
    let fileSize = file[0]?.size;

    if ((uploadAllowedMimeType().includes(fileType))) {
      if (fileSize < TenMegaBytes) {
        const selectedFiles = [...file];
        setUploadLoader(true);
        const [fileResponse] = await uploadFileInWasabi(selectedFiles);
        let fileName = fileResponse && fileResponse.length > 0 && fileResponse[0].attachmentName;

        if (fileName) {
          setEventPhoto(fileName);
        }
        setUploadLoader(false);
      } else {
        alertMsg({
          msg: 'File too large. Please upload a file less than 10 MB.',
          type: 'info',
        });
      }
    } else {
      alertMsg({ msg: 'Please upload a valid image file.', type: 'info' });
    }
  };
  const stepTwoMeetingBody = () => {

    return (
      <>
        <form className="form-format">
          <div className="row firstrow align-items-center justify-content-start p-0 modal_min_height">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8 col-8 offset-md-2 offset-2 darkbg borderRD10">
                  <h6 className="semiBold mb-15">Welcome-Page Settings</h6>
                  <div className="form-group row">
                    <label className="col-md-4 col-4 justify-content-end pr-0">
                      Event Headline:
                    </label>
                    <div className="col-md-7 col-7">
                      <input
                        spellCheck="false"
                        type="text"
                        required
                        maxLength="200"
                        name="headline"
                        className="form-control width85 float-left"
                        placeholder=""
                        value={state.headline}
                        onChange={e => setStateHandler(e)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-4 col-4 justify-content-end pr-0 hAuto">
                      &nbsp;
                    </label>
                    <div className="col-md-7 col-7">
                      <label className="checkwrap width70 float-left justify-left hAuto">
                        Show Countdown Timer
                        <input
                          spellCheck="false"
                          type="checkbox"
                          name="countdown"
                          checked={state.countdown}
                          onChange={e => setStateHandler(e)}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-4 col-4 justify-content-end pr-0">
                      Welcome-Page Photo:
                    </label>
                    <div className="col-md-7 col-7 widthSmall">
                      <div className="custom-input-file">
                        <input
                          spellCheck="false"
                          type="file"
                          id="eventfile"
                          accept="image/*"
                          className="form-control"
                          onChange={e => uploadFile(e.target.files)}
                        />
                        <div className="custom-file justify-content-start">
                          <button>Choose file</button>
                          <span>{state.photo}</span>
                        </div>
                      </div>
                      {
                        uploadLoader &&
                        <div className="upload-progress">
                          <ProgressBar />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row arrowrap mt-3">
            <div className="col-md-6 col-6 d-flex align-items-center">
              {isEditModalOpen ? (
                <button
                  type="submit"
                  className="btn-green btn-mini float-right"
                  onClick={e => {
                    setStepTwo(false);
                  }}>
                  {'Close'}
                </button>
              ) : (
                <a
                  className="font30"
                  data-toggle="modal"
                  data-target="#ARstep2-3"
                  onClick={e => {
                    backButton(e, 'stepTwo');
                  }}>
                  <svg width=".7em" viewBox="2 1.99 20 20">
                    <path
                      fill="#6cb33f"
                      d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
                    />
                    <path
                      fill="#6cb33f"
                      d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
                    />
                  </svg>
                </a>
              )}
            </div>
            <div className="col-md-6 col-6 text-right">
              <button
                type="submit"
                onClick={e => saveHandler(e)}
                className="btn-green btn-mini float-right"
                disabled={uploadLoader}
              >
                {isEditModalOpen ? 'Save' : 'Next'}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const stepTwoModalObject = {
    heading: 'Event',
    body: stepTwoMeetingBody,
    classNameModal: 'fade scheduleMeet modal-transbg createsched',
    show: true,
    headingSize: 'h5',
  };

  return <CommonModal {...stepTwoModalObject} />;
};

export default StepTwoMeetingModal;
