import React, {useState} from 'react';

import {CommonModal} from '../../../common/web';

function RoomErrorModal() {
  const [room, setRoom] = useState('');

  const handleChange = e => {
    const value = e.target.value;
    const newValue = value.replace(/[^A-Z0-9]+/gi, '');
    setRoom(newValue);
  };

  const manageRedirect = type => {
    event.preventDefault();
    if (type === 'join') {
      window.location.href = `/${room}`;
    } else {
      window.location.href = '/';
    }
  };

  const roomErrorBody = () => {
    return (
      <>
        <div className="row">
          <form onSubmit={() => manageRedirect('join')}>
            <div className="col-md-12 text-center modal_min_height--sm d-flex flex-column justify-content-center">
              <h4 className="mb-2">
                That room name does not exist. Please try again.
              </h4>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Room Name"
                  required
                  maxLength={50}
                  value={room}
                  onChange={e => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-link btn-mini"
                  onClick={() => manageRedirect('cancel')}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-green btn-mini float-right">
                  Join
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  };

  const loginObject = {
    body: roomErrorBody,
    show: true,
    classNameDialog: 'modal-sm forgot-password',
  };

  return (
    <>
      <CommonModal {...loginObject} />
    </>
  );
}
export default RoomErrorModal;
