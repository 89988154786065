import React from 'react';
import Layout from './layout';

function Privacy() {
  return (
    <>
      <Layout>
        <div className="body-content bodywrap page-content policyTxt">
          <div className="container">
            <div className="row">
              <div className="col-md-12 policyTxtWrap">
                <h2 className="semiBold">Privacy Policy</h2>
                <p>
                  Meetn is committed to protecting the privacy as well as the
                  accuracy and security of information collected from our online
                  visitors. This privacy policy is a statement of our guidelines
                  and practices with respect to the collection, use, protection
                  and disclosure of personal information acquired through your
                  use of the website and online services.
                </p>
                <h5 className="semiBold">Collection of Information</h5>
                <p>
                  We collect information from online visitors who sign up for
                  our services, ask to be on our newsletter mailing list and/or
                  receive periodic email announcements. We collect this
                  information from you to 1) establish and manage your account,
                  including to provide notifications to you regarding your
                  account; 2) to respond to your requests for support from us;
                  3) to inform you of newly-developed features or services and
                  to solicit your adoption of these services; 4) to help us
                  develop new services and features that meet your needs; and 5)
                  to help you more fully utilize the service and its features,
                  and 6) to bill and collect for services to satisfy Meetn's
                  legitimate business interests.
                </p>
                <p>
                  We may request personally-identifiable information from you
                  such as your name, billing, work, or home address, telephone
                  number, e-mail address or credit card information. It is
                  solely your choice whether or not you provide this personally
                  identifiable information. However, should you choose not to
                  provide the information, we may be unable to process an order,
                  fulfill a service or display certain content on the Site. We
                  do not sell or rent personal information, including personally
                  identifiable information, to unrelated third parties.
                </p>
                <h5 className="semiBold">Removal From Email List</h5>
                <p>
                  You can choose to be removed from our email list at any time
                  via a unique unsubscribe link sent with each email message,
                  located at the very bottom or "footer" of the message. You may
                  also be removed from our mailing list by contacting our
                  support team at support.Meetn.com, by emailing
                  support@Meetn.com, or by calling 503-290-5600.
                </p>
                <p>
                  Removal from our mailing list may prevent you from receiving
                  timely information which may directly or even negatively
                  impact you or your business. These notifications may include
                  maintenance windows, changes to the way service is delivered,
                  and other messages important to the operation of your service.
                </p>
                <p>
                  Removal from our mailing list only removes you from bulk
                  notification messages. It does not remove you from all emails
                  that may be sent to you from our domain, including automated
                  billing notifications, emails from our support team and other
                  employees and vendors, or any other transactional messages
                  required to maintain service with Meetn.
                </p>
                <h5 className="semiBold">
                  How to Access Your Personal Information
                </h5>
                <p>
                  You have access to the information we collect from you via the
                  website and you may correct inaccuracies we obtained from your
                  registration. We require our customers to comply with
                  applicable privacy laws and regulations pertaining to the use
                  of our services.
                </p>
                <h5 className="semiBold">Browser "Cookies"</h5>
                <p>
                  Cookies are small files placed on your hard drive by various
                  internet services which store information that help tailor the
                  experience for your specific requirements. We may use cookies
                  as a way of improving your experience with our service.
                  Cookies do not personally identify users, although they do
                  identify a user's computer. Also, most browsers are initially
                  set to accept cookies, though you can set your browser to
                  refuse cookies. If your browser does not accept cookies, your
                  Meetn services may be disrupted, as many functions provided by
                  Meetn require cookies to fully operate.
                </p>
                <h5 className="semiBold">Security</h5>
                <p>
                  We utilize reasonable protections to ensure that personal
                  information in our care is not misused or accessed without
                  authorization. Personal information is stored on our own
                  platforms or on the platforms of our service providers, with
                  access restricted to those employees or contractors who have a
                  need for such access to perform a legitimate business function
                  relating to the services or for maintenance, internal security
                  or related issues.
                </p>
                <h5 className="semiBold">Third Party Web Sites</h5>
                <p>
                  Meetn's services may contain links to third party websites.
                  While we try to link only to sites that share our high
                  standards and respect for privacy, we are not responsible for
                  the content or the privacy practices of any third party
                  websites. Before disclosing personal information to 3rd-party
                  websites, we encourage you to review their specific privacy
                  policies.
                </p>
                <p>
                  Meetn also uses third-party tracking and analytics to
                  understand visitor traffic patterns, though this information
                  is aggregated and not traceable to the end user by Meetn.
                </p>
                <p>
                  In the future, Meetn may utilize third-party platforms such as
                  Facebook, LinkedIn, or downloadable applications to mobile
                  devices that allow us to access and/or collect certain
                  information from that platform. You allow us to collect and
                  access this information via this privacy policy as well as via
                  the privacy policies provided by the third-party platforms.
                  You also allow us to access information contained in cookies
                  placed on your device by the Third Party Platform as permitted
                  by the terms of your agreement and privacy settings with each
                  specific Third Party Platform. We may share this information
                  with the Third Party Platform for their use as permitted by
                  the terms of your agreement and privacy settings with the
                  Third Party Platform.
                </p>
                <p>
                  We will share some of the information we collect from you with
                  vendors and other service providers who work with us to
                  support the internal operations of our website and/or mobile
                  application for specific improvements to our services and
                  feature set.
                </p>
                <h5 className="semiBold">Consent</h5>
                <p>
                  By submitting personal information to Meetn or its affiliates
                  and agents, you agree that we may collect, use and disclose
                  such personal information in accordance with this privacy
                  policy and as permitted or required by law. Your use of the
                  Meetn website and services signifies your assent to this
                  Privacy Policy.
                </p>
                <h5 className="semiBold">Modifications</h5>
                <p>
                  We reserve the right to modify this Privacy Policy at any time
                  without notice. Please check this page periodically for
                  changes. Your continued use of the Meetn website and service
                  following the posting of changes to this Privacy Policy
                  constitutes acceptance of those changes.
                </p>
                <h5 className="semiBold">Contacting Us</h5>
                <p>We can be reached by contacting:</p>
                <p>
                  N Partners, LLC
                  <br />
                  1712 Pioneer Ave Suite 1018
                  <br />
                  Cheyenne WY 82001
                </p>
                <p className="mb-0">
                  Email: support@Meetn.com
                  <br />
                  Phone: 512-765-4321
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default Privacy;
