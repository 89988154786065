import React, {useState} from 'react';

import {CommonModal} from '../../../common/web';

const AddSubstitutionModal = ({addLinkSubstitution, closeModal}) => {
  const [state, setState] = useState({actual_text: '', replace_text: ''});
  const handleTextValue = e => {
    let text = e.target.value;
    let name = e.target.name;
    text = text.replace(/[^a-zA-Z0-9]/g, '');

    setState({...state, [name]: text});
  };
  const addSubstitutionBody = () => {
    return (
      <>
        <form>
          <div className="form-group">
            <p className="w-auto d-inline-block font-1">When</p>&nbsp;
            <input
              spellCheck="false"
              type="text"
              maxLength="50"
              className="form-control w-auto d-inline-block form-control-sm"
              name="actual_text"
              value={state.actual_text}
              onChange={e => handleTextValue(e)}
              required
            />
            &nbsp;
            <p className="w-auto d-inline-block font-1">
              appears in a link, replace it with
            </p>
            &nbsp;
            <input
              spellCheck="false"
              type="text"
              maxLength="50"
              className="form-control w-auto d-inline-block form-control-sm"
              name="replace_text"
              value={state.replace_text}
              onChange={e => handleTextValue(e)}
              required
            />
          </div>
          <p className="mt-3">
            <small>
              Note: This applies to links used in the chat, webpage popper, CTA
              popups, Button Bars, and the Exit-Page URL.
            </small>
          </p>
          <div className="row justify-left">
            <div className="col-md-12 text-right mt-3">
              <button
                type="button"
                className="btn-link"
                data-dismiss="modal"
                onClick={() => {
                  closeModal();
                }}>
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-green btn-mini abso-btn float-right"
                onClick={e => addLinkSubstitution(e, state)}>
                Save
                <i className="icon-arrow-right-circle icons"></i>
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const addSubstitutionObject = {
    heading: `Add Link Substitution`,
    body: addSubstitutionBody,
    classNameModal: 'fade',
    classNameDialog: 'modal-sm',
    show: true,
    headingSize: 'h5',
  };

  return (
    <>
      <CommonModal {...addSubstitutionObject} />
    </>
  );
};

export default AddSubstitutionModal;
