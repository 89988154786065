import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import $ from 'jquery';

import { CommonModal } from '../../../../common/web';
import SimpleReactValidator from 'simple-react-validator';
import 'react-datepicker/dist/react-datepicker.css';
import {
  durationArray,
  timeHours,
  timeMeridiem,
  timeMins,
  timezoneArray,
} from '../../../../../utils/util';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../../../utils/functions';
import { newEventTimeValidate } from '../../../../../utils/socket';
import { toast } from 'react-toastify';

const StepOneMeetingModal = ({
  nextButton,
  backButton,
  setState,
  state,
  setStateHandler,
  setStepOne,
  isEditModalOpen,
  saveEventsAndRecording,
}) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const { roomDetail } = useSelector(state => state.conf);
  const { room } = roomDetail;

  const showDatePicker = e => {
    $('#startDatePicker').click();
  };
  const handleDatePicker = date => {
    console.log('handleDatePicker date', date);
    setState({ ...state, start_date: date });
  };
  const quickSetDate = day => {
    if (day === 'tm') {
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      setState({ ...state, start_date: tomorrow });
    } else {
      let current_date = new Date();
      setState({ ...state, start_date: current_date });
    }
  };

  const saveHandler = e => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      const { start_date, time_hour, time_minutes, time_meridiem, timezone } =
        state;
      const formatedDate = formatDate(start_date);
      const payload = {
        room,
        start_date: formatedDate,
        start_time: `${time_hour}:${time_minutes}`,
        start_time_am: time_meridiem == 'AM' ? true : false,
        timezone,
        id: isEditModalOpen ? state.id : undefined,
      };
      newEventTimeValidate(payload).then(res => {
        if (res.status === 200) {
          if (isEditModalOpen) {
            setStepOne(false);
            saveEventsAndRecording(() => {
              setStepOne(false);
            });
          } else {
            nextButton(e, 'stepTwo');
          }
        } else {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };


  const stepOneMeetingBody = () => {
    return (
      <>
        <form>
          <div className="row firstrow modal_min_height">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8 col-8 offset-md-2 offset-2 darkbg borderRD10">
                  <h6 className="semiBold mb-15">Date &amp; Time</h6>
                  <div className="form-group row">
                    <label className="col-md-4 col-4 justify-content-end pr-0 label-inline pr-inside-3">
                      Start Date:
                    </label>
                    <div className="col-md-8 col-8 pr-0">
                      <div className="row">
                        <div className="col-md-12 calendar-wrap">
                          <DatePicker
                            showPopperArrow={false}
                            name="start_date"
                            dateFormat="MM/dd/yyyy"
                            className="form-control width85"
                            id="startDatePicker"
                            popperPlacement="bottom"
                            popperModifiers={{
                              flip: {
                                behavior: ['bottom'],
                              },
                              preventOverflow: {
                                enabled: false,
                              },
                              hide: {
                                enabled: false,
                              },
                            }}
                            selected={state.start_date}
                            onChange={date => handleDatePicker(date)}
                          />
                          <i
                            className="fa fa-calendar calendar-icon"
                            onClick={e => showDatePicker(e)}
                          />
                        </div>
                        <div className="col-md-12">
                          <label className="linkLabel wauto">
                            <a onClick={e => quickSetDate('td')}>
                              <small>Today</small>
                            </a>
                          </label>
                          <label className="linkLabel wauto ml-3">
                            <a onClick={e => quickSetDate('tm')}>
                              <small>Tomorrow</small>
                            </a>
                          </label>
                        </div>
                      </div>

                      <p className="m-0">
                        <small>
                          {simpleValidator.current.message(
                            'date',
                            state.start_date,
                            'required',
                            { messages: { default: 'Date field is required.' } },
                          )}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-4 justify-content-end pr-0 label-inline pr-inside-3">
                      Start Time:
                    </label>
                    <div className="col-md-8 col-8">
                      <div className="row">
                        <div className="width85 start_date_event select_custom">
                          <div className="col-md-4 col-4 p-0">
                            <select
                              name="time_hour"
                              value={state.time_hour}
                              className="form-control"
                              onChange={e => setStateHandler(e)}>
                              {timeHours?.map((hour, index) => (
                                <option value={hour}>{hour}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-4 col-4 p-0">
                            <select
                              name="time_minutes"
                              value={state.time_minutes}
                              className="form-control"
                              onChange={e => setStateHandler(e)}>
                              {timeMins?.map((minute, index) => (
                                <option value={minute}>{minute}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-4 col-4 p-0">
                            <select
                              name="time_meridiem"
                              className="form-control"
                              value={state.time_meridiem}
                              onChange={e => setStateHandler(e)}>
                              {timeMeridiem?.map((meridiem, index) => (
                                <option value={meridiem}>{meridiem}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row select_custom">
                    <label className="col-md-4 col-4 justify-content-end pr-0 label-inline">
                      Duration:
                    </label>
                    <div className="col-md-8 col-8 pr-0">
                      <select
                        type="text"
                        name="duration"
                        className="form-control width85 float-left"
                        value={state.duration}
                        onChange={e => setStateHandler(e)}>
                        {durationArray?.map((durationItem, index) => (
                          <option value={durationItem.value}>
                            {durationItem.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row select_custom">
                    <label className="col-md-4 col-4 justify-content-end pr-0 label-inline">
                      Time Zone:
                    </label>
                    <div className="col-md-8 col-8 pr-0">
                      <select
                        type="text"
                        name="timezone"
                        className="form-control width85 float-left"
                        value={state.timezone}
                        onChange={e => setStateHandler(e)}>
                        {timezoneArray?.map((timeZoneItem, index) => (
                          <option
                            key={index}
                            value={timeZoneItem.value}
                            disabled={timeZoneItem.value === '' ? true : false}>
                            {timeZoneItem.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row arrowrap mt-3">
            <div className="col-md-6 col-6 d-flex align-items-center">
              {isEditModalOpen ? (
                <button
                  type="submit"
                  className="btn-green btn-mini float-right"
                  onClick={e => {
                    setStepOne(false);
                  }}>
                  {'Close'}
                </button>
              ) : (
                <a
                  onClick={e => {
                    backButton(e, 'stepOne');
                  }}
                  className="font30"
                  data-toggle="modal"
                  data-target="#LMstep1">
                  <svg width=".7em" viewBox="2 1.99 20 20">
                    <path
                      fill="#6cb33f"
                      d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
                    />
                    <path
                      fill="#6cb33f"
                      d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
                    />
                  </svg>
                </a>
              )}
            </div>
            <div className="col-md-6 col-6 text-right">
              <button
                type="submit"
                className="btn-green btn-mini float-right"
                onClick={e => {
                  saveHandler(e);
                }}>
                {isEditModalOpen ? 'Save' : 'Next'}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const stepOneModalObject = {
    heading: 'Event',
    body: stepOneMeetingBody,
    classNameModal: 'fade scheduleMeet createsched',
    show: true,
    headingSize: 'h5',
  };

  return <CommonModal {...stepOneModalObject} />;
};

export default StepOneMeetingModal;
