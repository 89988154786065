/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {CommonModal} from '../../../../common/web';
import EditMeetingModal from './editMeetingModal';
import StepOneMeetingModal from './stepOneMeetingModal';
import StepTwoMeetingModal from './stepTwoMeetingModal';
import StepThreeMeetingModal from './stepThreeMeetingModal';
import StepFourMeetingModal from './stepFourMeetingModal';
import StepFiveMeetingModal from './stepFiveMeetingModal';
import AlertMeetingModal from '../alertMeetingModal';
import {setShowMeetingModal} from '../../../../../reduxStore/confSlice';
import {
  eventsAndRecording,
  getDownloadedSignedUrl,
  getEventsAndRecordingList,
  removeEventsAndRecording,
} from '../../../../../utils/socket';
import {
  formatDate,
  secondstoHoursAndMinutes,
} from '../../../../../utils/functions';
import moment from 'moment';
import {
  eventsAndRecordingTypes,
  timezoneArray,
} from '../../../../../utils/util';
import {toast} from 'react-toastify';
import CustomPagination from '../../../../common/web/customPagination';
import ManageReplay from './manageReplay';
const initialState = {
  start_date: new Date(),
  time_hour: '07',
  time_minutes: '00',
  time_meridiem: 'AM',
  duration: '15',
  timezone: 'PST',
  headline: 'Welcome to the Meetn',
  countdown: false,
  photo: '',
  hide_muted_attendees: false,
  hide_guest_count_names_for_guests: false,
  everyone_joins_muted: false,
  active_speaker_default_view: false,
  hide_widgets_for_guests: false,
  hide_chat_for_guests: false,
  prevent_chat_auto_appear: false,
  host_required: false,
  guests_have_extra_powers: false,
  guests_can_unmute_themselves: false,
  lem_mode: false,
  hide_share_screen_for_guests: false,
  hide_raise_hand_for_guests: false,
  public_name: '',
  private_name: '',
  auto_replay: true,
  passcode: '',
  invite_text: '',
};
const EventAndRecordingModal = () => {
  const dispatch = useDispatch();
  const [isAlert, setIsAlert] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [stepFour, setStepFour] = useState(false);
  const [stepFive, setStepFive] = useState(false);
  const [showManageReplay, setManageReplay] = useState(false);
  const {eventsList, roomDetail} = useSelector(state => state.conf);
  const [eventsAndRecordingList, setEventsAndRecordingList] = useState([]);
  console.log('eventsAndRecordingList', eventsAndRecordingList);

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const [pageOfItems] = useState([]);
  const [skip, setSkip] = useState(0);
  const [lastElementTimestamp, setLastElementTimestamp] = useState('');
  const [listLoader, setListLoader] = useState(true);
  const [downloadRecordingLoader, setDownloadRecordingLoader] = useState([]);

  const {room} = roomDetail;
  const [state, setState] = useState(initialState);
  const [filter, setFilter] = useState('');
  const manageFinalSteps = e => {
    e.preventDefault();
    setState(initialState);
    setStepOne(false);
    setStepTwo(false);
    setStepThree(false);
    setStepFour(false);
    setStepFive(false);
  };

  useEffect(() => {
    if (eventsAndRecordingList.length > 0) {
      const timeStamp =
        eventsAndRecordingList[eventsAndRecordingList.length - 1]?.start_utc;
      setLastElementTimestamp(timeStamp);
    } else {
      setLastElementTimestamp('');
    }
  }, [eventsAndRecordingList]);

  const eventAndRecordingListHandler = () => {
    setListLoader(true);
    getEventsAndRecordingList({
      room,
      timestamp: lastElementTimestamp,
      limit: pageSize,
      filter,
    }).then(res => {
      setListLoader(false);
      if (res.status === 200) {
        setEventsAndRecordingList([...res.data]);
        setTotalRecords(res.records);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  useEffect(() => {
    eventAndRecordingListHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (e, type, editableData) => {
    e.preventDefault();
    if (type === 'edit') {
      const {start_time, start_time_am, start_date} = editableData;
      const timearrayData = start_time.split(':');
      setState({
        ...editableData,
        time_hour: timearrayData[0],
        time_minutes: timearrayData[1],
        time_meridiem: start_time_am ? 'AM' : 'PM',
        start_date: moment(start_date).toDate(),
      });
      setIsEditModalOpen(true);
    } else if (type === 'new') {
      setState(initialState);
      setStepOne(true);
    }
  };

  const closeModal = (e, type) => {
    e.preventDefault();
    if (type === 'mainModal') {
      dispatch(setShowMeetingModal(false));
    } else if (type === 'editMeetingModal') {
      setIsEditModalOpen(false);
    }
  };

  const nextButton = (e, type) => {
    console.log('nextButton', type);
    e?.preventDefault();
    if (type === 'stepOne') {
      setStepOne(true);
    } else if (type === 'stepTwo') {
      setStepTwo(true);
    } else if (type === 'stepThree') {
      setStepThree(true);
    } else if (type === 'stepFour') {
      setStepFour(true);
    } else if (type === 'stepFive') {
      setStepFive(true);
    } else if (type === 'manageReplay') {
      setManageReplay(true);
    }
  };

  const backButton = (e, type) => {
    e.preventDefault();
    if (type === 'stepOne') {
      setStepOne(false);
    } else if (type === 'stepTwo') {
      setStepOne(true);
      setStepTwo(false);
    } else if (type === 'stepThree') {
      setStepTwo(true);
      setStepThree(false);
    } else if (type === 'stepFour') {
      setStepFour(false);
      setStepThree(true);
    } else if (type === 'stepFive') {
      setStepFive(false);
      setStepFour(true);
    }
  };

  const handleCloseAlert = () => {
    setIsAlert(false);
  };

  const setStateHandler = e => {
    setState({
      ...state,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const setEventPhoto = file => {
    setState(prevState => ({
      ...prevState,
      photo: file,
    }));
  };

  const updateStateHandler = e => {
    const {
      start_date,
      time_hour,
      time_minutes,
      time_meridiem,
      duration,
      timezone,
      headline,
      countdown,
      photo,
      hide_muted_attendees,
      hide_guest_count_names_for_guests,
      everyone_joins_muted,
      active_speaker_default_view,
      hide_widgets_for_guests,
      hide_chat_for_guests,
      prevent_chat_auto_appear,
      host_required,
      guests_have_extra_powers,
      guests_can_unmute_themselves,
      lem_mode,
      hide_share_screen_for_guests,
      hide_raise_hand_for_guests,
      public_name,
      private_name,
      auto_replay,
      passcode,
      status,
      replay_enabled,
      download_audio,
      download_video,
    } = state;

    const formatedDate = formatDate(start_date);

    const payload = {
      start_date: formatedDate,
      start_time: `${time_hour}:${time_minutes}`,
      start_time_am: time_meridiem == 'AM' ? true : false,
      duration,
      timezone,
      headline,
      countdown,
      photo,
      hide_muted_attendees,
      hide_guest_count_names_for_guests,
      everyone_joins_muted,
      active_speaker_default_view,
      hide_widgets_for_guests,
      hide_chat_for_guests,
      prevent_chat_auto_appear,
      host_required,
      guests_have_extra_powers,
      guests_can_unmute_themselves,
      lem_mode,
      hide_share_screen_for_guests,
      hide_raise_hand_for_guests,
      public_name,
      private_name,
      auto_replay,
      passcode,
      status,
      room: roomDetail?.room,
      id: state.id,
      replay_enabled,
      download_audio,
      download_video,
    };

    payload[e.target.name] =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    eventsAndRecording(payload).then(res => {
      if (res.status === 200) {
        const tempEventsAndRecordingList = [...eventsAndRecordingList];
        const ItemIndex = tempEventsAndRecordingList.findIndex(
          item => item.id === res.data.id,
        );
        tempEventsAndRecordingList[ItemIndex] = res.data;
        setEventsAndRecordingList(tempEventsAndRecordingList);
        setState(prevState => ({
          ...prevState,
          ...res.data,
        }));
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const saveEventsAndRecording = (cb, setLoader) => {
    const {
      start_date,
      time_hour,
      time_minutes,
      time_meridiem,
      duration,
      timezone,
      headline,
      countdown,
      photo,
      hide_muted_attendees,
      hide_guest_count_names_for_guests,
      everyone_joins_muted,
      active_speaker_default_view,
      hide_widgets_for_guests,
      hide_chat_for_guests,
      prevent_chat_auto_appear,
      host_required,
      guests_have_extra_powers,
      guests_can_unmute_themselves,
      lem_mode,
      hide_share_screen_for_guests,
      hide_raise_hand_for_guests,
      public_name,
      private_name,
      auto_replay,
      passcode,
      status,
      replay_enabled,
      download_audio,
      download_video,
    } = state;
    const formatedDate = formatDate(start_date);
    const payload = {
      start_date: formatedDate,
      start_time: `${time_hour}:${time_minutes}`,
      start_time_am: time_meridiem == 'AM' ? true : false,
      duration,
      timezone,
      headline,
      countdown,
      photo,
      hide_muted_attendees,
      hide_guest_count_names_for_guests,
      everyone_joins_muted,
      active_speaker_default_view,
      hide_widgets_for_guests,
      hide_chat_for_guests,
      prevent_chat_auto_appear,
      host_required,
      guests_have_extra_powers,
      guests_can_unmute_themselves,
      lem_mode,
      hide_share_screen_for_guests,
      hide_raise_hand_for_guests,
      public_name,
      private_name,
      auto_replay,
      passcode,
      status,
      room: roomDetail?.room,
      replay_enabled,
      download_audio,
      download_video,
    };
    if (isEditModalOpen) {
      payload['id'] = state.id;
    }
    eventsAndRecording(payload).then(res => {
      if (res.status === 200) {
        if (isEditModalOpen) {
          const tempEventsAndRecordingList = [...eventsAndRecordingList];
          const ItemIndex = tempEventsAndRecordingList.findIndex(
            item => item.id === res.data.id,
          );
          tempEventsAndRecordingList[ItemIndex] = res.data;
          setEventsAndRecordingList(tempEventsAndRecordingList);
        } else {
          setEventsAndRecordingList([...eventsAndRecordingList, res.data]);
        }
        setState({...state, invite_text: res.data.invite_text});
        cb();
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (setLoader) {
        setLoader(false);
      }
    });
  };

  const removeEventsAndRecordingHandler = (deleteId, index) => {
    removeEventsAndRecording({
      id: deleteId,
    }).then(res => {
      if (res.status === 200) {
        const deleteItem = eventsAndRecordingList.filter(
          (item, indx) => indx !== index,
        );
        setEventsAndRecordingList(deleteItem);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const changeFilterHandler = e => {
    const currentType = e.target.value;
    const payload = {room, limit: pageSize};
    if (currentType !== '') {
      payload['filter'] = currentType;
    }
    getEventsAndRecordingList(payload).then(res => {
      if (res.status === 200) {
        setEventsAndRecordingList([...res.data]);
        setFilter(currentType);
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const onChangePage = (pageOfItems, page) => {
    if (page !== currentPage) {
      let timeStamp = lastElementTimestamp;
      if (page < currentPage) {
        timeStamp = eventsAndRecordingList[0]?.start_utc;
      }
      const payload = {
        room,
        filter,
        timestamp: timeStamp,
        limit: pageSize,
      };
      getEventsAndRecordingList(payload).then(res => {
        if (res.status === 200) {
          setEventsAndRecordingList([...res.data]);
          setCurrentPage(page);
        } else {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  };

  const downloadRecordingHandler = (recording_video_url, id = '') => {
    var filename = recording_video_url?.substring(
      recording_video_url.lastIndexOf('/') + 1,
    );

    if (id) {
      setDownloadRecordingLoader(prevLoading => [...prevLoading, id]);
    }

    getDownloadedSignedUrl({filename: `video/${filename}`}).then(response => {
      if (response.status == 200) {
        const url = response?.data?.url;
        fetch(url)
          .then(res => {
            return res.blob();
          })
          .then(blob => {
            if (id) {
              setDownloadRecordingLoader(prevLoading =>
                prevLoading.filter(item => item !== id),
              );
            }
            const href = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.download = filename;
            a.href = href;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
      }
    });
  };

  const cancelMeeting = () => {};
  const meetingBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <form className="row form-inline">
              <div className="col-md-12">
                <div className="form-group float-left">
                  <select
                    name="filter"
                    className="form-control custom-select"
                    value={filter}
                    onChange={e => changeFilterHandler(e)}>
                    {eventsAndRecordingTypes.map((eventItem, index) => (
                      <option value={eventItem.value} key={index}>
                        {eventItem.label}
                      </option>
                    ))}
                  </select>
                </div>
                <a
                  data-toggle="modal"
                  onClick={e => openModal(e, 'new')}
                  className="btn btn-green btn-mini abso-btn float-right">
                  New Event
                </a>
              </div>
            </form>

            <div className="row firstrow align-items-baseline p-0 modal_min_height">
              {listLoader ? (
                <>
                  <img
                    src="assets/images/loader-x.gif"
                    className="pop-loader"
                  />
                </>
              ) : (
                <>
                  <div className="col-md-12 table-responsive mb-3 mt-1 table-max">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th width="20%">
                            <span>Date</span>
                          </th>

                          <th width="15%" className="text-center">
                            <span>Event</span>
                          </th>

                          <th width="15%" className="text-center">
                            <span>Status</span>
                          </th>

                          <th width="15%" className="text-center">
                            <span>Size</span>
                          </th>

                          <th width="15%" className="text-center">
                            <span>Duration</span>
                          </th>

                          <th width="10%" className="text-center">
                            <span>Recording</span>
                          </th>

                          <th width="10%" className="text-center">
                            <span>Actions</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {eventsAndRecordingList.length > 0 ? (
                          eventsAndRecordingList?.map((eventItem, index) => {
                            const durationConvertedData =
                              secondstoHoursAndMinutes(eventItem.duration);
                            const recordingFileName =
                              eventItem?.recording_video_url !== ''
                                ? eventItem?.recording_video_url?.substring(
                                    eventItem?.recording_video_url?.lastIndexOf(
                                      '/',
                                    ) + 1,
                                  )
                                : '';
                            return (
                              <tr>
                                <td>
                                  {`${moment(eventItem.start_date).format(
                                    'll',
                                  )} at ${eventItem.start_time} ${
                                    eventItem.start_time_am ? 'AM' : 'PM'
                                  } ${
                                    timezoneArray.find(
                                      item => item.value === eventItem.timezone,
                                    )?.label
                                  }`}
                                </td>
                                <td className="text-center">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={e =>
                                      openModal(e, 'edit', eventItem)
                                    }>
                                    {eventItem.private_name}
                                  </a>
                                </td>

                                <td className="text-center">
                                  {eventItem.status}
                                </td>
                                <td className="text-center">
                                  {eventItem?.size ?? '---'}
                                </td>
                                <td className="text-center">
                                  {eventItem?.duration}
                                </td>
                                <td className="text-center">
                                  {eventItem?.recording_video_url !== '' ? (
                                    <a
                                      onClick={() =>
                                        downloadRecordingHandler(
                                          eventItem.recording_video_url,
                                          eventItem.id,
                                        )
                                      }>
                                      {!downloadRecordingLoader.includes(
                                        eventItem.id,
                                      ) ? (
                                        <i className="fa fa-download text-grey" />
                                      ) : (
                                        <img
                                          src="assets/images/loader-x.gif"
                                          className="pop-loader event-pop-loader"
                                        />
                                      )}
                                    </a>
                                  ) : (
                                    'No Recording'
                                  )}
                                </td>
                                <td className="text-center">
                                  <a
                                    title=""
                                    onClick={e =>
                                      openModal(e, 'edit', eventItem)
                                    }>
                                    <i
                                      className="fas fa-pencil-alt text-grey"
                                      style={{color: 'red'}}
                                    />
                                  </a>
                                  <a
                                    onClick={() =>
                                      removeEventsAndRecordingHandler(
                                        eventItem.id,
                                        index,
                                      )
                                    }>
                                    <i className="fa fa-trash text-grey" />
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">
                              <span>No events and recordings.</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              <div className="col-md-12 pagingWrap text-right paging_inners">
                {totalRecords > 0 ? (
                  <CustomPagination
                    totalRecords={totalRecords}
                    items={pageOfItems}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onChangePage={onChangePage}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-right">
                <button
                  type="button"
                  onClick={e => {
                    closeModal(e, 'mainModal');
                  }}
                  data-toggle="modal"
                  data-target="#schedmeetings"
                  className="btn-link float-right">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const meetingModalObject = {
    heading: 'Events and Recordings',
    body: meetingBody,
    classNameModal: 'fade scheduleMeet backdrop createsched',
    show: true,
    headingSize: 'h5',
    classNameDialog: 'events-modal',
  };

  console.log('state', state);

  return (
    <>
      <CommonModal {...meetingModalObject} />
      {isEditModalOpen && (
        <EditMeetingModal
          closeModal={closeModal}
          nextButton={nextButton}
          state={state}
        />
      )}
      {stepOne && (
        <StepOneMeetingModal
          nextButton={nextButton}
          backButton={backButton}
          setState={setState}
          state={state}
          setStateHandler={setStateHandler}
          setStepOne={setStepOne}
          isEditModalOpen={isEditModalOpen}
          saveEventsAndRecording={saveEventsAndRecording}
        />
      )}
      {stepTwo && (
        <StepTwoMeetingModal
          nextButton={nextButton}
          backButton={backButton}
          setState={setState}
          state={state}
          setStateHandler={setStateHandler}
          setStepTwo={setStepTwo}
          isEditModalOpen={isEditModalOpen}
          saveEventsAndRecording={saveEventsAndRecording}
          setEventPhoto={setEventPhoto}
        />
      )}
      {stepThree && (
        <StepThreeMeetingModal
          nextButton={nextButton}
          backButton={backButton}
          setState={setState}
          state={state}
          setStateHandler={setStateHandler}
          setStepThree={setStepThree}
          isEditModalOpen={isEditModalOpen}
          saveEventsAndRecording={saveEventsAndRecording}
        />
      )}
      {stepFour && (
        <StepFourMeetingModal
          nextButton={nextButton}
          backButton={backButton}
          state={state}
          setStateHandler={setStateHandler}
          eventsAndRecordingList={eventsAndRecordingList}
          setEventsAndRecordingList={setEventsAndRecordingList}
          setState={setState}
          setStepFour={setStepFour}
          isEditModalOpen={isEditModalOpen}
          saveEventsAndRecording={saveEventsAndRecording}
        />
      )}
      {stepFive && (
        <StepFiveMeetingModal
          manageFinalSteps={manageFinalSteps}
          backButton={backButton}
          state={state}
          setStateHandler={setStateHandler}
        />
      )}
      {isAlert && (
        <AlertMeetingModal
          handleCloseAlert={handleCloseAlert}
          cancelMeeting={cancelMeeting}
        />
      )}
      {showManageReplay && (
        <ManageReplay
          state={state}
          closeModal={setManageReplay}
          updateStateHandler={updateStateHandler}
          downloadRecordingHandler={downloadRecordingHandler}
        />
      )}
    </>
  );
};

export default EventAndRecordingModal;
