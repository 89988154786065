import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { CommonModal } from '../../../../common/web';
import { removeTags } from '../../../../../utils/functions';

const EditMeetingModal = ({ closeModal, nextButton, state }) => {
  const { invite_text } = state;
  const editMeetingBody = () => {
    return (
      <>
        <div className="row modal_min_height">
          <div className="col-md-12">
            <div className="container-fluid darkbg borderRD10">
              <div className="row">
                <div className="col-md-5 col-5">
                  <h6 className="semiBold">Event Settings</h6>
                  <ul className="p-0 no-Lstyle">
                    <li>
                      <a onClick={e => nextButton(e, 'stepOne')}>
                        <u>Date & Time</u>
                      </a>
                    </li>
                    <li>
                      <a onClick={e => nextButton(e, 'stepTwo')}>
                        <u>Welcome-Page</u>
                      </a>
                    </li>
                    <li>
                      <a onClick={e => nextButton(e, 'stepThree')}>
                        <u>Webinar Settings</u>
                      </a>
                    </li>

                    <li>
                      <a onClick={e => nextButton(e, 'stepFour')}>
                        <u>Basic Information</u>
                      </a>
                    </li>
                  </ul>
                  <h6 className="semiBold">Actions</h6>
                  <ul className="p-0 no-Lstyle mb-0">
                    <li>
                      <a onClick={e => nextButton(e, 'manageReplay')}>
                        <u>Manage Replays & Recordings</u>
                      </a>
                    </li>
                    <li>
                      <a target="_blank">
                        <u>View Event Page</u>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-7 col-7">
                  <h5 className="semiBold mb-15">Invite Link</h5>
                  <p className="mb-1">
                    <small>
                      Copy the following text and send
                      <br /> it to everyone you would like to invite.
                    </small>
                  </p>
                  <div className="form-group">
                    <p className="form-control">
                      {invite_text.indexOf('<br') !== -1 ? (
                        <div
                          className="TextHighlight"
                          dangerouslySetInnerHTML={{
                            __html: invite_text.replace(
                              /(<? *script)/gi,
                              'illegalscript',
                            ),
                          }}
                        />
                      ) : (
                        invite_text
                      )}
                    </p>
                  </div>
                  <div className="form-group">
                    <CopyToClipboard
                      options={{ format: 'text/plain' }}
                      text={removeTags(invite_text)}>
                      <button role="button" className="btn btn-green m-0">
                        Copy Link
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="row arrowrap">
              <div className="col-md-12 col-12 text-right">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#EditEvent"
                  onClick={e => closeModal(e, 'editMeetingModal')}
                  className="btn-green btn-mini float-right">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const editMeetingModalObject = {
    heading: 'Event Settings',
    body: editMeetingBody,
    classNameModal: 'fade eventSettingsPop',
    show: true,
    headingSize: 'h5',
  };

  return <CommonModal {...editMeetingModalObject} />;
};

export default EditMeetingModal;
