/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {
  createInitialLocalTracks,
  createVideoTracks,
  createAudioTracks,
  JitsiTrackEvents,
  toggleBackgroundEffect,
  localVideoTrackAdd,
} from '../../../libs';
import {
  setAVPageRefresh,
  setPersistMicDevice,
  setPersistCameraDevice,
} from '../../../reduxStore/persistSlice';
import {
  setMicDevice,
  setCameraDevice,
  setSpeakerDevice,
  sethasVideoEnable,
  setPage,
  setConferenceConnecting,
  setHasAudioPermission,
  setHasVideoPermission,
  setJoinWithVideo,
  setUserDetail,
  setMediaPermissionPrompt,
  addLocalAudioTrack,
  setNoiseSuppression,
} from '../../../reduxStore/confSlice';
import {setMirrorView} from '../../conference/web/utils/functions';
import VideoInputPreview from '../../device-selection/videoInputPreview';
import AudioInputPreview from '../../device-selection/audioInputPreview';
import swal from 'sweetalert';
import VirtualBackgroundModal from '../../conference/web/modals/virtual-background/VirtualBackgroundModal';
var localAudioTrack = [];
var localVideoTrack = [];
var player = null;
var mounted = false;
const AVSettings = () => {
  const store = useStore();
  const [virtualBackground, showVirtualBackgroundModal] = useState(false);

  const [chkPermissionLoader, setChkPermissionLoader] = useState(true);
  const [joinWithVideo, enableJoinWithVideo] = useState(false);
  const {
    noiseSuppression,
    audioInputList,
    videoInputList,
    audioOutputList,
    hasAudioPermission,
    mediaPermissionPrompt,
    hasVideoPermission,
    previousRouteStep,
    room,
    cameraDevice,
    micDevice,
    speakerDevice,
    localUser,
    virtualBackgroundOptions,
  } = useSelector(state => state.conf);

  const {persistmicDevice, persistcameraDevice} = useSelector(
    state => state.persist,
  );

  const {mirrorSelfView} = useSelector(state => state.persist);
  const [mic, setMic] = useState(null);
  const [noiceFilter, setNoiceFilter] = useState(null);
  const [speaker, setSpeaker] = useState(null);
  const [camera, setCamera] = useState(null);
  const [playAudioBtnTxt, setPlayAudioBtnTxt] = useState('Play a test sound');
  const [videoPreviewTracks, setVideoPreviewTracks] = useState(null);
  const [audioTracks, setAudioTracks] = useState(null);
  const [camerFailed, setCamerFailed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const tmplocalUser = {...localUser};
    tmplocalUser.audio = true;
    tmplocalUser.video = true;
    tmplocalUser.speaker = true;
    dispatch(setJoinWithVideo(true));

    dispatch(setUserDetail(tmplocalUser));
    dispatch(setConferenceConnecting(true));
    dispatch(setPage('Conference'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   dispatch(setAVPageRefresh({}));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   let tmpcameraDevice = cameraDevice;
  //   let tmpmicDevice = micDevice;
  //   console.log('initial tracks videoInputList...', videoInputList);

  //   console.log('initial tracks ...', persistmicDevice, persistcameraDevice);
  //   console.log('initial tracks ...', tmpmicDevice, tmpcameraDevice);
  //   if (persistcameraDevice) {
  //     const device = videoInputList.filter(
  //       d =>
  //         d.deviceId === persistcameraDevice.deviceId &&
  //         d.label === persistcameraDevice.label,
  //     );
  //     if (device.length > 0) {
  //       tmpcameraDevice = persistcameraDevice;
  //     }
  //   }
  //   if (persistmicDevice) {
  //     const device = audioInputList.filter(
  //       d =>
  //         d.deviceId === persistmicDevice.deviceId &&
  //         d.label === persistmicDevice.label,
  //     );
  //     if (device.length > 0) {
  //       tmpmicDevice = persistmicDevice;
  //     }
  //   }
  //   const {tryCreateLocalTracks, errors} = createInitialLocalTracks(
  //     tmpmicDevice,
  //     tmpcameraDevice,
  //   );
  //   Promise.all([tryCreateLocalTracks]).then(([tracks]) => {
  //     console.log(errors);
  //     setChkPermissionLoader(false);
  //     const temAudioInputList = store.getState().conf.audioInputList;
  //     const temVideoInputList = store.getState().conf.videoInputList;
  //     if (Object.keys(errors).length === 0) {
  //       dispatch(setMediaPermissionPrompt(false));
  //     }

  //     const audioTrack = tracks.filter(t => {
  //       return t.type === 'audio';
  //     });
  //     const videoTrack = tracks.filter(t => {
  //       return t.type === 'video';
  //     });

  //     if (audioTrack.length > 0) {
  //       // localAudioTrack = audioTrack[0];
  //       const audioDevice = temAudioInputList.filter(device => {
  //         return device.deviceId === audioTrack[0].deviceId;
  //       });

  //       localAudioTrack.unshift({
  //         track: audioTrack[0],
  //         device: audioDevice[0],
  //       });
  //       setAudioTracks(audioTrack[0]);
  //       if (audioDevice.length > 0) {
  //         setMic(audioDevice[0]);
  //         dispatch(setMicDevice(audioDevice[0]));
  //         dispatch(setPersistMicDevice(audioDevice[0]));
  //       }
  //       dispatch(setHasAudioPermission(true));
  //     } else {
  //       dispatch(setHasAudioPermission(false));
  //     }
  //     if (videoTrack.length > 0) {
  //       // localVideoTrack = videoTrack[0];
  //       const videodevice = temVideoInputList.filter(device => {
  //         return device.deviceId === videoTrack[0].deviceId;
  //       });
  //       localVideoTrack.unshift({
  //         track: videoTrack[0],
  //         device: videodevice[0],
  //       });
  //       if (Object.keys(virtualBackgroundOptions).length > 0) {
  //         toggleBackgroundEffect(virtualBackgroundOptions, videoTrack[0]);
  //       }
  //       if (videodevice.length > 0) {
  //         setCamera(videodevice[0]);
  //         dispatch(setCameraDevice(videodevice[0]));
  //         setVideoPreviewTracks(localVideoTrack[0].track);
  //         dispatch(setPersistCameraDevice(videodevice[0]));
  //       }
  //       dispatch(setHasVideoPermission(true));
  //       enableJoinWithVideo(true);
  //     } else {
  //       if (errors.videoOnlyError.name === 'gum.permission_denied') {
  //         dispatch(setHasVideoPermission(false));
  //       } else {
  //         dispatch(setHasVideoPermission(true));
  //         setCamera(tmpcameraDevice);
  //         dispatch(setCameraDevice(tmpcameraDevice));
  //       }
  //       setVideoPreviewTracks(null);
  //       enableJoinWithVideo(false);
  //     }
  //     mounted = true;
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   if (mounted) {
  //     if (micDevice) {
  //       if (
  //         JSON.stringify(micDevice) !==
  //         JSON.stringify(localAudioTrack[0]?.device)
  //       ) {
  //         console.log('Audio track changed');
  //         showMic(micDevice);
  //       } else {
  //         console.log('Audio track not changed');
  //       }
  //     } else {
  //       setMic(null);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [micDevice]);
  // useEffect(() => {
  //   if (mounted) {
  //     if (cameraDevice) {
  //       const videoDevice = videoInputList.filter(device => {
  //         return device.deviceId === localVideoTrack[0]?.device?.deviceId;
  //       });
  //       if (videoDevice.length > 0) {
  //         console.log('Current Video track found in list');
  //       } else {
  //         if (
  //           JSON.stringify(cameraDevice) !==
  //           JSON.stringify(localVideoTrack[0]?.device)
  //         ) {
  //           setDefaultVideo(cameraDevice);
  //           console.log('Video track changed');
  //         } else {
  //           console.log('Video track not changed');
  //         }
  //       }
  //     } else {
  //       setCamera(null);
  //       enableJoinWithVideo(false);
  //       setVideoPreviewTracks(null);
  //       setCamerFailed(true);
  //       disposeLocalVideoTrack();
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cameraDevice]);

  // useEffect(() => {
  //   if (speakerDevice) {
  //     setSpeaker(speakerDevice);
  //   }
  // }, [speakerDevice]);

  // useEffect(() => {
  //   player = document.getElementById('audioElement');
  //   player.addEventListener('ended', function () {
  //     player.currentTime = 0;
  //     setPlayAudioBtnTxt('Play a test sound');
  //   });
  // }, []);

  const setDefaultVideo = device => {
    disposeLocalVideoTrack().then(() => {
      createVideoTracks(device.deviceId)
        .then(track => {
          dispatch(setMediaPermissionPrompt(false));
          localVideoTrack.unshift({
            track: track[0],
            device: device,
          });
          if (Object.keys(virtualBackgroundOptions).length > 0) {
            toggleBackgroundEffect(virtualBackgroundOptions, track[0]);
          }
          setVideoPreviewTracks(track[0]);
          enableJoinWithVideo(true);
          dispatch(setCameraDevice(device));
          setCamera(device);
          dispatch(setHasVideoPermission(true));
          dispatch(setPersistCameraDevice(device));
          setCamerFailed(false);
        })
        .catch(error => {
          console.error(error.name);
          dispatch(setHasVideoPermission(true));
          enableJoinWithVideo(false);
          setVideoPreviewTracks(null);
          setCamerFailed(true);
        });
    });
  };
  const playAudio = () => {
    let speakerId = speaker?.deviceId;
    if (speakerId !== null && speakerId !== undefined) {
      player.setSinkId(speakerId);
    }
    setPlayAudioBtnTxt('A sound is playing...');
    player.play();
  };
  const showMic = device => {
    console.log('showMic', device);
    disposeLocalAudioTrack().then(() => {
      createAudioTracks(device.deviceId)
        .then(track => {
          dispatch(setMediaPermissionPrompt(false));
          localAudioTrack.unshift({
            track: track[0],
            device: device,
          });
          dispatch(setMicDevice(device));
          dispatch(setPersistMicDevice(device));
          setMic(device);
          dispatch(setHasAudioPermission(true));
          setAudioTracks(track[0]);
        })
        .catch(error => {
          if (error.name === 'gum.not_found') {
            dispatch(setHasAudioPermission(true));
          }
          dispatch(setMicDevice(null));
        });
    });
  };
  const disposeLocalAudioTrack = () => {
    return new Promise(async resolve => {
      const tmplocalAudioTrack = {...localAudioTrack};
      Object.keys(tmplocalAudioTrack).forEach(async key => {
        await localAudioTrack[key].track.dispose();
        localAudioTrack.shift();
        setAudioTracks(null);
      });
      resolve();
    });
  };
  const disposeLocalVideoTrack = () => {
    return new Promise(async resolve => {
      const tmplocalVideoTrack = {...localVideoTrack};
      Object.keys(tmplocalVideoTrack).forEach(async key => {
        await localVideoTrack[key].track.dispose();
        localVideoTrack.shift();
      });
      resolve();
    });
  };

  const selectMic = micDevideId => {
    const device = audioInputList.filter(d => d.deviceId === micDevideId);

    if (device.length > 0) {
      setMic(device[0]);
      showMic(device[0]);
    }
  };
  const selectCamera = cameraDeviceId => {
    const device = videoInputList.filter(d => d.deviceId === cameraDeviceId);
    if (device.length > 0) {
      setCamera(device[0]);
      setDefaultVideo(device[0]);
    }
  };
  const selectSpeaker = speakerDevideId => {
    const device = audioOutputList.filter(d => d.deviceId === speakerDevideId);
    if (device.length > 0) {
      setSpeaker(device[0]);
      dispatch(setSpeakerDevice(device[0]));
      player.setSinkId(device[0].deviceId);
    }
  };

  const refreshPage = e => {
    e.preventDefault();
    const data = {
      time: new Date().getTime(),
      room: room,
      localUser: localUser,
    };
    dispatch(setAVPageRefresh(data));
    window.location.reload();
  };

  const handelConferenceVideo = (e, withVideo) => {
    e.preventDefault();
    const {roomDetail} = store.getState().conf;
    const tmplocalUser = {...localUser};
    tmplocalUser.audio = false;
    tmplocalUser.video = false;
    tmplocalUser.speaker = true;
    if (localAudioTrack.length > 0) {
      if (
        roomDetail?.everyone_joins_muted ||
        (tmplocalUser.role === 'guest' && roomDetail?.waiting_room)
      ) {
        disposeLocalAudioTrack();
      } else {
        tmplocalUser.audio = true;
        dispatch(addLocalAudioTrack(localAudioTrack[0]));
      }
    }
    if (withVideo) {
      dispatch(setJoinWithVideo(true));
      dispatch(sethasVideoEnable(true));
    }
    if (localVideoTrack.length > 0) {
      if (
        (tmplocalUser.role === 'guest' && roomDetail?.waiting_room) ||
        !withVideo
      ) {
        disposeLocalVideoTrack();
      } else {
        tmplocalUser.video = true;
        localVideoTrackAdd(localVideoTrack[0]);
      }
    }
    dispatch(setUserDetail(tmplocalUser));
    dispatch(setConferenceConnecting(true));
    dispatch(setPage('Conference'));
  };

  const stepBackHandler = e => {
    e.preventDefault();
    disposetracks();
    mounted = false;
    dispatch(setPage(previousRouteStep));
  };
  const toggleNoiseSuppression = e => {
    e.preventDefault();
    dispatch(setNoiseSuppression(e.target.checked));
  };
  useEffect(() => {
    setNoiceFilter(noiseSuppression);
  }, [noiseSuppression]);

  useEffect(() => {
    mediaPermissionPrompt
      ? swal({
          title: 'Allow Meetn to use your camera and microphone',
          text: 'Meetn needs to access your camera and microphone so that other participants can see and hear you. Meetn will ask you to confirm this decision on each browser and computer that you use.',
          closeOnClickOutside: false,
          buttons: {
            cancel: 'Dismiss',
          },
          dangerMode: true,
        }).then(willDelete => {
          if (willDelete) {
          } else {
          }
        })
      : swal.getState().isOpen && swal.close();
  }, [mediaPermissionPrompt]);
  const disposetracks = () => {
    disposeLocalAudioTrack().then(() => {});

    disposeLocalVideoTrack().then(() => {});
  };
  return (
    <>
      <div
        className="col-md-7 col-7 light-bg av-pop"
        hidden={chkPermissionLoader ? 'hidden' : ''}>
        <>
          <div className="row">
            <div className="col-md-12 col-12">
              <h2>AV Settings</h2>
            </div>
          </div>

          <div className="pl-0 pr-0">
            <div className="row">
              <div className="col-md-8 col-8">
                <div className="form-group-container mb-0">
                  <div className="form-group">
                    <label>Microphone</label>
                    <div className="dropdown">
                      {audioInputList.length > 0 && hasAudioPermission ? (
                        <a
                          className="form-control"
                          id="avdrop"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button">
                          <span>
                            {hasAudioPermission
                              ? mic
                                ? mic?.label
                                : 'No device found'
                              : 'Permission not granted'}
                          </span>
                          <svg
                            width="1.957em"
                            viewBox="0 0 24 24"
                            focusable="false"
                            role="presentation">
                            <path
                              d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                              fill="currentColor"
                              fillRule="evenodd"
                            />
                          </svg>
                        </a>
                      ) : (
                        <a
                          className="form-control"
                          id="avdrop"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button">
                          <span>
                            {hasAudioPermission
                              ? mic
                                ? mic?.label
                                : 'No device found'
                              : 'Permission not granted'}
                          </span>
                        </a>
                      )}

                      <div className="dropdown-menu" aria-labelledby="avdrop">
                        <ul>
                          {audioInputList.map(device => {
                            return (
                              <li key={device.deviceId}>
                                <a onClick={() => selectMic(device.deviceId)}>
                                  {device.label}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Speaker</label>
                    <div className="dropdown">
                      {audioOutputList.length > 0 ? (
                        <a
                          className="form-control"
                          id="avdrop"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button"
                          href="#">
                          <span>
                            {hasAudioPermission
                              ? speaker
                                ? speaker?.label
                                : 'Default Speaker'
                              : 'Default Speaker'}
                          </span>
                          <svg
                            width="1.957em"
                            viewBox="0 0 24 24"
                            focusable="false"
                            role="presentation">
                            <path
                              d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                              fill="currentColor"
                              fillRule="evenodd"
                            />
                          </svg>
                        </a>
                      ) : (
                        <a
                          className="form-control"
                          id="avdrop"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button"
                          href="#">
                          <span>Default Speaker</span>
                        </a>
                      )}
                      <div className="dropdown-menu" aria-labelledby="avdrop">
                        <ul>
                          {audioOutputList.map(device => {
                            return (
                              <li key={device.deviceId}>
                                <a
                                  onClick={() =>
                                    selectSpeaker(device.deviceId)
                                  }>
                                  {device.label}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Camera</label>
                    <div className="dropdown">
                      {videoInputList.length > 0 && hasVideoPermission ? (
                        <a
                          className="form-control"
                          id="vdrop"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button">
                          <span>
                            {hasVideoPermission
                              ? camera
                                ? camera?.label
                                : 'Select a device'
                              : 'Permission not granted'}
                          </span>
                          <svg
                            width="1.957em"
                            viewBox="0 0 24 24"
                            focusable="false"
                            role="presentation">
                            <path
                              d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                              fill="currentColor"
                              fillRule="evenodd"
                            />
                          </svg>
                        </a>
                      ) : (
                        <a
                          className="form-control"
                          id="vdrop"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button">
                          <span>
                            {hasVideoPermission
                              ? camera
                                ? camera?.label
                                : 'No device found'
                              : 'Permission not granted'}
                          </span>
                        </a>
                      )}
                      <div className="dropdown-menu" aria-labelledby="vdrop">
                        <ul>
                          {videoInputList.map(device => {
                            return (
                              <li key={device.deviceId}>
                                <a
                                  onClick={() => selectCamera(device.deviceId)}>
                                  {device.label}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex align-items-center av-switch mb-0">
                      <label className="switch modal-switch mb-0">
                        <input
                          type="checkbox"
                          checked={mirrorSelfView}
                          onChange={e => setMirrorView(e)}
                        />
                        <span className="slider round" />
                        <span className="slider_text">Mirror Self-View</span>
                      </label>
                    </div>
                    <div className="d-flex align-items-center av-switch">
                      <label className="switch modal-switch mb-0">
                        <input
                          type="checkbox"
                          checked={noiceFilter ? 'checked' : ''}
                          onChange={toggleNoiseSuppression}
                        />
                        <span className="slider round" />
                        <span className="slider_text">
                          Reduce Background Noise
                        </span>
                      </label>
                      <a
                        className="tooltip_container"
                        data-tooltip="Disable this feature if you are playing a musical instrument and for similar situations.">
                        <span className="with_circle">
                          <svg height="60%" viewBox="7 4 10 16">
                            <path
                              fill="#ffffff"
                              d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <p>
                  Having problems?
                  <a href="#" onClick={refreshPage}>
                    &nbsp;Refresh this page
                  </a>
                  , &nbsp;or switch to {/* <a href=""> */} phone audio
                  {/* </a> */}
                </p>
                <p className="locktext">
                  To enable audio/video, please click on{' '}
                  <i className="fa fa-lock" aria-hidden="true" /> icon in
                  browser address bar and allow audio/video permission. You will
                  be asked to reload once this is done.
                </p>
              </div>
              <div className="col-md-4 col-4 audioWrap">
                <div className="playsound">
                  <small className="micro-text">Microphone Test</small>
                  <div className="progress light-bars">
                    <AudioInputPreview audioTrack={audioTracks} />
                  </div>
                  <div className="audio-output-preview">
                    <a onClick={playAudio}>{playAudioBtnTxt}</a>
                    <audio title="audio file" id="audioElement" autoPlay={0}>
                      <source src="assets/sounds/ring.wav" />
                      This browser does not support the audio element.
                    </audio>
                  </div>
                  <div className="column-video">
                    <div className="column-video-inner">
                      <div className="video-input-preview">
                        {/* <video
                        autoPlay=""
                        className="video-input-preview-display flipVideoY"
                        id=""
                        playsInline=""
                      /> */}
                        {videoPreviewTracks ? (
                          <VideoInputPreview tracks={videoPreviewTracks} />
                        ) : camerFailed ? (
                          <div className="video-input-preview-error">
                            <b>Camera Failed.</b>
                            Is camera already in use.
                          </div>
                        ) : (
                          <div className="video-input-preview-error">
                            <b>Preview Unavailable.</b> Did you give permission?{' '}
                            <br /> Is camera already in use? <br /> Is camera
                            on?
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <button
                      type="button"
                      className="btn btn-green"
                      disabled={
                        camerFailed || !hasVideoPermission ? 'true' : ''
                      }
                      onClick={() => showVirtualBackgroundModal(true)}>
                      Virtual Backgrounds
                    </button>
                    <a
                      className="tooltip_container tooltip_container--right ml-2"
                      data-tooltip="HINT: Good lighting REALLY helps (frame rate + quality)">
                      <span className="with_circle">
                        <svg height="60%" viewBox="7 4 10 16">
                          <path
                            fill="#ffffff"
                            d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="backWrap avbackWrap col-md-12 mt-3 col-12">
                <a
                  href="#"
                  className="font30"
                  aria-label="Close"
                  onClick={stepBackHandler}>
                  <svg width=".7em" viewBox="2 1.99 20 20">
                    <path
                      fill="#6cb33f"
                      d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
                    />
                    <path
                      fill="#6cb33f"
                      d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
                    />
                  </svg>
                </a>
                <span>
                  {joinWithVideo ? (
                    <button
                      id="joinWithVideo"
                      type="button"
                      className="btn btn-green"
                      onClick={e => handelConferenceVideo(e, true)}>
                      Join With Video
                    </button>
                  ) : (
                    <button
                      id="joinWithVideo"
                      type="button"
                      className="btn btn-green btn-green-disabled">
                      Join With Video
                    </button>
                  )}
                  <button
                    id="joinWithoutVideo"
                    type="button"
                    className="btn btn-green ml-2"
                    onClick={e => handelConferenceVideo(e, false)}>
                    Join Without Video
                  </button>
                </span>
              </div>
            </div>
          </div>
        </>
      </div>
      <div
        className="col-md-7 col-7 light-bg av-pop"
        hidden={chkPermissionLoader ? '' : 'hidden'}>
        <p
          id="step7-first-content"
          className="pl-0 pr-0 d-flex align-items-center justify-content-center h-100 mb-0 semiBold">
          Checking mic and camera permissions…
        </p>
      </div>

      {virtualBackground && (
        <VirtualBackgroundModal
          showVirtualBackgroundModal={showVirtualBackgroundModal}
          localVideoTrack={localVideoTrack[0].track}
          virtualBackgroundOptions={virtualBackgroundOptions}
        />
      )}
    </>
  );
};

export default AVSettings;
