import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AvSettingModal from './modals/avSettingModal';
import { setShowAvSettingModal } from '../../../reduxStore/confSlice';

function AvSettings() {
  const { showAvSettingModal } = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const handleOpenModal = e => {
    e.preventDefault();
    dispatch(setShowAvSettingModal(true));
  };
  return (
    <>
      <a className="nav-link" onClick={e => handleOpenModal(e)}>
        <div className="menu_icons">
          <img
            height="20"
            width="20"
            src="assets/images/av-settings-menu.png"
          />
        </div>
        <span className="pl-2">Audio/Video Settings</span>
      </a>
      {showAvSettingModal && <AvSettingModal />}
    </>
  );
}

export default AvSettings;
