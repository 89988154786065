import React, { useRef, useState } from 'react';
import { CommonModal } from '../../../common/web';
import { useDispatch, useSelector } from 'react-redux';
import { startRecording, stopRecording } from '../../../../utils/socket';
import {
  setRoomDetails,
  setOpenRecordingModal,
} from '../../../../reduxStore/confSlice';
import { roomSettings } from '../../../../utils/socket';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';

function StartRecording() {
  const dispatch = useDispatch();
  const [recordingName, setRecordingName] = useState('');
  const { roomDetail } = useSelector(state => state.conf);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loader, setLoader] = useState(false);

  const handleCancelRecording = e => {
    e.preventDefault();
    dispatch(setOpenRecordingModal(false));
  };

  const handleStartRecording = e => {
    e.preventDefault();
    setLoader(true);
    console.log(recordingName);
    if (simpleValidator.current.allValid()) {
      startRecording({
        room: roomDetail.room,
        recording_name: recordingName,
        recorder_view: roomDetail?.recorder_view,
      }).then(res => {
        if (res.status === 200) {
          dispatch(setOpenRecordingModal(false));
        } else {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setLoader(false);
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setLoader(false);
    }
  };

  const handleStopRecording = e => {
    e.preventDefault();
    setLoader(true);
    stopRecording({ room: roomDetail.room }).then(res => {
      if (res.status === 200) {
        const temproomDetail = { ...roomDetail };
        temproomDetail['recording'] = false;
        temproomDetail['recording_timestamp'] = null;
        dispatch(setRoomDetails(temproomDetail));
        roomSettings(temproomDetail);
        dispatch(setOpenRecordingModal(false));
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }

      setLoader(false);
    });
  };

  const handleGalleryRecording = e => {
    e.preventDefault();
    let tmpRoomDetail = { ...roomDetail };
    tmpRoomDetail.recorder_view = 'gallery';
    dispatch(setRoomDetails(tmpRoomDetail));
  };

  const handleActiveRecording = e => {
    e.preventDefault();
    let tmpRoomDetail = { ...roomDetail };
    tmpRoomDetail.recorder_view = 'active';
    dispatch(setRoomDetails(tmpRoomDetail));
  };

  const recordingBody = () => {
    return (
      <>
        <div className="row">
          <div
            className={`col-md-12 modal_min_height--sm ${roomDetail.recording &&
              'd-flex align-items-center justify-content-center'
              }`}>
            {roomDetail.recording ? (
              <p className="mb-0 text-center">
                Are you sure you want to stop the recording?
              </p>
            ) : (
              <form>
                <div className="form-group d-flex flex-row">
                  <label className="label-inline col-md-4 col-4">
                    Recording Name:
                  </label>
                  <div className="col-md-8 col-8 d-flex max_recording_value flex-column">
                    <div className="d-flex">
                      <input
                        name="recordingName"
                        spellCheck="false"
                        type="text"
                        minLength="2"
                        maxLength="50"
                        className="form-control"
                        onChange={e => {
                          setRecordingName(e.target.value);
                        }}
                        value={recordingName}
                      />

                      <label className="label-inline">
                        ({50 - recordingName?.length})
                      </label>
                    </div>
                    {simpleValidator.current.message(
                      'recordingName',
                      recordingName,
                      'required',
                    )}
                  </div>
                </div>
                <div className="form-group d-flex flex-row">
                  <label className="label-inline col-md-4 col-4">
                    What to Record?:
                  </label>
                  <div className="btn-group col-md-8 col-8 d-flex align-items-center">
                    <button
                      type="button"
                      className={`btn ${roomDetail.recorder_view === 'gallery'
                        ? 'btn-green'
                        : 'btn-link'
                        }`}
                      onClick={handleGalleryRecording}>
                      Gallery View
                    </button>
                    <button
                      type="button"
                      className={`btn ${roomDetail.recorder_view === 'gallery'
                        ? 'btn-link'
                        : 'btn-green'
                        }`}
                      onClick={handleActiveRecording}>
                      Active Speaker
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className="col-md-12 text-right mt-3">
            <button
              type="button"
              className="btn btn-link"
              onClick={handleCancelRecording}>
              {`${roomDetail.recording ? 'Continue Recording' : 'Cancel'}`}
            </button>
            {roomDetail.recording ? (
              <button
                type="button"
                className="btn btn-green"
                onClick={handleStopRecording}>
                {loader ? 'Please Wait...' : 'Stop Recording'}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-green"
                onClick={handleStartRecording}>
                {loader ? 'Please Wait...' : 'Start Recording'}
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  const recordingObject = {
    heading: 'Recording',
    body: recordingBody,
    show: true,
    classNameDialog: 'midmodal',
    headingSize: 'h5',
  };

  return <>{<CommonModal {...recordingObject} />}</>;
}

export default StartRecording;
