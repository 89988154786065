import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <nav
      id="main_header"
      className="navbar navbar-expand-lg navbar-dark custom-nav join-nav">
      <Link to="/" className="navbar-brand">
        <img src="assets/images/logo.png" alt="" />
      </Link>
    </nav>
  );
};

export default Header;
