import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setPage, setOpenLoginModel} from '../../../../reduxStore/confSlice';
import {CommonModal} from '../../../common/web';
import {setGoToSignIn} from '../../../../reduxStore/persistSlice';

function HostSignInModal() {
  const dispatch = useDispatch();
  const {localUser, openLoginModel} = useSelector(state => state.conf);

  const handleClose = e => {
    e.preventDefault();
    dispatch(setOpenLoginModel(false));
  };

  const handleLogin = e => {
    e.preventDefault();
    dispatch(setGoToSignIn(true));
    window.location.reload();
  };

  const manageRedirect = e => {
    // e.preventDefault();
    // window.open = '/';
    const host = `${window.location.origin}`;
        window.open(host, "_blank");
  };
  const loginBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12 modal_min_height d-flex flex-column justify-content-center align-items-center">
            <p className="mt-10 mb-0 text-center">
              {localUser !== undefined
                ? localUser.role === 'guest'
                  ? 'Host+'
                  : localUser.role === 'host'
                  ? 'Admin+'
                  : 'Owner'
                : 'Host'}{' '}
              Access Required for this feature.
            </p>
            <p className="mb-0 text-center">
              If you are a{' '}
              {localUser !== undefined
                ? localUser.role === 'guest'
                  ? 'host'
                  : localUser.role === 'host'
                  ? 'admin'
                  : 'owner'
                : 'host'}
              , please{' '}
              <a href={location.href} onClick={handleLogin}>
                sign in
              </a>
              .
            </p>
          </div>
          <div className="col-md-12 form-group mt-3 mb-0 justify-end text-right">
            <button
              type="button"
              data-dismiss="modal"
              className="btn-link"
              onClick={handleClose}>
              Close
            </button>
            <a
              href={void 0}
              className="btn btn-green btn-mini abso-btn float-right"
              onClick={manageRedirect}>
              Learn more about Meetn
              <i className="icon-arrow-right-circle icons"></i>
            </a>
          </div>
        </div>
      </>
    );
  };

  const loginObject = {
    heading: `${
      localUser !== undefined
        ? localUser.role === 'guest'
          ? 'Host+'
          : localUser.role === 'host'
          ? 'Admin+'
          : 'Owner'
        : 'Host'
    }${' '}Access Required`,
    body: loginBody,
    show: true,
    classNameDialog: 'modal-width-md',
    headingSize: 'h5',
  };

  return <>{openLoginModel === true && <CommonModal {...loginObject} />}</>;
}

export default HostSignInModal;
