import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';

function CommonModal(props) {
  const {
    heading,
    body,
    show,
    headingSize,
    classNameBody,
    classNameModal,
    classNameHeader,
    classNameDialog,
    imgLink,
    headingTags,
    headingContent,
    svgIcon,
  } = props;

  return (
    <>
      <Modal
        show={show}
        animation={false}
        dialogClassName={classNameDialog}
        className={classNameModal}>
        <Modal.Header className={classNameHeader}>
          {headingTags && headingTags()}
          <Modal.Title as={headingSize}>
            {imgLink && <img src={imgLink} />}
            {svgIcon && svgIcon}
            {heading} {headingContent && headingContent()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classNameBody}>{body && body()}</Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default CommonModal;
