import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {updateWaitingRoomPopup} from '../../../../reduxStore/confSlice';

import {CommonModal} from '../../../common/web';
import {
  individualAttendeeAction,
  multipleAttendeeAction,
} from '../../../../utils/socket';
import {toast} from 'react-toastify';

function WaitingRoomPopUp() {
  const dispatch = useDispatch();
  const [waitingRoomUsers, setWaitingRoomUsers] = useState([]);
  const [user, setUser] = useState({});
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [declineLoader, setDeclineLoader] = useState(false);
  const {waitingRoomPopup, participantsWaiting, roomDetail, localUser} =
    useSelector(state => state.conf);

  const {room} = roomDetail;

  useEffect(() => {
    if (Object.keys(participantsWaiting).length > 0) {
      let lastWaitingRoomUser =
        participantsWaiting[
          Object.keys(participantsWaiting)[
            Object.keys(participantsWaiting).length - 1
          ]
        ];
      setUser(lastWaitingRoomUser);
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantsWaiting]);

  const handleClose = () => {
    dispatch(updateWaitingRoomPopup(false));
  };

  const handleAccept = () => {
    setAcceptLoader(true);
    if (Object.keys(participantsWaiting).length > 1) {
      multipleAttendeeAction({
        room: roomDetail?.room,
        token_unique: localUser?.token_unique,
        action: 'w-accept-all',
      }).then(res => {
        setAcceptLoader(false);
        if (res.status !== 200) {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          dispatch(updateWaitingRoomPopup(false));
        }
      });
    } else {
      individualAttendeeAction({
        room: roomDetail?.room,
        token_unique: localUser?.token_unique,
        attendee_token_unique: user.token_unique,
        action: 'w-accept',
      }).then(res => {
        setAcceptLoader(false);
        if (res.status !== 200) {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          dispatch(updateWaitingRoomPopup(false));
        }
      });
    }
  };

  const handleDecline = () => {
    setDeclineLoader(true);
    if (Object.keys(participantsWaiting).length > 1) {
      multipleAttendeeAction({
        room: roomDetail?.room,
        token_unique: localUser?.token_unique,
        action: 'w-decline-all',
      }).then(res => {
        setDeclineLoader(false);
        if (res.status !== 200) {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          dispatch(updateWaitingRoomPopup(false));
        }
      });
    } else {
      individualAttendeeAction({
        room: roomDetail?.room,
        token_unique: localUser?.token_unique,
        attendee_token_unique: user.token_unique,
        action: 'w-decline',
      }).then(res => {
        setDeclineLoader(false);
        if (res.status !== 200) {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          dispatch(updateWaitingRoomPopup(false));
        }
      });
    }
  };
  const waitingRoomBody = () => {
    return (
      <>
        <div className="row">
          <div className="waiting-popup">
            <div className="col-md-12 text-center">
              <h5 className="mb-0">
                {Object.keys(participantsWaiting).length > 2 ? (
                  <>
                    {' '}
                    {user?.name} and{' '}
                    {Object.keys(participantsWaiting).length - 1} others are in
                    the waiting room
                  </>
                ) : Object.keys(participantsWaiting).length === 2 ? (
                  <>
                    {
                      participantsWaiting[Object.keys(participantsWaiting)[0]]
                        .name
                    }{' '}
                    and{' '}
                    {
                      participantsWaiting[Object.keys(participantsWaiting)[1]]
                        .name
                    }{' '}
                    are in the waiting room
                  </>
                ) : (
                  <>{user?.name} is in the waiting room</>
                )}
              </h5>
            </div>
            <div className="col-md-12 text-center mt-4 pl-em-2">
              <button
                className="btn btn-green"
                onClick={() => handleAccept()}
                disabled={acceptLoader || declineLoader}>
                {Object.keys(participantsWaiting).length > 1
                  ? acceptLoader
                    ? 'Please Wait'
                    : 'Accept All'
                  : acceptLoader
                  ? 'Please Wait'
                  : 'Accept'}
              </button>
              <button
                className="btn btn-red"
                onClick={() => handleDecline()}
                disabled={declineLoader}>
                {Object.keys(participantsWaiting).length > 1
                  ? declineLoader
                    ? 'Please Wait'
                    : 'Decline All'
                  : declineLoader
                  ? 'Please Wait'
                  : 'Decline'}
              </button>
              <button className="btn-link" onClick={handleClose}>
                Skip
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const waitingObject = {
    body: waitingRoomBody,
    show: true,
    classNameDialog: 'modal-width-sm',
  };

  return <>{waitingRoomPopup === true && <CommonModal {...waitingObject} />}</>;
}

export default WaitingRoomPopUp;
