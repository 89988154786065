import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import swal from 'sweetalert';
import {
  setNoiseSuppressionEnabled,
  cameraDeviceChange,
  micDeviceChange,
  speakerDeviceChange,
} from '../../../libs';
import Pagination_Custom from './pagination';

import {
  setWaitingEnabled,
  setMuteUnmutePermission,
  setWindowDimension,
  updateWaitingRoomPopup,
  setChatClick,
  setUnreadMessageCount,
  setAttendeesClick,
} from '../../../reduxStore/confSlice';
import {handleMic, playAudio} from '../../../utils/functions';

import Chat from './chat';
import FeaturedChatMessage from './FeaturedChatMessage';
import MuteUnmutePermission from './modals/muteUnmutePermissionModal';
import VideoContainer from './videoContainer';
import {viewType} from '../../../utils/util';
import WaitingRoomPopUp from './modals/waitingRoomPopUp';
import Attendees from './attendees/attendees';
import HostSignInModal from './modals/hostSignInByModal';
import ExtensionHandler from './extensions/extensionHandler';
import usePrevious from './utils/hooks/usePrevious';
import Widgets from './widgets/widgets';

const ConferenceInner = ({roomBackgroundSrc}) => {
  const dispatch = useDispatch();

  const {
    featuredMsg,
    chatClick,
    attendeesClick,
    roomDetail,
    localUser,
    screenDimension,
    view,
    participantsWaiting,
    waitingRoomPopup,
    unreadMessageCount,
    participantsHandRaised,
    noiseSuppression,
    cameraDevice,
    micDevice,
    speakerDevice,
    openLoginModel,
    extensionShow,
    widgetClick,
  } = useSelector(state => state.conf);

  const {viewWidth} = screenDimension;
  const {guests_have_extra_powers} = roomDetail;
  const prevParticipantsWaiting = usePrevious(participantsWaiting);

  useEffect(() => {
    const {
      everyone_joins_muted,
      hide_muted_attendees,
      guests_can_unmute_themselves,
    } = roomDetail;
    if (
      everyone_joins_muted ||
      hide_muted_attendees ||
      !guests_can_unmute_themselves
    ) {
      const wrapper = document.createElement('div');
      wrapper.innerHTML = `
                <p class="text-left">Currently, in this room…</p>
                <ul class="pl-3 text-left mb-list">
                 ${everyone_joins_muted ? '<li>Everyone joins muted</li> ' : ''}
                 ${
                   hide_muted_attendees
                     ? '<li>Muted attendees are hidden</li> '
                     : ''
                 }
                 ${
                   !guests_can_unmute_themselves
                     ? '<li>Guests cannot unmute themselves</li>'
                     : ''
                 }
                 </ul>
                `;
      swal({
        content: wrapper,
      });
    }

    document.body.classList.add('conference-inner-body');
    return () => {
      document.body.classList.remove('conference-inner-body');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   micDeviceChange(micDevice);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [micDevice]);

  // useEffect(() => {
  //   cameraDeviceChange(cameraDevice);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cameraDevice]);

  // useEffect(() => {
  //   speakerDeviceChange(speakerDevice);
  // }, [speakerDevice]);

  useEffect(() => {
    if (noiseSuppression) {
      setNoiseSuppressionEnabled(true);
    } else {
      setNoiseSuppressionEnabled(false);
    }
  }, [noiseSuppression]);

  useEffect(() => {
    const setWindowSize = () => {
      const {innerHeight, innerWidth} = window;

      dispatch(
        setWindowDimension({
          ...{
            clientWidth: innerWidth,
            clientHeight: innerHeight,
          },
        }),
      );
    };
    setWindowSize();
    window.addEventListener('resize', setWindowSize);
    return () => {
      window.removeEventListener('resize', setWindowSize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      unreadMessageCount > 0 &&
      !roomDetail?.prevent_chat_auto_appear &&
      localUser?.auto_appear_chat &&
      !attendeesClick &&
      !chatClick
    ) {
      dispatch(setUnreadMessageCount(0));
      dispatch(setChatClick(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessageCount]);

  useEffect(() => {
    document.body.classList.add('conference-inner-body');
    return () => {
      document.body.classList.remove('conference-inner-body');
    };
  }, []);

  useEffect(() => {
    console.log('prevParticipantsWaiting', prevParticipantsWaiting);
    if (
      Object.keys(participantsWaiting).length > 0 &&
      roomDetail?.waiting_room
    ) {
      if (localUser.role !== 'guest' || guests_have_extra_powers === true) {
        if (
          localUser.auto_appear_waiting_room &&
          !chatClick &&
          !attendeesClick
        ) {
          dispatch(setAttendeesClick(true));
        }

        if (localUser?.play_ding_waiting_room) {
          playAudio({src: 'assets/sounds/waitingRoomSound.mp3', volume: 0.5});
        }

        if (localUser?.show_popup_waiting_room && !waitingRoomPopup) {
          const prevParticipantsWaitingCount =
            prevParticipantsWaiting === undefined
              ? 0
              : Object.keys(prevParticipantsWaiting).length;
          if (
            prevParticipantsWaitingCount <
            Object.keys(participantsWaiting).length
          ) {
            dispatch(updateWaitingRoomPopup(true));
          }
        }
      }
    }

    if (Object.keys(participantsWaiting).length === 0) {
      if (waitingRoomPopup) {
        dispatch(updateWaitingRoomPopup(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantsWaiting]);

  useEffect(() => {
    if (Object.keys(participantsHandRaised).length > 0) {
      if (localUser.role !== 'guest' || guests_have_extra_powers === true) {
        if (
          roomDetail?.hide_raise_hand_for_guests &&
          localUser?.auto_appear_raise_hand &&
          !chatClick &&
          !attendeesClick
        ) {
          dispatch(setAttendeesClick(true));
        }

        if (localUser?.play_woosh_raise_hand) {
          playAudio({src: 'assets/sounds/raiseHandSound.mp3', volume: 0.2});
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantsHandRaised]);

  // useEffect(() => {
  //   if (localUser?.ask_to_unmute) {
  //     dispatch(setMuteUnmutePermission(true));
  //   } else {
  //     dispatch(setMuteUnmutePermission(false));
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [localUser]);

  // useEffect(() => {
  //   console.log('keep_muted', localUser?.keep_muted);
  //   if (localUser?.keep_muted && localUser?.audio) {
  //     console.log('keep_muted mute user');
  //     handleMic();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [localUser?.keep_muted]);

  return (
    <>
      <div
        className={`galleryMain ${
          chatClick || attendeesClick || widgetClick ? 'wrap-width' : ''
        } ${roomBackgroundSrc !== '' ? 'room__background' : ''}`}
        style={
          roomBackgroundSrc !== ''
            ? {backgroundImage: `url(${roomBackgroundSrc})`}
            : {}
        }>
        <div className="videoGalleryWrapper" style={{width: `${viewWidth}px`}}>
          <VideoContainer />
        </div>
        {view === viewType.gallery && <Pagination_Custom />}
      </div>
      <div
        className={`Slidebar asside ${
          chatClick || attendeesClick || widgetClick ? 'active' : ''
        }`}
        id="conferecne-sidebar">
        <div className="tab-content" id="myTabContent">
          {attendeesClick && <Attendees />}
          {chatClick && <Chat />}
          {widgetClick && <Widgets />}
        </div>
      </div>

      {Object.keys(featuredMsg).length > 0 && featuredMsg.id !== '' && (
        <FeaturedChatMessage />
      )}
      <MuteUnmutePermission />
      {waitingRoomPopup && <WaitingRoomPopUp />}
      {openLoginModel === true && <HostSignInModal />}
      {extensionShow && <ExtensionHandler />}
    </>
  );
};
export default ConferenceInner;
