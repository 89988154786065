import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import swal from 'sweetalert';
import {CommonModal} from '../../../../common/web';
import {
  deleteVirtualBackground,
  getVirtualBackgroundList,
  uploadVirtualBackground,
} from '../../../../../utils/socket';
import VirtualBackgroundPreview from './VirtualBackgroundPreview';
import {alertMsg} from '../../../../common/web/alert';
import {TenMegaBytes, uploadImageMimeType} from '../../../../../utils/util';
import {
  updateVirtualBackground,
  uploadFileInWasabi,
} from '../../../../../utils/functions';
import {Icon, IconClose, IconQuestionMark} from '../../../../../icons';
import {toggleBackgroundEffect} from '../../../../../libs';
const VirtualBackgroundModal = ({
  showVirtualBackgroundModal,
  localVideoTrack,
  virtualBackgroundOptions,
}) => {
  const {
    roomDetail: {room},
    localUser: {token, email},
  } = useSelector(state => state.conf);
  const [options, setOptions] = useState(
    virtualBackgroundOptions ? virtualBackgroundOptions : {},
  );
  const [selectedCategory, setselectedCategory] = useState('abstract');
  const [backgroundList, setBackgroundList] = useState([]);
  const [loading, isloading] = useState(false);
  const uploadButton = useRef(null);
  const dispatch = useDispatch();
  const [backgroundCategories] = useState({
    room: 'Room',
    city: 'City',
    office: 'Office',
    abstract: 'Abstract',
    nature: 'Nature',
    space: 'Space',
    sunset: 'Sunset',
    beach: 'Beach',
    forest: 'Forest',
    mountain: 'Mountain',
    walls: 'Walls',
    upload: 'Uploaded Images',
  });

  useEffect(() => {
    getBackgroundsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (Object.keys(options).length > 0) {
      toggleBackgroundEffect(options, localVideoTrack);
    }
  }, [options, localVideoTrack]);

  const getBackgroundsList = () => {
    isloading(true);
    getVirtualBackgroundList({room, token, email, category: selectedCategory})
      .then(response => {
        if (response.status === 200) {
          setBackgroundList(response.data);
        }
      })
      .finally(() => {
        isloading(false);
      });
  };

  const removeUploadedImages = async id => {
    swal({
      title: 'Are you sure that you want to delete this background?',
      closeOnClickOutside: false,
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        deleteVirtualBackground({id}).then(response => {
          if (response.status === 200) {
            getBackgroundsList();
          }
        });
      } else {
      }
    });
  };

  const uploadBackgroundFile = async imageFile => {
    let imgType = imageFile[0]?.type;
    let imgSize = imageFile[0]?.size;

    if (uploadImageMimeType()?.includes(imgType)) {
      if (imgSize < TenMegaBytes) {
        const selectedFiles = [...imageFile];
        isloading(true);
        const [fileResponse] = await uploadFileInWasabi(selectedFiles);
        let backgroundImageName =
          fileResponse &&
          fileResponse.length > 0 &&
          fileResponse[0].attachmentName;

        if (backgroundImageName) {
          const payload = {
            email,
            token,
            background: backgroundImageName,
          };

          uploadVirtualBackground(payload).then(() => {
            getBackgroundsList();
          });
        } else {
          isloading(false);
        }
      } else {
        alertMsg({
          msg: 'File too large. Please upload a file less than 10 MB.',
          type: 'info',
        });
      }
    } else {
      alertMsg({msg: 'Please upload a valid image file.', type: 'info'});
    }
  };
  const toDataURL = async url => {
    return new Promise((resolve, reject) => {
      fetch(url + '?not-from-cache-please')
        .then(response => response.blob())
        .then(async blob => {
          const resData = await blobToData(blob);
          resolve(resData);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  const blobToData = blob =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result?.toString());
      reader.readAsDataURL(blob);
    });

  const setImageBackground = async (image, e) => {
    console.log(image, e.target);

    toDataURL(image.background)
      .then(src => {
        setOptions({
          backgroundEffectEnabled: true,
          selectedThumbnail: image.uuid,
          backgroundType: 'image',
          blurValue: null,
          virtualSource: src,
        });
      })
      .catch(err => {
        console.error('VirtualBackground Fetch image error.', err);
      });
  };
  const handleBlurEffect = async e => {
    if (e.target.checked) {
      enableBlur(8, 'blur');
    } else {
      removeBackground();
    }
  };

  const enableBlur = async blurValue => {
    setOptions({
      backgroundEffectEnabled: true,
      selectedThumbnail: 'slight-blur',
      backgroundType: 'blur',
      blurValue: blurValue,
      virtualSource: null,
    });
  };

  const removeBackground = async () => {
    setOptions({
      backgroundEffectEnabled: false,
      selectedThumbnail: 'none',
      backgroundType: null,
      blurValue: null,
      virtualSource: null,
    });
  };

  const applyVirtualBackground = async () => {
    isloading(true);
    dispatch(updateVirtualBackground(options));
    isloading(false);
    showVirtualBackgroundModal(false);
  };
  const closeModel = () => {
    if (localVideoTrack) {
      if (Object.keys(virtualBackgroundOptions).length > 0)
        toggleBackgroundEffect(
          {
            backgroundEffectEnabled: false,
            selectedThumbnail: 'none',
            backgroundType: null,
            blurValue: null,
            virtualSource: null,
          },
          localVideoTrack,
        );
      else toggleBackgroundEffect(virtualBackgroundOptions, localVideoTrack);
    }
    showVirtualBackgroundModal(false);
  };
  const virtualBackgroundBody = () => {
    return (
      <>
        <div className="virtual-background-modal modal_min_height">
          {loading && (
            <div className="virtual-background-loading">
              <span>
                <img
                  height="50px"
                  width="50px"
                  src="assets/images/refresh-loader.gif"
                />
              </span>
            </div>
          )}
          <div className="background-container">
            <div className="background-selection">
              <div className="upload-background select_custom">
                <label>Select a Category:</label>
                <select
                  className="form-control"
                  value={selectedCategory}
                  onChange={e => setselectedCategory(e.target.value)}>
                  {Object.entries(backgroundCategories).map(
                    ([categoryKey, categoryValue]) => {
                      return (
                        <option value={categoryKey}>{categoryValue}</option>
                      );
                    },
                  )}
                </select>
              </div>

              <input
                spellCheck="false"
                accept="image/x-png,image/jpeg"
                className="file-upload-btn"
                ref={uploadButton}
                onChange={e => uploadBackgroundFile(e.target.files)}
                type="file"
              />

              <div className="virtual-background-dialog">
                <div className="all-backgrounds">
                  <div
                    className={
                      options?.selectedThumbnail === 'none'
                        ? 'none-selected'
                        : 'virtual-background-none'
                    }
                    onClick={removeBackground}>
                    None
                  </div>

                  {selectedCategory === 'upload' && (
                    <div
                      className={'virtual-background-none'}
                      onClick={() => {
                        uploadButton.current.click();
                      }}>
                      Upload
                    </div>
                  )}
                  {backgroundList.length > 0 ? (
                    backgroundList.map((image, index) => (
                      <div className={'thumbnail-container'} key={index}>
                        <img
                          className={
                            options?.selectedThumbnail === image.uuid
                              ? 'thumbnail-selected'
                              : 'thumbnail'
                          }
                          key={index}
                          onClick={e => setImageBackground(image, e)}
                          src={image.background}
                          loading="lazy"
                        />
                        {selectedCategory === 'upload' && (
                          <Icon
                            className={'delete-image-icon'}
                            onClick={() => removeUploadedImages(image.uuid)}
                            src={IconClose}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <>
                      {!loading && selectedCategory === 'upload' && (
                        <p>Images you upload will appear here</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <VirtualBackgroundPreview localVideoTrack={localVideoTrack} />
          </div>
        </div>

        <div className="virtual-background-switch">
          <label className="switch modal-switch">
            <input
              spellCheck="false"
              type="checkbox"
              checked={
                options?.selectedThumbnail === 'slight-blur' ? true : false
              }
              onChange={e => handleBlurEffect(e)}
            />
            <span className="slider round" />
            <span className="slider_text blur-background">Blur Background</span>
          </label>
          <a
            className="tooltip_container"
            data-tooltip="We recommend blur until we improve our background feature.">
            <span className="with_circle">
              <IconQuestionMark />
            </span>
          </a>
        </div>
        <div className="col-md-12 custom-for-electron">
          <button
            id="electron-btn-cancel"
            className="css-1w1qcye"
            type="button"
            onClick={closeModel}>
            <span className="css-19r5em7">Cancel</span>
          </button>
          <button
            form="modal-dialog-form"
            id="electron-btn"
            className="css-1jq66ko"
            type="button"
            onClick={applyVirtualBackground}
            disabled={localVideoTrack ? '' : 'true'}>
            <span className="css-19r5em7">Apply</span>
          </button>
        </div>
      </>
    );
  };

  const virtualBackgroundObject = {
    heading: 'Virtual Background',
    body: virtualBackgroundBody,
    classNameModal: 'fade virtual-background-outer',
    show: true,
    headingSize: 'h5',
  };

  return (
    <>
      <CommonModal {...virtualBackgroundObject} />
    </>
  );
};

export default VirtualBackgroundModal;
