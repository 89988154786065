import React, {useState, useEffect} from 'react';
import {JitsiTrackEvents} from '../../libs';

const AudioInputPreview = ({audioTrack}) => {
  const [audioLevel, setAudioLevel] = useState(0);

  useEffect(() => {
    listenForAudioUpdates(audioTrack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioTrack]);

  const updateAudioLevel = level => {
    setAudioLevel(level);
  };

  const listenForAudioUpdates = track => {
    stopListeningForAudioUpdates();
    // console.log('listenForAudioUpdates', track);
    track &&
      track.on(JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, updateAudioLevel);
  };

  const stopListeningForAudioUpdates = () => {
    // console.log('stopListeningForAudioUpdates');
    audioTrack &&
      audioTrack.off(
        JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED,
        updateAudioLevel,
      );
  };

  const sty = {width: '4%', marginRight: '3px', overflow: 'hidden'};
  let arr = Math.floor(Math.floor(audioLevel * 150) / 5);
  arr = arr > 20 ? 19 : arr;
  return (
    <>
      {[...Array(arr > 0 ? arr : 0).keys()].map((v, i) => {
        return (
          <div
            className={`progress-bar ${
              i <= 6 ? 'bg-green' : i > 6 && i <= 10 ? 'bg-yellow' : 'bg-red'
            } `}
            role="progressbar"
            style={sty}
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
            key={'preview' + i}
          />
        );
      })}
    </>
  );
};

export default AudioInputPreview;
