import React, {useEffect, useState, useMemo} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useDispatch, useSelector} from 'react-redux';
import {CommonModal} from '../../../common/web';
import {setShowInviteModal} from '../../../../reduxStore/confSlice';
import {removeTags} from '../../../../utils/functions';
import {
  guestPinUpdate,
  hostLinkUpdate,
  hostPinUpdate,
  presenterLinkUpdate,
} from '../../../../utils/socket';
import {toast} from 'react-toastify';
import {getPasscodeHandler} from '../utils/functions';

const InvitePeopleModal = () => {
  const dispatch = useDispatch();
  const {roomDetail, localUser} = useSelector(state => state.conf);
  const [state, setState] = useState({passcode: ''});

  const {room, host_link_id, presenter_link_id, guest_pin, host_pin} =
    roomDetail;
  const {token} = localUser;
  const host = window.location.origin;

  const numberPageUrl = useMemo(() => {
    return `${host}/Numbers`;
  }, [host]);

  const hostPhoneAndPin = useMemo(() => {
    return `+1-360-777-7000 PIN:${host_pin !== undefined ? host_pin : ''}`;
  }, [host_pin]);

  const roomUrl = useMemo(() => {
    return `${host}/${room}`;
  }, [host, room]);

  const guestPhoneAndPin = useMemo(() => {
    return `+1-360-777-7000 PIN:${guest_pin !== undefined ? guest_pin : ''} ${
      state?.passcode !== '' ? `Passcode:${state?.passcode}` : ''
    }`;
  }, [guest_pin, state]);

  const combinedText = useMemo(() => {
    return `To join the meeting click this url
    ${roomUrl}

    Or, dial this number From the U.S
    ${guestPhoneAndPin}

    p.s. International dial-in numbers are available at ${numberPageUrl}
    `;
  }, [roomUrl, guestPhoneAndPin, numberPageUrl]);

  const hostLink = useMemo(() => {
    return `${roomUrl}?h=${host_link_id}`;
  }, [roomUrl, host_link_id]);

  const presenterLink = useMemo(() => {
    return `${roomUrl}?p=${presenter_link_id}`;
  }, [roomUrl, presenter_link_id]);

  useEffect(() => {
    getPasscodeHandler(setState);
  }, []);

  const handleClose = e => {
    e.preventDefault();
    dispatch(setShowInviteModal(false));
  };

  const guestPinUpdateHandler = () => {
    guestPinUpdate({room, token}).then(res => {
      if (res.status === 200) {
        toast.success(res.data[0], {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const hostPinUpdateHandler = () => {
    hostPinUpdate({room, token}).then(res => {
      if (res.status === 200) {
        toast.success(res.data[0], {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const presenterLinkUpdateHandler = () => {
    presenterLinkUpdate({room, token}).then(res => {
      if (res.status === 200) {
        toast.success(res.data[0], {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const hostLinkUpdateHandler = () => {
    hostLinkUpdate({room, token}).then(res => {
      if (res.status === 200) {
        toast.success(res.data[0], {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };
  const invitePeopleBody = () => {
    return (
      <>
        <div className="row firstrow p-0">
          <div className="col-md-12 custom-tabs modal_min_height">
            <ul className="nav nav-tabs  mr-auto" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active show"
                  id="copy-tab"
                  data-toggle="tab"
                  href="#copy"
                  role="tab"
                  aria-controls="copy">
                  Copy & Paste
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade active show"
                id="copy"
                role="tabpanel"
                aria-labelledby="copy-tab">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-11 col-11 mb-10 mt-10">
                        <div className="form-group row">
                          <label className="col-md-4 col-4 justify-content-end pr-0 label-inline">
                            Room Link:
                            <a
                              className="tooltip_container ml-2"
                              data-tooltip="This is usually the best link to give people.">
                              <span className="with_circle">
                                <svg height="60%" viewBox="7 4 10 16">
                                  <path
                                    fill="#ffffff"
                                    d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                                  />
                                </svg>
                              </span>
                            </a>
                          </label>
                          <div className="col-md-6 col-6 d-flex">
                            <p className="form-control mb-0 TextHighlight">
                              {roomUrl}
                            </p>
                          </div>
                          <div className="col-md-2 col-2 p-0 d-flex align-items-center">
                            <CopyToClipboard
                              options={{format: 'text/plain'}}
                              text={removeTags(roomUrl)}>
                              <button
                                role="button"
                                className="btn btn-green btn-block m-0 mt-03">
                                Copy
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-4 col-4 justify-content-end pr-0 label-inline">
                            Phone + Guest PIN:
                            <a className="tooltip_container ml-2">
                              <span className="with_circle">
                                <svg height="60%" viewBox="7 4 10 16">
                                  <path
                                    fill="#ffffff"
                                    d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                                  />
                                </svg>
                              </span>
                              <div className="tooltip_data">
                                If you want people to dial in by phone as a
                                guest, share this phone number and PIN. If you
                                have non-US guests, also share&nbsp;
                                <a
                                  href="/numbers"
                                  target="_blank"
                                  className="text-green">
                                  meetn.com/numbers
                                </a>
                              </div>
                            </a>
                          </label>
                          <div className="col-md-6 col-6 d-flex">
                            <p className="form-control mb-0 TextHighlight">
                              {guestPhoneAndPin}
                            </p>
                          </div>
                          <div className="col-md-2 col-2 p-0 d-flex align-items-center">
                            <CopyToClipboard
                              options={{format: 'text/plain'}}
                              text={removeTags(guestPhoneAndPin)}>
                              <button
                                role="button"
                                className="btn btn-green btn-block m-0 mt-03">
                                Copy
                              </button>
                            </CopyToClipboard>
                            {localUser.role !== 'guest' && (
                              <a
                                href="javascript:void(0)"
                                className="reset_links_icon tooltip_container tooltip_container--right--auto tooltip_container--sm"
                                data-tooltip="Get a New PIN"
                                onClick={() => guestPinUpdateHandler()}>
                                <i class="fa fa-refresh" aria-hidden="true" />
                              </a>
                            )}
                          </div>
                        </div>
                        {localUser.role !== 'guest' && (
                          <>
                            <div className="form-group row">
                              <label className="col-md-4 col-4 justify-content-end pr-0 label-inline">
                                Host Link:
                                <a
                                  className="tooltip_container ml-2"
                                  data-tooltip="Anyone who joins using this link will be a host in the room for that session.">
                                  <span className="with_circle">
                                    <svg height="60%" viewBox="7 4 10 16">
                                      <path
                                        fill="#ffffff"
                                        d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                                      />
                                    </svg>
                                  </span>
                                </a>
                              </label>
                              <div className="col-md-6 col-6 d-flex">
                                <p className="form-control mb-0 TextHighlight">
                                  {hostLink}
                                </p>
                                {/* <input type="text" disabled name="phonePin" value={this.state.phonePin} className="form-control" /> */}
                              </div>
                              <div className="col-md-2 col-2 p-0 d-flex align-items-center">
                                <CopyToClipboard
                                  options={{
                                    format: 'text/plain',
                                  }}
                                  text={removeTags(hostLink)}>
                                  <button
                                    role="button"
                                    className="btn btn-green btn-block m-0 mt-03">
                                    Copy
                                  </button>
                                </CopyToClipboard>
                                <a
                                  href="javascript:void(0)"
                                  className="reset_links_icon tooltip_container tooltip_container--right--auto tooltip_container--sm"
                                  data-tooltip="Get a New LINK"
                                  onClick={() => hostLinkUpdateHandler()}>
                                  <i class="fa fa-refresh" aria-hidden="true" />
                                </a>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-4 col-4 justify-content-end pr-0 label-inline">
                                Presenter Link:
                                <a
                                  className="tooltip_container ml-2"
                                  data-tooltip="Anyone who joins using this link will be a presenter in the room for that session.">
                                  <span className="with_circle">
                                    <svg height="60%" viewBox="7 4 10 16">
                                      <path
                                        fill="#ffffff"
                                        d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                                      />
                                    </svg>
                                  </span>
                                </a>
                              </label>
                              <div className="col-md-6 col-6 d-flex">
                                <p className="form-control mb-0 TextHighlight">
                                  {presenterLink}
                                </p>
                              </div>
                              <div className="col-md-2 col-2 p-0 d-flex align-items-center">
                                <CopyToClipboard
                                  options={{
                                    format: 'text/plain',
                                  }}
                                  text={removeTags(presenterLink)}>
                                  <button
                                    role="button"
                                    className="btn btn-green btn-block m-0 mt-03">
                                    Copy
                                  </button>
                                </CopyToClipboard>
                                &nbsp;
                                <a
                                  href="javascript:void(0)"
                                  className="reset_links_icon tooltip_container tooltip_container--right--auto tooltip_container--sm"
                                  data-tooltip="Get a New LINK"
                                  onClick={() => presenterLinkUpdateHandler()}>
                                  <i class="fa fa-refresh" aria-hidden="true" />
                                </a>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-4 col-4 justify-content-end pr-0 label-inline">
                                Phone + Host PIN:
                                <a className="tooltip_container ml-2">
                                  <span className="with_circle">
                                    <svg height="60%" viewBox="7 4 10 16">
                                      <path
                                        fill="#ffffff"
                                        d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                                      />
                                    </svg>
                                  </span>
                                  <div className="tooltip_data">
                                    If you want people to dial in by phone as a
                                    host / speaker, share this phone number and
                                    PIN. If you have non-US hosts, also
                                    share&nbsp;
                                    <a
                                      href="/numbers"
                                      target="_blank"
                                      className="text-green">
                                      meetn.com/numbers
                                    </a>
                                  </div>
                                </a>
                              </label>
                              <div className="col-md-6 col-6 d-flex">
                                <p className="form-control mb-0 TextHighlight">
                                  {hostPhoneAndPin}
                                </p>
                              </div>
                              <div className="col-md-2 col-2 p-0 d-flex align-items-center">
                                <CopyToClipboard
                                  options={{format: 'text/plain'}}
                                  text={removeTags(hostPhoneAndPin)}>
                                  <button
                                    role="button"
                                    className="btn btn-green btn-block m-0 mt-03">
                                    Copy
                                  </button>
                                </CopyToClipboard>
                                <a
                                  href="javascript:void(0)"
                                  className="reset_links_icon tooltip_container tooltip_container--right--auto tooltip_container--sm"
                                  data-tooltip="Get a New PIN"
                                  onClick={() => hostPinUpdateHandler()}>
                                  <i class="fa fa-refresh" aria-hidden="true" />
                                </a>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="form-group row mb-0">
                          <label className="col-md-4 col-4 justify-content-end pr-0 label-inline align-baseline">
                            Combo Guest Invite:
                          </label>
                          <div className="col-md-6 col-6">
                            <p className="form-control hAuto mb-0 TextHighlight">
                              To join the meeting click this url
                              <br />
                              {roomUrl}
                              <br />
                              <br />
                              Or, dial this U.S. number
                              <br />
                              {guestPhoneAndPin} <br />
                              <br />
                              p.s. International dial-in numbers are available
                              at&nbsp;
                              <a href={numberPageUrl} target="_blank">
                                {numberPageUrl}
                              </a>
                            </p>
                          </div>
                          <div className="col-md-2 col-2 p-0">
                            <CopyToClipboard
                              options={{format: 'text/plain'}}
                              text={removeTags(combinedText)}>
                              <button
                                type="button"
                                className="btn btn-green btn-block m-0 mt-03">
                                Copy
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-right mt-3">
            <button
              type="button"
              data-toggle="modal"
              data-target="#invitemodal"
              onClick={e => {
                handleClose(e);
              }}
              className="btn btn-link float-right">
              Close
            </button>
          </div>
        </div>
      </>
    );
  };

  const invitePeopleObject = {
    heading: 'Invite People',
    body: invitePeopleBody,
    classNameModal: 'fade invitewrap',
    show: true,
    classNameDialog: 'modal-lg',
    headingSize: 'h5',
    handleClose: handleClose,
  };

  return <CommonModal {...invitePeopleObject} />;
};

export default InvitePeopleModal;
