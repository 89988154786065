import React from 'react';
import {updateUserSettings} from '../utils/functions';
import {useSelector} from 'react-redux';

function AttendeesDropdown() {
  const {localUser} = useSelector(state => state.conf);
  const {
    auto_appear_waiting_room,
    play_ding_waiting_room,
    show_popup_waiting_room,
    auto_appear_raise_hand,
    play_woosh_raise_hand,
  } = localUser;
  return (
    <div className="dropdown chat-dropdown attendees-dropdown ml-auto mr-auto">
      <h5
        className="semiBold font16 mb-0 dropdown-toggle"
        id="attendees-tab"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <span className="webpage-title mr-auto ml-auto">Attendees</span>
      </h5>
      <div className="dropdown-menu" aria-labelledby="attendees-tab">
        <ul>
          <li>
            <a
              href="javascript:void(0)"
              onClick={e => updateUserSettings('auto_appear_waiting_room')}>
              <i
                className={
                  auto_appear_waiting_room ? 'fas fa-check text-green' : ''
                }
              />
              Auto Appear on Waiting Room Entry
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={e => updateUserSettings('play_ding_waiting_room')}>
              <i
                className={
                  play_ding_waiting_room ? 'fas fa-check text-green' : ''
                }
              />
              Play Ding on Waiting Room Entry
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={e => updateUserSettings('show_popup_waiting_room')}>
              <i
                className={
                  show_popup_waiting_room ? 'fas fa-check text-green' : ''
                }
              />
              Show popup when people are in waiting-room
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={e => updateUserSettings('auto_appear_raise_hand')}>
              <i
                className={
                  auto_appear_raise_hand ? 'fas fa-check text-green' : ''
                }
              />
              Auto Appear on Raised Hand
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={e => updateUserSettings('play_woosh_raise_hand')}>
              <i
                className={
                  play_woosh_raise_hand ? 'fas fa-check text-green' : ''
                }
              />
              Play Ring on Raised Hand
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AttendeesDropdown;
