import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PhoneCommandModal from './modals/phoneCommandModal';
import {setShowPhoneCommandModal} from '../../../reduxStore/confSlice';

function PhoneCommand() {
  const {showPhoneCommandModal} = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    dispatch(setShowPhoneCommandModal(true));
  };
  return (
    <>
      <a className="nav-link" onClick={handleOpenModal}>
        <div className="menu_icons">
          <img height="20" width="20" src="assets/images/phone-command.png" />
        </div>
        <span className="pl-2">Phone Commands</span>
      </a>
      {showPhoneCommandModal && <PhoneCommandModal />}
    </>
  );
}

export default PhoneCommand;
