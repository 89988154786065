import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {unfeatureChatMessage} from '../../../utils/socket';
import {setFeatureMsg} from '../../../reduxStore/confSlice';

function FeaturedChatMessage() {
  const {featuredMsg, localUser, roomDetail, participantsList} = useSelector(
    state => state.conf,
  );
  const dispatch = useDispatch();
  const {room, guests_have_host_powers} = roomDetail;
  const {name, message, id} = featuredMsg;
  const {role} = localUser;

  let profile_image = featuredMsg?.profile_image;
  let userName = name;

  const featuredMsgUser = useMemo(() => {
    return participantsList[featuredMsg.token]
      ? participantsList[featuredMsg.token]
      : {};
  }, [participantsList, featuredMsg]);

  if (Object.keys(featuredMsgUser).length > 0) {
    userName = featuredMsgUser.name;
    profile_image = featuredMsgUser.profile_image;
  }

  const haveExtraPermission =
    role === 'owner' ||
    role === 'admin' ||
    role === 'host' ||
    guests_have_host_powers === true;

  if (role === 'guest' && featuredMsg?.hide_chat_for_guests) {
    return <></>;
  }

  const msgUnFeature = () => {
    const payload = {
      room,
      id,
    };
    unfeatureChatMessage(payload).then(() => {});
  };
  const closeFeaturedMsg = () => {
    dispatch(setFeatureMsg({}));
  };
  return (
    <>
      <div className="d-flex featured_block position-fixed">
        <div className="featured_cir bg-dark d-flex align-items-center justify-content-center bg-white text-green">
          {profile_image ? (
            <img src={profile_image} />
          ) : (
            <span>{userName && userName[0]}</span>
          )}
        </div>

        <div className="featured_modal d-flex flex-column position-relative">
          {haveExtraPermission ? (
            <div
              className="px-1 py-1 btn-xs btn-green w-auto d-flex align-items-center unfeature"
              onClick={() => msgUnFeature()}>
              Unfeature
            </div>
          ) : (
            <a
              href="javascript:void(0);"
              className="close_icon_featured"
              onClick={() => closeFeaturedMsg()}>
              <svg height=".55em" viewBox="6.29 6.34 11.31 11.31">
                <path
                  fill="#ffffff"
                  d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
              </svg>
            </a>
          )}
          <div
            className={`featured_head pl-5 py-2 text-white ${
              userName && userName.split(' ')[0].length >= 8 ? 'w-75' : 'w-55'
            }`}>
            {userName}
          </div>
          <div className="featured_text bg-white pl-5 py-3 pr-3">{message}</div>
        </div>
      </div>
    </>
  );
}

export default React.memo(FeaturedChatMessage);
