/* eslint-disable no-void */
import React from 'react';
import { CommonModal } from '../../../../../common/web';

function FacebookGroupPermissionModal({ selectedGroup, handleReturn }) {

    const facebookGroupPermissionBody = () => {
        return (
            <>
                <div className="row modal_min_height">
                    <div className="col-md-12">
                        <p>To connect your group to Meetn</p>

                        <ul className="h-max-auto">
                            <li className="mb-2">
                                <span className="content">1. Open your group settings</span>

                                <a href={`https://www.facebook.com/groups/${selectedGroup}/apps/store`} target="_blank">
                                    <span class="btn bg-facebook">
                                        <svg width="1.1em" viewBox="2 2 20 19.88">
                                            <path fill="#ffffff" d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z" />
                                        </svg>&nbsp;
                                        Open Facebook group settings</span>
                                </a>
                            </li>
                            <li className="mb-2">
                                <span className="content">2. Search for "meetn" and click on the Meetn app</span>
                                <img src="assets/images/meetnapp.PNG" className="w-max-500 mt-1" />
                            </li>
                            <li className="mb-2">
                                <span className="content">3. Click Add</span>
                            </li>
                            <li className="mb-2">
                                <span className="content">4. Return back to List and choose again.</span>
                            </li>
                        </ul>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <a href={void (0)} className="btn-green btn-mini mr-0" onClick={() => handleReturn(false)}>Return</a>
                    </div>
                </div>
            </>
        );
    };

    const facebookProfileObject = {
        heading: 'Add the Meetn app to your Facebook group',
        body: facebookGroupPermissionBody,
        show: true,
        classNameBody: 'overflow_visible',
        classNameModal: 'fade facebook-list-modal',
        headingSize: 'h5',
    };
    return <CommonModal {...facebookProfileObject} />;
}

export default FacebookGroupPermissionModal;
