/* eslint-disable no-void */
import React from 'react';
import { CommonModal } from '../../../../../common/web';

function FacebookProfileModal({ fbLoader, fb_profile_data, handleAddFacebookDestination, handleReturn }) {

    const facebookProfileBody = () => {
        return (
            <>
                {
                    fbLoader ? <><div className="row modal_min_height">
                        <a href={void 0} className="loader_icon">
                            <img src="assets/images/refresh-loader.gif" />
                        </a>
                    </div> </> : <>    <div className="row">
                        <div className="col-md-12">
                            {fb_profile_data.length > 0 ? (
                                <p>Choose a Facebook profile</p>
                            ) : (
                                <p>We couldn't find any Facebook profile</p>
                            )}
                            <ul>
                                {fb_profile_data.length > 0 && (
                                    <>
                                        <li>
                                            <a href={void (0)} onClick={() => handleAddFacebookDestination(fb_profile_data[0].id, fb_profile_data[0].name)}>
                                                <div className="wrap-image">
                                                    <img src={fb_profile_data[0].picture} />
                                                </div>
                                                <div className="wrap-content">
                                                    <span className="heading">
                                                        {fb_profile_data[0].name}
                                                    </span>
                                                    <span className="content">Profile</span>
                                                </div>
                                            </a>
                                        </li>
                                        <p>
                                            Want to use a different account? Log in to that account on
                                            facebook.com then try again.
                                        </p>
                                    </>
                                )}
                            </ul>

                            {
                                <div className="col-md-12 text-right">
                                    <a
                                        href={void (0)}
                                        className="btn-green btn-mini mr-0"
                                        data-dismiss="modal"
                                        onClick={e => handleReturn(false)}>
                                        Return
                                    </a>
                                </div>
                            }
                        </div>
                    </div > </>
                }

            </>
        );
    };

    const facebookProfileObject = {
        heading: 'Choose a Facebook profile',
        body: facebookProfileBody,
        show: true,
        classNameModal: 'fade facebook-list-modal',
        headingSize: 'h5',
    };
    return <CommonModal {...facebookProfileObject} />;
}

export default FacebookProfileModal;
