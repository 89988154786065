/* eslint-disable no-void */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonModal } from '../../../../common/web';
import {
  setOpenStreamManagerModal,
  setRoomDetails,
} from '../../../../../reduxStore/confSlice';
import AddDestinationModal from './addDestinationModal';
import {
  getStreamingDestinations,
  roomSettings,
  startSocialStreaming,
  stopSocialStreaming,
} from '../../../../../utils/socket';
import { IconQuestionMark } from '../../../../../icons';
import {
  DEFAULT_LAODING_MSG,
  destionationIcons,
} from '../../../../../utils/util';
import DeleteDestination from './deleteDestinationModal';
import { alertMsg } from '../../../../common/web/alert';
import { toast } from 'react-toastify';

const StreamManager = () => {
  const { roomDetail } = useSelector(state => state.conf);
  const { room, recorder_view } = roomDetail;
  const [showAddDestination, setAddDestination] = useState(false);
  const [startStreamingLoader, setStartStreamingLoader] = useState(false);
  const [stopStreamingLoader, setStopStreamingLoader] = useState(false);
  const [listLoader, setlistLoader] = useState(false);
  const [destinationsList, setDestinationsList] = useState([]);
  const [showDeleteDestination, setDeleteDestination] = useState(false);
  const [deleteDestinationData, setDeleteDestinationData] = useState({});
  const [state, setState] = useState({
    title: '',
    description: '',
    streaming_comment: false,
    destinations: [],
  });

  const { title, description, streaming_comment, destinations } = state;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showAddDestination || !showDeleteDestination) {
      getDestinationList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddDestination, showDeleteDestination]);

  const getDestinationList = () => {
    setlistLoader(true);
    getStreamingDestinations({ room })
      .then(response => {
        if (response.status === 200) {
          setDestinationsList(response.data);
        }
      })
      .finally(() => {
        setlistLoader(false);
      });
  };

  const deletionConfiramtion = destination => {
    if (!roomDetail?.streaming) {
      setDeleteDestinationData(destination);
      setDeleteDestination(true);
    } else {
      alertMsg({
        msg: 'Please stop the current stream to delete destination.',
        type: 'info',
      });
    }

  };

  const handleInputValue = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setState(prevState => ({ ...prevState, [e.target.name]: value }));
  };

  const handleView = view => {
    let tmpRoomDetail = { ...roomDetail };
    tmpRoomDetail.recorder_view = view;
    dispatch(setRoomDetails(tmpRoomDetail));
  };

  const handleDestiantionValues = (e, id) => {
    let selectedDestination = [...destinations];

    console.log('streaming=>', roomDetail?.streaming)
    if (!roomDetail?.streaming) {
      if (e.target.checked) {
        if (selectedDestination.length < 5) {
          selectedDestination.push(id);
        } else {
          alertMsg({ msg: 'Maximum destinations selected.', type: 'info' });
        }
      } else {
        let index = selectedDestination.indexOf(id);
        if (index !== -1) {
          selectedDestination.splice(index, 1);
        }
      }
      setState(prevState => ({
        ...prevState,
        destinations: selectedDestination,
      }));
    } else {
      alertMsg({
        msg: 'Please stop the current stream to edit destinations.',
        type: 'info',
      });
    }
  };

  const startStreaming = async () => {
    if (title !== '') {
      if (destinations.length > 0) {
        setStartStreamingLoader(true);
        let payload = {
          room,
          title,
          description,
          streaming_comment,
          destination: destinations,
          recorder_view,
        };
        console.log('payload', payload);

        startSocialStreaming(payload).then(response => {
          console.log('response', response);
          if (response.status === 200) {
            const temproomDetail = { ...roomDetail };
            temproomDetail['streaming'] = true;
            temproomDetail['streaming_timestamp'] = response?.data?.streaming_timestamp;
            dispatch(setRoomDetails(temproomDetail));
            dispatch(setOpenStreamManagerModal(false));
          } else {
            toast.error(response.data[0]?.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }).finally(() => {
          setStartStreamingLoader(false);
        });
      } else {
        alertMsg({
          msg: 'You cannot stream without choosing at least one destination.',
          type: 'info',
        });
      }
    } else {
      alertMsg({ msg: 'Please enter title.', type: 'info' });
    }
  };

  const stopStreaming = async () => {
    setStopStreamingLoader(true);

    stopSocialStreaming({ room }).then(response => {
      console.log('response', response);
      if (response.status === 200) {
        dispatch(setOpenStreamManagerModal(false));
      } else {
        toast.error(response.data[0]?.msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }).finally(() => {
      setStopStreamingLoader(false);
    });
  };

  const streamManagerBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="modal_min_height">
              <p className="mb-1 semiBold text-center">
                Type a stream title and description. Then, select the
                destination(s)
                <br />
                you would like to stream to and click 'Start Streaming'.
              </p>
              <div className="stream-fields">
                <div className="content-left">
                  <h5>Stream Information</h5>
                  <div className="form-group">
                    <label className="required">
                      Title: ({100 - title.length})
                    </label>
                    <input
                      spellCheck="false"
                      type="text"
                      maxLength="100"
                      name="title"
                      className="form-control"
                      onChange={e => handleInputValue(e)}
                      value={(roomDetail?.streaming && roomDetail?.streaming_title) ? roomDetail?.streaming_title : title}
                      disabled={startStreamingLoader || roomDetail?.streaming}
                    />
                  </div>
                  <div className="form-group">
                    <label>Description: ({5000 - description.length})</label>
                    <textarea
                      spellCheck="false"
                      maxLength="5000"
                      className="form-control textarea streaming_textfield"
                      name="description"
                      onChange={e => handleInputValue(e)}
                      value={(roomDetail?.streaming && roomDetail?.streaming_description) ? roomDetail?.streaming_description : description}
                      disabled={startStreamingLoader || roomDetail?.streaming}
                    />
                  </div>
                  <div className="form-group d-flex align-items-center flex-row">
                    <label className="">View:</label>
                    <div className="btn-group d-flex align-items-center">
                      <button
                        type="button"
                        className={`btn ${recorder_view === 'gallery' ? 'btn-green' : 'btn-link'
                          }`}
                        onClick={() => handleView('gallery')}>
                        Gallery
                      </button>
                      <button
                        type="button"
                        className={`btn ${recorder_view === 'active' ? 'btn-green' : 'btn-link'
                          }`}
                        onClick={() => handleView('active')}>
                        Active Speaker
                      </button>
                    </div>
                  </div>
                  <div className="form-group form-group-switch">
                    <label className="checkwrap checkwrap_profile justify-left hAuto">
                      Allow comments from YouTube and Facebook Live Streams to
                      be added in Meetn Chat
                      <input
                        spellCheck="false"
                        type="checkbox"
                        name="streaming_comment"
                        onChange={(e) => handleInputValue(e)}
                        checked={(roomDetail?.streaming && roomDetail?.streaming_comment) ? roomDetail?.streaming_comment : streaming_comment}
                        disabled={startStreamingLoader || roomDetail?.streaming}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="content-right">
                  <div className="listing-header">
                    <div>
                      <h5>
                        Destinations
                        <a
                          className="tooltip_container ml-2"
                          data-tooltip="A platform you would like to live stream your Meetn to.">
                          <span className="with_circle">
                            <IconQuestionMark />
                          </span>
                        </a>
                      </h5>
                    </div>
                    <button
                      type="button"
                      className="btn btn-green btn-mini btn-stroke"
                      onClick={() => setAddDestination(true)}>
                      Add a Destination
                    </button>
                  </div>
                  <div className="listing-body">
                    {listLoader && (
                      <span className="loader_container">
                        <img
                          src="assets/images/loader-x.gif"
                          className="pop-loader"
                        />
                      </span>
                    )}
                    <ul>
                      {destinationsList.length > 0 ? (
                        destinationsList.map((destination, key) => {

                          return (
                            <>
                              <li key={key}>
                                <span>
                                  <div className="content-left">
                                    <div className="stream_icon">
                                      {destionationIcons.get(
                                        destination.destination,
                                      )}
                                    </div>
                                    {destination.nickname}
                                  </div>
                                  <div className="content-right">
                                    {
                                      destination.error && <div className="helping-tooltip warning">
                                        <a className="tooltip_container tooltip_container--right text-yellow ml-0" data-tooltip={`${destination.error_message}`}>
                                          <i className="fas fa-exclamation-triangle" />
                                        </a>
                                      </div>
                                    }
                                    <label className="switch modal-switch">
                                      <input
                                        type="checkbox"
                                        name="destinations"
                                        checked={destinations.includes(
                                          destination.id,
                                        ) || (destination.enabled === true)}
                                        onChange={e =>
                                          handleDestiantionValues(
                                            e,
                                            destination.id,
                                          )
                                        }
                                      />
                                      <div className="slider round" />
                                    </label>
                                    <a
                                      href={void 0}
                                      onClick={() =>
                                        deletionConfiramtion(destination)
                                      }>
                                      <i className="fas fa-times" />
                                    </a>
                                  </div>
                                </span>
                              </li>
                            </>
                          );
                        })
                      ) : (
                        <li className="no-destinations">
                          <span>No Destinations</span>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="status">
                    <label className="mb-0">Status: {roomDetail?.streaming ? 'Streaming' : 'Not Streaming'}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="stream-submit">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-link"
                onClick={() => dispatch(setOpenStreamManagerModal(false))}>
                Close
              </button>
              {
                roomDetail?.streaming ? <button type="button" className="btn btn-red" disabled={stopStreamingLoader} onClick={() => stopStreaming()}>
                  {stopStreamingLoader ? DEFAULT_LAODING_MSG : 'Stop Streaming'}
                </button> : <button type="button"
                  className="btn btn-green"
                  disabled={startStreamingLoader}
                  onClick={e => startStreaming(e)}>
                  {startStreamingLoader ? DEFAULT_LAODING_MSG : 'Start Streaming'}
                </button>
              }

            </div>
          </div>
        </div>
      </>
    );
  };

  const streamManagerObject = {
    imgLink: 'assets/images/signal-stream.png',
    heading: 'Stream Manager',
    body: streamManagerBody,
    show: true,
    classNameModal: 'fade streamManager',
    headingSize: 'h5',
  };

  return (
    <>
      {/* stream manager modal */}

      <CommonModal {...streamManagerObject} />
      {showAddDestination && (
        <AddDestinationModal setAddDestination={setAddDestination} />
      )}
      {showDeleteDestination && (
        <DeleteDestination
          deleteDestinationData={deleteDestinationData}
          setDeleteDestination={setDeleteDestination}
        />
      )}
    </>
  );
};

export default StreamManager;
