import React from 'react';
import {useDispatch} from 'react-redux';

import {CommonModal} from '../../../common/web';
import {setShowPhoneCommandModal} from '../../../../reduxStore/confSlice';

function PhoneCommandModal() {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setShowPhoneCommandModal(false));
  };

  const phoneCommandBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12 modal_min_height">
            <p className="mb-1 semiBold">
              If you’re calling in via the phone, you can use the following
              commands.
            </p>

            <label className="semiBold">For Guests</label>
            <ul
              style={{
                'listStyleType?': 'none',
              }}
              className="font13 listSnone">
              <li>(*0) - Reads this list of commands to the caller.</li>
              <li>(*2) - Raises or lowers the caller's "hand".</li>
              <li>(*6) - Mutes the caller.</li>
              <li>(*7) - Unmutes the caller.</li>
            </ul>
            <label className="semiBold">For Hosts</label>
            <ul
              style={{
                'listStyleType?': 'none',
              }}
              className="font13 listSnone">
              <li>(99) - Unmutes all callers.</li>
              <li>(##) - Hard mute all guests -- Guests cannot unmute.</li>
              <li>(88) - Soft mute all guests -- Guests can unmute.</li>
              <li>(**) - Tells the host how many people are on the call.</li>
            </ul>

            <p className="semiBold mb-0">
              NOTE: To call in by phone, either select “Phone Audio” before
              joining the meetn, or open “AV Settings” (top, center toolbar) and
              click “Switch to Phone Audio”.
            </p>
          </div>
          <div className="col-md-12 text-right mt-3">
            <a
              className="btn-link btn-mini"
              data-toggle="modal"
              data-target="#phonecommands"
              onClick={() => {
                closeModal();
              }}>
              Close
            </a>
          </div>
        </div>
      </>
    );
  };

  const phoneCommandObject = {
    heading: 'Phone Commands',
    body: phoneCommandBody,
    classNameModal: 'fade smallmodalwrap',
    classNameDialog: 'smallmodal',
    classNameBody: 'flexdiv pl-0 pr-0',
    show: true,
    headingSize: 'h5',
    closeModal: closeModal,
  };
  return (
    <>
      <CommonModal {...phoneCommandObject} />;
    </>
  );
}

export default PhoneCommandModal;
