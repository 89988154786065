/* eslint-disable react-native/no-inline-styles */

import React, { useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { APP_VERSION, BUILD_NO, DEFAULT_LAODING_MSG, TenMegaBytes, uploadAllowedMimeType } from '../../../../utils/util';
import { uploadFileInWasabi } from '../../../../utils/functions';
import { alertMsg } from '../../../common/web/alert';
import ProgressBar from '../../../common/web/progressBar';
import { toast } from 'react-toastify';
import { reportProblem } from '../../../../utils/socket';
import { useSelector } from 'react-redux';

function ReportProblemModal({ closeModal }) {
    const simpleValidator = useRef(new SimpleReactValidator());
    const { localUser, roomDetail } = useSelector(state => state.conf);
    const [state, setState] = useState({ name: '', email: '', description: '', acknowledgement: false, reportLoader: false, attachment: '', fileLoading: false, selectedFile: '', severity: 'Suggestion' });
    const [uploadLoader, setUploadLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [, forceUpdate] = useState();

    const handleInputValue = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setState(prevState => ({ ...prevState, [e.target.name]: value }));
    };

    const submitReportProblem = (e) => {
        toast.dismiss();
        const { name, email, description, attachment, severity, acknowledgement } = state;
        if (simpleValidator.current.allValid()) {
            const payload = { token: localUser?.token, room: roomDetail.room, name, email, description, attachment, severity, acknowledgement, jid: localUser.jid, version: `WEB ${APP_VERSION}(${BUILD_NO})` };

            setSubmitLoader(true);
            reportProblem(payload).then(response => {
                if (response.status === 200) {
                    toast.success('Reported Successfully', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    closeModal(false);
                } else {
                    toast.error(response.data[0].msg, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }).finally(() => {
                setSubmitLoader(false);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    const uploadFile = async file => {
        let fileType = file[0]?.type;
        let fileSize = file[0]?.size;

        if ((uploadAllowedMimeType().includes(fileType))) {
            if (fileSize < TenMegaBytes) {
                const selectedFiles = [...file];
                setUploadLoader(true);
                const [fileResponse] = await uploadFileInWasabi(selectedFiles);
                let fileName = fileResponse && fileResponse.length > 0 && fileResponse[0].attachmentName;

                if (fileName) {
                    setState(prevState => ({ ...prevState, attachment: fileName }));
                }
                setUploadLoader(false);
            } else {
                alertMsg({
                    msg: 'File too large. Please upload a file less than 10 MB.',
                    type: 'info',
                });
            }
        } else {
            alertMsg({ msg: 'Please upload a valid image file.', type: 'info' });
        }
    };
    return (<>
        <div className="modal fade show" id="report-problem" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog smallmodal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title capcase mr-auto">Anonymous Problem Report</h5>
                    </div>
                    <div className="modal-body d-flex flex-column justify-content-between">
                        <div className="modal_min_height">
                            <p className="semiBold">
                                This form is anonymous. If you need a response, send an email to <a href="mailto:support@meetn.com" target="_blank">Support@meetn.com</a>
                            </p>
                            <div className="form-group d-flex flex-row">
                                <label className="col-md-3 col-3 d-flex align-items-center p-0 mb-0">Name (Optional):</label>
                                <div className="col-md-9 col-9 p-0">
                                    <input spellCheck="false" type="text" name="name" onChange={handleInputValue} value={state.name} className="form-control" />
                                    {simpleValidator.current.message('name', state.name, 'max:50', { messages: { max: 'Sorry, the name has to be 50 characters or less' } })}
                                </div>
                            </div>

                            <div className="form-group d-flex flex-row">
                                <label className="col-md-3 col-3 d-flex align-items-center p-0 mb-0">Attachment (Optional):</label>
                                <div className="col-md-9 col-9 p-0">
                                    <div className="custom-input-file">
                                        <input
                                            spellCheck="false"
                                            accept="image/*,video/*"
                                            type="file"
                                            id="attachment"
                                            className="form-control"
                                            onChange={e => uploadFile(e.target.files)}
                                        />
                                        <div className="custom-file">
                                            <button>Choose file</button>
                                            <span>{state.attachment}</span>
                                        </div>
                                    </div>
                                    {
                                        uploadLoader &&
                                        <div className="upload-progress">
                                            <ProgressBar />
                                        </div>
                                    }

                                </div>
                            </div>

                            <div className="form-group d-flex flex-row">
                                <label className="col-md-3 col-3 d-flex align-items-center p-0 mb-0">Severity:</label>
                                <div className="col-md-9 col-9 p-0">
                                    <label className="radiowrap float-left mb-0">
                                        Suggestion
                                        <input
                                            spellCheck="false"
                                            type="radio"
                                            name="severity"
                                            value="Suggestion"
                                            checked={state.severity === 'Suggestion'}
                                            onChange={handleInputValue}
                                        />
                                        <span className="checkmark" />
                                    </label>
                                    <label className="radiowrap float-left mb-0">
                                        Minor Issue
                                        <input
                                            spellCheck="false"
                                            type="radio"
                                            name="severity"
                                            value="Minor Issue"
                                            checked={state.severity === 'Minor Issue'}
                                            onChange={handleInputValue}
                                        />
                                        <span className="checkmark" />
                                    </label>
                                    <label className="radiowrap float-left mb-0">
                                        Emergency
                                        <input
                                            spellCheck="false"
                                            type="radio"
                                            name="severity"
                                            value="Emergency"
                                            checked={state.severity === 'Emergency'}
                                            onChange={handleInputValue}
                                        />
                                        <span className="checkmark" />
                                    </label>
                                </div>
                            </div>
                            <div className="form-group mt-3 mb-0">
                                <label>Problem Description: ({500 - state.description.length})</label>
                                <textarea spellCheck="false" type="text" name="description" onChange={handleInputValue} value={state.description} className="form-control" maxLength="500" rows="4" />
                                {simpleValidator.current.message('description', state.description, 'required|max:500', { messages: { default: 'Problem Description is required.', max: 'Problem Description can be max 500 characters.' } })}
                            </div>
                            <div className="form-group mt-3 mb-0">
                                <label className="checkwrap justify-left hAuto d-flex">
                                    <input
                                        type="checkbox"
                                        name="acknowledgement"
                                        checked={state.acknowledgement}
                                        onChange={handleInputValue}

                                    />
                                    <span className="checkmark" />
                                    I understand I will not get a response since this is anonymous
                                    <a className="tooltip_container tooltip_container--right ml-2">
                                        <span className="with_circle">
                                            <svg height="60%" viewBox="7 4 10 16" >
                                                <path
                                                    fill="#ffffff"
                                                    d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                                                />
                                            </svg>
                                        </span>
                                        <div className="tooltip_data">
                                            To get a response, please send an email to &nbsp;
                                            <a href="mailto:support@meetn.com" target="_blank">Support@meetn.com</a>
                                        </div>
                                    </a>
                                </label>

                            </div>
                        </div>
                        <div className="mt-3 d-flex justify-content-end">
                            <button type="button" data-dismiss="modal" className="btn btn-link" onClick={() => closeModal(false)}>Cancel</button>
                            <button type="button" className="btn btn-green" disabled={uploadLoader || !state.acknowledgement || submitLoader} onClick={submitReportProblem} >{submitLoader ? DEFAULT_LAODING_MSG : 'Submit'} </button>
                        </div>
                    </div>
                    <div className="modal-footer" />
                </div>
            </div>
        </div>
    </>);
}

export default ReportProblemModal;
