import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import {
  setPage,
  setUserDetail,
  setBackButtonShow,
  setPreviousRouteStep,
  setVirtualBackgroundOptions,
} from '../../../reduxStore/confSlice';
import { setRecentUsers } from '../../../reduxStore/persistSlice';
import { useSearchParams } from 'react-router-dom';
import { Capitalize } from '../../../utils/functions';

const RecentUser = () => {
  const dispatch = useDispatch();
  const { recentUsers } = useSelector(state => state.persist);
  const { roomDetail } = useSelector(state => state.conf);
  const [disabledUser, setDisabledUser] = useState('');
  const [searchParams] = useSearchParams();
  const presenter_id = searchParams.get('p');
  const host_id = searchParams.get('h');

  const handleGuest = e => {
    e.preventDefault();
    dispatch(setPage('Guest'));
    dispatch(setBackButtonShow(true));
  };

  const handleLogin = e => {
    e.preventDefault();
    dispatch(setPage('Login'));
    dispatch(setPreviousRouteStep('RecentUser'));
  };

  const deleteUser = (e, index) => {
    e.preventDefault();
    const deleteItem = recentUsers.filter((item, indx) => indx !== index);
    dispatch(setRecentUsers(deleteItem));
    deleteItem.length === 0 && dispatch(setPage('Guest'));
  };

  const loginValidate = item => {
    setDisabledUser(item?.token);
    let detail = item;
    if (detail?.email && (detail.role === 'guest' && detail.sub_role !== 'presenter')) {
      swal({
        title: `Welcome ${detail.email}. You are a GUEST in this room.`,
        closeOnClickOutside: false,
        buttons: ['Log in as Someone Else', 'Continue'],
        dangerMode: true,
      }).then(Continue => {
        if (Continue) {
          dispatch(setUserDetail(item));
          if (item.virtual_background) {
            dispatch(setVirtualBackgroundOptions(item.virtual_background));
          }

          if (roomDetail?.pin_required) {
            dispatch(setPage('Passcode'));
          } else {
            dispatch(setPage('Connect'));
          }
          dispatch(setPreviousRouteStep('RecentUser'));
          setDisabledUser('');
        } else {
          dispatch(setPage('Login'));
          dispatch(setPreviousRouteStep('RecentUser'));
          setDisabledUser('');
        }
      });
    } else {
      dispatch(setUserDetail(item));
      if (item.virtual_background) {
        dispatch(setVirtualBackgroundOptions(item.virtual_background));
      }
      console.log('pin_required=>', roomDetail?.pin_required);
      if ((detail.role === 'guest' && detail.sub_role !== 'presenter') && roomDetail?.pin_required) {
        dispatch(setPage('Passcode'));
      } else {
        dispatch(setPage('Connect'));
      }
      dispatch(setPreviousRouteStep('RecentUser'));
      setDisabledUser('');
    }
  };
  return (
    <div
      className={`${recentUsers.length >= 3 ? 'col-md-8 col-8' : 'col-md-6 col-6 '
        } light-bg text-center`}>
      <h2>
        To Join the Meetn
        <br />
        Select Your Identity
      </h2>
      <div className="row justify-center joinprowrap mt-3">
        {recentUsers?.map((item, index) => {
          return (
            <div className="joinwrap" key={index}>
              <i className="fa fa-close" onClick={e => deleteUser(e, index)} />
              <div className="imgwrap border5-white rounded-circle mb-15">
                <a
                  className="recent-img-tag"
                  onClick={() => loginValidate(item)}>
                  {item?.profile_image ? (
                    <img
                      src={item?.profile_image}
                      className="img-fluid"
                      alt=""
                    />
                  ) : (
                    <img
                      src="assets/images/img5.png"
                      className="img-fluid"
                      alt=""
                    />
                  )}
                </a>
              </div>
              <div className="form-group buttonsubmit cookies-text">
                <button
                  type="submit"
                  className="btn btn-green btn-mini"
                  disabled={disabledUser === item.token ? true : false}
                  onClick={() => loginValidate(item)}>
                  <small className="mb-0 text-white">Continue as</small>
                  <span> {item.name}</span>
                  <small className="mb-0 text-white">
                    (
                    {item.role === 'guest' && item.sub_role === 'presenter'
                      ? Capitalize(item.sub_role)
                      : Capitalize(item.role)}
                    )
                  </small>
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <h5 id="progress" />
      <div className="row justify-center mt-3 continueBtn">
        <a>
          <button
            type="submit"
            className="btn btn-green m-0"
            onClick={handleGuest}>
            Continue as someone else
          </button>
        </a>
      </div>
      {!presenter_id && !host_id && (
        <div class="row justify-center mt-3 loginLink">
          <button class="btn btn-orange ml-0" onClick={handleLogin}>
            Hosts & Speakers, Log In
          </button>
        </div>
      )}
    </div>
  );
};
export default RecentUser;
