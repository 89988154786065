import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setPage} from '../../../reduxStore/confSlice';
import {CommonModal} from '../../common/web';

const HostRequired = () => {
  const dispatch = useDispatch();
  const {hostRequiredModal} = useSelector(state => state.conf);

  const manageRedirect = e => {
    e.preventDefault();
    window.location.href = '/';
  };

  const handleLogin = e => {
    e.preventDefault();
    dispatch(setPage('Login'));
  };

  const hostRequiredBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12 text-center modal_min_height--sm d-flex flex-column justify-content-center">
            <h4 className="mb-2">
              The meetn will start when a host arrives. Please stand by.
            </h4>
            <div className="form-group">
              <small>
                If you are the host, please&nbsp;
                <a href="#" onClick={handleLogin}>
                  &nbsp;log in
                </a>
              </small>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-link btn-mini float-right"
                onClick={manageRedirect}>
                {' '}
                Exit Meetn
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const hostRequiredObject = {
    body: hostRequiredBody,
    show: true,
    classNameDialog: 'modal-width-sm',
  };
  return (
    <>
      {hostRequiredModal === true && <CommonModal {...hostRequiredObject} />};
    </>
  );
};

export default HostRequired;
