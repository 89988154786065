import React, {useEffect, useState} from 'react';

const CustomPagination = props => {
  let {totalRecords, items, pageSize, currentPage, onChangePage} = props;
  const [initialPage, setInitialPage] = useState(1);
  const [pager, setPager] = useState({});
  console.log('CustomPagination');

  const setPage = (page, nextPage = false) => {
    if (page < 1 || page > pager?.totalPages) {
      return;
    }

    let pagerData = getPager(totalRecords, page, pageSize);

    const pageOfItems = items;

    setPager(pagerData);

    if (nextPage) {
      onChangePage(pageOfItems, page);
    }
  };

  const getPager = (totalItems, currentPage, pageSize) => {
    currentPage = currentPage || 1;

    pageSize = pageSize || 10;

    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage, endPage;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 3;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    let pages = [...Array(endPage + 1 - startPage).keys()].map(
      i => startPage + i,
    );

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  };

  useEffect(() => {
    if (items && items.length) {
      setPage(initialPage);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPage(currentPage);
  }, [totalRecords, currentPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  // if (!pager.pages || pager.pages.length <= 1) {
  //   return null;
  // }

  console.log('pager', pager);

  return (
    <>
      {pager?.pages && pager.pages.length > 1 && (
        <ul className="pagination">
          <li className={pager.currentPage === 1 ? 'disabled' : ''}>
            <a className="pagination-arrow" onClick={() => setPage(1, true)}>
              &#8249;
            </a>
          </li>
          <li className={pager.currentPage === 1 ? 'disabled' : ''}>
            <a
              className="pagination-arrow"
              onClick={() => setPage(pager.currentPage - 1, true)}>
              &#8249;&#8249;
            </a>
          </li>
          {pager.pages.map((page, index) => (
            <li
              key={index}
              className={pager.currentPage === page ? 'active' : ''}>
              <a onClick={() => setPage(page, true)}>{page}</a>
            </li>
          ))}
          <li
            className={
              pager.currentPage === pager.totalPages ? 'disabled' : ''
            }>
            <a
              className="pagination-arrow"
              onClick={() => setPage(pager.currentPage + 1, true)}>
              &#8250;&#8250;
            </a>
          </li>
          <li
            className={
              pager.currentPage === pager.totalPages ? 'disabled' : ''
            }>
            <a
              className="pagination-arrow"
              onClick={() => setPage(pager.totalPages, true)}>
              &#8250;
            </a>
          </li>
        </ul>
      )}
    </>
  );
};

export default CustomPagination;
