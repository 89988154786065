import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// import {
//   copyChat,
//   deleteMessage,
//   featureHandler,
//   manageAttendeesSettings,
//   _sendChat,
// } from '../../../utils/functions';
import {
  setAttendeesClick,
  setChatClick,
  setUserDetail,
  // setUnreadMessageCount,
} from '../../../reduxStore/confSlice';
import {
  blockUsers,
  deleteChatMessage,
  featureChatMessage,
  individualAttendeeAction,
  remParticipant,
  sendChatMessage,
  unblockUsers,
  unfeatureChatMessage,
  updParticipant,
  updateProfile,
} from '../../../utils/socket';
import {copyChat} from '../../../utils/functions';
import {toast} from 'react-toastify';
import {updateUserSettings} from './utils/functions';
import Linkify from 'react-linkify';

const Chat = () => {
  const {
    chatMessage,
    localUser,
    roomDetail,
    featuredMsg,
    chatClick,
    unreadMessageCount,
    participantsList,
    chatBlockUsers,
  } = useSelector(state => state.conf);
  const [message, setMessage] = useState('');
  const [onMouseOverMsg, setOnMouseOverMsg] = useState('');
  const {token_unique, role, name, auto_appear_chat, profile_image} = localUser;
  console.log('localUser', localUser);
  console.log('chatBlockUsers', chatBlockUsers);
  const {hide_chat_for_guests, room, guests_have_extra_powers} = roomDetail;
  const dispatch = useDispatch();
  const lastElement = useRef(null);
  const haveExtraPermission =
    role === 'owner' ||
    role === 'admin' ||
    role === 'host' ||
    guests_have_extra_powers === true;
  const isElectron = window.navigator.userAgent.match(/(Electron)/);

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" className="">
      {text}
    </a>
  );

  const handleKeyPress = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      if (message !== '') {
        const trimmed = message.trim();
        if (trimmed) {
          let isBlocked = chatBlockUsers?.includes(token_unique);
          const payload = {
            room: room,
            token: token_unique,
            message: message,
            hide_chat_for_guests,
            name: name,
            blocked: isBlocked,
            profile_image: profile_image,
          };
          sendChatMessage(payload).then(res => {
            setMessage('');
          });
        }
      }
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (hide_chat_for_guests && localUser.role === 'guest') {
      dispatch(setChatClick(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomDetail]);

  const handleChat = e => {
    e.preventDefault();
    dispatch(setChatClick(!chatClick));
    dispatch(setAttendeesClick(false));
  };

  const msgFeature = msg => {
    featureChatMessage(msg).then(() => {});
  };

  const msgUnFeature = msgId => {
    const payload = {
      room,
      id: msgId,
    };
    unfeatureChatMessage(payload).then(() => {});
  };
  const deleteMsg = msgId => {
    if (msgId === featuredMsg?.id) {
      msgUnFeature(msgId);
    }
    const payload = {
      room,
      id: msgId,
    };
    deleteChatMessage(payload).then(() => {});
  };

  const scrollToBottom = () => {
    lastElement.current?.scrollIntoView({behavior: 'smooth'});
    const el = document.getElementById('chat-feed');
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  };

  useEffect(() => {
    console.log('useffect');
    if (unreadMessageCount === 0) {
      scrollToBottom();
    }
  }, [unreadMessageCount, chatMessage]);

  const autoAppearChatHandler = () => {
    updateUserSettings('auto_appear_chat');
  };

  const deleteMsgAndKickOutUser = (msgId, userToken) => {
    const tmpUser = {...participantsList[userToken]};
    console.log('tmpUser', tmpUser);
    individualAttendeeAction({
      room: roomDetail?.room,
      token_unique: localUser?.token_unique,
      attendee_token_unique: tmpUser.token_unique,
      action: 'kicked-out',
    });
    deleteMsg(msgId);
  };

  const blockAndUnBlockUser = (isBlocked, user_token_unique) => {
    const payload = {
      room,
      token_unique: user_token_unique,
    };
    if (!isBlocked) {
      blockUsers(payload);
    } else {
      unblockUsers(payload);
    }
  };

  return (
    <>
      <div
        className={`tab-pane ${chatClick ? 'active' : ''}`}
        id="chat"
        role="tabpanel"
        aria-labelledby="chat-tab">
        <div className="chat-header">
          <div className="dropdown chat-dropdown ml-auto mr-auto">
            <h5
              className="semiBold font16 mb-0 dropdown-toggle"
              id="chat-tab"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <span className="webpage-title">&nbsp; Chat</span>
            </h5>
            <div className="dropdown-menu" aria-labelledby="chat-tab">
              <ul>
                <li>
                  <a onClick={e => autoAppearChatHandler()}>
                    {auto_appear_chat === true && (
                      <i className="fas fa-check text-green" />
                    )}
                    Auto-appear
                  </a>
                </li>
                {isElectron === null && (
                  <li>
                    <a onClick={() => copyChat()}>Copy to Clipboard</a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          {auto_appear_chat ? (
            <div className="close_dropdown chat-dropdown">
              <div
                className="chat-close text-grey float-right chatdeactive dropdown-toggle"
                data-toggle="dropdown">
                <svg width=".7em" viewBox="6.29 6.34 11.31 11.31">
                  <path
                    fill="#93a5ad"
                    d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                </svg>
              </div>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton">
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  onClick={e => handleChat(e)}>
                  Hide Chat Sidebar
                </a>
                <a
                  className="dropdown-item"
                  href="javascript:void(0)"
                  onClick={e => autoAppearChatHandler(e)}>
                  Disable Auto-Appear
                </a>
              </div>
            </div>
          ) : (
            <div
              className="chat-close text-grey float-right chatdeactive"
              onClick={handleChat}>
              <svg width=".7em" viewBox="6.29 6.34 11.31 11.31">
                <path
                  fill="#93a5ad"
                  d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
                />
              </svg>
            </div>
          )}
        </div>
        <div id="chatconversation">
          {localUser.role !== 'guest' && hide_chat_for_guests && (
            <span className="host-only-text">Chat for Hosts+ Only</span>
          )}
          {chatMessage.map((chatItem, index) => {
            const {hide_chat_for_guests, blocked} = chatItem;
            if (hide_chat_for_guests && localUser.role === 'guest') {
              return false;
            }
            if (blocked && chatItem.token !== token_unique) {
              return false;
            }
            let isBlocked = chatBlockUsers?.includes(chatItem.token);
            console.log('isBlocked', isBlocked);
            return (
              <div
                className={`chat-message-group ${
                  chatItem.token === token_unique ? 'local' : 'remote'
                } `}
                key={index}
                onMouseEnter={() =>
                  haveExtraPermission ? setOnMouseOverMsg(chatItem.id) : ''
                }
                onMouseLeave={() => setOnMouseOverMsg('')}>
                <div className="chatmessage-wrapper">
                  <div className="chatmessage">
                    <div className="replywrapper">
                      {chatItem.id === onMouseOverMsg && (
                        <div
                          className={`icons_group d-flex ${
                            chatItem.token === token_unique
                              ? 'icons_group_local'
                              : 'icons_group_remote'
                          }`}>
                          <div
                            className={`btn btn-xs ${
                              featuredMsg?.id === chatItem.id
                                ? 'bg-white text-green'
                                : 'btn-green'
                            }`}
                            onClick={() =>
                              featuredMsg?.id === chatItem.id
                                ? msgUnFeature(chatItem.id)
                                : msgFeature(chatItem)
                            }>
                            {featuredMsg?.id === chatItem.id
                              ? 'Unfeature'
                              : 'Feature'}
                          </div>
                          <div
                            className="btn btn-xs btn-warning"
                            onClick={() => deleteMsg(chatItem.id)}>
                            Delete
                          </div>
                          {chatItem.token !== token_unique && (
                            <>
                              <div
                                className="btn btn-xs btn-warning"
                                onClick={() =>
                                  deleteMsgAndKickOutUser(
                                    chatItem.id,
                                    chatItem.token,
                                  )
                                }>
                                Delete + Kick
                              </div>
                              <div
                                className="btn btn-xs btn-warning"
                                onClick={() =>
                                  blockAndUnBlockUser(isBlocked, chatItem.token)
                                }>
                                {isBlocked ? 'Unblock' : 'Block'}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      <div className="messagecontent">
                        <div className="display-name">{chatItem?.name}</div>
                        <Linkify componentDecorator={componentDecorator}>
                          <div
                            className={`usermessage  ${
                              chatItem.id === onMouseOverMsg
                                ? 'bg-light-blue text-dark'
                                : ''
                            } `}>
                            {chatItem.message}
                          </div>
                        </Linkify>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div ref={lastElement} />
        </div>
        <div id="chat-input">
          <div className="usrmsg-form">
            <textarea
              spellCheck="false"
              id="usermsg"
              autoComplete="off"
              className="form-control"
              placeholder="Type a message"
              maxLength={1000}
              value={message}
              onChange={e => setMessage(e.target.value)}
              onKeyDown={e => handleKeyPress(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(Chat);
