import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {
  setGuestsUnmuteLoader,
  setMuteAllLoader,
  setMutedAttendeesLoader,
  setOpenLoginModel,
  setRoomDetails,
  setUnmuteAllLoader,
} from '../../../../reduxStore/confSlice';
import {multipleAttendeeAction, roomSettings} from '../../../../utils/socket';
import {toast} from 'react-toastify';

function AttendeesButton() {
  const {
    localUser,
    muteAllLoader,
    unmuteAllLoader,
    roomDetail,
    guestsUnmuteLoader,
    mutedAttendeesLoader,
  } = useSelector(state => state.conf);

  const dispatch = useDispatch();

  const handleMuteAll = e => {
    e.preventDefault();
    if (localUser?.role !== 'guest') {
      dispatch(setMuteAllLoader(true));
      multipleAttendeeAction({
        room: roomDetail?.room,
        token_unique: localUser?.token_unique,
        action: 'mute-all',
      }).then(res => {
        if (res.status !== 200) {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        dispatch(setMuteAllLoader(false));
      });
    } else {
      dispatch(setOpenLoginModel(true));
    }
  };

  const handleUnmuteAll = e => {
    e.preventDefault();
    console.log('handleUnmuteAll enter');
    if (localUser?.role !== 'guest') {
      dispatch(setUnmuteAllLoader(true));
      multipleAttendeeAction({
        room: roomDetail?.room,
        token_unique: localUser?.token_unique,
        action: 'unmute-all',
      }).then(res => {
        console.log('handleUnmuteAll res', res);
        if (res.status !== 200) {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        dispatch(setUnmuteAllLoader(false));
      });
    } else {
      dispatch(setOpenLoginModel(true));
    }
  };

  const handleGuestsCanUnmute = e => {
    e.preventDefault();
    dispatch(setGuestsUnmuteLoader(true));
    let doc = {...roomDetail};
    doc.guests_can_unmute_themselves = !doc.guests_can_unmute_themselves;
    roomSettings(doc).then(res => {
      if (res.status === 200) {
        dispatch(setRoomDetails(doc));
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      dispatch(setGuestsUnmuteLoader(false));
    });
  };

  const handleHideMutedAttendees = e => {
    e.preventDefault();
    dispatch(setMutedAttendeesLoader(true));
    let doc = {...roomDetail};
    doc.hide_muted_attendees = !doc.hide_muted_attendees;
    roomSettings(doc).then(res => {
      if (res.status === 200) {
        dispatch(setRoomDetails(doc));
      } else {
        toast.error(res.data[0].msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      dispatch(setMutedAttendeesLoader(false));
    });
  };
  return (
    <div className="mt-1 mb-0 attendees_controls">
      <div className="attendees_controls__block">
        <label className="attendees_controls__label">Guests</label>
        <div className="attendees_controls__button">
          <button
            className={`btn ${
              muteAllLoader ? 'btn-gray' : 'btn-green'
            } btn-mini`}
            onClick={e =>
              localUser.role !== 'guest'
                ? handleMuteAll(e)
                : dispatch(setOpenLoginModel(true))
            }>
            {muteAllLoader ? <div className="spinner" /> : 'Mute'}
          </button>
          <button
            className={`btn ${
              unmuteAllLoader ? 'btn-gray' : 'btn-green'
            } btn-mini btn-unmute`}
            onClick={e =>
              localUser.role !== 'guest'
                ? handleUnmuteAll(e)
                : dispatch(setOpenLoginModel(true))
            }>
            {unmuteAllLoader ? <div className="spinner" /> : 'Unmute'}
          </button>
        </div>
      </div>
      <div className="attendees_controls__block">
        <label className="attendees_controls__label">Guests Can Unmute</label>
        <div className="attendees_controls__button">
          <label className="switch toggle__switch">
            <input
              spellCheck="false"
              type="checkbox"
              name="guests_can_unmute_themselves"
              checked={!roomDetail?.guests_can_unmute_themselves}
              onChange={e => {
                localUser.role !== 'guest'
                  ? handleGuestsCanUnmute(e, roomDetail)
                  : dispatch(setOpenLoginModel(true));
              }}
              disabled={guestsUnmuteLoader}
            />
            <span className="slider round" />
            <span
              className={`toggle_switch_text ${
                guestsUnmuteLoader ? 'btn-gray' : ''
              }`}>
              {guestsUnmuteLoader ? (
                <div className="spinner" />
              ) : (
                <>
                  <span className="toggle_switch_text_span">Yes</span>
                  <span className="toggle_switch_text_span">No</span>
                </>
              )}
            </span>
          </label>
        </div>
      </div>
      <div className="attendees_controls__block">
        <label className="attendees_controls__label">Muted Attendees</label>
        <div className="attendees_controls__button">
          <label className="switch toggle__switch">
            <input
              spellCheck="false"
              type="checkbox"
              checked={roomDetail?.hide_muted_attendees}
              name="hide_muted_attendees"
              onChange={e => {
                localUser.role !== 'guest'
                  ? handleHideMutedAttendees(e, roomDetail)
                  : dispatch(setOpenLoginModel(true));
              }}
              disabled={mutedAttendeesLoader}
            />
            <span className="slider round" />
            <span
              className={`toggle_switch_text ${
                mutedAttendeesLoader ? 'btn-gray' : ''
              }`}>
              {mutedAttendeesLoader ? (
                <div className="spinner" />
              ) : (
                <>
                  <span className="toggle_switch_text_span">Show</span>
                  <span className="toggle_switch_text_span">Hide</span>
                </>
              )}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default AttendeesButton;
