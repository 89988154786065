import React from 'react';

import {CommonModal} from '../../../../common/web';
import {useSelector} from 'react-redux';
import {useRef} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import {useState} from 'react';
import {addRoomPermission} from '../../../../../utils/socket';
import {toast} from 'react-toastify';
import {DEFAULT_LAODING_MSG} from '../../../../../utils/util';

const AddHostModal = ({
  closeModal,
  userListForRoomPermission,
  setUserListForRoomPermission,
  roomDetail,
}) => {
  const [state, setState] = useState({email: '', role: 'host'});
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loader, setLoader] = useState(false);
  const {room} = roomDetail;

  const saveSetting = async e => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      const {email, role} = state;
      setLoader(true);
      addRoomPermission({room, email, role}).then(res => {
        console.log('addRoomPermission=>', res);
        if (res.status === 200) {
          const updatedUserListForRoomPermission = [
            ...userListForRoomPermission,
          ];
          const tempIndex = userListForRoomPermission.findIndex(
            item => item.token === res?.data?.token,
          );
          if (tempIndex !== -1) {
            updatedUserListForRoomPermission[tempIndex] = res?.data;
          } else {
            updatedUserListForRoomPermission.push(res.data);
          }

          setUserListForRoomPermission(updatedUserListForRoomPermission);
          closeModal();
        } else {
          toast.error(res.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoader(false);
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const addHostBody = () => {
    return (
      <>
        <form className="row" name="settingInviteModelForm">
          <div className="col-md-12 modal_min_height">
            <div className="form-group">
              <label className="">Email</label>
              <input
                spellCheck="false"
                type="email"
                name="email"
                maxLength="100"
                className="form-control"
                onChange={e => setState({...state, email: e.target.value})}
              />

              {simpleValidator.current.message(
                'email',
                state.email,
                'required|email',
                {
                  messages: {
                    default: 'Email field is required.',
                    email: 'Email must be a valid email address.',
                  },
                },
              )}
            </div>
            <div className="form-group d-flex flex-column">
              <label>Access Level</label>
              <div>
                <label className="radiowrap float-left">
                  Host
                  <input
                    spellCheck="false"
                    type="radio"
                    name="access"
                    value="host"
                    checked={state.role === 'host'}
                    onChange={e => setState({...state, role: e.target.value})}
                  />
                  <span className="checkmark" />
                </label>
                <label className="radiowrap float-left">
                  Admin
                  <input
                    spellCheck="false"
                    type="radio"
                    name="access"
                    value="admin"
                    checked={state.role === 'admin'}
                    onChange={e => setState({...state, role: e.target.value})}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className="form-group mt-2">
              <ul className="li-text mb-0">
                <li>
                  HOSTS can mute/unmute people, start/stop recordings, and
                  adjust webinar settings.
                </li>
                <li>
                  ADMINS can also schedule events, access past events, and
                  manage replays.
                </li>
                <li>
                  OWNERS can also add/remove/upgrade rooms and update billing
                  info.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-12 mt-3 buttonwrap text-right">
            <button
              type="button"
              data-toggle="modal"
              onClick={() => {
                closeModal();
              }}
              className="btn-link">
              Cancel
            </button>
            <button
              type="submit"
              disabled={loader}
              className="btn btn-green btn-mini abso-btn float-right"
              onClick={e => saveSetting(e)}>
              {loader ? DEFAULT_LAODING_MSG : 'Save'}
              <i className="icon-arrow-right-circle icons" />
            </button>
          </div>
        </form>
      </>
    );
  };
  const addHostModalObject = {
    heading: 'Add Host / Admin',
    body: addHostBody,
    classNameModal: 'fade midmodalwrap',
    show: true,
    headingSize: 'h5',
    classNameDialog: 'midmodal',
    classNameTitle: 'ng-binding',
  };

  return <CommonModal {...addHostModalObject} />;
};

export default AddHostModal;
