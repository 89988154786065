import React from 'react';

import {CommonModal} from '../../../common/web';

const AlertMeetingModal = ({handleCloseAlert, cancelMeeting}) => {
  const alertMeetingBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12 text-center p-0 modal_min_height--sm">
            <p className="mb-1 semiBold pt-4 mt-3">Alert!</p>
            <p className="col-md-12 font13 mb-0">
              Are you sure you want to cancel this meeting?
            </p>
          </div>
          <div className="col-md-12 text-right mt-3">
            <a
              className="btn-link btn-mini"
              data-toggle="modal"
              onClick={() => handleCloseAlert()}>
              No
            </a>
            <a
              className="btn-green btn-mini float-right"
              onClick={e => cancelMeeting(e)}>
              Yes
            </a>
          </div>
        </div>
      </>
    );
  };

  const alertMeetingModalObject = {
    body: alertMeetingBody,
    classNameModal: 'fade smallmodalwrap',
    show: true,
    headingSize: 'h5',
    classNameDialog: 'smallmodal',
    classNameBody: 'flexdiv pl-0 pr-0',
    closeModal: handleCloseAlert,
  };

  return <CommonModal {...alertMeetingModalObject} />;
};

export default AlertMeetingModal;
