import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {setAVPageRefresh} from '../../../../reduxStore/persistSlice';

import {CommonModal} from '../../../common/web';
import {setShowAvSettingModal} from '../../../../reduxStore/confSlice';

import {
  setMicDevice,
  setCameraDevice,
  setSpeakerDevice,
  sethasAudioEnable,
  sethasVideoEnable,
  setHasAudioPermission,
  setHasVideoPermission,
  setMediaPermissionPrompt,
  setNoiseSuppression,
  setUserDetail,
} from '../../../../reduxStore/confSlice';
import {
  replaceLocalVideoTrack,
  replaceLocalAudioTrack,
  createVideoTracks,
  createAudioTracks,
  toggleBackgroundEffect,
  localVideoTrackAdd,
} from '../../../../libs';
import VirtualBackgroundModal from './virtual-background/VirtualBackgroundModal';
import VideoInputPreview from '../../../device-selection/videoInputPreview';
import AudioInputPreview from '../../../device-selection/audioInputPreview';
import {setMirrorView} from '../utils/functions';
import {updParticipant} from '../../../../utils/socket';

import {
  setPersistMicDevice,
  setPersistCameraDevice,
} from '../../../../reduxStore/persistSlice';
var localAudioTrack = null;
var player = null;

const AvSettingModal = () => {
  const store = useStore();
  const [virtualBackground, showVirtualBackgroundModal] = useState(false);

  const [videoPreviewTracks, setVideoPreviewTracks] = useState(null);
  const [audioTracks, setAudioTracks] = useState(null);
  const {
    noiseSuppression,
    audioInputList,
    videoInputList,
    audioOutputList,
    hasAudioPermission,
    hasVideoPermission,
    room,
    cameraDevice,
    micDevice,
    speakerDevice,
    localUser,
    virtualBackgroundOptions,
  } = useSelector(state => state.conf);
  const {persistmicDevice, persistcameraDevice} = useSelector(
    state => state.persist,
  );
  const [mic, setMic] = useState(null);
  const [noiceFilter, setNoiceFilter] = useState(null);
  const [speaker, setSpeaker] = useState(null);
  const [camera, setCamera] = useState(null);
  const [playAudioBtnTxt, setPlayAudioBtnTxt] = useState('Play a test sound');
  const {mirrorSelfView} = useSelector(state => state.persist);
  const [camerFailed, setCamerFailed] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(virtualBackgroundOptions).length > 0) {
      toggleBackgroundEffect(
        virtualBackgroundOptions,
        videoPreviewTracks?.track,
      );
    }
  }, [virtualBackgroundOptions, videoPreviewTracks]);

  const closeModal = () => {
    const oldTrack = store.getState().conf.localVideoTrack;
    if (videoPreviewTracks?.track) {
      const trackData = {
        track: videoPreviewTracks.track,
        device: camera,
      };

      if (camera && camera?.deviceId !== cameraDevice.deviceId) {
        // if (localUser?.video) {
        replaceLocalVideoTrack(
          oldTrack?.jitsiTrack,
          trackData.track,
          oldTrack?.jitsiTrack,
        ).then(() => {
          localVideoTrackAdd(trackData);
          dispatch(setCameraDevice(camera));
          dispatch(setPersistCameraDevice(camera));
        });
        const tmpUser = {...localUser};
        tmpUser.video = true;
        dispatch(setUserDetail(tmpUser));
        updParticipant(tmpUser);
        // } else {
        //   dispatch(setCameraDevice(camera));
        //   dispatch(setPersistCameraDevice(camera));

        //   if (videoPreviewTracks) {
        //     videoPreviewTracks.track.dispose();
        //   }
        // }
        setVideoPreviewTracks(null);
      } else {
        if (Object.keys(virtualBackgroundOptions).length > 0 && oldTrack) {
          toggleBackgroundEffect(virtualBackgroundOptions, oldTrack.jitsiTrack);
        }
        if (videoPreviewTracks) {
          videoPreviewTracks.track.dispose();
        }
        setVideoPreviewTracks(null);
      }
    }
    if (mic && mic?.deviceId !== micDevice?.deviceId) {
      if (localUser?.audio) {
        dispatch(sethasAudioEnable(true));
      } else {
        dispatch(sethasAudioEnable(false));
      }
      dispatch(setMicDevice(mic));
      dispatch(setPersistMicDevice(mic));
    }
    if (localAudioTrack) {
      localAudioTrack.track.dispose();
    }
    localAudioTrack = null;
    if (speaker && speaker?.deviceId !== speakerDevice) {
      dispatch(setSpeakerDevice(speaker));
    }
    dispatch(setShowAvSettingModal(false));
  };
  useEffect(() => {
    if (micDevice) {
      let tmpmicDevice = micDevice;
      if (persistmicDevice) {
        const device = audioInputList.filter(
          device =>
            device.deviceId === persistmicDevice.deviceId &&
            device.label === persistmicDevice.label,
        );
        if (device.length > 0) {
          tmpmicDevice = persistmicDevice;
        }
      }
      showMic(tmpmicDevice);
    } else {
      setMic(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [micDevice]);
  useEffect(() => {
    if (cameraDevice) {
      let tmpcameraDevice = cameraDevice;
      if (persistcameraDevice) {
        const device = videoInputList.filter(
          d =>
            d.deviceId === persistcameraDevice.deviceId &&
            d.label === persistcameraDevice.label,
        );
        if (device.length > 0) {
          tmpcameraDevice = persistcameraDevice;
        }
      }
      console.log('cameraDevice', videoPreviewTracks);
      if (videoPreviewTracks?.device?.deviceId === tmpcameraDevice.deviceId) {
        console.log('Current Video track found in list');
      } else {
        setDefaultVideo(tmpcameraDevice);
      }
    } else {
      setCamera(null);
      setCamerFailed(true);
      disposeLocalVideoTrack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraDevice]);

  useEffect(() => {
    if (speakerDevice) {
      setSpeaker(speakerDevice);
    }
  }, [speakerDevice]);

  useEffect(() => {
    player = document.getElementById('audioElement');
    player.addEventListener('ended', function () {
      player.currentTime = 0;
      setPlayAudioBtnTxt('Play a test sound');
    });
  }, []);

  const setDefaultVideo = device => {
    disposeLocalVideoTrack().then(() => {
      createVideoTracks(device.deviceId)
        .then(track => {
          setVideoPreviewTracks({track: track[0], device: device});
          const videodevice = videoInputList.filter(device => {
            return device.deviceId === track[0].deviceId;
          });
          // dispatch(setCameraDevice(track[0].deviceId));
          // dispatch(sethasVideoEnable(true));
          setCamera(videodevice[0]);
          setCamerFailed(false);
          dispatch(setHasVideoPermission(true));
        })
        .catch(error => {
          if (error.name === 'gum.not_found') {
            dispatch(setHasVideoPermission(true));
          }
          setCamerFailed(true);

          setCamera(device);
          setVideoPreviewTracks(null);
          // dispatch(setCameraDevice(null));
          // dispatch(sethasVideoEnable(false));
        });
    });
  };
  const playAudio = () => {
    let speakerId = speaker?.deviceId;
    if (speakerId !== null && speakerId !== undefined) {
      player.setSinkId(speakerId);
    }
    setPlayAudioBtnTxt('A sound is playing...');
    player.play();
  };
  const showMic = device => {
    disposeLocalAudioTrack().then(() => {
      createAudioTracks(device.deviceId)
        .then(track => {
          dispatch(setMediaPermissionPrompt(false));
          localAudioTrack = {track: track[0], device: device};
          setMic(device);
          setAudioTracks(track[0]);
        })
        .catch(error => {
          if (error.name === 'gum.not_found') {
            dispatch(setHasAudioPermission(true));
          }
        });
    });
  };
  const disposeLocalAudioTrack = () => {
    return new Promise(async resolve => {
      if (localAudioTrack) {
        await localAudioTrack.track.dispose();
        resolve();
      } else {
        resolve();
      }
    });
  };
  const disposeLocalVideoTrack = () => {
    return new Promise(async resolve => {
      if (videoPreviewTracks) {
        await videoPreviewTracks.track.dispose();
        setVideoPreviewTracks(null);
        resolve();
      } else {
        resolve();
      }
    });
  };

  const selectMic = micDevideId => {
    const device = audioInputList.filter(d => d.deviceId === micDevideId);

    if (device.length > 0) {
      setMic(device[0]);
      showMic(device[0]);
    }
  };
  const selectCamera = cameraDeviceId => {
    const device = videoInputList.filter(d => d.deviceId === cameraDeviceId);
    if (device.length > 0) {
      setCamera(device[0]);
      setDefaultVideo(device[0]);
    }
  };
  const selectSpeaker = speakerDevideId => {
    const device = audioOutputList.filter(d => d.deviceId === speakerDevideId);
    if (device.length > 0) {
      setSpeaker(device[0]);
      player.setSinkId(device[0].deviceId);
    }
  };

  const refreshPage = e => {
    e.preventDefault();
    const data = {
      time: new Date().getTime(),
      room: room,
      localUser: localUser,
    };
    dispatch(setAVPageRefresh(data));
    window.location.reload();
  };

  const toggleNoiseSuppression = e => {
    e.preventDefault();
    dispatch(setNoiseSuppression(e.target.checked));
  };
  useEffect(() => {
    setNoiceFilter(noiseSuppression);
  }, [noiseSuppression]);

  const avSettingBody = () => {
    return (
      <>
        <div className="col-md-12 col-12  av-pop">
          <div className="border-container">
            <div className="pl-0 pr-0">
              <div className="row">
                <div className="col-md-8 col-8">
                  <div className="form-group-container mb-0">
                    <div className="form-group">
                      <label>Microphone</label>
                      <div className="dropdown">
                        {audioInputList.length > 0 && hasAudioPermission ? (
                          <a
                            className="form-control"
                            id="avdrop"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            role="button">
                            <span>
                              {hasAudioPermission
                                ? mic
                                  ? mic?.label
                                  : 'No device found'
                                : 'Permission not granted'}
                            </span>
                            <svg
                              width="1.957em"
                              viewBox="0 0 24 24"
                              focusable="false"
                              role="presentation">
                              <path
                                d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                                fill="currentColor"
                                fillRule="evenodd"
                              />
                            </svg>
                          </a>
                        ) : (
                          <a
                            className="form-control"
                            id="avdrop"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            role="button">
                            <span>
                              {hasAudioPermission
                                ? mic
                                  ? mic?.label
                                  : 'No device found'
                                : 'Permission not granted'}
                            </span>
                          </a>
                        )}

                        <div className="dropdown-menu" aria-labelledby="avdrop">
                          <ul>
                            {audioInputList.map(device => {
                              return (
                                <li key={device.deviceId}>
                                  <a onClick={() => selectMic(device.deviceId)}>
                                    {device.label}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Speaker</label>
                      <div className="dropdown">
                        {audioOutputList.length > 0 ? (
                          <a
                            className="form-control"
                            id="avdrop"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            role="button"
                            href="#">
                            <span>
                              {hasAudioPermission
                                ? speaker
                                  ? speaker?.label
                                  : 'Default Speaker'
                                : 'Default Speaker'}
                            </span>
                            <svg
                              width="1.957em"
                              viewBox="0 0 24 24"
                              focusable="false"
                              role="presentation">
                              <path
                                d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                                fill="currentColor"
                                fillRule="evenodd"
                              />
                            </svg>
                          </a>
                        ) : (
                          <a
                            className="form-control"
                            id="avdrop"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            role="button"
                            href="#">
                            <span>Default Speaker</span>
                          </a>
                        )}
                        <div className="dropdown-menu" aria-labelledby="avdrop">
                          <ul>
                            {audioOutputList.map(device => {
                              return (
                                <li key={device.deviceId}>
                                  <a
                                    onClick={() =>
                                      selectSpeaker(device.deviceId)
                                    }>
                                    {device.label}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Camera</label>
                      <div className="dropdown">
                        {videoInputList.length > 0 && hasVideoPermission ? (
                          <a
                            className="form-control"
                            id="vdrop"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            role="button">
                            <span>
                              {hasVideoPermission
                                ? camera
                                  ? camera?.label
                                  : 'Select a device'
                                : 'Permission not granted'}
                            </span>
                            <svg
                              width="1.957em"
                              viewBox="0 0 24 24"
                              focusable="false"
                              role="presentation">
                              <path
                                d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                                fill="currentColor"
                                fillRule="evenodd"
                              />
                            </svg>
                          </a>
                        ) : (
                          <a
                            className="form-control"
                            id="vdrop"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            role="button">
                            <span>
                              {hasVideoPermission
                                ? camera
                                  ? camera?.label
                                  : 'No device found'
                                : 'Permission not granted'}
                            </span>
                          </a>
                        )}
                        <div className="dropdown-menu" aria-labelledby="vdrop">
                          <ul>
                            {videoInputList.map(device => {
                              return (
                                <li key={device.deviceId}>
                                  <a
                                    onClick={() =>
                                      selectCamera(device.deviceId)
                                    }>
                                    {device.label}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div class="d-flex align-items-center av-switch mb-0">
                        <label class="switch modal-switch mb-0">
                          <input
                            type="checkbox"
                            checked={mirrorSelfView}
                            onChange={e => setMirrorView(e)}
                          />
                          <span class="slider round" />
                          <span class="slider_text">Mirror Self-View</span>
                        </label>
                      </div>
                      <div className="d-flex align-items-center av-switch mb-0">
                        <label className="switch modal-switch mb-0">
                          <input
                            type="checkbox"
                            checked={noiceFilter ? 'checked' : ''}
                            onChange={toggleNoiseSuppression}
                          />
                          <span className="slider round" />
                          <span className="slider_text">
                            Reduce Background Noise
                          </span>
                        </label>
                        <a
                          className="tooltip_container"
                          data-tooltip="Disable this feature if you are playing a musical instrument and for similar situations.">
                          <span className="with_circle">
                            <svg height="60%" viewBox="7 4 10 16">
                              <path
                                fill="#ffffff"
                                d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                              />
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 font13 mb-1 semiBold">
                    Having problems?
                    <a href="#" onClick={refreshPage}>
                      &nbsp;Refresh this page
                    </a>
                    , &nbsp;or switch to {/* <a href=""> */} phone audio
                    {/* </a> */}
                  </p>
                </div>
                <div className="col-md-4 col-4 audioWrap">
                  <div className="playsound">
                    <small className="micro-text">Microphone Test</small>
                    <div className="progress light-bars">
                      <AudioInputPreview audioTrack={audioTracks} />
                    </div>
                    <div className="audio-output-preview">
                      <a onClick={playAudio}>{playAudioBtnTxt}</a>
                      <audio title="audio file" id="audioElement" autoPlay={0}>
                        <source src="assets/sounds/ring.wav" />
                        This browser does not support the audio element.
                      </audio>
                    </div>
                    <div className="column-video">
                      <div className="column-video-inner">
                        <div className="video-input-preview">
                          {/* <video
                        autoPlay=""
                        className="video-input-preview-display flipVideoY"
                        id=""
                        playsInline=""
                      /> */}
                          {videoPreviewTracks ? (
                            <VideoInputPreview
                              tracks={videoPreviewTracks.track}
                            />
                          ) : camerFailed ? (
                            <div className="video-input-preview-error">
                              <b>Camera Failed.</b>
                              Is camera already in use.
                            </div>
                          ) : (
                            <div className="video-input-preview-error">
                              <b>Preview Unavailable.</b> Did you give
                              permission? <br /> Is camera already in use?{' '}
                              <br /> Is camera on?
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mt-1">
                    <button
                      type="button"
                      className="btn btn-green"
                      disabled={
                        camerFailed || !hasVideoPermission ? 'true' : ''
                      }
                      onClick={() => showVirtualBackgroundModal(true)}>
                      Virtual Backgrounds
                    </button>
                    <a
                      className="tooltip_container tooltip_container--right ml-2"
                      data-tooltip="HINT: Good lighting REALLY helps (frame rate + quality)">
                      <span className="with_circle">
                        <svg height="60%" viewBox="7 4 10 16">
                          <path
                            fill="#ffffff"
                            d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="backWrap avbackWrap col-md-8 col-8 mt-3" />
                <div className="backWrap avbackWrap Buttons col-md-4 col-4 mt-3 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-green float-right"
                    onClick={closeModal}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const avSettingObject = {
    heading: 'AV Settings',
    body: avSettingBody,
    classNameModal: 'fade',
    show: true,
    classNameDialog: 'modal-lg av-settings-inner',
    headingSize: 'h5',
    svgIcon: (
      <svg viewBox="2 3 20 18" width="1.5em" class="mr-2 rotate-270">
        <path
          fill="#FFFFFF"
          d="M13 5h9v2h-9zM2 7h7v2h2V3H9v2H2zm7 10h13v2H9zm10-6h3v2h-3zm-2 4V9.012h-2V11H2v2h13v2zM7 21v-6H5v2H2v2h3v2z"></path>
      </svg>
    ),
    classNameHeader: 'modal-header-with-image',
    closeModal: closeModal,
  };

  return (
    <>
      <CommonModal {...avSettingObject} />
      {virtualBackground && (
        <VirtualBackgroundModal
          showVirtualBackgroundModal={showVirtualBackgroundModal}
          localVideoTrack={videoPreviewTracks?.track}
          virtualBackgroundOptions={virtualBackgroundOptions}
        />
      )}
    </>
  );
};

export default AvSettingModal;

const audioDetector = audioLevel => {
  const sty = {width: '4%', marginRight: '3px', overflow: 'hidden'};
  let arr = Math.floor(Math.floor(audioLevel * 150) / 5);
  arr = arr > 20 ? 19 : arr;

  return (
    <>
      {[...Array(arr > 0 ? arr : 0).keys()].map((v, i) => {
        return (
          <div
            className={`progress-bar ${
              i <= 6 ? 'bg-green' : i > 6 && i <= 10 ? 'bg-yellow' : 'bg-red'
            } `}
            role="progressbar"
            style={sty}
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
            key={'preview' + i}
          />
        );
      })}
    </>
  );
};
