import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {calculateGallerySize} from '../../../utils/functions';
import {Video, Audio, Avatar} from '../../common';
import {viewType} from '../../../utils/util';
import {setCurrentPage, setNumPages} from '../../../reduxStore/confSlice';
import {
  changeTrackResolution,
  changeTrackResolutionActiveSpeaker,
  localTracksFromVideo,
  createLocalTracksFromStream,
} from '../../../libs';
import {AttendeeName} from '../../common/web';

const VideoContainer = () => {
  const {
    localUser,
    participantsJID,
    dominantSpeaker,
    screenDimension,
    windowDimension,
    chatClick,
    attendeesClick,
    localVideoTrack,
    localDesktopVideoTrack,
    view,
    roomDetail,
    tracks,
    currrentPage,
    maxVideos,
    speaker,
    screenSharedJid,
  } = useSelector(state => state.conf);
  const [audioTracks, setAudioTracks] = useState([]);
  const [hideMutedAttendies, setHideMutedAttendies] = useState(
    roomDetail.hide_muted_attendees,
  );

  const [containerView, setcontainerView] = useState('');
  const [galleryViewParticipantsCount, setGalleryViewParticipantsCount] =
    useState(0);
  const {mirrorSelfView} = useSelector(state => state.persist);
  const {height, width} = screenDimension;
  const [mp4Video, setMp4Video] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(mp4Video);
    if (mp4Video) {
      const leftVideo = document.getElementById('leftVideo');
      console.log('Video element found');
      leftVideo.load();
      leftVideo.addEventListener(
        'loadeddata',
        function () {
          console.log('video loaded =>');
          leftVideo.play();
          console.log('video play start=>');
          // Video is loaded and can be played
        },
        false,
      );
      leftVideo.onplay = function () {
        // leftVideo.addEventListener('canplay', () => {
        console.log('Getting video sreams=>');
        let stream;
        const fps = 0;
        if (leftVideo.captureStream) {
          stream = leftVideo.captureStream(fps);
        } else if (leftVideo.mozCaptureStream) {
          stream = leftVideo.mozCaptureStream(fps);
        } else {
          console.error('Stream capture is not supported');
          stream = null;
        }
        createLocalTracksFromStream(stream)
          .then(localTracksFromVideo)
          .catch(error => {
            throw error;
          });
      };
      // );
    }
  }, [mp4Video]);
  useEffect(() => {
    if (speaker) {
      const allAudioTracks = [];
      Object.keys(participantsJID).forEach(key => {
        let tmpTrack = tracks.filter(
          track => track.type === 'audio' && track.participantId === key,
        );
        if (tmpTrack.length > 0) {
          allAudioTracks.push(tmpTrack[0]);
        }
      });
      setAudioTracks(allAudioTracks);
    } else {
      setAudioTracks([]);
    }
  }, [tracks, participantsJID, speaker]);
  useEffect(() => {
    const desktopVideoTrack = getTrack(localDesktopVideoTrack);
    const videoTrack = getTrack(localVideoTrack);

    //console.log('participantsJID', participantsJID);
    if (view === viewType.gallery) {
      setGallery(videoTrack, desktopVideoTrack);
    }
    if (view === viewType.active) {
      // setActiveSpeaker(videoTrack);
    }
    if (view === viewType.presentation) {
      // setPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    view,
    width,
    height,
    localVideoTrack,
    localDesktopVideoTrack,
    participantsJID,
    currrentPage,
    maxVideos,
    dominantSpeaker,
    hideMutedAttendies,
    tracks,
    mirrorSelfView,
    localUser?.profile_image,
    roomDetail?.show_names_on_videos,
  ]);

  useEffect(() => {
    if (roomDetail.hide_muted_attendees !== hideMutedAttendies) {
      //console.log('captek room detil update');
      setHideMutedAttendies(roomDetail.hide_muted_attendees);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomDetail]);
  useEffect(() => {
    dispatch(calculateGallerySize(galleryViewParticipantsCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    galleryViewParticipantsCount,
    windowDimension,
    chatClick,
    attendeesClick,
  ]);
  const getvideoClass = count => {
    let videoclass = 'videoSpeaker';
    videoclass = count === 2 ? 'videoTwo' : videoclass;
    videoclass = count >= 3 && count <= 4 ? 'videoUptoFour' : videoclass;
    videoclass = count >= 5 && count <= 6 ? 'videoUptoSix' : videoclass;
    videoclass = count >= 7 && count <= 9 ? 'videoUptoNine' : videoclass;
    videoclass = count >= 10 && count <= 12 ? 'videoUptoTwelve' : videoclass;
    videoclass = count >= 13 && count <= 16 ? 'videoUptoSixteen' : videoclass;
    videoclass = count >= 17 && count <= 20 ? 'videoUptoTwenty' : videoclass;
    videoclass =
      count >= 21 && count <= 24 ? 'videoUptoTwentyFour' : videoclass;

    return videoclass;
  };
  const setGallery = (videoTrack, desktopVideoTrack) => {
    //console.log('captek gallery called', localUser?.profile_image);
    const tmpParticipantsTracks = [];
    const participantSelectedTracks = [];
    const participantId = [];
    const participant = {
      track: null,
      type: 'camera',
      name: localUser.name,
      profile_image: localUser?.profile_image,
      jid: localUser.jid,
      local: true,
    };
    participantSelectedTracks.push(participant);
    // if (hideMutedAttendies) {
    //   if (localUser.audio) {
    //     if (videoTrack) {
    //       const participant = {
    //         track: videoTrack.jitsiTrack,
    //         type: 'camera',
    //         name: localUser.name,
    //         profile_image: localUser?.profile_image,
    //         jid: localUser.jid,
    //         local: true,
    //       };
    //       participantSelectedTracks.push(participant);
    //     } else {
    //       const participant = {
    //         track: null,
    //         type: 'camera',
    //         name: localUser.name,
    //         profile_image: localUser?.profile_image,
    //         jid: localUser.jid,
    //         local: true,
    //       };
    //       participantSelectedTracks.push(participant);
    //     }
    //     // if (desktopVideoTrack && desktopVideoTrack !== null) {
    //     //   const participant = {
    //     //     track: desktopVideoTrack.jitsiTrack,
    //     //     type: 'desktop',
    //     //     name: localUser.name,
    //     //   };
    //     //   participantSelectedTracks.push(participant);
    //     // }
    //   }
    // } else {
    //   if (videoTrack) {
    //     const participant = {
    //       track: videoTrack.jitsiTrack,
    //       type: 'camera',
    //       name: localUser.name,
    //       profile_image: localUser?.profile_image,
    //       jid: localUser.jid,
    //       local: true,
    //     };
    //     participantSelectedTracks.push(participant);
    //   } else {
    //     const participant = {
    //       track: null,
    //       type: 'camera',
    //       name: localUser.name,
    //       profile_image: localUser?.profile_image,
    //       jid: localUser.jid,
    //       local: true,
    //     };
    //     participantSelectedTracks.push(participant);
    //   }
    // }
    // if (desktopVideoTrack && desktopVideoTrack !== null) {
    //   const participant = {
    //     track: desktopVideoTrack.jitsiTrack,
    //     type: 'desktop',
    //     name: localUser.name + '&#39;s Presentation',
    //     local: true,
    //   };
    //   // participantSelectedTracks.push(participant);
    // }

    let tmpParticipantsJID = {...participantsJID};

    Object.keys(tmpParticipantsJID).forEach(key => {
      if (hideMutedAttendies) {
        if (tmpParticipantsJID[key].audio) {
          const remoteVideoTrack = tracks.filter(
            track =>
              track.participantId === key &&
              track.type === 'video' &&
              track.mediaType === 'camera' &&
              track.muted === false,
          );
          if (remoteVideoTrack.length > 0) {
            const participant = {
              track: remoteVideoTrack[0].jitsiTrack,
              type: 'camera',
              name: tmpParticipantsJID[key].name,
              profile_image: tmpParticipantsJID[key]?.profile_image,
              jid: key,
              local: false,
            };
            tmpParticipantsTracks.push(participant);
          } else {
            const participant = {
              track: null,
              type: 'camera',
              name: tmpParticipantsJID[key].name,
              profile_image: tmpParticipantsJID[key]?.profile_image,
              jid: key,
              local: false,
            };
            tmpParticipantsTracks.push(participant);
          }
        }
      } else {
        const remoteVideoTrack = tracks.filter(
          track =>
            track.participantId === key &&
            track.type === 'video' &&
            track.mediaType === 'camera' &&
            track.muted === false,
        );
        if (remoteVideoTrack.length > 0) {
          const participant = {
            track: remoteVideoTrack[0].jitsiTrack,
            type: 'camera',
            name: tmpParticipantsJID[key].name,
            profile_image: tmpParticipantsJID[key]?.profile_image,
            jid: key,
            local: false,
          };
          tmpParticipantsTracks.push(participant);
        } else {
          const participant = {
            track: null,
            type: 'camera',
            name: tmpParticipantsJID[key].name,
            profile_image: tmpParticipantsJID[key]?.profile_image,
            jid: key,
            local: false,
          };
          tmpParticipantsTracks.push(participant);
        }
      }
      const remoteDesktopTrack = tracks.filter(
        track =>
          track.participantId === key &&
          track.type === 'video' &&
          track.mediaType === 'desktop' &&
          track.muted === false,
      );

      if (remoteDesktopTrack.length > 0) {
        const participant = {
          track: remoteDesktopTrack[0].jitsiTrack,
          type: 'desktop',
          name: tmpParticipantsJID[key].name + '&#39;s Presentation',
          profile_image: tmpParticipantsJID[key]?.profile_image,
          jid: key,
          local: false,
        };
        tmpParticipantsTracks.push(participant);
      }
    });
    //console.log('initial tmpParticipantsTracks', tmpParticipantsTracks);

    let tmpParticipantsLength =
      tmpParticipantsTracks.length + participantSelectedTracks.length;
    //console.log('initial tmpParticipantsLength', tmpParticipantsLength);
    const numPages = Math.ceil(tmpParticipantsLength / maxVideos);

    dispatch(setNumPages(numPages));
    //console.log('initial currrentPage', currrentPage);
    //console.log('initial numPages', numPages);
    if (tmpParticipantsLength > 0) {
      if (currrentPage > numPages) {
        dispatch(setCurrentPage(currrentPage - 1));
      } else {
        let startCount = currrentPage * maxVideos;
        startCount = startCount - maxVideos;
        let endCount =
          currrentPage * maxVideos - participantSelectedTracks.length;
        if (startCount >= maxVideos) {
          startCount = startCount - participantSelectedTracks.length;
        }
        //console.log('startCount', startCount);
        //console.log('endCount', endCount);

        for (
          let i = startCount;
          i < endCount && i < tmpParticipantsTracks.length;
          i++
        ) {
          participantSelectedTracks.push(tmpParticipantsTracks[i]);
          if (
            tmpParticipantsTracks[i]?.track &&
            !tmpParticipantsTracks[i]?.local
          ) {
            participantId.push(tmpParticipantsTracks[i].track.getSourceName());
          }
        }

        changeTrackResolution(participantId);
        const count = participantSelectedTracks.length;

        //console.log('numPages=>', numPages);
        //console.log('currrentPage=>', currrentPage);
        //console.log('participantSelectedTracks=>', participantSelectedTracks);
        //console.log('count=>', count);
        setcontainerView(
          getgalleryContainer(participantSelectedTracks, getvideoClass(count)),
        );
        setGalleryViewParticipantsCount(count);
      }
    } else {
      setcontainerView(getgalleryContainer(participantSelectedTracks, 0));
      setGalleryViewParticipantsCount(0);
    }
  };
  const setActiveSpeaker = videoTrack => {
    let track = videoTrack;
    let name = localUser.name;
    let tmpParticipant = localUser;
    let tmpTrack = [];
    //console.log('setActiveSpeaker dominantSpeaker', dominantSpeaker);
    const usersId = [];
    Object.keys(participantsJID).forEach(key => {
      tmpTrack = tracks.filter(
        track =>
          track.type === 'audio' &&
          track.participantId === key &&
          track.muted === false &&
          track.participantId !== dominantSpeaker,
      );
      if (tmpTrack.length > 0) {
        let tmpTrackVideo = tracks.filter(
          track =>
            track.type === 'video' &&
            track.mediaType === 'camera' &&
            track.muted === false &&
            track.participantId === key,
        );
        if (tmpTrackVideo.length > 0) {
          usersId.push(tmpTrackVideo[0].jitsiTrack.getSourceName());
        }
      }
    });

    if (dominantSpeaker === null || dominantSpeaker === localUser.jid) {
      track = videoTrack;
      name = localUser.name;
    } else if (participantsJID[dominantSpeaker]) {
      tmpParticipant = participantsJID[dominantSpeaker];
      let trackArray = tracks.filter(
        t =>
          t.participantId === dominantSpeaker &&
          t.type === 'video' &&
          t.mediaType === 'camera' &&
          t.muted === false,
      );
      if (trackArray.length > 0 && tmpParticipant.audio) {
        track = trackArray[0];
        usersId.unshift(track.jitsiTrack.getSourceName());
      } else {
        track = null;
      }
      changeTrackResolutionActiveSpeaker(usersId);

      name = participantsJID[dominantSpeaker].name;
    }
    if (tmpParticipant.audio) {
      setGalleryViewParticipantsCount(1);
      setcontainerView(
        getActiveSpeakerContainer(
          track,
          name,
          getvideoClass(1),
          tmpParticipant,
        ),
      );
    } else if (tmpTrack.length > 0) {
      changeTrackResolutionActiveSpeaker(usersId);
      tmpParticipant = participantsJID[tmpTrack[0].participantId];
      let trackArray = tracks.filter(
        t =>
          t.participantId === tmpTrack[0].participantId &&
          t.type === 'video' &&
          t.mediaType === 'camera' &&
          t.muted === false,
      );
      if (trackArray.length > 0) {
        track = trackArray[0];
      } else {
        track = null;
      }
      name = tmpParticipant.name;
      setGalleryViewParticipantsCount(1);
      setcontainerView(
        getActiveSpeakerContainer(
          track,
          name,
          getvideoClass(1),
          tmpParticipant,
        ),
      );
    } else {
      setcontainerView(
        getActiveSpeakerContainer(track, name, 0, tmpParticipant),
      );
      setGalleryViewParticipantsCount(0);
    }
  };
  const setPresentation = () => {
    let track = null;
    let name = null;

    const participent = participantsJID[screenSharedJid];
    if (participent) {
      let trackArray = tracks.filter(
        t =>
          t.participantId === screenSharedJid &&
          t.type === 'video' &&
          t.mediaType === 'desktop' &&
          t.muted === false,
      );
      if (trackArray.length > 0) {
        track = trackArray[0];
        changeTrackResolution([track.jitsiTrack.getSourceName()]);
      }
      name = participantsJID[screenSharedJid].name + '&#39;s Presentation';
    }
    setGalleryViewParticipantsCount(1);
    setcontainerView(
      getpresenterContainer(track, name, getvideoClass(1), participent),
    );
  };

  const getgalleryContainer = (participantSelectedTracks, videoClass) => {
    return (
      <>
        {participantSelectedTracks.length > 0 ? (
          participantSelectedTracks.map(participant => {
            let speakerClass = '';
            if (
              participant.jid === dominantSpeaker &&
              participant.type === 'camera'
            ) {
              speakerClass = 'highlighted-box';
            }
            let darkBackground = 'darkGrey';
            if (participant.track) {
              darkBackground = '';
            }
            let mirrorClass =
              participant.jid === localUser.jid && mirrorSelfView
                ? 'flipVideoX'
                : '';

            return (
              <div
                className={`videoBlock ${videoClass}  ${darkBackground}`}
                style={{width: `${width}px`, height: `${height}px`}}
                key={participant.jid + '_' + participant.type + '_partent'}>
                <div
                  className="videoBlockInner"
                  key={participant.jid + '_' + participant.type + '_child1'}>
                  {participant.local ? (
                    <>
                      <VideoElementMp4 cb={setMp4Video} />
                      <AttendeeName
                        name={participant.name}
                        key={`${participant.jid}_${participant.type}_child2`}
                      />
                    </>
                  ) : participant.track ? (
                    <>
                      {videoElement(participant.track, mirrorClass)}
                      {/* When video is visible */}

                      <AttendeeName
                        name={participant.name}
                        key={`${participant.jid}_${participant.type}_child2`}
                      />

                      {/* When video is off */}
                    </>
                  ) : (
                    <>
                      <Avatar
                        participant={participant}
                        key={
                          participant.jid + '_' + participant.type + '_child2'
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <span className="white stand-by">
            Please stand by until someone is unmuted.
          </span>
        )}
      </>
    );
  };
  const getpresenterContainer = (videoTrack, name, videoClass, participant) => {
    return (
      <>
        <div
          className={`videoBlock ${videoClass} `}
          style={{width: `${width}px`, height: `${height}px`}}>
          <div className="videoBlockInner">
            {videoTrack !== null ? (
              <>
                {videoElement(videoTrack.jitsiTrack)}

                {/* When video is visible */}
                <AttendeeName name={name} />

                {/* When video is off */}
              </>
            ) : (
              <>
                <Avatar participant={participant} />
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  const getActiveSpeakerContainer = (videoTrack, name, videoClass, p) => {
    let darkBackground = 'darkGrey';
    if (videoTrack) {
      darkBackground = '';
    }

    return (
      <>
        {p.audio ? (
          <div
            className={`videoBlock ${videoClass} ${darkBackground}`}
            style={{width: `${width}px`, height: `${height}px`}}>
            <div className="videoBlockInner">
              {videoTrack !== null ? (
                <>
                  {videoElement(videoTrack.jitsiTrack)}

                  {/* When video is visible */}
                  <AttendeeName name={name} />
                  {/* When video is off */}
                </>
              ) : (
                <>
                  <Avatar participant={p} />
                </>
              )}
            </div>
          </div>
        ) : (
          <span className="white stand-by">
            Please stand by until someone is unmuted.
          </span>
        )}
      </>
    );
  };
  return (
    <>
      {audioTracks.map(track => {
        return AudioContainer(track.jitsiTrack);
      })}
      {containerView}
    </>
  );
};
export default VideoContainer;

const videoElement = (track, className = '') => {
  let videoTrack = {jitsiTrack: track};

  return (
    <Video
      key={`video_${track.getId()}`}
      id={`video_${track.getId()}`}
      videoTrack={videoTrack}
      className={className}
    />
  );
};

const AudioContainer = localAudioTrack => {
  let audioTrack = {jitsiTrack: localAudioTrack};
  return (
    <Audio key={`audio_${localAudioTrack.getId()}`} audioTrack={audioTrack} />
  );
};

const getTrack = track => {
  return track !== null && track?.muted === false ? track : null;
};

const VideoElementMp4 = ({cb}) => {
  cb(true);
  return (
    <video id="leftVideo" playsInline controls loop>
      <source id="vidSource" src="chrome.mp4" type="video/mp4" />
      <p>This browser does not support the video element.</p>
    </video>
  );
};
