/* eslint-disable no-void */
import React, { useState } from 'react';
import { IconFacebookPage } from '../../../../../icons';
import { CommonModal } from '../../../../common/web';
import { FB_APP_ID } from '../../../../../utils/constants';
import { addFacebookDestination, getfbGroupsList, getfbPagesList, getfbProfileData } from '../../../../../utils/socket';
import FacebookPageModal from './facebook/facebookPagesModal';
import AddCustomRTMPModal from './custom/addCustomRtmpModal';
import FacebookProfileModal from './facebook/facebookProfileModal';
import { useSelector } from 'react-redux';
import FacebookGroupModal from './facebook/facbookGroupsModal';

function AddDestinationModal({ setAddDestination }) {
  const { roomDetail: { room }, localUser: { token } } = useSelector(state => state.conf);
  const [selectedDestination, updateSelectedDestination] = useState('');
  const [showFacebookPages, showFacebookPagesList] = useState(false);
  const [showFacebookProfile, showFacebookProfileData] = useState(false);
  const [showFacebookGroups, showFacebookGroupsList] = useState(false);
  const [fbLoader, setfbLoader] = useState(false);
  const [addCustomRTMP, setAddCustomRTMP] = useState(false);
  const [fb_pages_list, setFbPagesList] = useState([]);
  const [fb_profile_data, setFbUserData] = useState([]);
  const [fb_groups_list, setFbGroupsList] = useState([]);
  const [fb_user, updateFbUserDetail] = useState({});

  const selectDestination = async (type) => {
    if (type === 'fb_page') {
      fbLogin('page');
    } else if (type === 'fb_profile') {
      fbLogin('profile');
    } else if (type === 'fb_group') {
      fbLogin('group');
    }
    updateSelectedDestination(type);
  };

  const fbLogin = (type) => {
    window.FB.init({
      appId: FB_APP_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v17.0',
    });

    window.FB.getLoginStatus((response) => {

      if (response.status === 'connected') {
        if (type === 'page') {
          facebookPagesList(response.authResponse.accessToken, response.authResponse.userID);
        } else if (type === 'profile') {
          facebookProfileData(response.authResponse.accessToken, response.authResponse.userID);
        } else if (type === 'group') {
          facebookGroupsList(response.authResponse.accessToken, response.authResponse.userID);
        }
      } else {
        window.FB.login((res) => {
          if (res.status === 'connected') {
            console.log('status', res.status);
            if (type === 'page') {
              facebookPagesList(res.authResponse.accessToken, res.authResponse.userID);
            } else if (type === 'profile') {
              facebookProfileData(res.authResponse.accessToken, res.authResponse.userID);
            } else if (type === 'group') {
              facebookGroupsList(res.authResponse.accessToken, res.authResponse.userID);
            }
          }

        }, { scope: 'public_profile,email,pages_manage_posts, pages_read_engagement,pages_show_list,publish_video,read_insights,groups_access_member_info,publish_to_groups,page_events,pages_read_user_content,pages_manage_engagement,user_videos' });
      }

    });
  };

  const facebookPagesList = async (access_token, user_id) => {
    setfbLoader(true);
    showFacebookPagesList(true);

    getfbPagesList({ access_token, user_id }).then((response) => {
      if (response.status === 200) {
        setFbPagesList(response.data);
        updateFbUserDetail({ user_id, access_token });
      } else {
        showFacebookPagesList(false);
      }
    }).finally(() => {
      setfbLoader(false);
    });

  };

  const facebookProfileData = async (access_token, user_id) => {
    setfbLoader(true);
    showFacebookProfileData(true);

    getfbProfileData({ access_token, user_id }).then((response) => {
      if (response.status === 200) {
        setFbUserData(response.data);
        updateFbUserDetail({ user_id, access_token });
      } else {
        showFacebookProfileData(false);
      }
    }).finally(() => {
      setfbLoader(false);
    });
  };

  const facebookGroupsList = async (access_token, user_id) => {
    setfbLoader(true);
    showFacebookGroupsList(true);

    getfbGroupsList({ access_token, user_id }).then((response) => {
      if (response.status === 200) {
        setFbGroupsList(response.data);
        updateFbUserDetail({ user_id, access_token });
      } else {
        showFacebookGroupsList(false);
      }
    }).finally(() => {
      setfbLoader(false);
    });
  };

  const closeAll = () => {
    setAddDestination(false);
    showFacebookPagesList(false);
    showFacebookProfileData(false);
    showFacebookGroupsList(false);
  };

  const handleAddFacebookDestination = (id, name) => {
    let payload = {
      room,
      token,
      name,
      id,
      destination: selectedDestination,
      access_token: fb_user.access_token,
    };

    addFacebookDestination(payload).then((response) => {
      if (response.status === 200) {
        closeAll();
      }
    }).finally(() => {

    });
  };

  const addDestinationBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="modal_min_height">
              <p className="mb-1 semiBold text-center">
                Select the destination you would like to add
              </p>
              <div className="destinations">
                <a href={void (0)} onClick={() => selectDestination('fb_page')} >
                  <span className={'bg-facebook'} >
                    <IconFacebookPage />
                    Facebook Page
                  </span>
                </a>

                <a href={void (0)} onClick={() => selectDestination('fb_profile')}>
                  <span className={'bg-facebook'} >
                    <svg width="1.1em" viewBox="2 2 20 19.88">
                      <path
                        fill="#ffffff"
                        d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z" />
                    </svg>
                    Facebook Profile
                  </span>
                </a>

                <a href={void (0)} onClick={() => selectDestination('fb_group')}  >
                  <span className="bg-facebook fb_group">
                    <svg width="1.1em" viewBox="2 2 20 19.88">
                      <path
                        fill="#ffffff"
                        d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z" />
                    </svg>
                    Facebook Group
                  </span>
                </a>

                {/* <a href={void (0)}>
                  <span className={'bg-youtube'}>
                    <svg width="1.1em" viewBox="1.99 5 20.01 14.01">
                      <path
                        fill="#ffffff"
                        d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z" />
                    </svg>
                    Youtube Channel
                  </span>
                </a>

                <a
                  href={void (0)}
                >
                  <span className={'bg-twitch'}>
                    <svg width="1.1em" viewBox="3 3 18.01 18.85">
                      <path
                        fill="#ffffff"
                        d="M4.265 3 3 6.236v13.223h4.502V21l2.531.85 2.392-2.391h3.658l4.923-4.924V3H4.265zm15.052 10.691-2.813 2.814h-4.502l-2.391 2.391v-2.391H5.813V4.688h13.504v9.003zm-2.812-5.767v4.923h-1.688V7.924h1.688zm-4.502 0v4.923h-1.688V7.924h1.688z" />
                    </svg>
                    Twitch
                  </span>
                </a>

                <a href={void (0)} >
                  <span className={'bg-rtmp'}>
                    <svg
                      width="1.2em"
                      className="rotate--180"
                      viewBox="2 3 20 18">
                      <path
                        fill="#ffffff"
                        d="M20 3H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-5 5h-2V6h2zm4 0h-2V6h2zm1 5H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zm-5 5h-2v-2h2zm4 0h-2v-2h2z" />
                    </svg>
                    Custom RTMP
                  </span>
                </a> */}

              </div>
            </div>
            <div className="stream-submit">
              <button type="button" data-dismiss="modal" className="btn btn-link" onClick={() => setAddDestination(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const addDestinationObject = {
    imgLink: 'assets/images/signal-stream.png',
    heading: 'Stream Manager',
    body: addDestinationBody,
    show: true,
    classNameModal: 'fade streamManager',
    headingSize: 'h5',
  };

  return (<>
    <CommonModal {...addDestinationObject} />
    {
      showFacebookPages && <FacebookPageModal fbLoader={fbLoader} fb_pages_list={fb_pages_list} handleReturn={showFacebookPagesList} handleAddFacebookDestination={handleAddFacebookDestination} />
    }
    {
      showFacebookProfile && <FacebookProfileModal fbLoader={fbLoader} fb_profile_data={fb_profile_data} handleReturn={showFacebookProfileData} handleAddFacebookDestination={handleAddFacebookDestination} />
    }
    {
      showFacebookGroups && <FacebookGroupModal fbLoader={fbLoader} fb_groups_list={fb_groups_list} handleReturn={showFacebookGroupsList} handleAddFacebookDestination={handleAddFacebookDestination} />
    }
    {
      addCustomRTMP && <AddCustomRTMPModal />
    }
  </>);
}

export default AddDestinationModal;
