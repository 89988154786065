/* eslint-disable no-void */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endMeetnForGuests, isLemMode } from '../../../../utils/functions';
import { setExitModalShow } from '../../../../reduxStore/confSlice';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { disposeConference } from '../../../../libs';
import { useNavigate } from 'react-router-dom';


function ExitModal() {
    const { localUser, roomDetail } = useSelector(state => state.conf);
    const [disabled, setDisabled] = useState(false);
    const [exitLoader, setExitLoader] = useState(false);
    const lemMode = isLemMode();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const _doHangup = (type) => {
        if (type === 'exit') {
            if (lemMode) {
                if (roomDetail?.webpage_on_exit_url) {
                    setExitLoader(true);
                    window.location = roomDetail?.webpage_on_exit_url;
                } else {
                    navigate('/');
                }

            } else {
                disposeConference().then(() => {
                    if (roomDetail?.webpage_on_exit_url) {
                        setExitLoader(true);
                        window.location = roomDetail?.webpage_on_exit_url;
                    } else {
                        navigate('/');
                    }
                });
            }

        } else if (type === 'end') {
            swal({
                title: 'Do you want to end the meeting for guests?',
                closeOnClickOutside: false,
                buttons: ['Cancel', 'OK'],
                dangerMode: true,
            }).then(async ok => {
                if (ok) {
                    setDisabled(true);
                    let response = await dispatch(endMeetnForGuests());

                    if (response.status === 200) {
                        dispatch(setExitModalShow(false));
                        setDisabled(false);
                    } else {
                        toast.error(response.msg, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        setDisabled(false);
                    }
                }
            });
        }
    };


    return (<>
        <div
            className="modal fade congrats-modal exit-modal"
            id="exit-modal"
            tabIndex="-1"
            role="dialog"
            data-backdrop="static"
            data-keyboard="false"
            aria-labelledby="exampleModalLabel">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header" />
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12 buttons_container">
                                <button
                                    className="btn btn-dark mb-3"
                                    disabled={exitLoader}
                                    onClick={() => _doHangup('exit')}>
                                    Exit MEETN

                                </button>
                                {localUser.role !== 'guest' && (
                                    <div className="d-flex align-items-center mb-4 end_padding">
                                        <button
                                            className={`btn ${disabled ? 'btn-gray' : 'btn-green'}`}
                                            disabled={disabled}
                                            onClick={e => _doHangup('end')}>
                                            {disabled ? (
                                                <div className="spinner" />
                                            ) : (
                                                'End MEETN for Guests'
                                            )}
                                        </button>
                                        <a
                                            className="tooltip_container tooltip_container--right ml-2"
                                            data-tooltip="Only Hosts+ can see this button">
                                            <span className="with_circle">
                                                <svg height="60%" viewBox="7 4 10 16">
                                                    <path
                                                        fill="#ffffff"
                                                        d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z"
                                                    />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                )}
                                <a href={void (0)} onClick={() => dispatch(setExitModalShow(false))} className="returnLink">
                                    <small className="text-green">
                                        <u>Return to MEETN</u>
                                    </small>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" />
                </div>
            </div>
        </div >
    </>)
}

export default ExitModal

