import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebinarSettingsModal from './modals/webinarSettingsModal';
import {
  setOpenLoginModel,
  setShowWebinarSettingModal,
} from '../../../reduxStore/confSlice';
import { alertMsg } from '../../common/web/alert';
import { ULTIMATE_FEATURE_MSG, ULTIMATE_FEATURE_TRIAL_MSG } from '../../../utils/util';
import { featureAccess } from '../../../utils/functions';

function WebinarSetting() {
  const { showWebinarSettingModal, localUser, roomDetail, trial } = useSelector(state => state.conf);
  const { plan } = roomDetail;
  const dispatch = useDispatch();
  const handleOpenModal = () => {

    if (featureAccess('webinar', plan)) {
      dispatch(setShowWebinarSettingModal(true));
    } else {
      if (plan === 'pro' && trial) {
        alertMsg({ msg: ULTIMATE_FEATURE_TRIAL_MSG, type: 'info' });
      } else {
        alertMsg({ msg: ULTIMATE_FEATURE_MSG, type: 'info' });
      }
    }
  };


  return (
    <>
      <a
        className="nav-link"
        onClick={() =>
          localUser.role !== 'guest'
            ? handleOpenModal()
            : dispatch(setOpenLoginModel(true))
        }>
        <i className="fas fa-wifi bg-naviblue webinaricon"></i>
        <span>Webinar Settings</span>
      </a>
      {showWebinarSettingModal && (
        <WebinarSettingsModal
          roomDetail={roomDetail}
          closeWebinarSettingModal={() =>
            dispatch(setShowWebinarSettingModal(false))
          }
        />
      )}
    </>
  );
}

export default WebinarSetting;
