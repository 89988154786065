import React, {useState, useMemo} from 'react';
import {useSelector} from 'react-redux';

const ParticularWidget = props => {
  const {particularWidgetData} = props;
  const [iframeLoader, setIframeLoader] = useState(true);
  const {localUser, roomDetail} = useSelector(state => state.conf);
  const {token} = localUser;
  const {room} = roomDetail;

  const IframeSrc = useMemo(() => {
    let url = particularWidgetData?.extensionURL;
    const finalUrl = url + '?room=' + room + '&token=' + token;
    return finalUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [particularWidgetData]);

  return (
    <>
      <div className="col-md-12 extensions-wrap on-first extensions-container p-0">
        {iframeLoader ? (
          <span className="loader-ext-blur">
            <img
              src="assets/images/extenstion-loader-blur.webp"
              className="loader-ext-blur-img"
            />
          </span>
        ) : null}
        <iframe
          src={IframeSrc}
          id={`control_panel_${particularWidgetData.extensionid}`}
          width="100%"
          height="100%"
          style={{border: 'none'}}
          onLoad={() => setIframeLoader(false)}
        />
      </div>
    </>
  );
};

export default ParticularWidget;
