/* eslint-disable no-void */
import React, { useState } from 'react';
import { CommonModal } from '../../../../../common/web';

function AddCustomRTMPModal() {
    const [server_password] = useState('N');

    const customRTMPBody = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p className="semiBold mb-7 font15">
                            We suggest googling for "Stream key" along with the
                            <br />
                            name of the website you want to stream to.
                        </p>

                        <form >
                            <div className="form-group">
                                <label>
                                    RTMP Server URL:
                                    <a
                                        className="tooltip_container tooltip_container ml-2"
                                        data-tooltip="This begins with RTMP and will likely have the name of the website.">
                                        <span className="with_circle">
                                            <svg height="60%" viewBox="7 4 10 16">
                                                <path
                                                    fill="#ffffff"
                                                    d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z" />
                                            </svg>
                                        </span>
                                    </a>
                                    <span className="required" />
                                </label>
                                <input
                                    spellCheck="false"
                                    className="form-control"
                                    maxLength="100"
                                    type="text"
                                    name="rmtp_server_url"
                                />

                            </div>
                            <div className="form-group">
                                <label>
                                    Stream Key:
                                    <a
                                        className="tooltip_container tooltip_container ml-2"
                                        data-tooltip="Provided by platform. Also known as 'Stream Name'">
                                        <span className="with_circle">
                                            <svg height="60%" viewBox="7 4 10 16">
                                                <path
                                                    fill="#ffffff"
                                                    d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z" />
                                            </svg>
                                        </span>
                                    </a>
                                    <span className="required" />
                                </label>
                                <input
                                    spellCheck="false"
                                    className="form-control"
                                    maxLength="100"
                                    type="text"
                                    name="stream_key"
                                />

                            </div>
                            <div className="form-group">
                                <label>
                                    Nickname:
                                    <a
                                        className="tooltip_container tooltip_container ml-2"
                                        data-tooltip="Personal name for destination">
                                        <span className="with_circle">
                                            <svg height="60%" viewBox="7 4 10 16">
                                                <path
                                                    fill="#ffffff"
                                                    d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z" />
                                            </svg>
                                        </span>
                                    </a>
                                    <span className="required" />
                                </label>
                                <input
                                    spellCheck="false"
                                    className="form-control"
                                    maxLength="100"
                                    type="text"
                                    name="nickname"
                                />

                            </div>
                            {server_password === 'Y' && (
                                <>
                                    <div className="form-group pl-3 pr-7">
                                        <label>
                                            RTMP Username:
                                            <span className="required" />
                                        </label>
                                        <input
                                            spellCheck="false"
                                            className="form-control"
                                            maxLength="100"
                                            type="text"
                                            name="rmtp_username"
                                        />
                                    </div>
                                    <div className="form-group pl-3 pr-7">
                                        <label>
                                            RTMP Password:
                                            <span className="required" />
                                        </label>
                                        <input
                                            spellCheck="false"
                                            className="form-control"
                                            maxLength="100"
                                            type="text"
                                            name="rmtp_password"
                                        />
                                    </div>
                                </>
                            )}
                            <div className="col-md-12 text-right p-0 mt-3">
                                <a
                                    href={void (0)}
                                    className="btn-link btn-mini"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </a>
                                <button
                                    type="submit"
                                    className="btn-green btn-mini mr-0"
                                >
                                    Add RTMP Server
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    };

    const addCustomRTMPObject = {
        heading: 'Add a custom RTMP destination',
        body: customRTMPBody,
        show: true,
        classNameDialog: 'smallmodal',
        classNameModal: 'fade smallmodalwrap add-RTMP',
        headingSize: 'h5',
    };
    return <CommonModal {...addCustomRTMPObject} />;
}

export default AddCustomRTMPModal;
