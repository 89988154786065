/* eslint-disable no-void */
import React from 'react';
import { CommonModal } from '../../../../common/web';
import { deleteStreamingDestinations } from '../../../../../utils/socket';
import { useSelector } from 'react-redux';


function DeleteDestination({ deleteDestinationData, setDeleteDestination }) {
    const { roomDetail: { room } } = useSelector(state => state.conf);
    const handleDeleteDestination = () => {

        deleteStreamingDestinations({ room, id: deleteDestinationData?.id }).then((response) => {
            if (response.status === 200) {
                setDeleteDestination(false);
            }
        }).finally(() => {

        });
    };

    const deleteDestinationBody = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12 text-center p-0">
                        <p className="semiBold mb-0 modal_min_height--sm font15 d-flex align-items-center justify-content-center">
                            Are you sure you want to delete
                            <br />
                            {deleteDestinationData?.nickname}?
                            <br />
                        </p>
                        <div className="col-md-12 text-right mt-3">
                            <a
                                href={void (0)}
                                className="btn-link btn-mini"
                                data-dismiss="modal"
                                onClick={() => setDeleteDestination(false)}>
                                No Keep
                            </a>
                            <a
                                href={void (0)}
                                className="btn-green btn-mini mr-0"
                                onClick={handleDeleteDestination}>
                                Yes Delete
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const deleteDestinationObject = {
        body: deleteDestinationBody,
        show: true,
        classNameDialog: 'smallmodal',
        classNameModal: 'fade smallmodalwrap',
        classNameBody: 'flexdiv pl-0 pr-0',
        headingSize: 'h5',
    };
    return <CommonModal {...deleteDestinationObject} />;
}

export default DeleteDestination;
