import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ManageRoomModal from './modals/manageRooms/manageRoomModal';
import {setShowManageRoomModal} from '../../../reduxStore/confSlice';

function AddRemoveMangeRoom() {
  const {showManageRoomModal} = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    dispatch(setShowManageRoomModal(true));
  };
  return (
    <>
      <a className="nav-link" onClick={handleOpenModal}>
        <i className="fas fa-moon bg-purple addroomicon"></i>
        <span>Add/Remove/Manage Rooms</span>
      </a>
      {showManageRoomModal && <ManageRoomModal />}
    </>
  );
}

export default AddRemoveMangeRoom;
