import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {recentUsersList} from '../../../utils/functions';
import {
  setPage,
  setPreviousRouteStep,
  setLoginAsGuest,
  setGuestDetail,
  setUserDetail,
} from '../../../reduxStore/confSlice';
import {useSearchParams} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

const Guest = () => {
  const [name, setName] = useState('');
  const [err, setErr] = useState({});
  const dispatch = useDispatch();
  const {isBackButtonShow, guestDetail, roomDetail} = useSelector(
    state => state.conf,
  );
  const [disabled, setDisabled] = useState(false);
  const [searchParams] = useSearchParams();
  const presenter_id = searchParams.get('p');
  const host_id = searchParams.get('h');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  useEffect(() => {
    const tmpGuestDetail = {...guestDetail};
    dispatch(setGuestDetail(tmpGuestDetail));
    dispatch(setUserDetail(tmpGuestDetail));
    dispatch(recentUsersList(tmpGuestDetail));
    dispatch(setPreviousRouteStep('Guest'));
    dispatch(setLoginAsGuest(true));

    dispatch(setPage('Connect'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Login = e => {
    e.preventDefault();
    dispatch(setPage('Login'));
    dispatch(setPreviousRouteStep('Guest'));
  };

  const RecentUser = e => {
    e.preventDefault();
    dispatch(setPage('RecentUser'));
  };

  const handleConnect = e => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      const tmpGuestDetail = {...guestDetail};
      tmpGuestDetail.name = name;
      dispatch(setGuestDetail(tmpGuestDetail));
      dispatch(setUserDetail(tmpGuestDetail));
      dispatch(recentUsersList(tmpGuestDetail));
      dispatch(setPreviousRouteStep('Guest'));
      dispatch(setLoginAsGuest(true));

      if (
        roomDetail?.pin_required &&
        tmpGuestDetail.role === 'guest' &&
        tmpGuestDetail.sub_role !== 'presenter'
      ) {
        dispatch(setPage('Passcode'));
      } else {
        dispatch(setPage('Connect'));
      }
    } else {
      simpleValidator.current.showMessages(true);
      forceUpdate(1);
    }
  };

  const ChangeName = event => {
    const updatedErr = {...err};
    if (event.target.value !== '') {
      delete updatedErr.name;
      setErr({...updatedErr});
    } else {
      updatedErr.name = 'Name field is required.';
      setErr({...updatedErr});
    }
    setName(event.target.value);
  };

  return (
    <div className="col-md-6 col-6 light-bg loginProcess pl-15">
      <h2>
        Type Your Name and
        <br /> Click Continue
      </h2>
      <form className="mt-3">
        <div className="form-group">
          <label className="">Name</label>
          <input
            spellCheck="false"
            type="text"
            name="name"
            id="guestName"
            maxLength="50"
            required=""
            className="form-control"
            onChange={ChangeName}
            value={name}
          />
          {simpleValidator.current.message('name', name, 'required', {
            messages: {
              default: 'Name field is required.',
            },
          })}
        </div>
        <div className="form-group mt-4">
          <button
            type="submit"
            id="guestLogin"
            className="btn btn-green"
            disabled={disabled}
            onClick={handleConnect}>
            Continue
          </button>
        </div>
        {!presenter_id && !host_id && (
          <div className="form-group mt-4">
            <button class="btn btn-orange ml-0" onClick={Login}>
              Hosts & Speakers, Log In
            </button>
          </div>
        )}

        {isBackButtonShow === true && (
          <div className="form-group backWrap">
            <a
              href="#"
              className="font30"
              aria-label="Close"
              onClick={RecentUser}>
              <svg width=".7em" viewBox="2 1.99 20 20">
                <path
                  fill="#6cb33f"
                  d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
                />
                <path
                  fill="#6cb33f"
                  d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
                />
              </svg>
            </a>
          </div>
        )}
      </form>
    </div>
  );
};

export default Guest;
