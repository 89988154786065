import React from 'react';
import { ToastContainer } from 'react-toastify';
import OuterHeader from './header';
import OuterFooter from './footer';

function Layout({ children }) {
  return (
    <>
      <ToastContainer
        hideProgressBar={true}
        draggable={false}
        autoClose={3000}
        closeOnClick={false}
      />
      <OuterHeader />

      {children}
      <OuterFooter />
    </>
  );
}
export default Layout;
