/* eslint-disable react/react-in-jsx-scope */
export default function IconTick() {
    return (<svg
        viewBox="5.29 7.29 14.41 11.12"
        width=".95em"
        className="tick-icon">
        <path
            fill="#6CB33F"
            d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"
        />
    </svg>)
}

