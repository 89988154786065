import React, { useEffect, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { getStreamingDuration } from '../../../utils/functions';

function Start({ stopwatchOffset }) {

    const { seconds, minutes, hours } = useStopwatch({
        autoStart: true,
        offsetTimestamp: stopwatchOffset,
    });

    return (
        <>
            {hours.toString().length === 1 ? '0' + hours : hours}:
            {minutes.toString().length === 1 ? '0' + minutes : minutes}:
            {seconds.toString().length === 1 ? '0' + seconds : seconds}
        </>
    );
}

export default function StreamingTimer() {
    const [stopwatchOffset, setStopWatchOffset] = useState('');

    useEffect(() => {
        const dateOffset = new Date();
        const streamingduration = getStreamingDuration();
        const seconds = Math.floor((streamingduration % (1000 * 60)) / 1000);

        dateOffset.setSeconds(dateOffset.getSeconds() + seconds);
        setStopWatchOffset(dateOffset);
    }, []);

    return (
        <div>{stopwatchOffset && <Start stopwatchOffset={stopwatchOffset} />}</div>
    );
}
