import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import RoomPermissionModal from './modals/roomPermission/roomPermissionModal';
import {
  setOpenLoginModel,
  setShowRoomPermissionModal,
} from '../../../reduxStore/confSlice';

function RoomPermission() {
  const {showRoomPermissionModal, localUser} = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    dispatch(setShowRoomPermissionModal(true));
  };
  const {roomDetail} = useSelector(state => state.conf);
  return (
    <>
      <a
        className="nav-link"
        onClick={() =>
          localUser.role !== 'guest'
            ? handleOpenModal()
            : dispatch(setOpenLoginModel(true))
        }>
        <i className="fas fa-volume-up bg-darkred roompericon"></i>
        <span>Room Permissions</span>
      </a>
      {showRoomPermissionModal && (
        <RoomPermissionModal
          roomDetail={roomDetail}
          closeRoomPermissionModal={() =>
            dispatch(setShowRoomPermissionModal(false))
          }
        />
      )}
    </>
  );
}

export default RoomPermission;
