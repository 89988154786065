import React, { useState, useRef, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Capitalize, validatePhone } from '../../../utils/functions';
import { STRIPE_TEST_KEY } from '../../../utils/constants';
import { DEFAULT_LAODING_MSG, PasswordErrorMsg, PasswordRegex, PasswordTooltipMsg, isMobileBrowser, signupPlans } from '../../../utils/util';
import { userSignup, validateEmail } from '../../../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from './layout';
import { IconQuestionMark } from '../../../icons';

const stripePromise = loadStripe(STRIPE_TEST_KEY);

function Signup() {
    const [selectedPlan, setPlan] = useState('');
    const [marketingOffer, setMarketingOffer] = useState('');
    const [activeStep, setActiveStep] = useState(1);
    const simpleValidator = useRef(new SimpleReactValidator());
    const billingValidator = useRef(new SimpleReactValidator());

    const [, forceUpdate] = useState();
    const [state, setState] = useState({
        name: '',
        phone: '',
        zip: '',
        email: '',
        password: '',
        confpassword: '',
        errorMsg: '',
        accepted: false,
        passwordMatched: false,
        isPasswordValid: true,
    });
    const [loader, setLoader] = useState(false);
    const { search } = useLocation();
    const navigate = useNavigate();
    const { email, name, phone, errorMsg, password, confpassword, passwordMatched } = state;

    useEffect(() => {
        const params = new URLSearchParams(search);
        const plan = params.get('plan');

        if (plan && signupPlans.includes(plan)) {
            if (plan === 'pro' || plan === 'ultimate') {
                setPlan(plan);
            } else {
                const offer = params.get('offer');
                if (offer) {
                    setPlan(plan);
                    setMarketingOffer(offer);
                } else {
                    navigate('/pricing');
                }
            }
        } else {
            navigate('/pricing');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    useEffect(() => {
        if (password === confpassword) {
            setState(prevSate => ({ ...prevSate, passwordMatched: true }));
        } else {
            setState(prevSate => ({ ...prevSate, passwordMatched: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confpassword]);

    useEffect(() => {
        if (errorMsg) {
            setState(prevSate => ({ ...prevSate, errorMsg: '' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    const handleInputValue = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setState(prevState => ({ ...prevState, [e.target.name]: value }));
    };

    const verifyEmail = async () => {
        if (simpleValidator.current.fieldValid('email')) {
            setLoader(true);
            const response = await validateEmail({ email });
            if (response.status === 200) {
                setLoader(false);
                setState(prevState => ({ ...prevState, errorMsg: '' }));
                if (selectedPlan === 'free') {
                    createUserAccount();
                } else {
                    setActiveStep(3);
                }

            } else {
                setLoader(false);
                setState(prevState => ({ ...prevState, errorMsg: response.data[0].msg }));
            }
        }
    };

    const handleForm = event => {
        event.preventDefault();

        if (simpleValidator.current.allValid()) {
            verifyEmail();
        } else {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        }

    };

    const createPaidAccount = async (event, stripe, elements) => {
        event.preventDefault();
        toast.dismiss();
        if (billingValidator.current.allValid()) {
            setLoader(true);
            const cardNumberElement = elements.getElement(CardNumberElement);
            const { token, error } = await stripe.createToken(cardNumberElement);

            if (token !== undefined) {
                createUserAccount(token.id);
            } else {
                setLoader(false);
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else {
            billingValidator.current.showMessages(true);
            forceUpdate(2);
        }
    };

    const createUserAccount = async (stripe_token = '') => {

        toast.dismiss();
        setLoader(true);
        const payload = {
            email,
            password,
            name,
            stripe_token,
            referral: '',
            phone,
            plan: selectedPlan,
            marketingid: marketingOffer,
            test_mode_id: 'LbvbmWbOrdKQPlHPoaTeYck',
        };

        const response = await userSignup(payload);

        if (response.status === 200) {
            setLoader(false);
            navigate(`/room-selection?id=${response.data[0].id}`);
        } else {
            setLoader(false);
            toast.error(response.data[0].msg, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };
    const billingForm = () => {
        const elfonts = [
            {
                family: 'Titillium Web',
                src: "local('Titillium Web Regular'), local('TitilliumWeb-Regular'), url(https://fonts.gstatic.com/s/titilliumweb/v9/NaPecZTIAOhVxoMyOr9n_E7fdMPmDQ.woff2) format('woff2')",
                style: 'normal',
                fontWeight: 400,
                unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
            },
        ];
        return (<>
            <Elements stripe={stripePromise} options={{ fonts: elfonts }}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        <form onSubmit={(e) => createPaidAccount(e, stripe, elements)} >
                            <p className="sub-heading">Step 2: Billing Info</p>
                            <div className="form-group d-flex">
                                <div>
                                    <label>Name On Credit Card:</label>
                                    <input spellCheck="false" type="text" value={state.name} minLength="4" name="name" onChange={(e) => handleInputValue(e)} className="form-control" placeholder="John Doe" />
                                    {billingValidator.current.message('name', state.name, 'required|max:26', { messages: { default: 'Name field is required.', max: 'Sorry, the name has to be 26 characters or less' } })}

                                </div>
                                <div>
                                    <label>Zip/Postal Code:</label>
                                    <input spellCheck="false" type="text" className="form-control" maxLength="6" name="zip" onChange={(e) => handleInputValue(e)} value={state.zip} placeholder="1248" />
                                    {billingValidator.current.message('zip', state.zip, 'required', { messages: { default: 'Zip code field is required.' } })}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Card Number:</label>
                                <CardNumberElement
                                    options={{

                                        style: {
                                            base: {
                                                fontSize:
                                                    `${isMobileBrowser() ? '14px' : '3vw'}`,
                                                color: '#2e3235',
                                                fontWeight: '400',
                                                fontFamily:
                                                    '"Titillium Web",sans-serif',
                                            },

                                        },
                                    }} className="form-control" />


                            </div>
                            <div className="form-group d-flex">
                                <div>
                                    <label>Exp. Date:</label>
                                    <CardExpiryElement options={{
                                        style: {
                                            base: {
                                                fontSize:
                                                    `${isMobileBrowser() ? '14px' : '7vw'}`,
                                                color: '#2e3235',
                                                fontWeight: '400',
                                                fontFamily:
                                                    '"Titillium Web",sans-serif',
                                            },

                                        },
                                    }} className="form-control" />

                                </div>
                                <div>
                                    <label>3-or-4-digit CVC Number:</label>
                                    <CardCvcElement options={{
                                        style: {
                                            base: {
                                                fontSize:
                                                    `${isMobileBrowser() ? '14px' : '7vw'}`,
                                                color: '#2e3235',
                                                fontWeight: '400',
                                                fontFamily:
                                                    '"Titillium Web",sans-serif',
                                            },

                                        },
                                    }} className="form-control" />

                                </div>
                            </div>
                            <div className="form-group">
                                <label>Phone Number:</label>
                                <input spellCheck="false" type="text" minLength="10" maxLength="15" name="phone" value={state.phone} onKeyUp={(e) => validatePhone(e)} onChange={(e) => handleInputValue(e)} className="form-control" placeholder="e.g. 999-999-9999" />
                                {billingValidator.current.message('phone', state.phone, 'required|min:10', { messages: { default: 'Phone number field is required.', min: 'Please add valid phone number.' } })}

                            </div>
                            <div className="form-group">
                                <label className="checkwrap">I have read and accept the <a href="terms" target="_blank"> <u>terms of services</u> </a>
                                    <input spellCheck="false" type="checkbox" name="accepted" checked={state.accepted} id="confirm" onChange={(e) => handleInputValue(e)} />
                                    <span className="checkmark" />
                                    {billingValidator.current.message('accepted', state.accepted, 'accepted', { messages: { default: 'Terms of service must be accepted.' } })}
                                </label>
                            </div>
                            <div className="form-group text-center mt-4">
                                <button type="submit" disabled={loader} className="btn btn-green d-inline-flex">{loader ? DEFAULT_LAODING_MSG : 'Next'}</button>
                            </div>
                        </form>

                    )}
                </ElementsConsumer>
            </Elements>

        </>);
    };

    return (
        <>
            {selectedPlan && (
                <Layout>
                    <div className="body-content feature-signup pricing_plans">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-5">
                                    <h1 className="heading semiBold">Sign Up For MEETN {Capitalize(selectedPlan)}</h1>
                                    {
                                        activeStep === 3 ? billingForm() :
                                            <form onSubmit={handleForm}>
                                                <p className="sub-heading">
                                                    Step 1: Create Your Account
                                                </p>
                                                {
                                                    selectedPlan === 'free' && <div className="form-group">
                                                        <label>Name:</label>
                                                        <input spellCheck="false" type="text" value={name} minLength="4" name="name" onChange={(e) => handleInputValue(e)} className="form-control" />
                                                        {simpleValidator.current.message('name', name, 'required|max:26', { messages: { default: 'Name field is required.', max: 'Sorry, the name has to be 26 characters or less' } })}
                                                    </div>
                                                }

                                                <div className="form-group">
                                                    <label>Primary Email Address (For Logging In):</label>
                                                    <div className="form-control-container">
                                                        <input type="email" name="email" maxLength="100" value={email} onChange={(e) => handleInputValue(e)} className="form-control" />
                                                    </div>
                                                    {simpleValidator.current.message('email', email, 'required|email', { messages: { default: 'Primary Email is required.', email: 'Primary email must be a valid email address.' } })}
                                                    {errorMsg && simpleValidator.current.fieldValid('email') && <div className="srv-validation-message">{errorMsg}</div>}
                                                </div>

                                                <div className="form-group">
                                                    <label>Desired Password:</label>
                                                    <div className="form-control-container">
                                                        <input spellCheck="false" type="password" minLength="4" maxLength="20" name="password" value={password} onChange={(e) => handleInputValue(e)} className="form-control" autoComplete="new-password" />

                                                        <a className="tooltip_container tooltip_container--right" data-tooltip={PasswordTooltipMsg}>
                                                            <span className="with_circle">
                                                                <IconQuestionMark />
                                                            </span>
                                                        </a>

                                                    </div>

                                                    {simpleValidator.current.message('password', password, ['required', { min: 4 }, { regex: PasswordRegex }], { messages: { default: 'Desired password is required.', min: 'Desired password must be minimum 4 characters.', regex: PasswordErrorMsg } })}

                                                </div>

                                                <div className="form-group">
                                                    <label>Confirm Password:</label>
                                                    <div className="form-control-container">
                                                        <input spellCheck="false" type="password" minLength="4" maxLength="20" className="form-control" id="confpassword" name="confpassword" value={confpassword} onChange={(e) => handleInputValue(e)} />
                                                        {simpleValidator.current.message('confpassword', confpassword, `required|in:${password}`, { messages: { in: 'The confirm password must be equal to the password', default: 'Confirm Password is required.' } })}

                                                        {confpassword !== '' && passwordMatched ? <>
                                                            <i className="fas fa-check abso-icon text-green" />
                                                        </> : confpassword !== '' ?
                                                            <>
                                                                <i className="fas fa-remove abso-icon text-red" />
                                                            </> : ''
                                                        }
                                                    </div>
                                                </div>


                                                {
                                                    selectedPlan === 'free' && <div className="form-group">
                                                        <label>Phone Number:</label>
                                                        <input spellCheck="false" type="text" minLength="10" maxLength="15" name="phone" value={state.phone} onKeyUp={(e) => validatePhone(e)} onChange={(e) => handleInputValue(e)} className="form-control" placeholder="e.g. 999-999-9999" />
                                                        {simpleValidator.current.message('phone', state.phone, 'required|min:10', { messages: { default: 'Phone number field is required.', min: 'Please add valid phone number.' } })}

                                                    </div>
                                                }
                                                {/* <div className="form-group  mb-1 refered_by_text">

                                    <small><span>Referred by</span> </small>

                                </div> */}
                                                <div className="form-group text-center mt-4">

                                                    <button type="Submit" disabled={loader} className="btn btn-green d-inline-flex">
                                                        {loader ? DEFAULT_LAODING_MSG : 'Next'}
                                                    </button>
                                                </div>
                                            </form>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>

                </Layout>

            )}
        </>

    );

}

export default Signup;
