const buildType = 'Live'; // Live / Beta / Dev

export const baseUrl = buildType === 'Dev' ? 'https://dev.meetn.com' : buildType === 'Beta' ? 'https://beta.meetn.com' : 'https://meetn.com';


export const validationParam = {
  email: 'email',
  password: 'password',
  name: 'name',
  room: 'room',
};
export const FB_APP_ID = '358830395840314';

export const YOUTUBE_CLIENT_ID =
  '299691178513-jvlchp5cddfjs2b5u3jhqpmmrq3dhv2b.apps.googleusercontent.com';
export const YOUTUBE_API_KEY = 'AIzaSyDjbKwcbOipUI2hYhym0bDuRjWN-QCJJ4g';
export const YOUTUBE_CLIENT_SCOPE = 'https://www.googleapis.com/auth/youtube';
export const YOUTUBE_CLIENT_LOAD =
  'https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest';

export const TWITCH_CLIENT_ID = 'zyp09qaeiydbup3rujyq539rs7059k';
export const BUNDLE_BASE_URL = `${baseUrl}/Bundle?ID=`;

export const MOBILE_DOWNLOAD_LINK_ANDROID =
  'https://play.google.com/store/apps/details?id=com.meetn.app';
export const MOBILE_DOWNLOAD_LINK_IOS =
  'https://apps.apple.com/us/app/meetn/id1479818305?ls=1s';

export const ANDROID_APP_DOWNLOAD_LINK =
  'https://download.meetn.com/android/MEETN-Android.apk';
export const ANDROID_BETA_APP_DOWNLOAD_LINK =
  'https://download.meetn.com/android/MEETN-Android-BETA.apk';

export const STRIPE_TEST_KEY = 'pk_test_AL7EmLP1jHeQPRUuZGuUcfBx00NUWnfk9a';

export const socketURL = 'wss://api.meetn.com';
export const apiBaseUrl = 'https://api.meetn.com';

export const pageDescription = {
  homePage: 'This is home Page',
  pricingPage: 'This is pricing Page',
  supportPage: 'This is support Page',
};
export const pageTitle = {
  homePage: 'Home',
  pricingPage: 'Pricing',
  supportPage: 'Support',
};
