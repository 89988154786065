import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWidgetClick } from '../../../../reduxStore/confSlice';
import WidgetList from './widgetList';
import ParticularWidget from './particularWidget';

const Widgets = () => {
  const { widgetClick, roomDetail, localUser } = useSelector(state => state.conf);
  const [isWidgetList, setIsWidgetList] = useState(true);
  const [particularWidgetData, setParticularWidgetData] = useState({});
  const { hide_widgets_for_guests } = roomDetail;
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(particularWidgetData).length > 0) {
      setIsWidgetList(false);
    } else {
      setIsWidgetList(true);
    }
  }, [particularWidgetData]);

  useEffect(() => {
    if (hide_widgets_for_guests && localUser.role === 'guest') {
      dispatch(setWidgetClick(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hide_widgets_for_guests]);

  return (
    <>
      <div
        className={`tab-pane ${widgetClick ? 'active show' : ''}`}
        id="extensions"
        role="tabpanel"
        aria-labelledby="extenions-tab">
        <div className="extenions-header">
          <h5
            className={`semiBold font16 mb-0 sharing-back-arrow ml-auto mr-auto`}
            id="extensions-tab">
            {isWidgetList ? (
              ''
            ) : (
              <a
                className="sharing-back-arrow_icon"
                title="Back to Widgets List"
                onClick={() => setParticularWidgetData({})}>
                <svg viewBox="7.59 6.29 7.12 11.41" height="1em">
                  <path
                    fill="#ffffff"
                    d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"
                  />
                </svg>
                <small>List</small>
              </a>
            )}
            <span className={`webpage-title`}>{`${isWidgetList ? 'Widgets' : particularWidgetData?.extension
              }`}</span>
          </h5>

          <div
            className="chat-close text-grey float-right"
            onClick={e => dispatch(setWidgetClick(false))}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="6.29 6.34 11.31 11.31" height=".8em"><path fill="#ffffff" d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg> */}
            <svg width=".7em" viewBox="6.29 6.34 11.31 11.31">
              <path
                fill="#93a5ad"
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              />
            </svg>
          </div>
        </div>

        {isWidgetList ? (
          <WidgetList setParticularWidgetData={setParticularWidgetData} />
        ) : (
          <ParticularWidget particularWidgetData={particularWidgetData} />
        )}
      </div>
    </>
  );
};

export default Widgets;
