import React, {useRef, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {useDispatch, useSelector} from 'react-redux';
import {setForgotModal, setLoginModal} from '../../../../reduxStore/mainSlice';
import {CommonModal} from '../../../common/web';
import SimpleReactValidator from 'simple-react-validator';
import {toast} from 'react-toastify';
import swal from 'sweetalert';
import {useNavigate} from 'react-router-dom';
import {resetPassword, userLogin} from '../../../../utils/api';
import {recentUsersList} from '../../../../utils/functions';
import {setLoggedInUserData} from '../../../../reduxStore/persistSlice';

function ForgotPassword() {
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
  });
  const [loader, setLoader] = useState(false);
  const [, forceUpdate] = useState();

  const setStateHandler = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const closeForgotPasswordHandler = () => {
    dispatch(setForgotModal(false));
    dispatch(setLoginModal(true));
  };

  const submitForm = e => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoader(true);
      resetPassword({
        email: state.email,
      }).then(async res => {
        if (res.status !== 500) {
          if (res.status === 200) {
            toast.success(
              'Email has been sent to your email address. Please check your email.',
              {
                position: toast.POSITION.TOP_CENTER,
              },
            );
            closeForgotPasswordHandler();
          } else {
            toast.error(res.data[0].msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoader(false);
          }
        } else {
          swal({
            title:
              'Something went wrong, unable to connect to meetn, please check your network.',
            closeOnClickOutside: false,
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: false,
          }).then(() => {
            navigate('/');
          });
        }
      });
    } else {
      validator.current.showMessages(true);
      forceUpdate(1);
    }
  };

  const forgotBody = () => {
    return (
      <>
        <form onSubmit={e => submitForm(e)}>
          <div className="form-group forgot-email modal_min_height--sm mb-0">
            <div class="form-group">
              <label class="">Email</label>
              <input
                spellCheck="false"
                type="email"
                name="email"
                value={state.email}
                onChange={e => setStateHandler(e)}
                maxlength="50"
                required=""
                class="form-control"
              />
              {validator.current.message(
                'email',
                state.email,
                'required|email',
                {
                  messages: {
                    default: 'Email field is required.',
                    email: 'Email must be a valid email address.',
                  },
                },
              )}
            </div>
          </div>
          <div class="form-group text-right mt-3 modal-footer-container mb-0">
            <button
              type="button"
              class="btn-link"
              data-dismiss="modal"
              onClick={() => closeForgotPasswordHandler()}>
              Cancel
            </button>
            <button type="submit" class="btn btn-green abso-btn">
              {loader ? 'Please Wait...' : 'Submit'}
            </button>
          </div>
        </form>
      </>
    );
  };

  const loginObject = {
    heading: 'Forgot Password',
    body: forgotBody,
    show: true,
    classNameDialog: 'modal-sm forgot-password',
    headingSize: 'h5',
  };

  return (
    <>
      <CommonModal {...loginObject} />
    </>
  );
}
export default ForgotPassword;
