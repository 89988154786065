import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EditProfileModal from './modals/editProfileModal';
import {setShowEditProfileModal} from '../../../reduxStore/confSlice';

function EditProfile() {
  const {showEditProfileModal} = useSelector(state => state.conf);
  const dispatch = useDispatch();
  const handleOpenModal = e => {
    e.preventDefault();
    dispatch(setShowEditProfileModal(true));
  };
  return (
    <>
      <a className="nav-link" onClick={e => handleOpenModal(e)}>
        <div className="menu_icons">
          <img
            height="20"
            width="20"
            src="assets/images/edit-profile-menu.png"
          />
        </div>
        <span className="pl-2">Edit Profile</span>
      </a>
      {showEditProfileModal && <EditProfileModal />}
    </>
  );
}

export default EditProfile;
