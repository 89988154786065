import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';

import Guest from './guest';
import {Conference, LargeEvent} from '../../conference';
import Connect from './connect';
import ConnectPhone from './connectPhone';
import ForgotPassword from './forgotPassword';
import Login from './login';
import AVSettings from './avSettings';
import Header from './header';
import RecentUser from './recentUser';
import {setPage, setUserDetail} from '../../../reduxStore/confSlice';
import {LoaderEffect} from '../../common';
import {setGoToSignIn} from '../../../reduxStore/persistSlice';
import {useSearchParams} from 'react-router-dom';
import EventWelcome from './eventWelcome';
import Passcode from './passcode';

const JoinFlow = () => {
  const dispatch = useDispatch();
  const {roomDetail, room, page} = useSelector(state => state.conf);
  const {recentUsers, AVPageRefresh, loggedInUserData, goToSignIn} =
    useSelector(state => state.persist);
  const [loaderEffect, setloaderEffect] = useState(true);
  const [searchParams] = useSearchParams();
  const meeting_id = searchParams.get('id');

  useEffect(() => {
    dispatch(setPage('Guest'));

    //
    document.getElementsByTagName('body')[0].className += ' conference-body';
    setloaderEffect(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const joinFlowPage = () => {
    switch (page) {
      case 'Login':
        return <Login />;
      case 'AVSettings':
        return <AVSettings />;
      case 'Guest':
        return <Guest />;
      case 'ForgotPassword':
        return <ForgotPassword />;
      case 'Connect':
        return <Connect />;
      case 'ConnectPhone':
        return <ConnectPhone />;
      case 'RecentUser':
        return <RecentUser />;
      case 'Event':
        return <EventWelcome />;
      case 'Passcode':
        return <Passcode />;
      default:
        return <Guest />;
    }
  };

  const renderJoinFlow = () => {
    return (
      <>
        <ToastContainer
          hideProgressBar={true}
          draggable={false}
          autoClose={3000}
          closeOnClick={false}
        />
        <Header />
        <div className="body-content page-content updatedWrap joinFlowWrap ">
          <div className="container-fluid">
            <div className="row updatedWraprow">
              <div
                className={
                  page === 'AVSettings'
                    ? 'col-md-5 col-5 dark-bg imgwrap eventImgwrap'
                    : page === 'RecentUser' && recentUsers.length >= 3
                    ? 'col-md-4 col-4 dark-bg imgwrap eventImgwrap'
                    : 'col-md-6 col-6 dark-bg imgwrap eventImgwrap'
                }>
                {roomDetail?.event_id && roomDetail?.event_photo ? (
                  <img
                    src={roomDetail?.event_photo}
                    className="img-fluid rounded-circle"
                  />
                ) : (
                  <h4>{roomDetail?.room_headline}</h4>
                )}
              </div>
              {joinFlowPage()}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderPage = () => {
    switch (page) {
      case 'JoinFlow':
        return renderJoinFlow();
      case 'Conference':
        return <Conference />;
      case 'Lem':
        return <LargeEvent />;
      default:
        return renderJoinFlow();
    }
  };

  return loaderEffect ? <LoaderEffect /> : <> {renderPage()}</>;
};

export default JoinFlow;
