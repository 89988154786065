/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../../reduxStore/confSlice';
import { useTimer } from 'react-timer-hook';
import { alertMsg } from '../../common/web/alert';

function EventWelcome() {
    const { recentUsers } = useSelector(state => state.persist);
    const { roomDetail } = useSelector(state => state.conf);
    // let time_difference = 225837;
    let time_difference = roomDetail?.time_difference;
    const time = new Date();
    time.setSeconds(time.getSeconds() + time_difference);
    const dispatch = useDispatch();
    const { seconds, minutes, hours, days, start } = useTimer({ expiryTimestamp: time, autoStart: false, onExpire: () => { showCountDownTimer(false); } });

    const [countDownTimer, showCountDownTimer] = useState(false);

    useEffect(() => {
        if (roomDetail?.enable_countdown_timer && time_difference > 0) {
            start();
            showCountDownTimer(true);
        }
    }, []);

    const checkMeetingTime = () => {
        if (countDownTimer && remainingTime > thirtyMinutes) {
            alertMsg({ msg: 'Please return to this page at event time.', type: 'info' });
        } else {
            if (recentUsers.length > 0) {
                dispatch(setPage('RecentUser'));
            } else {
                dispatch(setPage('Guest'));
            }
        }
    };

    console.log('hours', days, hours);

    const remainingTime = (parseInt(days) * 24 * 60 * 60 * 1000) + (parseInt(hours) * 60 * 60 * 1000) + (parseInt(minutes) * 60 * 1000);
    const thirtyMinutes = 30 * 60 * 1000;
    return (<>
        <div className="col-md-6 col-6 light-bg text-center" >
            {
                roomDetail?.status === 'completed' || roomDetail?.status === 'cancelled' ? 'This meeting is ' + roomDetail?.status : <form>
                    <div className="form-group">
                        {countDownTimer ?
                            <>
                                <p className="font15">The Meetn starts in...</p>
                                <div className="col-md-12 p-0 d-flex justify-center">

                                    <span className="circlewrap">
                                        <span className="circlediv">{days}</span>Days
                                    </span>

                                    <span className="circlewrap">
                                        <span className="circlediv">{hours}</span>Hours
                                    </span>


                                    <span className="circlewrap">
                                        <span className="circlediv">{minutes}</span>Minutes
                                    </span>


                                    <span className="circlewrap">
                                        <span className="circlediv">{seconds}</span>Seconds
                                    </span>

                                </div>
                            </>
                            : null
                        }
                    </div>
                    <div className="form-group">
                        <button onClick={checkMeetingTime} type="button" className="btn btn-green abso-btn">{(countDownTimer && remainingTime > thirtyMinutes) ? 'Please return to this page at event time.' : 'Enter the Meetn'}</button>

                    </div>
                </form>
            }


        </div>

    </>);
}

export default EventWelcome