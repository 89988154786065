import React, { useRef, useState, useEffect } from 'react';
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBackButtonShow,
  setUserDetail,
  setPage,
  setPreviousRouteStep,
} from '../../../reduxStore/confSlice';
import { recentUsersList } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { hostLogin } from '../../../utils/socket';

const Login = props => {
  const dispatch = useDispatch();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [states, setState] = useState({
    email: '',
    password: '',
    rememberMe: true,
  });
  const { roomDetail } = useSelector(state => state.conf);
  const { previousRouteStep } = useSelector(state => state.conf);
  const [, forceUpdate] = useState();
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const stepBackHandler = e => {
    e.preventDefault();
    dispatch(setPage(previousRouteStep));
    dispatch(setBackButtonShow(false));
  };

  const login = e => {
    e.preventDefault();
    const { email, password, rememberMe } = states;
    if (simpleValidator.current.allValid()) {
      setDisabled(true);
      hostLogin({
        room: roomDetail?.room,
        email: email,
        password: password,
      }).then(ret => {
        console.log('login', ret);
        if (ret?.status === 200) {
          dispatch(setUserDetail(ret?.data?.login));
          if (rememberMe) {
            dispatch(recentUsersList(ret?.data?.login));
          }
          if (ret?.data?.login.email && (ret?.data?.login.role === 'guest' && ret?.data?.login.sub_role !== 'presenter')) {
            swal({
              title: `Welcome ${ret?.data?.login.email}. You are a GUEST in this room.`,
              closeOnClickOutside: false,
              buttons: ['Log in as Someone Else', 'Continue'],
              dangerMode: true,
            }).then(Continue => {
              if (Continue) {
                if (roomDetail?.pin_required) {
                  dispatch(setPage('Passcode'));
                } else {
                  dispatch(setPage('Connect'));
                }
                dispatch(setPreviousRouteStep('Login'));
              }
            });
          } else {
            dispatch(setPage('Connect'));
            dispatch(setPreviousRouteStep('Login'));
          }
        } else if (ret?.status === 422) {
          toast.error(ret?.data[0].msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          swal({
            title:
              'Something went wrong, unable to connect to meetn, please check your network.',
            closeOnClickOutside: false,
            buttons: {
              cancel: false,
              confirm: true,
            },
            dangerMode: false,
          }).then(() => {
            navigate('/');
          });
        }
        setDisabled(false);
      });
    } else {
      simpleValidator.current.showMessages(true);
      forceUpdate(1);
    }
  };

  const ForgotPassword = e => {
    e.preventDefault();
    dispatch(setPage('ForgotPassword'));
  };

  const CheckInputValue = e => {
    if (e.target.name === 'rememberMe') {
      let val = e.target.checked ? true : false;
      setState({ ...states, [e.target.name]: val });
    } else {
      setState({ ...states, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className="col-md-6 col-6 light-bg loginProcess pl-15">
      <h2>Log into your account</h2>
      <form className="mt-3 login-button">
        <div className="form-group">
          <label className="">Email</label>
          <input
            spellCheck="false"
            type="email"
            name="email"
            maxLength="100"
            required=""
            className="form-control"
            value={states.email}
            onChange={e => CheckInputValue(e)}
          />
          {simpleValidator.current.message(
            'email',
            states.email,
            'required|email',
            {
              messages: {
                default: 'Email field is required.',
                email: 'Email must be a valid email address.',
              },
            },
          )}
        </div>
        <div className="form-group">
          <label className="">Password</label>
          <input
            spellCheck="false"
            type="password"
            name="password"
            maxLength="20"
            required=""
            className="form-control"
            value={states.password}
            onChange={e => CheckInputValue(e)}
          />
          {simpleValidator.current.message(
            'password',
            states.password,
            'required',
            { messages: { default: 'Password field is required.' } },
          )}
        </div>
        <div className="form-group">
          <label className="checkwrap justify-left hAuto">
            Remember me
            <input
              spellCheck="false"
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              checked={states.rememberMe}
              onChange={e => CheckInputValue(e)}
            />
            <span className="checkmark" />
          </label>
        </div>
        <div className="form-group mt-4">
          <button className="btn btn-green" disabled={disabled} onClick={login}>
            {disabled ? <div className="spinner" /> : 'Join'}
          </button>
        </div>
        <div className="form-group mt-4">
          <a href="#" className="forgotP" onClick={ForgotPassword}>
            <small className="text-green">
              <b>Forgot Password?</b>
            </small>
          </a>
        </div>
        <div className="form-group backWrap">
          <a
            href="#"
            className="font30"
            aria-label="Close"
            onClick={stepBackHandler}>
            <svg width=".7em" viewBox="2 1.99 20 20">
              <path
                fill="#6cb33f"
                d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
              />
              <path
                fill="#6cb33f"
                d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
              />
            </svg>
          </a>
        </div>
      </form>
    </div>
  );
};

export default Login;
