import React from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {setPage, setPreviousRouteStep} from '../../../reduxStore/confSlice';

const ConnectPhone = props => {
  const dispatch = useDispatch();

  const handleConnect = e => {
    e.preventDefault();
    dispatch(setPage('Connect'));
  };

  const handleAVSettings = e => {
    e.preventDefault();
    dispatch(setPage('AVSettings'));
    dispatch(setPreviousRouteStep('ConnectPhone'));
  };
  const handleJoin = e => {
    e.preventDefault();
    dispatch(setPage('Conference'));
  };
  const params = useParams();

  return (
    <div className="col-md-6 col-6 light-bg p-equal MobileContent">
      <h2>Join via Phone Call</h2>
      <div className="join_with_phone_container">
        <div className="form-group plus-margin drop-down-wrap select_custom">
          <label>Number to dial:</label>
          <select className="form-control" id="numberToDial">
            <option value="+1 360-777-7000">
              USA (Nationwide) +1 360-777-7000
            </option>
          </select>
        </div>
        <p className="minus-margin TextHighlight">
          <span className="text-red mb-10 d-inline-block">
            PIN code to use: <b className="font-18">96346</b>
          </span>
          <br />
          Or,
          <a href="" onClick={handleAVSettings}>
            {' '}
            Connect by Computer.
          </a>
          <br />
          <span>When you are in the meetn, click "'Next'</span>
        </p>
        <div className="row mt-3 mb-3 pb-3">
          <div className="col-md-4 col-4 backWrap pl-0">
            <a
              href=""
              className="font30 backwrap"
              onClick={handleConnect}
              aria-label="Close">
              <svg width=".7em" viewBox="2 1.99 20 20">
                <path
                  fill="#6cb33f"
                  d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z"
                />
                <path
                  fill="#6cb33f"
                  d="m12.012 7.989-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z"
                />
              </svg>
            </a>
          </div>
          <div className="col-md-8 col-8 text-right pr-0">
            <button
              type="button"
              onClick={handleJoin}
              className="btn btn-green minwidth100">
              Next
            </button>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <p className="">
          NOTE: When you click 'next', you will VIEW the meeting
          <br />
          on this device, but use your phone to HEAR and SPEAK.
        </p>
      </div>
    </div>
  );
};

export default ConnectPhone;
