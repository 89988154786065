import {apiBaseUrl} from './constants';

export const apiClient = async (url = '', options = {}) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 30 * 1000);
  try {
    // eslint-disable-next-line no-undef
    const request = new Request(`${apiBaseUrl}/api/${url}`, {
      method: options.method,
      headers: new Headers({'Content-Type': 'application/json'}),
      body: JSON.stringify(options.body),
      signal: controller.signal,
    });

    return await fetch(request).then(async res => {
      clearTimeout(timeoutId);
      if (res?.status === 200 || res?.status === 422) {
        let data = await res.json();

        return {status: res.status, data};
      } else {
        return {status: 500, data: {msg: ['Something went wrong']}};
      }
    });
  } catch (error) {
    clearTimeout(timeoutId);
    return {status: 500, data: {msg: ['Something went wrong']}};
  }
};

export const validateEmail = async req => {
  return await apiClient('validate/email', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const userSignup = async req => {
  return await apiClient('signup', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const validateTrialRoom = async req => {
  return await apiClient('validate/trialRoom', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const createTrialRoom = async req => {
  return await apiClient('create/trialRoom', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const resetPassword = async req => {
  return await apiClient('reset/password', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const userLogin = async req => {
  return await apiClient('login', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const getUserRooms = async req => {
  return await apiClient('rooms', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const resetPasswordProcess = async req => {
  return await apiClient('reset/password/process', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const validateInvite = async req => {
  return await apiClient('validate/invite', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const createUserAccount = async req => {
  return await apiClient('invite/signup', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};
export const validateEvent = async req => {
  return await apiClient('validate/event', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};

export const validateEventPasscode = async req => {
  return await apiClient('event/passcode', {
    method: 'POST',
    body: req,
  }).then(res => {
    return res;
  });
};
