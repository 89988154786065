import {createSlice} from '@reduxjs/toolkit';

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    routeDetail: {},
    loginModal: false,
    forgotModal: false,
  },
  reducers: {
    setRouteDetail: (state, action) => {
      state.routeDetail = action.payload;
    },
    setLoginModal: (state, action) => {
      state.loginModal = action.payload;
    },
    setForgotModal: (state, action) => {
      state.forgotModal = action.payload;
    },
  },
});

export const {setRouteDetail, setLoginModal, setForgotModal} =
  mainSlice.actions;

export default mainSlice.reducer;
