import JitsiMeetJS from './_';
import {
  setAudioInputList,
  setAudioOutputList,
  setVideoInputList,
  setCameraDevice,
  setMicDevice,
  setSpeakerDevice,
  trackAdd,
  trackRemove,
  setDominantSpeaker,
  trackMediaTypeUpdate,
  trackMuteUpdate,
  disposeLocalTracks,
  setMediaPermissionPrompt,
  updateLocalVideoTrackMute,
  addLocalVideoTrack,
  addLocalDesktopVideoTrack,
  muteLocalDesktopVideoTrack,
  setUserDetail,
  setNoiseSuppressionEnabledState,
  setConferenceFailed,
  setConferenceLeft,
  setFailedCameraAlert,
  addLocalAudioTrack,
  setHasVideoPermission,
  setHasAudioPermission,
  addLocalDesktopAudioTrack,
  sethasVideoEnable,
  sethasAudioEnable,
  SetLocalParticipantKicked,
  setKickedOutRequest,
} from '../reduxStore/confSlice';
import {conference} from '../app';
import {updParticipant, socketDisconnect} from '../utils/socket';
import {
  isLemMode,
  getParticipantsList,
  updateLastSpeakerTime,
} from '../utils/functions';

// import {config} from './config';
import {store} from '../reduxStore/store';
import createTFLiteModule from './tflite/tflite';
import createTFLiteSIMDModule from './tflite/tflite-simd';
export {JitsiMeetJS as default};
export const browser = JitsiMeetJS.util.browser;
export const JitsiConferenceErrors = JitsiMeetJS.errors.conference;
export const JitsiConferenceEvents = JitsiMeetJS.events.conference;
export const JitsiConnectionErrors = JitsiMeetJS.errors.connection;
export const JitsiConnectionEvents = JitsiMeetJS.events.connection;
export const JitsiConnectionQualityEvents =
  JitsiMeetJS.events.connectionQuality;
export const JitsiDetectionEvents = JitsiMeetJS.events.detection;
export const JitsiE2ePingEvents = JitsiMeetJS.events.e2eping;
export const JitsiMediaDevicesEvents = JitsiMeetJS.events.mediaDevices;
export const JitsiParticipantConnectionStatus =
  JitsiMeetJS.constants.participantConnectionStatus;
export const JitsiTrackStreamingStatus =
  JitsiMeetJS.constants.trackStreamingStatus;
export const JitsiRecordingConstants = JitsiMeetJS.constants.recording;
export const JitsiSIPVideoGWStatus = JitsiMeetJS.constants.sipVideoGW;
export const JitsiTrackErrors = JitsiMeetJS.errors.track;
export const JitsiTrackEvents = JitsiMeetJS.events.track;
export const JitsiMediaDevices = JitsiMeetJS.mediaDevices;
export const createLocalTracks = JitsiMeetJS.createLocalTracks;
let dispatch = null;
let jitsiConnection = null;
let room = null;
let listener = true;
let localDesktopAudioTrack = null;
window.setNoiseSuppressionEnabled = setNoiseSuppressionEnabled;
window.store = store;
const config = {
  hosts: {
    muc: null,
    focus: null,
    domain: null,
  },
  disableSimulcast: false,
  constraints: {
    video: {
      height: {
        ideal: 1080,
        max: 1080,
        min: 180,
      },
      width: {
        ideal: 1920,
        max: 1920,
        min: 360,
      },
    },
  },
  enableP2P: false,
  p2p: {
    enabled: false,
    enableUnifiedOnChrome: true,
    disableH264: true,
    useStunTurn: true,
  },
  useStunTurn: true,
  useTurnUdp: false,
  bosh: null,
  websocket: null,
  websocketKeepAliveUrl: null,
  clientNode: null,
  desktopSharingSources: ['screen', 'window'],
  desktopSharingChromeSources: ['screen', 'window', 'tab'],
  enableLipSync: false,
  enableSaveLogs: false,
  disableRtx: false,
  enableScreenshotCapture: false,
  channelLastN: 25,
  videoQuality: {
    maxBitratesVideo: {
      low: 200000,
      standard: 500000,
      high: 1500000,
    },
  },
  useNewBandwidthAllocationStrategy: true,
  startBitrate: '800',
  disableAudioLevels: false,
  stereo: false,
  forceJVB121Ratio: -1,
  enableTalkWhileMuted: true,
  mouseMoveCallbackInterval: 1000,
  enableNoAudioDetection: true,
  enableNoisyMicDetection: true,
  disableLocalVideoFlip: false,
  hiddenDomain: null,
  enableUserRolesBasedOnToken: false,
  enableLayerSuspension: true,
  enableUnifiedOnChrome: true,
  enableWelcomePage: true,
  enableInsecureRoomNameWarning: false,
  e2eping: {
    pingInterval: -1,
  },
  abTesting: {},
  resolution: 720,
  maxFullResolutionParticipants: 50,
  desktopSharingFrameRate: {
    min: 30,
    max: 30,
  },
  hideDominantSpeakerBadge: true,
  firefox_fake_device: true,
  makeJsonParserHappy: 'even if last key had a trailing comma',
};
export const MEDIA_TYPE = {
  AUDIO: 'audio',
  PRESENTER: 'presenter',
  SCREENSHARE: 'screenshare',
  VIDEO: 'video',
};
let firstTime = false;
let tmpLocalVideoTrack = null;

export const isLocalParticipantKicked = () => {
  return store.getState().conf.localParticipantKicked;
};

export async function createLocalTracksFromStream(stream) {
  return JitsiMeetJS.createLocalTracksFromStream(stream).catch(error => {
    return Promise.reject(error);
  });
}

export function localTracksFromVideo(tracks) {
  const {roomDetail, localAudioTrack, localVideoTrack, localUser} =
    store.getState().conf;
  console.log('localTracks before=>', localAudioTrack, localVideoTrack);
  const audioTrack = tracks.find(track => track.getType() === MEDIA_TYPE.AUDIO);
  const videoTrack = tracks.find(track => track.getType() === MEDIA_TYPE.VIDEO);
  console.log('localTracks after=>', audioTrack, videoTrack);

  if (!firstTime) {
    if (roomDetail?.everyone_joins_muted) {
      console.log('join muted');
      audioTrack.mute();
    }
    firstTime = true;
    room.addTrack(audioTrack);
    room.addTrack(videoTrack);
    dispatch(addLocalAudioTrack({track: audioTrack, device: null}));
    dispatch(addLocalVideoTrack({track: videoTrack, device: null}));
    tmpLocalVideoTrack = videoTrack;
  } else {
    if (!localUser.audio) {
      audioTrack.mute();
    }
    room.replaceTrack(localAudioTrack.jitsiTrack, audioTrack).then(() => {
      dispatch(addLocalAudioTrack({track: audioTrack, device: null}));
    });
    if (localUser.video) {
      room.replaceTrack(localVideoTrack.jitsiTrack, videoTrack).then(() => {
        dispatch(addLocalVideoTrack({track: videoTrack, device: null}));
        tmpLocalVideoTrack = videoTrack;
      });
    }
  }
}

export function localVideoTrackMute() {
  return new Promise(resolve => {
    const localVideoTrack = store.getState().conf.localVideoTrack;
    if (localVideoTrack) {
      console.debug('Muting existing local video track.', localVideoTrack);
      // localVideoTrack.jitsiTrack.mute();
      room.removeTrack(localVideoTrack.jitsiTrack);
      store.dispatch(updateLocalVideoTrackMute(true));
      resolve({video: true});
    }
  });
}
export function localVideoTrackUnMute() {
  return new Promise((resolve, reject) => {
    const localVideoTrack = store.getState().conf.localVideoTrack;
    if (localVideoTrack) {
      console.debug(
        'UnMuting existing local video track.',
        localVideoTrack,
        tmpLocalVideoTrack,
      );
      room.addTrack(tmpLocalVideoTrack);

      // localVideoTrack.jitsiTrack.unmute();
      store.dispatch(updateLocalVideoTrackMute(false));
      resolve({video: true});
    }
  });
}
export function localAudioTrackMute() {
  const localAudioTrack = store.getState().conf.localAudioTrack;

  return new Promise(resolve => {
    if (localAudioTrack && !localAudioTrack?.jitsiTrack?.isMuted()) {
      console.debug('Muting existing local audio track.');
      localAudioTrack.jitsiTrack.mute();
      resolve({audio: true});
    } else {
      resolve({audio: false});
    }
  });
}
export function localAudioTrackUnMute() {
  return new Promise(resolve => {
    const micDevice = store.getState().conf.micDevice;
    const localAudioTrack = store.getState().conf.localAudioTrack;
    if (localAudioTrack?.jitsiTrack) {
      console.debug('Unmuting existing local audio track.', localAudioTrack);
      localAudioTrack.jitsiTrack.unmute();
      resolve({audio: true});
    } else {
      console.debug('Trying to create local audio track.');

      createAudioTracks(micDevice?.deviceId)
        .then(async track => {
          const trackData = {
            track: track[0],
            device: micDevice,
          };
          store.dispatch(micDevice);
          store.dispatch(addLocalAudioTrack(trackData));
          if (localDesktopAudioTrack !== null) {
            const localAudio = trackData.track;
            room.replaceTrack(localDesktopAudioTrack, trackData.track);
            let mixerEffect = new AudioMixerEffect(localDesktopAudioTrack);
            console.debug(
              `_switchToScreenSharing is mixing ${localDesktopAudioTrack} and ${localAudio}` +
                ' as a single audio stream',
            );
            await localAudio.setEffect(mixerEffect);
            resolve({audio: true});
          } else {
            room.addTrack(track[0]);
          }
          resolve({audio: true});
        })
        .catch(error => {
          console.error(error);
          resolve({audio: false});
        });
    }
  });
}
export function initdevices(d, roomName, domain) {
  return new Promise(resolve => {
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

    dispatch = d;
    config.hosts.muc = 'conference.' + domain;
    config.hosts.focus = 'focus.' + domain;
    config.hosts.domain = domain;
    config.bosh = 'https://' + domain + '/http-bind';
    config.serviceUrl =
      'wss://' + domain + '/xmpp-websocket' + `?room=${roomName}`;
    config.websocketKeepAliveUrl = 'https://' + domain + `?room=${roomName}`;
    config.clientNode = 'https://' + domain;
    config.hiddenDomain = 'recorder.' + domain;
    console.log(config);
    JitsiMeetJS.init(config);

    getAvailableDevices().then(getAvailableDevice => {
      let devices = groupDevicesByKind(getAvailableDevice);
      console.log('getAvailableDevices', devices);
      dispatch(setAudioInputList(devices.audioInput));
      dispatch(setVideoInputList(devices.videoInput));
      dispatch(setAudioOutputList(devices.audioOutput));
      if (devices.videoInput.length > 0) {
        dispatch(setCameraDevice(devices.videoInput[0]));
        console.log('setCameraDevice ', devices.videoInput[0]);
      } else {
        dispatch(setCameraDevice(null));
        console.log('setCameraDevice null');
      }
      if (devices.audioInput.length > 0) {
        dispatch(setMicDevice(devices.audioInput[0]));
        console.log('setMicDevice ', devices.audioInput[0]);
      } else {
        dispatch(setMicDevice(null));
        console.log('setMicDevice null');
      }

      if (devices.audioOutput.length > 0) {
        dispatch(setSpeakerDevice(devices.audioOutput[0]));
        console.log('setSpeakerDevice ', devices.audioOutput[0]);
      } else {
        dispatch(setSpeakerDevice(null));
        console.log('setSpeakerDevice null');
      }
      JitsiMediaDevices.addEventListener(
        JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED,
        deviceChangeListener,
      );
    });
    resolve();
  });
  // startConference(config, roomName);
}

const getAvailableDevices = () => {
  return new Promise(resolve => {
    const {mediaDevices} = JitsiMeetJS;

    if (
      mediaDevices.isDeviceListAvailable() &&
      mediaDevices.isDeviceChangeAvailable()
    ) {
      mediaDevices.enumerateDevices(devices => {
        resolve(devices);
      });
    } else {
      resolve([]);
    }
  });
};

const groupDevicesByKind = devices => {
  return {
    audioInput: devices.filter(
      device => device.kind === 'audioinput' && device.label !== '',
    ),
    audioOutput: devices.filter(
      device => device.kind === 'audiooutput' && device.label !== '',
    ),
    videoInput: devices.filter(
      device => device.kind === 'videoinput' && device.label !== '',
    ),
  };
};
export function setAudioOutputDevice(deviceId) {
  if (deviceId) {
    JitsiMediaDevices.setAudioOutputDevice(deviceId);
  }
}
const deviceChangeListener = availableDevices => {
  let devices = groupDevicesByKind(availableDevices);
  console.log('deviceChangeListener', devices);
  const state = store.getState();
  const audioInput = state.conf.audioInputList;
  const videoInput = state.conf.videoInputList;
  const audioOutput = state.conf.audioOutputList;
  // const localVideoTrack = state.conf.localVideoTrack;
  // const localUser = state.conf.localUser;
  const persistcameraDevice = store.getState().persist.persistcameraDevice;
  dispatch(setAudioInputList(devices.audioInput));
  dispatch(setVideoInputList(devices.videoInput));
  dispatch(setAudioOutputList(devices.audioOutput));
  if (devices.videoInput.length > 0) {
    if (JSON.stringify(videoInput) !== JSON.stringify(devices.videoInput)) {
      let tmpCameraDevice = devices.videoInput[0];
      if (persistcameraDevice) {
        const videoDevice = devices.videoInput.filter(device => {
          return persistcameraDevice.deviceId === device.deviceId;
        });
        if (videoDevice.length > 0) {
          tmpCameraDevice = persistcameraDevice;
        }
      }
      dispatch(setCameraDevice(tmpCameraDevice));
      console.log('setCameraDevice ', tmpCameraDevice);
    }
  } else {
    dispatch(setCameraDevice(null));
    console.log('setCameraDevice null');
  }
  if (devices.audioInput.length > 0) {
    console.log(JSON.stringify(audioInput), JSON.stringify(devices.audioInput));
    if (JSON.stringify(audioInput) !== JSON.stringify(devices.audioInput)) {
      dispatch(setMicDevice(devices.audioInput[0]));
      console.log('setMicDevice ', devices.audioInput[0]);
    }
  } else {
    dispatch(setMicDevice(null));
    console.log('setMicDevice null');
  }

  if (devices.audioOutput.length > 0) {
    console.log(audioOutput);
    console.log(devices.audioOutput);

    if (JSON.stringify(audioOutput) !== JSON.stringify(devices.audioOutput)) {
      dispatch(setSpeakerDevice(devices.audioOutput[0]));
      console.log('setSpeakerDevice ', devices.audioOutput[0]);
    }
  } else {
    dispatch(setSpeakerDevice(null));
    console.log('setSpeakerDevice null');
  }
};

export async function createTracks(options) {
  return JitsiMeetJS.createLocalTracks(options).catch(error => {
    return Promise.reject(error);
  });
}
export function createInitialLocalTracks(micDevice, cameraDevice) {
  const errors = {};
  const initialDevices = ['audio', 'video'];
  const cameraDeviceId = cameraDevice?.deviceId;
  const micDeviceId = micDevice?.deviceId;

  console.log('createInitialLocalTracks', cameraDeviceId, micDeviceId);
  if (listener) {
    JitsiMeetJS.mediaDevices.addEventListener(
      JitsiMediaDevicesEvents.PERMISSION_PROMPT_IS_SHOWN,
      browserName => {
        console.log(
          'mediaPermissionPromptVisibilityChanged',
          true,
          browserName,
        );
        dispatch(setMediaPermissionPrompt(true));
      },
    );
    listener = false;
  }
  let tryCreateLocalTracks;
  const timeout = browser.isElectron() ? 15000 : 60000;
  const audioOptions = {
    devices: ['audio'],
    timeout,
    firePermissionPromptIsShownEvent: true,
    micDeviceId,
  };

  tryCreateLocalTracks = createTracks({
    devices: initialDevices,
    timeout,
    firePermissionPromptIsShownEvent: true,
    cameraDeviceId,
    micDeviceId,
  })
    .catch(err => {
      errors.videoOnlyError = err;
      if (err.name === JitsiTrackErrors.TIMEOUT && !browser.isElectron()) {
        errors.audioOnlyError = err;
        return [];
      }

      return createTracks(audioOptions);
    })
    .catch(err => {
      errors.audioOnlyError = err;
      delete errors.videoOnlyError;
      return createTracks({
        devices: ['video'],
        firePermissionPromptIsShownEvent: true,
        cameraDeviceId,
      });
    })
    .catch(err => {
      errors.videoOnlyError = err;
      return [];
    });

  return {
    tryCreateLocalTracks,
    errors,
  };
}
export async function createVideoTracks(cameraDeviceId) {
  let options = {
    devices: ['video'],
    firePermissionPromptIsShownEvent: true,
    fireSlowPromiseEvent: true,
    resolution: 720,
  };
  if (typeof cameraDeviceId !== 'undefined' && cameraDeviceId !== null) {
    options.cameraDeviceId = cameraDeviceId;
  }
  return JitsiMeetJS.createLocalTracks(options).catch(error => {
    return Promise.reject(error);
  });
}

export async function createAudioTracks(micDeviceId) {
  let options = {
    devices: ['audio'],
    firePermissionPromptIsShownEvent: true,
    fireSlowPromiseEvent: true,
  };
  if (typeof micDeviceId !== 'undefined' && micDeviceId !== null) {
    options.micDeviceId = micDeviceId;
  }

  return JitsiMeetJS.createLocalTracks(options).catch(error => {
    return Promise.reject(error);
  });
}

export function startConference(roomName) {
  // const roomName = room; //getstate().meetn.currentRoom.toLowerCase();
  // console.log('startConference', jitsiConnection, roomName);

  roomName = roomName?.toLowerCase();
  if (!jitsiConnection) {
    connect(roomName)
      .then(connection => {
        jitsiConnection = connection;
        room = connection.initJitsiConference(roomName, config);
        const lemMode = isLemMode();
        if (lemMode) {
          room = {
            isJoined: () => {
              return true;
            },
            getParticipants: () => {
              return getParticipantsList();
            },
            getConnectionState: () => {
              return 'connected';
            },
          };
          conference._room = room;
        } else {
          conference._room = room;
        }
      })
      .catch(() => {});
  }
}

export async function connect(_roomName) {
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
  JitsiMeetJS.init(config);
  // console.log('connect', config);
  const connection = new JitsiMeetJS.JitsiConnection(null, null, config);
  return new Promise((resolve, reject) => {
    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_ESTABLISHED,
      handleConnectionEstablished,
    );
    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_FAILED,
      handleConnectionFailed,
    );

    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_FAILED,
      connectionFailedHandler,
    );

    connection.connect();

    function connectionFailedHandler(error, message, credentials, details) {
      /* eslint-enable max-params */
      // APP.store.dispatch(
      //     connectionFailed(
      //         connection, {
      //             credentials,
      //             details,
      //             message,
      //             name: error
      //         }));
      console.log('isFatalJitsiConnectionError', error);
      if (isFatalJitsiConnectionError(error)) {
        console.log('isFatalJitsiConnectionError2', error);
        // connection.removeEventListener(
        //     JitsiConnectionEvents.CONNECTION_FAILED,
        //     connectionFailedHandler);
      }
    }

    function unsubscribe() {
      connection.removeEventListener(
        JitsiConnectionEvents.CONNECTION_ESTABLISHED,
        handleConnectionEstablished,
      );
      connection.removeEventListener(
        JitsiConnectionEvents.CONNECTION_FAILED,
        handleConnectionFailed,
      );
    }

    function handleConnectionEstablished() {
      unsubscribe();
      console.log('CONNECTION ESTABLISHED:', connection);
      resolve(connection);
    }

    function handleConnectionFailed(err) {
      unsubscribe();
      console.error('CONNECTION FAILED:', err);
      reject(err);
    }
  });
}
export function isFatalJitsiConnectionError(error) {
  if (typeof error !== 'string') {
    error = error.name;
  }

  return (
    error === JitsiConnectionErrors.CONNECTION_DROPPED_ERROR ||
    error === JitsiConnectionErrors.OTHER_ERROR ||
    error === JitsiConnectionErrors.SERVER_ERROR
  );
}
export function addTrackToRoom(track) {
  return new Promise((resolve, reject) => {
    if (room != null) {
      room
        .addTrack(track)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    } else {
      reject();
    }
  });
}
export async function joinRoom() {
  return new Promise((resolve, reject) => {
    // window.APP.conference._room = room;
    let cnt = 0;
    const conIntvl = setInterval(() => {
      if (room) {
        clearInterval(conIntvl);
        const {localAudioTrack, localVideoTrack, noiseSuppression} =
          store.getState().conf;
        console.log('joinRoom', localAudioTrack, localVideoTrack);
        if (room != null && localAudioTrack) {
          room.addTrack(localAudioTrack.jitsiTrack);
          if (noiseSuppression) {
            setNoiseSuppressionEnabled(true);
          }
        }
        if (room != null && localVideoTrack) {
          room.addTrack(localVideoTrack.jitsiTrack);
        }
        roomevents();
        room.join();
        window.room = room;
        let count = 0;
        const intvl = setInterval(() => {
          if (room.isJoined()) {
            console.log('room joined', room.isJoined());
            clearInterval(intvl);
            resolve(room.myUserId());
          } else if (count === 3) {
            reject();
          }
          count++;
        }, 1000);
      } else if (cnt === 3) {
        reject();
      }
      cnt++;
    }, 1000);
  });
  // return new Promise(resolve => {
  //   resolve(room.myUserId());
  // });
}

const roomevents = () => {
  const {localUser} = store.getState().conf;
  const {name} = localUser;
  room.on(JitsiConferenceEvents.TRACK_ADDED, track => {
    if (!track || track.isLocal()) {
      return;
    }
    dispatch(trackAdd(track));
    track.on(JitsiTrackEvents.TRACK_MUTE_CHANGED, t => {
      dispatch(trackMuteUpdate(t));
    });
    track.on(JitsiTrackEvents.TRACK_VIDEOTYPE_CHANGED, _type =>
      dispatch(trackMediaTypeUpdate(track)),
    );
  });
  room.on(JitsiConferenceEvents.TRACK_REMOVED, track => {
    dispatch(trackRemove(track));
  });
  room.on(JitsiConferenceEvents.CONFERENCE_JOINED, () => {});
  room.on(JitsiConferenceEvents.CONFERENCE_FAILED, onConferenceFailed);
  room.on(JitsiConferenceEvents.USER_JOINED, (_id, _user) => {
    // dispatch(partcipantAdd(id));
  });
  room.on(JitsiConferenceEvents.USER_LEFT, (_id, _user) => {
    // if (user.isHidden()) {
    //   return;
    // }
    // console.log(`USER ${id} LEFT:`, user);
  });
  room.on(
    JitsiConferenceEvents.PARTICIPANT_CONN_STATUS_CHANGED,
    (id, connectionStatus) => {
      //  dispatch(participantConnectionStatusChanged(id, connectionStatus));
      console.log('PARTICIPANT_CONN_STATUS_CHANGED', id, connectionStatus);
    },
  );
  room.on(JitsiConferenceEvents.CONFERENCE_LEFT, (...args) => {
    console.log('raman CONFERENCE_LEFT', args);
    dispatch(setConferenceLeft(true));
  });

  // room.on(JitsiConferenceEvents.DISPLAY_NAME_CHANGED, (userID, displayName) => {
  //   console.info(`${userID} - ${displayName}`);
  // });

  room.on(
    JitsiConferenceEvents.DOMINANT_SPEAKER_CHANGED,
    (id, _previousSpeakers) => {
      dispatch(setDominantSpeaker(id));
      updateLastSpeakerTime(id);
    },
  );

  room.setReceiverVideoConstraint('720');
  room.setDisplayName(name);
};
const onConferenceFailed = (err, ...params) => {
  console.log('CONFERENCE FAILED:', err, ...params);
  dispatch(setConferenceFailed(true));
};

// export function localVideoTrackMute() {
//   return new Promise(resolve => {
//     const localVideoTrack = store.getState().conf.localVideoTrack;
//     if (localVideoTrack) {
//       console.debug('Muting existing local video track.', localVideoTrack);
//       localVideoTrack.jitsiTrack.mute();
//       store.dispatch(updateLocalVideoTrackMute(true));
//       store.dispatch(sethasVideoEnable(false));
//       resolve({video: true});
//     }
//   });
// }
// export function localVideoTrackUnMute() {
//   return new Promise((resolve, reject) => {
//     const localVideoTrack = store.getState().conf.localVideoTrack;
//     const cameraDevice = store.getState().conf.cameraDevice;
//     const virtualBackgroundOptions =
//       store.getState().conf.virtualBackgroundOptions;
//     console.debug('Trying to create local video track.');
//     createVideoTracks(cameraDevice?.deviceId)
//       .then(track => {
//         if (Object.keys(virtualBackgroundOptions).length > 0) {
//           toggleBackgroundEffect(virtualBackgroundOptions, track[0]);
//         }
//         let trackData = {
//           track: track[0],
//           device: cameraDevice,
//         };

//         if (localVideoTrack) {
//           room
//             .replaceTrack(localVideoTrack.jitsiTrack, track[0])
//             .then(() => {
//               localVideoTrackAdd(trackData);
//               resolve({video: true});
//             })
//             .catch(error => {
//               console.error(error);
//               trackData.track.dispose();
//               resolve({video: false});
//             });
//           // store.dispatch(removeLocalVideoTrack());
//         } else {
//           room
//             .addTrack(track[0])
//             .then(() => {
//               localVideoTrackAdd(trackData);
//               resolve({video: true});
//             })
//             .catch(error => {
//               console.error(error);
//               trackData.track.dispose();
//               resolve({video: false});
//             });
//         }
//       })
//       .catch(error => {
//         console.error(error);
//         dispatch(setFailedCameraAlert(true));
//         resolve({video: false});
//       });
//   });
// }
// export function localAudioTrackMute() {
//   const localAudioTrack = store.getState().conf.localAudioTrack;

//   return new Promise(resolve => {
//     if (localAudioTrack && !localAudioTrack?.jitsiTrack?.isMuted()) {
//       console.debug('Muting existing local audio track.');
//       localAudioTrack.jitsiTrack.mute();
//       resolve({audio: true});
//     } else {
//       resolve({audio: false});
//     }
//   });
// }
// export function localAudioTrackUnMute() {
//   return new Promise(resolve => {
//     const micDevice = store.getState().conf.micDevice;
//     const localAudioTrack = store.getState().conf.localAudioTrack;
//     if (localAudioTrack?.jitsiTrack) {
//       console.debug('Unmuting existing local audio track.', localAudioTrack);
//       localAudioTrack.jitsiTrack.unmute();
//       resolve({audio: true});
//     } else {
//       console.debug('Trying to create local audio track.');

//       createAudioTracks(micDevice?.deviceId)
//         .then(async track => {
//           const trackData = {
//             track: track[0],
//             device: micDevice,
//           };
//           // store.dispatch(micDevice);
//           store.dispatch(addLocalAudioTrack(trackData));
//           if (localDesktopAudioTrack !== null) {
//             const localAudio = trackData.track;
//             room.replaceTrack(localDesktopAudioTrack, trackData.track);
//             let mixerEffect = new AudioMixerEffect(localDesktopAudioTrack);
//             console.debug(
//               `_switchToScreenSharing is mixing ${localDesktopAudioTrack} and ${localAudio}` +
//                 ' as a single audio stream',
//             );
//             await localAudio.setEffect(mixerEffect);
//             resolve({audio: true});
//           } else {
//             room.addTrack(track[0]);
//           }
//           resolve({audio: true});
//         })
//         .catch(error => {
//           console.error(error);
//           resolve({audio: false});
//         });
//     }
//   });
// }
export function micDeviceChange(micDevice) {
  const {localAudioTrack, localUser, hasAudioEnable} = store.getState().conf;
  if (micDevice) {
    const {audioInputList} = store.getState().conf;
    const {persistmicDevice} = store.getState().persist;
    let tmpmicDevice = micDevice;
    console.log('test', localAudioTrack);
    if (
      JSON.stringify(tmpmicDevice) !== JSON.stringify(localAudioTrack?.device)
    ) {
      if (persistmicDevice) {
        const device = audioInputList.filter(
          d =>
            d.deviceId === persistmicDevice.deviceId &&
            d.label === persistmicDevice.label,
        );
        if (device.length > 0) {
          tmpmicDevice = persistmicDevice;
        }
      }
      console.log('Audio track changed', hasAudioEnable, tmpmicDevice?.label);
      createAudioTracks(tmpmicDevice.deviceId)
        .then(track => {
          dispatch(setHasAudioPermission(true));
          const tmplocalAudioTrack = {
            track: track[0],
            device: tmpmicDevice,
          };
          replaceLocalAudioTrack(tmplocalAudioTrack).then(() => {
            const tmpUser = {...localUser};
            if (!hasAudioEnable) {
              localAudioTrackMute().then(res => {
                if (res.audio) {
                  tmpUser.audio = false;
                  dispatch(setUserDetail(tmpUser));
                  updParticipant(tmpUser);
                } else {
                  tmpUser.audio = true;
                  dispatch(setUserDetail(tmpUser));
                  updParticipant(tmpUser);
                }
              });
            } else {
              tmpUser.audio = true;
              dispatch(setUserDetail(tmpUser));
              updParticipant(tmpUser);
            }
          });
        })
        .catch(error => {
          console.error(error);
          dispatch(setMicDevice(tmpmicDevice));
          dispatch(setHasAudioPermission(true));
        });
    } else {
      console.log('Audio track not changed');
    }
  } else {
    dispatch(setHasVideoPermission(false));
    removeLocalAudioTrack();
    if (localUser?.audio) {
      dispatch(sethasAudioEnable(true));
    } else {
      dispatch(sethasAudioEnable(false));
    }
    const tmpUser = {...localUser};
    tmpUser.audio = false;
    dispatch(setUserDetail(tmpUser));
    updParticipant(tmpUser);
  }
}
export function cameraDeviceChange(cameraDevice) {
  const {localUser, hasVideoEnable, localVideoTrack} = store.getState().conf;
  console.log('cameraDeviceChange has changed', cameraDevice);
  if (cameraDevice) {
    const {persistcameraDevice} = store.getState().persist;

    console.log('raman hasVideoEnable', hasVideoEnable);

    if (persistcameraDevice?.deviceId !== cameraDevice?.deviceId) {
      if (hasVideoEnable) {
        startVideo(cameraDevice);
      } else {
        console.log('Video not enabled');
      }
    } else {
      if (
        hasVideoEnable &&
        (!localUser.video ||
          localVideoTrack?.device?.deviceId !== cameraDevice?.deviceId)
      ) {
        startVideo(cameraDevice);
      } else {
        console.log('Video not enabled');
      }
    }
  } else {
    console.log('Camera Device Null');
  }
}
export function startVideo(cameraDevice) {
  const {localVideoTrack, virtualBackgroundOptions, localUser} =
    store.getState().conf;
  console.log('raman startVideo', localVideoTrack);
  createVideoTracks(cameraDevice.deviceId)
    .then(track => {
      const trackData = {
        track: track[0],
        device: cameraDevice,
      };
      if (Object.keys(virtualBackgroundOptions).length > 0) {
        toggleBackgroundEffect(virtualBackgroundOptions, track[0]);
      }
      if (localVideoTrack) {
        replaceLocalVideoTrack(
          localVideoTrack?.jitsiTrack,
          trackData.track,
          localVideoTrack?.jitsiTrack,
        ).then(() => {
          localVideoTrackAdd(trackData);
          const tmpUser = {...localUser};
          tmpUser.video = true;
          dispatch(setUserDetail(tmpUser));
          updParticipant(tmpUser);
        });
      } else {
        trackData.track.dispose();
      }
      console.log('Video track changed');

      dispatch(setHasVideoPermission(true));
    })
    .catch(error => {
      console.error(error);
      dispatch(setHasVideoPermission(true));
      // dispatch(sethasVideoEnable(false));
    });
}
export function speakerDeviceChange(speakerDevice) {
  if (speakerDevice) {
    setAudioOutputDevice(speakerDevice.deviceId);
  }
}
export async function createDesktopTrack() {
  let options = {
    devices: ['desktop'],
    firePermissionPromptIsShownEvent: true,
    fireSlowPromiseEvent: true,
  };
  return JitsiMeetJS.createLocalTracks(options).catch(error => {
    return Promise.reject(error);
  });
}
export async function localVideoTrackAdd(localTrack) {
  const track = localTrack?.track;
  if (track) {
    track.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED, () => {
      console.log('LOCAL_TRACK_STOPPED camera', track);
      const {localUser} = store.getState().conf;
      // dispatch(setHasVideoPermission(false));
      if (localUser?.video) {
        dispatch(sethasVideoEnable(true));
      } else {
        dispatch(sethasVideoEnable(false));
      }
      const tmpUser = {...localUser};
      tmpUser.video = false;
      dispatch(setUserDetail(tmpUser));
      updParticipant(tmpUser);
    });
  }
  dispatch(addLocalVideoTrack(localTrack));
}
export const startscreenshare = async () => {
  return new Promise(resolve => {
    createDesktopTrack()
      .then(async desktoptrack => {
        console.debug('desktoptrack=>', desktoptrack);
        const desktopVideoStream = desktoptrack.find(
          stream => stream.getType() === MEDIA_TYPE.VIDEO,
        );
        const desktopAudioStream = desktoptrack.find(
          stream => stream.getType() === MEDIA_TYPE.AUDIO,
        );

        console.log('Screen share desktopVideoStream', desktopVideoStream);
        console.log('Screen share desktopAudioStream', desktopAudioStream);
        addDesktopVideoTrack(desktopVideoStream);
        addDesktopAudioTrack(desktopAudioStream);
        resolve({screenShare: true});
      })
      .catch(error => {
        console.error('screen share start error', error);
        resolve({screenShare: false});
      });
  });
};
const addDesktopAudioTrack = async desktopAudioStream => {
  if (desktopAudioStream) {
    const localAudioTrack = store.getState().conf.localAudioTrack;

    desktopAudioStream.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED, async () => {
      const localAudioTrack = store.getState().conf.localAudioTrack;

      console.debug(
        'Local screensharing audio track stopped. JitsiTrackEvents',
      );
      if (localAudioTrack !== null) {
        await localAudioTrack?.jitsiTrack?.setEffect(undefined);
      } else if (localDesktopAudioTrack !== null) {
        await room.replaceTrack(localDesktopAudioTrack, null);
      }
      if (localDesktopAudioTrack) {
        await localDesktopAudioTrack.dispose();
      }
      localDesktopAudioTrack = null;
    });
    const localAudio = localAudioTrack?.jitsiTrack;
    if (localAudio) {
      await setNoiseSuppressionEnabled(false);
      let mixerEffect = new AudioMixerEffect(desktopAudioStream);
      console.debug(
        `_switchToScreenSharing is mixing ${desktopAudioStream} and ${localAudio}` +
          ' as a single audio stream',
      );
      await localAudio.setEffect(mixerEffect);
    } else {
      console.debug(
        `_switchToScreenSharing is using ${desktopAudioStream} for replacing it as` +
          ' the only audio track on the conference',
      );
      await room.replaceTrack(null, desktopAudioStream);
    }
    localDesktopAudioTrack = desktopAudioStream;
  }
};
const addDesktopVideoTrack = desktopVideoStream => {
  const localDesktopVideoTrack = store.getState().conf.localDesktopVideoTrack;

  dispatch(addLocalDesktopVideoTrack(desktopVideoStream));
  desktopVideoStream.on(JitsiTrackEvents.LOCAL_TRACK_STOPPED, () => {
    console.debug('Local screensharing track stopped. JitsiTrackEvents');
    stopscreenshare();
  });
  if (localDesktopVideoTrack !== null) {
    room.replaceTrack(localDesktopVideoTrack.jitsiTrack, desktopVideoStream);
  } else {
    room.addTrack(desktopVideoStream);
  }
};
export const stopscreenshare = async () => {
  return new Promise(async resolve => {
    const localDesktopVideoTrack = store.getState().conf.localDesktopVideoTrack;
    const localAudioTrack = store.getState().conf.localAudioTrack;

    const noiseSuppression = store.getState().conf.noiseSuppression;
    console.log('Local screensharing track stopped. ', localDesktopVideoTrack);
    if (localDesktopVideoTrack !== null) {
      localDesktopVideoTrack.jitsiTrack.mute();
      dispatch(muteLocalDesktopVideoTrack());
      const tmpUser = {...store.getState().conf.localUser};
      tmpUser.screenShare = false;
      updParticipant(tmpUser);
      dispatch(setUserDetail(tmpUser));
    }

    if (localAudioTrack) {
      await localAudioTrack?.jitsiTrack?.setEffect(undefined);
      if (localDesktopAudioTrack) {
        await localDesktopAudioTrack.dispose();
      }
      localDesktopAudioTrack = null;
      if (noiseSuppression) {
        await setNoiseSuppressionEnabled(true);
      }
    } else if (localDesktopAudioTrack !== null) {
      await room.replaceTrack(localDesktopAudioTrack, null);
      if (localDesktopAudioTrack) {
        await localDesktopAudioTrack.dispose();
      }
      localDesktopAudioTrack = null;
    }
    resolve();
  });
};

export async function replaceLocalAudioTrack(newtrack) {
  return new Promise(async resolve => {
    const noiseSuppression = store.getState().conf.noiseSuppression;
    const localAudioTrack = store.getState().conf.localAudioTrack;
    console.log(localAudioTrack, newtrack);
    if (localAudioTrack?.jitsiTrack) {
      if (localDesktopAudioTrack) {
        await localAudioTrack.jitsiTrack.setEffect(undefined);
      } else {
        await setNoiseSuppressionEnabled(false);
      }
      room
        .replaceTrack(localAudioTrack.jitsiTrack, newtrack.track)
        .then(async () => {
          const trackData = {
            track: newtrack.track,
            device: newtrack.device,
          };
          dispatch(addLocalAudioTrack(trackData));
          if (localDesktopAudioTrack !== null) {
            let mixerEffect = new AudioMixerEffect(localDesktopAudioTrack);
            await trackData.track.setEffect(mixerEffect);
          } else {
            if (noiseSuppression) {
              await setNoiseSuppressionEnabled(true);
            }
          }
          resolve();
        });
    } else {
      if (localDesktopAudioTrack) {
        room
          .replaceTrack(localDesktopAudioTrack, newtrack.track)
          .then(async () => {
            const trackData = {
              track: newtrack.track,
              device: newtrack.device,
            };
            dispatch(addLocalAudioTrack(trackData));
            let mixerEffect = new AudioMixerEffect(localDesktopAudioTrack);
            await trackData.track.setEffect(mixerEffect);

            resolve();
          });
      } else {
        room.addTrack(newtrack.track);
        const trackData = {
          track: newtrack.track,
          device: newtrack.device,
        };
        dispatch(addLocalAudioTrack(trackData));
        if (localDesktopAudioTrack) {
          let mixerEffect = new AudioMixerEffect(localDesktopAudioTrack);
          await trackData.track.setEffect(mixerEffect);
        } else {
          if (noiseSuppression) {
            await setNoiseSuppressionEnabled(true);
          }
        }
        resolve();
      }
    }
  });
}
export async function removeLocalAudioTrack() {
  return new Promise(async resolve => {
    const localAudioTrack = store.getState().conf.localAudioTrack;

    let tmplocaltrack = localAudioTrack?.jitsiTrack;
    console.log(localAudioTrack);

    const trackData = {
      track: null,
      device: null,
    };
    if (tmplocaltrack) {
      room.removeTrack(tmplocaltrack).then(() => {
        dispatch(addLocalAudioTrack(trackData));
        console.log('local Audio track remove');
      });
    }
    resolve();
  });
}
export async function replaceLocalVideoTrack(oldtrack, newtrack) {
  return new Promise(resolve => {
    room.replaceTrack(oldtrack, newtrack).then(() => {
      resolve();
    });
  });
}
export async function removeLocalVideoTrack(track) {
  return new Promise(resolve => {
    console.log('room.getLocalTracks() before', room.getLocalTracks());
    room.removeTrack(track).then(() => {
      console.log('room.getLocalTracks() after', room.getLocalTracks());

      resolve();
    });
  });
}
export const changeTrackResolution = async participantId => {
  // console.log('receiverConstraints', participantId);
  if (participantId.length > 0) {
    let height = '180';
    const length = participantId.length;
    if (length <= 4) {
      height = '2160';
    }
    const receiverConstraints = {
      constraints: {},
      defaultConstraints: {maxHeight: 0},
      lastN: config.channelLastN,
      onStageSources: [],
      selectedSources: [],
    };

    participantId.forEach(p => {
      receiverConstraints.constraints[p] = {maxHeight: height};
    });
    receiverConstraints.onStageSources = participantId;
    receiverConstraints.selectedSources = participantId;

    // console.log('receiverConstraints', JSON.stringify(receiverConstraints));
    try {
      room.setReceiverConstraints(receiverConstraints);
    } catch (error) {
      console.error(
        error,
        `Failed to set receiver video constraints ${JSON.stringify(
          receiverConstraints,
        )}`,
      );
    }
  }
};
export const changeTrackResolutionActiveSpeaker = async participantId => {
  console.log('receiverConstraints', participantId);
  if (participantId.length > 0) {
    const receiverConstraints = {
      constraints: {},
      defaultConstraints: {maxHeight: 0},
      lastN: config.channelLastN,
      onStageSources: [],
      selectedSources: [],
    };
    let i = 0;
    participantId.forEach(p => {
      if (i === 0) {
        receiverConstraints.constraints[p] = {maxHeight: '2160'};
        i++;
      } else {
        receiverConstraints.constraints[p] = {maxHeight: '180'};
      }
    });
    receiverConstraints.onStageSources = participantId;
    receiverConstraints.selectedSources = participantId;

    console.log('receiverConstraints', JSON.stringify(receiverConstraints));
    try {
      room.setReceiverConstraints(receiverConstraints);
    } catch (error) {
      console.error(
        error,
        `Failed to set receiver video constraints ${JSON.stringify(
          receiverConstraints,
        )}`,
      );
    }
  }
};

export const disposeConference = () => {
  return new Promise(resolve => {
    const lemMode = isLemMode();
    if (!lemMode) {
      const tmpRoom = room;
      room = null;
      dispatch(setConferenceFailed(false));
      dispatch(setConferenceLeft(false));
      dispatch(setKickedOutRequest(false));
      dispatch(SetLocalParticipantKicked(true));
      if (tmpRoom) {
        socketDisconnect();
        tmpRoom
          .leave()
          .then(() => {
            dispatch(disposeLocalTracks());
            if (localDesktopAudioTrack) {
              localDesktopAudioTrack.dispose();
            }
            jitsiConnection.disconnect();
            jitsiConnection = null;
            room = null;
            resolve();
          })
          .catch(() => {
            dispatch(disposeLocalTracks());
            jitsiConnection.disconnect();
            jitsiConnection = null;
            room = null;
            resolve();
          });
      } else {
        resolve('error');
      }
    } else {
      dispatch(setConferenceFailed(false));
      dispatch(setConferenceLeft(false));
      dispatch(setKickedOutRequest(false));
      dispatch(SetLocalParticipantKicked(true));
      socketDisconnect();
      resolve();
    }
  });
};

export const disconnectConference = () => {
  return new Promise(resolve => {
    const tmpRoom = room;
    room = null;

    if (tmpRoom) {
      console.log(
        '🚀 ~ file: index.js:1262 ~ disconnectConference ~ tmpRoom:',
        tmpRoom.isJoined(),
      );
      if (tmpRoom.isJoined()) {
        tmpRoom
          .leave()
          .then(() => {
            console.log(
              '🚀 ~ file: index.js:1262 ~ disconnectConference ~ tmpRoom: leave',
            );

            dispatch(disposeLocalTracks(false));
            if (localDesktopAudioTrack) {
              localDesktopAudioTrack.dispose();
            }
            jitsiConnection.disconnect();
            jitsiConnection = null;
            room = null;
            resolve();
          })
          .catch(() => {
            console.log(
              '🚀 ~ file: index.js:1262 ~ disconnectConference ~ tmpRoom: catch',
            );

            dispatch(disposeLocalTracks(false));
            jitsiConnection.disconnect();
            jitsiConnection = null;
            room = null;
            resolve();
          });
      } else {
        dispatch(disposeLocalTracks(false));
        jitsiConnection.disconnect();
        jitsiConnection = null;
        room = null;
        resolve();
      }
    } else {
      resolve('error');
    }
  });
};
export async function toggleBackgroundEffect(options, jitsiTrack) {
  if (jitsiTrack) {
    try {
      if (options.backgroundEffectEnabled) {
        await jitsiTrack.setEffect(
          await createVirtualBackgroundEffect(options),
        );
      } else {
        await jitsiTrack.setEffect(undefined);
      }
    } catch (error) {
      console.error('Error on apply background effect:', error);
    }
  }
}

export async function setNoiseSuppressionEnabled(enabled) {
  const localAudio = store.getState().conf.localAudioTrack?.jitsiTrack;
  const noiseSuppressionEnabled =
    store.getState().conf.isNoiseSuppressionEnabled;

  console.log(`Attempting to set noise suppression enabled state: ${enabled}`);

  try {
    if (enabled && !noiseSuppressionEnabled) {
      if (!canEnableNoiseSuppression(localAudio)) {
        return;
      }

      await localAudio.setEffect(new NoiseSuppressionEffect());
      dispatch(setNoiseSuppressionEnabledState(true));
      console.log('Noise suppression enabled.');
    } else if (!enabled && noiseSuppressionEnabled) {
      await localAudio.setEffect(undefined);
      dispatch(setNoiseSuppressionEnabledState(false));
      console.log('Noise suppression disabled.');
    } else {
      console.log(`Noise suppression enabled state already: ${enabled}`);
    }
  } catch (error) {
    console.error(
      `Failed to set noise suppression enabled to: ${enabled}`,
      error,
    );
  }
}

export function canEnableNoiseSuppression(localAudio) {
  if (!localAudio) {
    console.log('Noise suppression localAudioTrack not found.');
    return false;
  }

  const {channelCount} = localAudio.track.getSettings();
  if (localDesktopAudioTrack) {
    console.log('Cannot enable Noise suppression desktop audio is shared');
    return false;
  }
  if (channelCount > 1) {
    console.log(
      'Noise suppression Stereo audio tracks are not currently supported',
    );
    return false;
  }

  return true;
}
export function shuffleArray(array) {
  let curId = array.length;
  // There remain elements to shuffle
  while (curId !== 0) {
    // Pick a remaining element
    let randId = Math.floor(Math.random() * curId);
    curId -= 1;
    // Swap it with the current element.
    let tmp = array[curId];
    array[curId] = array[randId];
    array[randId] = tmp;
  }
  return array;
}

export class AudioMixerEffect {
  constructor(mixAudio) {
    if (mixAudio.getType() !== MEDIA_TYPE.AUDIO) {
      throw new Error(
        'AudioMixerEffect only supports audio JitsiLocalTracks; effect will not work!',
      );
    }

    this._mixAudio = mixAudio;
  }

  isEnabled(sourceLocalTrack) {
    return sourceLocalTrack.isAudioTrack() && this._mixAudio.isAudioTrack();
  }

  startEffect(audioStream) {
    this._originalStream = audioStream;
    this._originalTrack = audioStream.getTracks()[0];

    this._audioMixer = JitsiMeetJS.createAudioMixer();
    this._audioMixer.addMediaStream(this._mixAudio.getOriginalStream());
    this._audioMixer.addMediaStream(this._originalStream);

    this._mixedMediaStream = this._audioMixer.start();
    this._mixedMediaTrack = this._mixedMediaStream.getTracks()[0];

    return this._mixedMediaStream;
  }

  stopEffect() {
    this._audioMixer.reset();
  }

  setMuted(muted) {
    this._originalTrack.enabled = !muted;
  }

  isMuted() {
    return !this._originalTrack.enabled;
  }
}

export class NoiseSuppressionEffect {
  startEffect(audioStream) {
    this._originalMediaTrack = audioStream.getAudioTracks()[0];
    this._audioContext = new AudioContext();
    this._audioSource = this._audioContext.createMediaStreamSource(audioStream);
    this._audioDestination = this._audioContext.createMediaStreamDestination();
    this._outputMediaTrack = this._audioDestination.stream.getAudioTracks()[0];

    const baseUrl = `${window.location.origin}/assets/libs/`;
    const workletUrl = `${baseUrl}noise-suppressor-worklet.min.js`;

    // Connect the audio processing graph MediaStream -> AudioWorkletNode -> MediaStreamAudioDestinationNode
    this._audioContext.audioWorklet
      .addModule(workletUrl)
      .then(() => {
        // After the resolution of module loading, an AudioWorkletNode can be constructed.
        this._noiseSuppressorNode = new AudioWorkletNode(
          this._audioContext,
          'NoiseSuppressorWorklet',
        );
        this._audioSource
          .connect(this._noiseSuppressorNode)
          .connect(this._audioDestination);
      })
      .catch(error => {
        console.error('Error while adding audio worklet module: ', error);
      });

    // Sync the effect track muted state with the original track state.
    this._outputMediaTrack.enabled = this._originalMediaTrack.enabled;

    // We enable the audio on the original track because mute/unmute action will only affect the audio destination
    // output track from this point on.
    this._originalMediaTrack.enabled = true;

    return this._audioDestination.stream;
  }

  /**
   * Checks if the JitsiLocalTrack supports this effect.
   *
   * @param {JitsiLocalTrack} sourceLocalTrack - Track to which the effect will be applied.
   * @returns {boolean} - Returns true if this effect can run on the specified track, false otherwise.
   */
  isEnabled(sourceLocalTrack) {
    // JitsiLocalTracks needs to be an audio track.
    return sourceLocalTrack.isAudioTrack();
  }

  /**
   * Clean up resources acquired by noise suppressor and rnnoise processor.
   *
   * @returns {void}
   */
  stopEffect() {
    // Sync original track muted state with effect state before removing the effect.
    this._originalMediaTrack.enabled = this._outputMediaTrack.enabled;

    // Technically after this process the Audio Worklet along with it's resources should be garbage collected,
    // however on chrome there seems to be a problem as described here:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1298955
    this._noiseSuppressorNode?.port?.close();
    this._audioDestination?.disconnect();
    this._noiseSuppressorNode?.disconnect();
    this._audioSource?.disconnect();
    this._audioContext?.close();
  }
}
const models = {
  modelLandscape: 'assets/libs/selfie_segmentation_landscape.tflite',
};

let modelBuffer;
let tflite;
let wasmCheck;
let isWasmDisabled = false;

const segmentationDimensions = {
  modelLandscape: {
    height: 144,
    width: 256,
  },
};

export function timeout(milliseconds, promise) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('408'));

      return;
    }, milliseconds);

    promise.then(resolve, reject);
  });
}
export async function createVirtualBackgroundEffect(virtualBackground) {
  if (
    !MediaStreamTrack.prototype.getSettings &&
    !MediaStreamTrack.prototype.getConstraints
  ) {
    throw new Error('JitsiStreamBackgroundEffect not supported!');
  }

  if (isWasmDisabled) {
    console.error('virtualBackground.backgroundEffectError WasmDisabled');
    return;
  }

  // Checks if WebAssembly feature is supported or enabled by/in the browser.
  // Conditional import of wasm-check package is done to prevent
  // the browser from crashing when the user opens the app.

  if (!tflite) {
    try {
      wasmCheck = require('wasm-check');
      const tfliteTimeout = 10000;

      if (wasmCheck?.feature?.simd) {
        tflite = await timeout(tfliteTimeout, createTFLiteSIMDModule());
      } else {
        tflite = await timeout(tfliteTimeout, createTFLiteModule());
      }
    } catch (err) {
      if (err?.message === '408') {
        console.error(
          'Failed to download tflite model! virtualBackground.backgroundEffectError',
        );
      } else {
        isWasmDisabled = true;
        console.error(
          'Looks like WebAssembly is disabled or not supported on this browser',
          err,
        );
      }

      return;
    }
  }

  if (!modelBuffer) {
    const modelResponse = await fetch(models.modelLandscape);

    if (!modelResponse.ok) {
      throw new Error('Failed to download tflite model!');
    }

    modelBuffer = await modelResponse.arrayBuffer();

    tflite.HEAPU8.set(
      new Uint8Array(modelBuffer),
      tflite._getModelBufferMemoryOffset(),
    );

    tflite._loadModel(modelBuffer.byteLength);
  }

  const options = {
    ...segmentationDimensions.modelLandscape,
    virtualBackground,
  };

  return new JitsiStreamBackgroundEffect(tflite, options);
}
export const SET_TIMEOUT = 1;
export const CLEAR_TIMEOUT = 2;
export const TIMEOUT_TICK = 3;
const code = `
    var timer;

    onmessage = function(request) {
        switch (request.data.id) {
        case ${SET_TIMEOUT}: {
            timer = setTimeout(() => {
                postMessage({ id: ${TIMEOUT_TICK} });
            }, request.data.timeMs);
            break;
        }
        case ${CLEAR_TIMEOUT}: {
            if (timer) {
                clearTimeout(timer);
            }
            break;
        }
        }
    };
`;
export const timerWorkerScript = URL.createObjectURL(
  new Blob([code], {type: 'application/javascript'}),
);
export const VIRTUAL_BACKGROUND_TYPE = {
  IMAGE: 'image',
  BLUR: 'blur',
  NONE: 'none',
};
export class JitsiStreamBackgroundEffect {
  /**
   * Represents a modified video MediaStream track.
   *
   * @class
   * @param {Object} model - Meet model.
   * @param {Object} options - Segmentation dimensions.
   */
  constructor(model, options) {
    this._options = options;

    if (
      this._options.virtualBackground.backgroundType ===
      VIRTUAL_BACKGROUND_TYPE.IMAGE
    ) {
      this._virtualImage = document.createElement('img');
      this._virtualImage.crossOrigin = 'anonymous';
      this._virtualImage.src =
        this._options.virtualBackground.virtualSource ?? '';
    }
    this._model = model;
    this._segmentationPixelCount = this._options.width * this._options.height;

    // Bind event handler so it is only bound once for every instance.
    this._onMaskFrameTimer = this._onMaskFrameTimer.bind(this);

    // Workaround for FF issue https://bugzilla.mozilla.org/show_bug.cgi?id=1388974
    this._outputCanvasElement = document.createElement('canvas');
    this._outputCanvasElement.getContext('2d');
    this._inputVideoElement = document.createElement('video');
  }

  /**
   * EventHandler onmessage for the maskFrameTimerWorker WebWorker.
   *
   * @private
   * @param {EventHandler} response - The onmessage EventHandler parameter.
   * @returns {void}
   */
  _onMaskFrameTimer(response) {
    if (response.data.id === TIMEOUT_TICK) {
      this._renderMask();
    }
  }

  /**
   * Represents the run post processing.
   *
   * @returns {void}
   */
  runPostProcessing() {
    const track = this._stream.getVideoTracks()[0];
    const {height, width} = track.getSettings() ?? track.getConstraints();
    const {backgroundType} = this._options.virtualBackground;

    if (!this._outputCanvasCtx) {
      return;
    }

    this._outputCanvasElement.height = height;
    this._outputCanvasElement.width = width;
    this._outputCanvasCtx.globalCompositeOperation = 'copy';

    // Draw segmentation mask.

    // Smooth out the edges.
    this._outputCanvasCtx.filter =
      backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE
        ? 'blur(4px)'
        : 'blur(8px)';
    this._outputCanvasCtx?.drawImage(
      // @ts-ignore
      this._segmentationMaskCanvas,
      0,
      0,
      this._options.width,
      this._options.height,
      0,
      0,
      this._inputVideoElement.width,
      this._inputVideoElement.height,
    );
    this._outputCanvasCtx.globalCompositeOperation = 'source-in';
    this._outputCanvasCtx.filter = 'none';

    // Draw the foreground video.
    // @ts-ignore
    this._outputCanvasCtx?.drawImage(this._inputVideoElement, 0, 0);

    // Draw the background.
    this._outputCanvasCtx.globalCompositeOperation = 'destination-over';
    if (backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE) {
      this._outputCanvasCtx?.drawImage(
        // @ts-ignore
        backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE
          ? this._virtualImage
          : this._virtualVideo,
        0,
        0,
        this._outputCanvasElement.width,
        this._outputCanvasElement.height,
      );
    } else {
      this._outputCanvasCtx.filter = `blur(${this._options.virtualBackground.blurValue}px)`;

      // @ts-ignore
      this._outputCanvasCtx?.drawImage(this._inputVideoElement, 0, 0);
    }
  }

  /**
   * Represents the run Tensorflow Interference.
   *
   * @returns {void}
   */
  runInference() {
    this._model._runInference();
    const outputMemoryOffset = this._model._getOutputMemoryOffset() / 4;

    for (let i = 0; i < this._segmentationPixelCount; i++) {
      const person = this._model.HEAPF32[outputMemoryOffset + i];

      // Sets only the alpha component of each pixel.
      this._segmentationMask.data[i * 4 + 3] = 255 * person;
    }
    this._segmentationMaskCtx?.putImageData(this._segmentationMask, 0, 0);
  }

  /**
   * Loop function to render the background mask.
   *
   * @private
   * @returns {void}
   */
  _renderMask() {
    this.resizeSource();
    this.runInference();
    this.runPostProcessing();

    this._maskFrameTimerWorker.postMessage({
      id: SET_TIMEOUT,
      timeMs: 1000 / 30,
    });
  }

  /**
   * Represents the resize source process.
   *
   * @returns {void}
   */
  resizeSource() {
    this._segmentationMaskCtx?.drawImage(
      // @ts-ignore
      this._inputVideoElement,
      0,
      0,
      this._inputVideoElement.width,
      this._inputVideoElement.height,
      0,
      0,
      this._options.width,
      this._options.height,
    );

    const imageData = this._segmentationMaskCtx?.getImageData(
      0,
      0,
      this._options.width,
      this._options.height,
    );
    const inputMemoryOffset = this._model._getInputMemoryOffset() / 4;

    for (let i = 0; i < this._segmentationPixelCount; i++) {
      this._model.HEAPF32[inputMemoryOffset + i * 3] =
        Number(imageData?.data[i * 4]) / 255;
      this._model.HEAPF32[inputMemoryOffset + i * 3 + 1] =
        Number(imageData?.data[i * 4 + 1]) / 255;
      this._model.HEAPF32[inputMemoryOffset + i * 3 + 2] =
        Number(imageData?.data[i * 4 + 2]) / 255;
    }
  }

  /**
   * Checks if the local track supports this effect.
   *
   * @param {JitsiLocalTrack} jitsiLocalTrack - Track to apply effect.
   * @returns {boolean} - Returns true if this effect can run on the specified track
   * false otherwise.
   */
  isEnabled(jitsiLocalTrack) {
    return (
      jitsiLocalTrack.isVideoTrack() && jitsiLocalTrack.videoType === 'camera'
    );
  }

  /**
   * Starts loop to capture video frame and render the segmentation mask.
   *
   * @param {MediaStream} stream - Stream to be used for processing.
   * @returns {MediaStream} - The stream with the applied effect.
   */
  startEffect(stream) {
    this._stream = stream;
    this._maskFrameTimerWorker = new Worker(timerWorkerScript, {
      name: 'Blur effect worker',
    });
    this._maskFrameTimerWorker.onmessage = this._onMaskFrameTimer;
    const firstVideoTrack = this._stream.getVideoTracks()[0];
    const {height, frameRate, width} = firstVideoTrack.getSettings
      ? firstVideoTrack.getSettings()
      : firstVideoTrack.getConstraints();

    this._segmentationMask = new ImageData(
      this._options.width,
      this._options.height,
    );
    this._segmentationMaskCanvas = document.createElement('canvas');
    this._segmentationMaskCanvas.width = this._options.width;
    this._segmentationMaskCanvas.height = this._options.height;
    this._segmentationMaskCtx = this._segmentationMaskCanvas.getContext('2d');

    this._outputCanvasElement.width = parseInt(width, 10);
    this._outputCanvasElement.height = parseInt(height, 10);
    this._outputCanvasCtx = this._outputCanvasElement.getContext('2d');
    this._inputVideoElement.width = parseInt(width, 10);
    this._inputVideoElement.height = parseInt(height, 10);
    this._inputVideoElement.autoplay = true;
    this._inputVideoElement.srcObject = this._stream;
    this._inputVideoElement.onloadeddata = () => {
      this._maskFrameTimerWorker.postMessage({
        id: SET_TIMEOUT,
        timeMs: 1000 / 30,
      });
    };

    return this._outputCanvasElement.captureStream(parseInt(frameRate, 10));
  }

  /**
   * Stops the capture and render loop.
   *
   * @returns {void}
   */
  stopEffect() {
    this._maskFrameTimerWorker.postMessage({
      id: CLEAR_TIMEOUT,
    });

    this._maskFrameTimerWorker.terminate();
  }
}
