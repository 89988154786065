import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CommonModal} from '../../../common/web';
import {manageUnmuteRequestPermission} from '../../../../utils/functions';

function MuteUnmutePermission() {
  const dispatch = useDispatch();
  const {muteUnmutePermission} = useSelector(state => state.conf);

  const muteUnmuteBody = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12 text-center">
            <h5 className="mb-0">
              <b>A host is asking you to unmute.</b>
            </h5>
          </div>
          <div className="col-md-12 text-center mt-4 pl-em-2">
            <button
              className="btn btn-green"
              onClick={() => manageUnmuteRequestPermission('U')}>
              Unmute
            </button>
            <button
              className="btn btn-green"
              onClick={() => manageUnmuteRequestPermission('AU')}>
              Always Unmute
            </button>
            <a
              onClick={() => manageUnmuteRequestPermission('SM')}
              className="ml-2 mr-2">
              <u>Stay Muted</u>
            </a>
          </div>
        </div>
      </>
    );
  };

  const muteUnmuteObject = {
    body: muteUnmuteBody,
    classNameModal: 'fade congrats-modal',
    show: true,
    classNameDialog: 'modal-width-sm',
    headingSize: 'h5',
    classNameBody: 'd-flex align-items-center',
  };

  return (
    <>{muteUnmutePermission === true && manageUnmuteRequestPermission('AU')}</>
  );
}

export default MuteUnmutePermission;
